import React, { Component } from "react";
import { connect } from "react-redux";
import { setIdCombinableOutbound } from "../../../../services/flights/actions";
import vueling from "../../../../static/img/airline-logos/VY.png";
import PopUpInfo from "../PoUpInfo";
import Popup from "reactjs-popup";
import { withTranslation } from "react-i18next";
import AirlineLogo from "../AirlineLogo";
import {returnCurrency} from "../../../../services/util";
import {format} from 'date-fns';

class OutBoundFlights extends Component {

    Transfer() {
        let arrival_date = +new Date(this.props.search.arrival);
        let departure_date = +new Date(this.props.search.departure);
        let days = (Math.abs(departure_date - arrival_date) / (1000 * 3600 * 24));
        return days >= 6;
    }

    render() {
        const { flight, combinable_outbound_token, selected_outbound_flight, setIdCombinableOutbound } = this.props;
        const stops = (flight.stops > 0);
        const last_stop = flight.stops;

        const free_luggage_outbound = flight.luggages_included;
        const free_transfer = this.Transfer();

        const { t } = this.props;

        return (
            <>
                {/*Vuelo de ida*/}
                <article className={"flight-card outbound" + (flight.rates[0].token == combinable_outbound_token ? " selected" : "")} >
                    <div className="combinable-header"
                        onClick={() => {
                            setIdCombinableOutbound(flight.rates[0].token);
                        }}>
                        <div>
                            <div className="circulo">
                                <span></span>
                            </div>
                        </div>
                        <p className="price">
                            {flight.rates[0].supplier === "OrbisFlight" &&
                                <span className="promoted">{t('multihotel.promoted')}&nbsp;</span>
                            }
                            <span>{(selected_outbound_flight.totalPrice < (parseInt(flight.rates[0].totalPrice)) ? "+" : "-")} {Math.abs(parseInt(flight.rates[0].totalPrice) - (parseInt(selected_outbound_flight.totalPrice))).toFixed(2)} {returnCurrency(flight.rates[0].totalPrice_currency)}</span>
                        </p>
                        <p className="seleccionado">{t('result.selected')}</p>
                    </div>
                    <header className="flight-card-header">
                        <p className="type">
                            <svg width="18" height="18" viewBox="0 0 640 512"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM80.55 341.27c6.28 6.84 15.1 10.72 24.33 10.71l130.54-.18a65.62 65.62 0 0 0 29.64-7.12l290.96-147.65c26.74-13.57 50.71-32.94 67.02-58.31 18.31-28.48 20.3-49.09 13.07-63.65-7.21-14.57-24.74-25.27-58.25-27.45-29.85-1.94-59.54 5.92-86.28 19.48l-98.51 49.99-218.7-82.06a17.799 17.799 0 0 0-18-1.11L90.62 67.29c-10.67 5.41-13.25 19.65-5.17 28.53l156.22 98.1-103.21 52.38-72.35-36.47a17.804 17.804 0 0 0-16.07.02L9.91 230.22c-10.44 5.3-13.19 19.12-5.57 28.08l76.21 82.97z"
                                    fill="currentColor" />
                            </svg>
                            <span>{t('cart.outbound')}</span>
                        </p>
                        <p className="date">{format(new Date(flight.flight_segment[0].departureDate), "dd/MM/yyyy")}</p>
                        <p className="airline">
                            <AirlineLogo airline_code={flight.flight_segment[0].airlineCode} airline_name={flight.flight_segment[0].airlineName}/>
                        </p>
                    </header>
                    <div className="flight-info">
                        <ul class="included">
                            <Popup
                                trigger={
                                    <a href="#" className="info-link">
                                        <li className="luggage" title="Equipaje facturado">
                                            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.75 4.49998H1.25C0.559635 4.49998 0 5.05962 0 5.74998V11.5833C0 12.2737 0.559635 12.8333 1.25 12.8333H1.66667V13.25C1.66667 13.4802 1.85312 13.6666 2.08333 13.6666H2.91667C3.14687 13.6666 3.33333 13.4802 3.33333 13.25V12.8333H6.66667V13.25C6.66667 13.4802 6.85312 13.6666 7.08333 13.6666H7.91667C8.14687 13.6666 8.33333 13.4802 8.33333 13.25V12.8333H8.75C9.44036 12.8333 10 12.2737 10 11.5833V5.74998C10 5.05962 9.44036 4.49998 8.75 4.49998ZM8.33333 10.125C8.33333 10.2401 8.2401 10.3333 8.125 10.3333H1.875C1.7599 10.3333 1.66667 10.2401 1.66667 10.125V9.70831C1.66667 9.59321 1.7599 9.49998 1.875 9.49998H8.125C8.2401 9.49998 8.33333 9.59321 8.33333 9.70831V10.125ZM8.33333 7.62498C8.33333 7.74008 8.2401 7.83331 8.125 7.83331H1.875C1.7599 7.83331 1.66667 7.74008 1.66667 7.62498V7.20831C1.66667 7.09321 1.7599 6.99998 1.875 6.99998H8.125C8.2401 6.99998 8.33333 7.09321 8.33333 7.20831V7.62498ZM3.75 1.58331H6.25V3.66665H7.5V1.58331C7.5 0.892948 6.94036 0.333313 6.25 0.333313H3.75C3.05964 0.333313 2.5 0.892948 2.5 1.58331V3.66665H3.75V1.58331Z" fill="currentColor" />
                                            </svg>
                                            &nbsp;
                                            <span>{t('luggage.baggage_information')}</span>
                                        </li>
                                    </a>
                                }
                                modal
                                nested
                            >{close => (
                                <PopUpInfo 
                                    close={close} 
                                    stops={flight.stops} 
                                    flight_segment={flight.flight_segment} 
                                    outbound={true} 
                                    luggages_included={flight.luggages_included}/>
                            )}
                            </Popup>
                               
                            {/* {free_transfer &&
                                <li class="taxi" title="Taxi al hotel incluido">
                                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3643 5.62606L11.7913 3.41669C11.5413 2.50002 10.708 1.83335 9.79134 1.83335H9.49967V1.00002C9.49967 0.539864 9.1265 0.166687 8.66634 0.166687H5.33301C4.87285 0.166687 4.49967 0.539864 4.49967 1.00002V1.83335H4.20801C3.29134 1.83335 2.45801 2.50002 2.20801 3.41669L1.63509 5.62606C0.89056 5.79273 0.333008 6.45497 0.333008 7.25002V8.50002C0.333008 9.11539 0.670247 9.6469 1.16634 9.93544V11C1.16634 11.4602 1.53952 11.8334 1.99967 11.8334H2.83301C3.29316 11.8334 3.66634 11.4602 3.66634 11V10.1667H10.333V11C10.333 11.4602 10.7062 11.8334 11.1663 11.8334H11.9997C12.4598 11.8334 12.833 11.4602 12.833 11V9.93544C13.3291 9.64663 13.6663 9.11512 13.6663 8.50002V7.25002C13.6663 6.45497 13.1088 5.79273 12.3643 5.62606ZM2.83301 8.50002C2.37285 8.50002 1.99967 8.12684 1.99967 7.66669C1.99967 7.20653 2.37285 6.83335 2.83301 6.83335C3.29316 6.83335 3.66634 7.20653 3.66634 7.66669C3.66634 8.12684 3.29316 8.50002 2.83301 8.50002ZM3.36816 5.58335L3.81608 3.85523C3.87415 3.64273 4.06582 3.50002 4.20827 3.50002H9.7916C9.93405 3.50002 10.1257 3.64273 10.1783 3.83492L10.6312 5.58335H3.36816ZM11.1663 8.50002C10.7062 8.50002 10.333 8.12684 10.333 7.66669C10.333 7.20653 10.7062 6.83335 11.1663 6.83335C11.6265 6.83335 11.9997 7.20653 11.9997 7.66669C11.9997 8.12684 11.6265 8.50002 11.1663 8.50002Z" fill="currentColor" />
                                    </svg>
                                </li>
                            } */}
                        </ul>
                        <div className="extra-info">
                            <Popup
                                trigger={
                                    <a href="#" className="info-link">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                fill="currentColor" />
                                        </svg>
                                    </a>
                                }
                                modal
                                nested
                            >{close => (
                                <PopUpInfo close={close} stops={flight.stops} flight_segment={flight.flight_segment} outbound={true} luggages_included={flight.luggages_included}/>
                            )}
                            </Popup>
                        </div>
                        {/*</p>*/}
                    </div>
                    <div className="flight-itinerary">
                        <div className="flight-departure">
                            <p className="flight-departure-time"><strong>{flight.flight_segment[0].departureTime}</strong></p>
                            <p className="flight-departure-airport">{flight.flight_segment[0].departureCityName} - {flight.flight_segment[0].departureAirport} ({flight.flight_segment[0].departureCode})</p>
                        </div>
                        <div className="flight-stops" style={{ display: stops ? 'inline' : 'none' }}>
                            <Popup
                                trigger={
                                    <a><strong>{flight.stops} {t("itinerary.stop")}</strong></a>
                                }
                                modal
                                nested
                            >{close => (
                                <PopUpInfo close={close} stops={flight.stops} flight_segment={flight.flight_segment} outbound={true} luggages_included={flight.luggages_included}/>
                            )}
                            </Popup>
                        </div>
                        <div className="flight-stops" style={{ display: !stops ? 'inline' : 'none' }}>
                            <strong>{t("itinerary.direct")}</strong>
                        </div>
                        <div className="flight-arrival">
                            <p className="flight-arrival-time"><strong>{flight.flight_segment[last_stop].arrivalTime}</strong></p>
                            <p className="flight-arrival-airport">{flight.flight_segment[last_stop].arrivalCityName} - {flight.flight_segment[last_stop].arrivalAirport} ({flight.flight_segment[last_stop].arrivalCode})</p>
                        </div>
                    </div>
                </article>
            </>
        );
    }
}

const mapStateToProps = state => ({
    selected_outbound_flight: state.flights.selected_flight[1],
    selected_flight_price: state.flights.selected_flight.price,
    combinable_outbound_token: state.flights.combinable_outbound_token,
    search: state.search.payload,
});

export default connect(mapStateToProps, { setIdCombinableOutbound })(withTranslation('common')(OutBoundFlights));
import React, {Component} from "react";
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {format} from 'date-fns';
import {isMobile} from "react-device-detect";
import CartMobile from "./CartMobile";
import Box from '@material-ui/core/Box';
import Collapse from "@material-ui/core/Collapse/Collapse";

class ItineraryHeader extends Component {

    state = {
        itineraryIsOpen: true,
    };

    openCart = () => {
        this.setState({isOpen: !(this.state.isOpen)});
    };

    render() {

        const {active, flight, cart, search, transfer} = this.props;
        const {t} = this.props;

        const callUs = {
            display: "grid",
            color: "var(--site-color)",
            background: "var(--site-color-light)",
            textAlign: "center",
            padding: "20px 23px",
        }

        //Funcion que calcula la diferencia en noches entre dos fechas
        const nightDiff = (dateString1, dateString2) => {
            let date1 = new Date(dateString1);
            let date2 = new Date(dateString2);
            let nightDifference = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
            return nightDifference;
        }


        return (
            <>
                <dl className="checkout-box itinerary-data">
                    <dt className="close" onClick={this.openCart}>
                        <h2 className="title">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.17334 14.0332C9.55127 14.376 10.1606 15.0088 10.146 15.5185C10.146 15.542 10.1431 15.5654 10.1431 15.5918C10.0903 16.1719 9.59228 16.5029 8.99757 16.5C8.26515 16.4971 7.82863 16.0078 7.86379 15.4512C7.89309 14.9766 8.46437 14.376 8.813 14.0567C8.88038 13.9951 8.8921 13.9776 8.97706 13.9512C9.07667 13.9512 9.09131 13.96 9.17334 14.0332ZM13.102 3.47183C12.6245 2.71891 11.9712 2.2824 11.2475 1.94549C10.5005 1.63201 9.64795 1.49432 9.00342 1.50018C7.71145 1.50018 6.91751 1.88689 6.62748 2.00701C5.05719 2.82438 4.15485 4.05483 4.1988 6.1466C4.4068 8.55477 7.04056 11.3028 8.76319 12.9961C8.813 13.043 8.89503 13.128 8.94483 13.1455L9.04151 13.1485C9.10303 13.128 9.09424 13.1338 9.14405 13.0869C10.6763 11.6456 13.2661 8.81551 13.7114 6.76769C13.9164 5.61927 13.8022 4.64369 13.102 3.47183ZM8.85108 11.8096C8.03078 10.3126 6.94095 7.98056 6.82084 6.27258C6.72123 4.88099 7.15482 2.42888 8.85108 2.26775V11.8096ZM13.0083 6.77355C12.5278 8.43759 10.7436 10.5235 9.5249 11.7627C10.3393 10.2423 11.435 7.85752 11.4936 6.14074C11.5405 4.79603 11.2065 3.04117 10.0874 2.29118C12.6889 2.82731 13.4711 5.16517 13.0083 6.77355Z"
                                    fill="currentColor"/>
                            </svg>
                            {/* TODO Traduccion */}
                            <span>{t('itinerary.your')}</span>
                        </h2>
                    </dt>
                    <Collapse in={this.state.isOpen} timeout="auto" unmountOnExit>
                        <dd className="plegable">
                            <div className="MuiCollapse-container" styles="display:none">
                                <div className="MuiCollapse-wrapper">
                                    <div className="MuiCollapse-wrapperInner">
                                        <div className="itinerary-wrapper">
                                            <div className="itinerary-step place">
                                                <div className="icon-circle">
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.2858 5.74507C12.2858 3.37899 10.3661 1.45935 8.00007 1.45935C5.634 1.45935 3.71436 3.37899 3.71436 5.74507C3.71436 7.78376 5.13995 9.49209 7.04769 9.92364V14.4355C7.04769 14.632 7.2084 14.7927 7.40483 14.7927H8.59531C8.79174 14.7927 8.95245 14.632 8.95245 14.4355V9.92364C10.8602 9.49209 12.2858 7.78376 12.2858 5.74507V5.74507ZM8.00007 8.12602C6.68757 8.12602 5.61912 7.05757 5.61912 5.74507C5.61912 4.43257 6.68757 3.36411 8.00007 3.36411C9.31257 3.36411 10.381 4.43257 10.381 5.74507C10.381 7.05757 9.31257 8.12602 8.00007 8.12602Z"
                                                            fill="currentColor"/>
                                                    </svg>
                                                </div>
                                                <div className="itinerary-step-info">
                                                    <p className="title">{flight[1].flight_segment[0].departureCityName}</p>
                                                    <p>{t('itinerary.origin')}</p>
                                                </div>
                                            </div>


                                            {/*ITINERARIO DE IDA*/}
                                            <div className="itinerary-step flight">
                                                {/* <div className="icon-taxi">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M20.0469 11.4391L19.1875 8.125C18.8125 6.75 17.5625 5.75 16.1875 5.75H15.75V4.5C15.75 3.80977 15.1902 3.25 14.5 3.25H9.5C8.80977 3.25 8.25 3.80977 8.25 4.5V5.75H7.8125C6.4375 5.75 5.1875 6.75 4.8125 8.125L3.95312 11.4391C2.83633 11.6891 2 12.6824 2 13.875V15.75C2 16.673 2.50586 17.4703 3.25 17.9031V19.5C3.25 20.1902 3.80977 20.75 4.5 20.75H5.75C6.44023 20.75 7 20.1902 7 19.5V18.25H17V19.5C17 20.1902 17.5598 20.75 18.25 20.75H19.5C20.1902 20.75 20.75 20.1902 20.75 19.5V17.9031C21.4941 17.4699 22 16.6727 22 15.75V13.875C22 12.6824 21.1637 11.6891 20.0469 11.4391ZM5.75 15.75C5.05977 15.75 4.5 15.1902 4.5 14.5C4.5 13.8098 5.05977 13.25 5.75 13.25C6.44023 13.25 7 13.8098 7 14.5C7 15.1902 6.44023 15.75 5.75 15.75ZM6.55273 11.375L7.22461 8.78281C7.31172 8.46406 7.59922 8.25 7.81289 8.25H16.1879C16.4016 8.25 16.6891 8.46406 16.768 8.75234L17.4473 11.375H6.55273V11.375ZM18.25 15.75C17.5598 15.75 17 15.1902 17 14.5C17 13.8098 17.5598 13.25 18.25 13.25C18.9402 13.25 19.5 13.8098 19.5 14.5C19.5 15.1902 18.9402 15.75 18.25 15.75Z"
                                                    fill="currentColor" />
                                            </svg>
                                        </div> */}
                                                <div className="itinerary-step-date">
                                                    <p className="date">
                                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M2.1665 13.4167C2.1665 14.1068 2.7264 14.6667 3.4165 14.6667H12.5832C13.2733 14.6667 13.8332 14.1068 13.8332 13.4167V6.33337H2.1665V13.4167ZM10.4998 8.31254C10.4998 8.14067 10.6405 8.00004 10.8123 8.00004H11.854C12.0259 8.00004 12.1665 8.14067 12.1665 8.31254V9.35421C12.1665 9.52608 12.0259 9.66671 11.854 9.66671H10.8123C10.6405 9.66671 10.4998 9.52608 10.4998 9.35421V8.31254ZM10.4998 11.6459C10.4998 11.474 10.6405 11.3334 10.8123 11.3334H11.854C12.0259 11.3334 12.1665 11.474 12.1665 11.6459V12.6875C12.1665 12.8594 12.0259 13 11.854 13H10.8123C10.6405 13 10.4998 12.8594 10.4998 12.6875V11.6459ZM7.1665 8.31254C7.1665 8.14067 7.30713 8.00004 7.479 8.00004H8.52067C8.69255 8.00004 8.83317 8.14067 8.83317 8.31254V9.35421C8.83317 9.52608 8.69255 9.66671 8.52067 9.66671H7.479C7.30713 9.66671 7.1665 9.52608 7.1665 9.35421V8.31254ZM7.1665 11.6459C7.1665 11.474 7.30713 11.3334 7.479 11.3334H8.52067C8.69255 11.3334 8.83317 11.474 8.83317 11.6459V12.6875C8.83317 12.8594 8.69255 13 8.52067 13H7.479C7.30713 13 7.1665 12.8594 7.1665 12.6875V11.6459ZM3.83317 8.31254C3.83317 8.14067 3.9738 8.00004 4.14567 8.00004H5.18734C5.35921 8.00004 5.49984 8.14067 5.49984 8.31254V9.35421C5.49984 9.52608 5.35921 9.66671 5.18734 9.66671H4.14567C3.9738 9.66671 3.83317 9.52608 3.83317 9.35421V8.31254ZM3.83317 11.6459C3.83317 11.474 3.9738 11.3334 4.14567 11.3334H5.18734C5.35921 11.3334 5.49984 11.474 5.49984 11.6459V12.6875C5.49984 12.8594 5.35921 13 5.18734 13H4.14567C3.9738 13 3.83317 12.8594 3.83317 12.6875V11.6459ZM12.5832 3.00004H11.3332V1.75004C11.3332 1.52087 11.1457 1.33337 10.9165 1.33337H10.0832C9.854 1.33337 9.6665 1.52087 9.6665 1.75004V3.00004H6.33317V1.75004C6.33317 1.52087 6.14567 1.33337 5.9165 1.33337H5.08317C4.854 1.33337 4.6665 1.52087 4.6665 1.75004V3.00004H3.4165C2.7264 3.00004 2.1665 3.55994 2.1665 4.25004V5.50004H13.8332V4.25004C13.8332 3.55994 13.2733 3.00004 12.5832 3.00004Z"
                                                                fill="currentColor"/>
                                                        </svg>
                                                        <span>{format(new Date(flight[1].flight_segment[0].departureDate), "dd/MM/yyyy")}</span>
                                                    </p>
                                                </div>
                                                <div className="icon-circle">
                                                    <svg width="18" height="18" viewBox="0 0 640 512"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM80.55 341.27c6.28 6.84 15.1 10.72 24.33 10.71l130.54-.18a65.62 65.62 0 0 0 29.64-7.12l290.96-147.65c26.74-13.57 50.71-32.94 67.02-58.31 18.31-28.48 20.3-49.09 13.07-63.65-7.21-14.57-24.74-25.27-58.25-27.45-29.85-1.94-59.54 5.92-86.28 19.48l-98.51 49.99-218.7-82.06a17.799 17.799 0 0 0-18-1.11L90.62 67.29c-10.67 5.41-13.25 19.65-5.17 28.53l156.22 98.1-103.21 52.38-72.35-36.47a17.804 17.804 0 0 0-16.07.02L9.91 230.22c-10.44 5.3-13.19 19.12-5.57 28.08l76.21 82.97z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                </div>
                                                <div className="itinerary-step-info">
                                                    <p className="title tooltip-linck">
                                                    <span className="tooltip-info">
                                                        <span className="tooltip-header">
                                                            <strong>
                                                                <svg width="18" height="18"
                                                                     viewBox="0 0 640 512"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM80.55 341.27c6.28 6.84 15.1 10.72 24.33 10.71l130.54-.18a65.62 65.62 0 0 0 29.64-7.12l290.96-147.65c26.74-13.57 50.71-32.94 67.02-58.31 18.31-28.48 20.3-49.09 13.07-63.65-7.21-14.57-24.74-25.27-58.25-27.45-29.85-1.94-59.54 5.92-86.28 19.48l-98.51 49.99-218.7-82.06a17.799 17.799 0 0 0-18-1.11L90.62 67.29c-10.67 5.41-13.25 19.65-5.17 28.53l156.22 98.1-103.21 52.38-72.35-36.47a17.804 17.804 0 0 0-16.07.02L9.91 230.22c-10.44 5.3-13.19 19.12-5.57 28.08l76.21 82.97z"
                                                                        fill="currentColor"></path>
                                                                </svg>
                                                                <span>{t('search.departure')}</span>
                                                            </strong>
                                                            <span
                                                                className="site-color">{format(new Date(flight[1].flight_segment[0].departureDate), "dd/MM/yyyy")}</span>
                                                        </span>
                                                        <span className="tooltip-body">
                                                            <span
                                                                className="small-text site-color">{flight[1].flight_segment[0].airlineName}</span>
                                                            <br/>
                                                            <span>
                                                                <svg width="12" height="12" viewBox="0 0 12 12"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M6 1C3.23859 1 1 3.23857 1 6C1 8.76143 3.23859 11 6 11C8.76141 11 11 8.76141 11 6C11 3.23859 8.76143 1 6 1ZM8.62315 3.37685C9.94266 4.69637 10.0344 6.71316 9.04002 8.1276L3.87238 2.96C5.28778 1.96492 7.30435 2.05808 8.62315 3.37685ZM3.37685 8.62315C2.05734 7.30363 1.96556 5.28683 2.95998 3.8724L8.12762 9.04C6.71224 10.0351 4.69565 9.94194 3.37685 8.62315Z"
                                                                        fill="currentColor"/>
                                                                </svg>
                                                                <span>{t('itinerary.no_reembolsable')}</span>
                                                            </span>
                                                            <span>
                                                                <svg width="10" height="14" viewBox="0 0 10 14"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M8.75 4.49998H1.25C0.559635 4.49998 0 5.05962 0 5.74998V11.5833C0 12.2737 0.559635 12.8333 1.25 12.8333H1.66667V13.25C1.66667 13.4802 1.85312 13.6666 2.08333 13.6666H2.91667C3.14687 13.6666 3.33333 13.4802 3.33333 13.25V12.8333H6.66667V13.25C6.66667 13.4802 6.85312 13.6666 7.08333 13.6666H7.91667C8.14687 13.6666 8.33333 13.4802 8.33333 13.25V12.8333H8.75C9.44036 12.8333 10 12.2737 10 11.5833V5.74998C10 5.05962 9.44036 4.49998 8.75 4.49998ZM8.33333 10.125C8.33333 10.2401 8.2401 10.3333 8.125 10.3333H1.875C1.7599 10.3333 1.66667 10.2401 1.66667 10.125V9.70831C1.66667 9.59321 1.7599 9.49998 1.875 9.49998H8.125C8.2401 9.49998 8.33333 9.59321 8.33333 9.70831V10.125ZM8.33333 7.62498C8.33333 7.74008 8.2401 7.83331 8.125 7.83331H1.875C1.7599 7.83331 1.66667 7.74008 1.66667 7.62498V7.20831C1.66667 7.09321 1.7599 6.99998 1.875 6.99998H8.125C8.2401 6.99998 8.33333 7.09321 8.33333 7.20831V7.62498ZM3.75 1.58331H6.25V3.66665H7.5V1.58331C7.5 0.892948 6.94036 0.333313 6.25 0.333313H3.75C3.05964 0.333313 2.5 0.892948 2.5 1.58331V3.66665H3.75V1.58331Z"
                                                                        fill="currentColor"/>
                                                                </svg>
                                                                <span>{t('itinerary.luggage_included')}</span>
                                                            </span>
                                                            <span>
                                                                <svg width="18" height="18" viewBox="0 0 18 18"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M14 7.33334H11.0237L8.28668 2.54321C8.25018 2.47944 8.19749 2.42644 8.13392 2.38958C8.07035 2.35272 7.99818 2.33331 7.9247 2.33331H6.21896C5.94214 2.33331 5.7424 2.59816 5.81844 2.8643L7.09527 7.33334H4.41661L3.29161 5.83333C3.21296 5.72838 3.08926 5.66666 2.95827 5.66666H1.91686C1.64576 5.66666 1.44681 5.92135 1.51269 6.18437L2.33327 9.00001L1.51269 11.8156C1.44681 12.0787 1.64576 12.3334 1.91686 12.3334H2.95827C3.08952 12.3334 3.21296 12.2716 3.29161 12.1667L4.41661 10.6667H7.09527L5.81844 15.1355C5.7424 15.4016 5.94214 15.6667 6.21896 15.6667H7.9247C8.07418 15.6667 8.2122 15.5865 8.28642 15.4568L11.0237 10.6667H14C14.9206 10.6667 16.5 9.92059 16.5 9.00001C16.5 8.07943 14.9206 7.33334 14 7.33334Z"
                                                                        fill="currentColor"></path>
                                                                </svg>
                                                                {flight[1].flight_segment.length === 1 ?
                                                                    <span>{t('itinerary.without_stops')}</span>
                                                                    :
                                                                    <span>
                                                                        {flight[1].flight_segment.length > 2 ?
                                                                            <>
                                                                                {flight[1].flight_segment.length - 1} {t('itinerary.stops')}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {flight[1].flight_segment.length - 1} {t('itinerary.stop')}
                                                                            </>
                                                                        }
                                                                    </span>
                                                                }
                                                            </span>
                                                        </span>
                                                    </span>

                                                        <span>{t('itinerary.flight_to')} {flight[1].flight_segment[flight[1].flight_segment.length - 1].arrivalCityName} </span>
                                                        <svg width="14" height="14" viewBox="0 0 14 14"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                                  fill="currentColor"></path>
                                                        </svg>
                                                    </p>
                                                    <p className="airport">
                                                        <strong>{flight[1].flight_segment[flight[1].flight_segment.length - 1].arrivalAirport} ({flight[1].flight_segment[flight[1].flight_segment.length - 1].arrivalCityCode})</strong>
                                                    </p>
                                                    <p className="date">
                                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M2.1665 13.4167C2.1665 14.1068 2.7264 14.6667 3.4165 14.6667H12.5832C13.2733 14.6667 13.8332 14.1068 13.8332 13.4167V6.33337H2.1665V13.4167ZM10.4998 8.31254C10.4998 8.14067 10.6405 8.00004 10.8123 8.00004H11.854C12.0259 8.00004 12.1665 8.14067 12.1665 8.31254V9.35421C12.1665 9.52608 12.0259 9.66671 11.854 9.66671H10.8123C10.6405 9.66671 10.4998 9.52608 10.4998 9.35421V8.31254ZM10.4998 11.6459C10.4998 11.474 10.6405 11.3334 10.8123 11.3334H11.854C12.0259 11.3334 12.1665 11.474 12.1665 11.6459V12.6875C12.1665 12.8594 12.0259 13 11.854 13H10.8123C10.6405 13 10.4998 12.8594 10.4998 12.6875V11.6459ZM7.1665 8.31254C7.1665 8.14067 7.30713 8.00004 7.479 8.00004H8.52067C8.69255 8.00004 8.83317 8.14067 8.83317 8.31254V9.35421C8.83317 9.52608 8.69255 9.66671 8.52067 9.66671H7.479C7.30713 9.66671 7.1665 9.52608 7.1665 9.35421V8.31254ZM7.1665 11.6459C7.1665 11.474 7.30713 11.3334 7.479 11.3334H8.52067C8.69255 11.3334 8.83317 11.474 8.83317 11.6459V12.6875C8.83317 12.8594 8.69255 13 8.52067 13H7.479C7.30713 13 7.1665 12.8594 7.1665 12.6875V11.6459ZM3.83317 8.31254C3.83317 8.14067 3.9738 8.00004 4.14567 8.00004H5.18734C5.35921 8.00004 5.49984 8.14067 5.49984 8.31254V9.35421C5.49984 9.52608 5.35921 9.66671 5.18734 9.66671H4.14567C3.9738 9.66671 3.83317 9.52608 3.83317 9.35421V8.31254ZM3.83317 11.6459C3.83317 11.474 3.9738 11.3334 4.14567 11.3334H5.18734C5.35921 11.3334 5.49984 11.474 5.49984 11.6459V12.6875C5.49984 12.8594 5.35921 13 5.18734 13H4.14567C3.9738 13 3.83317 12.8594 3.83317 12.6875V11.6459ZM12.5832 3.00004H11.3332V1.75004C11.3332 1.52087 11.1457 1.33337 10.9165 1.33337H10.0832C9.854 1.33337 9.6665 1.52087 9.6665 1.75004V3.00004H6.33317V1.75004C6.33317 1.52087 6.14567 1.33337 5.9165 1.33337H5.08317C4.854 1.33337 4.6665 1.52087 4.6665 1.75004V3.00004H3.4165C2.7264 3.00004 2.1665 3.55994 2.1665 4.25004V5.50004H13.8332V4.25004C13.8332 3.55994 13.2733 3.00004 12.5832 3.00004Z"
                                                                fill="currentColor"/>
                                                        </svg>
                                                        <span> {format(new Date(flight[1].flight_segment[0].departureDate), "dd/MM/yyyy")} </span>
                                                    </p>
                                                    <p className="time">
                                                        <span>{t('search.departure')}
                                                            <strong> {flight[1].flight_segment[0].departureTime} </strong></span>
                                                        <span>{t('search.arrival')}
                                                            <strong> {flight[1].flight_segment[flight[1].flight_segment.length - 1].arrivalTime} </strong></span>
                                                    </p>
                                                    <p className="airline">
                                                        <strong>{flight[1].flight_segment[0].airlineName}</strong>
                                                    </p>
                                                    {transfer.state !== undefined && transfer.state == true &&
                                                        <p className="site-color">{t('transfer.taxi_included')}</p>
                                                    }
                                                </div>
                                            </div>

                                            {/*HOTEL*/}
                                            <div className="itinerary-step hotel">
                                                {/* <div className="icon-taxi">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M20.0469 11.4391L19.1875 8.125C18.8125 6.75 17.5625 5.75 16.1875 5.75H15.75V4.5C15.75 3.80977 15.1902 3.25 14.5 3.25H9.5C8.80977 3.25 8.25 3.80977 8.25 4.5V5.75H7.8125C6.4375 5.75 5.1875 6.75 4.8125 8.125L3.95312 11.4391C2.83633 11.6891 2 12.6824 2 13.875V15.75C2 16.673 2.50586 17.4703 3.25 17.9031V19.5C3.25 20.1902 3.80977 20.75 4.5 20.75H5.75C6.44023 20.75 7 20.1902 7 19.5V18.25H17V19.5C17 20.1902 17.5598 20.75 18.25 20.75H19.5C20.1902 20.75 20.75 20.1902 20.75 19.5V17.9031C21.4941 17.4699 22 16.6727 22 15.75V13.875C22 12.6824 21.1637 11.6891 20.0469 11.4391ZM5.75 15.75C5.05977 15.75 4.5 15.1902 4.5 14.5C4.5 13.8098 5.05977 13.25 5.75 13.25C6.44023 13.25 7 13.8098 7 14.5C7 15.1902 6.44023 15.75 5.75 15.75ZM6.55273 11.375L7.22461 8.78281C7.31172 8.46406 7.59922 8.25 7.81289 8.25H16.1879C16.4016 8.25 16.6891 8.46406 16.768 8.75234L17.4473 11.375H6.55273V11.375ZM18.25 15.75C17.5598 15.75 17 15.1902 17 14.5C17 13.8098 17.5598 13.25 18.25 13.25C18.9402 13.25 19.5 13.8098 19.5 14.5C19.5 15.1902 18.9402 15.75 18.25 15.75Z"
                                                    fill="currentColor" />
                                            </svg>
                                        </div> */}
                                                <div className="itinerary-step-date">
                                                    <p className="date">
                                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M2.1665 13.4167C2.1665 14.1068 2.7264 14.6667 3.4165 14.6667H12.5832C13.2733 14.6667 13.8332 14.1068 13.8332 13.4167V6.33337H2.1665V13.4167ZM10.4998 8.31254C10.4998 8.14067 10.6405 8.00004 10.8123 8.00004H11.854C12.0259 8.00004 12.1665 8.14067 12.1665 8.31254V9.35421C12.1665 9.52608 12.0259 9.66671 11.854 9.66671H10.8123C10.6405 9.66671 10.4998 9.52608 10.4998 9.35421V8.31254ZM10.4998 11.6459C10.4998 11.474 10.6405 11.3334 10.8123 11.3334H11.854C12.0259 11.3334 12.1665 11.474 12.1665 11.6459V12.6875C12.1665 12.8594 12.0259 13 11.854 13H10.8123C10.6405 13 10.4998 12.8594 10.4998 12.6875V11.6459ZM7.1665 8.31254C7.1665 8.14067 7.30713 8.00004 7.479 8.00004H8.52067C8.69255 8.00004 8.83317 8.14067 8.83317 8.31254V9.35421C8.83317 9.52608 8.69255 9.66671 8.52067 9.66671H7.479C7.30713 9.66671 7.1665 9.52608 7.1665 9.35421V8.31254ZM7.1665 11.6459C7.1665 11.474 7.30713 11.3334 7.479 11.3334H8.52067C8.69255 11.3334 8.83317 11.474 8.83317 11.6459V12.6875C8.83317 12.8594 8.69255 13 8.52067 13H7.479C7.30713 13 7.1665 12.8594 7.1665 12.6875V11.6459ZM3.83317 8.31254C3.83317 8.14067 3.9738 8.00004 4.14567 8.00004H5.18734C5.35921 8.00004 5.49984 8.14067 5.49984 8.31254V9.35421C5.49984 9.52608 5.35921 9.66671 5.18734 9.66671H4.14567C3.9738 9.66671 3.83317 9.52608 3.83317 9.35421V8.31254ZM3.83317 11.6459C3.83317 11.474 3.9738 11.3334 4.14567 11.3334H5.18734C5.35921 11.3334 5.49984 11.474 5.49984 11.6459V12.6875C5.49984 12.8594 5.35921 13 5.18734 13H4.14567C3.9738 13 3.83317 12.8594 3.83317 12.6875V11.6459ZM12.5832 3.00004H11.3332V1.75004C11.3332 1.52087 11.1457 1.33337 10.9165 1.33337H10.0832C9.854 1.33337 9.6665 1.52087 9.6665 1.75004V3.00004H6.33317V1.75004C6.33317 1.52087 6.14567 1.33337 5.9165 1.33337H5.08317C4.854 1.33337 4.6665 1.52087 4.6665 1.75004V3.00004H3.4165C2.7264 3.00004 2.1665 3.55994 2.1665 4.25004V5.50004H13.8332V4.25004C13.8332 3.55994 13.2733 3.00004 12.5832 3.00004Z"
                                                                fill="currentColor"/>
                                                        </svg>
                                                        <span>{format(new Date(search.arrival), "dd/MM/yyyy")}</span>
                                                    </p>
                                                </div>
                                                <div className="icon-circle">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.73722 5.5188L5.86449 6.29337C6.59761 4.59464 7.66051 3.28736 8.76029 2.67466C6.76281 2.57279 4.82742 3.44465 3.58264 5.0038C3.44327 5.1788 3.52722 5.44234 3.73722 5.5188ZM6.4924 6.52212L11.4607 8.33106C12.2057 5.80234 11.8503 3.50486 10.5726 3.03987C10.4184 2.98362 10.2574 2.95653 10.0915 2.95653C8.88279 2.95674 7.41926 4.39756 6.4924 6.52212ZM12.1975 3.92715C12.3271 4.26673 12.4232 4.64797 12.4725 5.07693C12.5921 6.11629 12.443 7.33169 12.0778 8.55564L14.2156 9.33396C14.4259 9.41042 14.6596 9.26251 14.665 9.03876C14.7134 7.04524 13.7919 5.1338 12.1975 3.92715ZM13 11.9996H8.02217L9.37508 8.28127L8.12217 7.82502L6.60344 11.9994H1.66683C1.48266 11.9994 1.3335 12.1485 1.3335 12.3329V12.9998C1.3335 13.1839 1.48266 13.3333 1.66683 13.3333H13C13.1842 13.3333 13.3334 13.1839 13.3334 12.9998V12.3329C13.3334 12.1487 13.1842 11.9996 13 11.9996Z"
                                                            fill="currentColor"/>
                                                    </svg>
                                                </div>
                                                <div className="itinerary-step-info">
                                                    <p className="title">Tenerife</p>
                                                    <p className="date">
                                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M2.1665 13.4167C2.1665 14.1068 2.7264 14.6667 3.4165 14.6667H12.5832C13.2733 14.6667 13.8332 14.1068 13.8332 13.4167V6.33337H2.1665V13.4167ZM10.4998 8.31254C10.4998 8.14067 10.6405 8.00004 10.8123 8.00004H11.854C12.0259 8.00004 12.1665 8.14067 12.1665 8.31254V9.35421C12.1665 9.52608 12.0259 9.66671 11.854 9.66671H10.8123C10.6405 9.66671 10.4998 9.52608 10.4998 9.35421V8.31254ZM10.4998 11.6459C10.4998 11.474 10.6405 11.3334 10.8123 11.3334H11.854C12.0259 11.3334 12.1665 11.474 12.1665 11.6459V12.6875C12.1665 12.8594 12.0259 13 11.854 13H10.8123C10.6405 13 10.4998 12.8594 10.4998 12.6875V11.6459ZM7.1665 8.31254C7.1665 8.14067 7.30713 8.00004 7.479 8.00004H8.52067C8.69255 8.00004 8.83317 8.14067 8.83317 8.31254V9.35421C8.83317 9.52608 8.69255 9.66671 8.52067 9.66671H7.479C7.30713 9.66671 7.1665 9.52608 7.1665 9.35421V8.31254ZM7.1665 11.6459C7.1665 11.474 7.30713 11.3334 7.479 11.3334H8.52067C8.69255 11.3334 8.83317 11.474 8.83317 11.6459V12.6875C8.83317 12.8594 8.69255 13 8.52067 13H7.479C7.30713 13 7.1665 12.8594 7.1665 12.6875V11.6459ZM3.83317 8.31254C3.83317 8.14067 3.9738 8.00004 4.14567 8.00004H5.18734C5.35921 8.00004 5.49984 8.14067 5.49984 8.31254V9.35421C5.49984 9.52608 5.35921 9.66671 5.18734 9.66671H4.14567C3.9738 9.66671 3.83317 9.52608 3.83317 9.35421V8.31254ZM3.83317 11.6459C3.83317 11.474 3.9738 11.3334 4.14567 11.3334H5.18734C5.35921 11.3334 5.49984 11.474 5.49984 11.6459V12.6875C5.49984 12.8594 5.35921 13 5.18734 13H4.14567C3.9738 13 3.83317 12.8594 3.83317 12.6875V11.6459ZM12.5832 3.00004H11.3332V1.75004C11.3332 1.52087 11.1457 1.33337 10.9165 1.33337H10.0832C9.854 1.33337 9.6665 1.52087 9.6665 1.75004V3.00004H6.33317V1.75004C6.33317 1.52087 6.14567 1.33337 5.9165 1.33337H5.08317C4.854 1.33337 4.6665 1.52087 4.6665 1.75004V3.00004H3.4165C2.7264 3.00004 2.1665 3.55994 2.1665 4.25004V5.50004H13.8332V4.25004C13.8332 3.55994 13.2733 3.00004 12.5832 3.00004Z"
                                                                fill="currentColor"/>
                                                        </svg>
                                                        <span>{format(new Date(search.arrival), "dd/MM/yyyy")}</span>
                                                    </p>
                                                    <p className="night">
                                                        <svg width="10" height="10" viewBox="0 0 10 10"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5.44303 9.16671C6.72822 9.16671 7.902 8.58199 8.67964 7.62387C8.79468 7.48214 8.66924 7.27507 8.49146 7.30893C6.46992 7.69392 4.61349 6.14395 4.61349 4.1033C4.61349 2.92781 5.24276 1.84687 6.26548 1.26485C6.42313 1.17514 6.38348 0.936125 6.20435 0.903035C5.95322 0.856729 5.69839 0.833412 5.44303 0.833374C3.14308 0.833374 1.27637 2.69716 1.27637 5.00004C1.27637 7.29999 3.14015 9.16671 5.44303 9.16671Z"
                                                                fill="currentColor"/>
                                                        </svg>
                                                        <span>{nightDiff(search.arrival, search.departure)} {t('search.nights')}</span>
                                                    </p>
                                                    <p className="hotel-name"><strong>{cart[0].hotel_name}</strong>
                                                    </p>
                                                    <p>{cart[0].room_title}</p>
                                                    <p className="site-color">{cart[0].boardtype_text}</p>
                                                    <p>{t('person.Adults')}: {search.adults}</p>
                                                    <p>{t('person.Children')}: {search.room_num_childs}</p>
                                                </div>
                                            </div>


                                            {/*ITINERARIO DE VUELTA*/}
                                            <div className="itinerary-step flight">
                                                <div className="itinerary-step-date">
                                                    <p className="date">
                                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M2.1665 13.4167C2.1665 14.1068 2.7264 14.6667 3.4165 14.6667H12.5832C13.2733 14.6667 13.8332 14.1068 13.8332 13.4167V6.33337H2.1665V13.4167ZM10.4998 8.31254C10.4998 8.14067 10.6405 8.00004 10.8123 8.00004H11.854C12.0259 8.00004 12.1665 8.14067 12.1665 8.31254V9.35421C12.1665 9.52608 12.0259 9.66671 11.854 9.66671H10.8123C10.6405 9.66671 10.4998 9.52608 10.4998 9.35421V8.31254ZM10.4998 11.6459C10.4998 11.474 10.6405 11.3334 10.8123 11.3334H11.854C12.0259 11.3334 12.1665 11.474 12.1665 11.6459V12.6875C12.1665 12.8594 12.0259 13 11.854 13H10.8123C10.6405 13 10.4998 12.8594 10.4998 12.6875V11.6459ZM7.1665 8.31254C7.1665 8.14067 7.30713 8.00004 7.479 8.00004H8.52067C8.69255 8.00004 8.83317 8.14067 8.83317 8.31254V9.35421C8.83317 9.52608 8.69255 9.66671 8.52067 9.66671H7.479C7.30713 9.66671 7.1665 9.52608 7.1665 9.35421V8.31254ZM7.1665 11.6459C7.1665 11.474 7.30713 11.3334 7.479 11.3334H8.52067C8.69255 11.3334 8.83317 11.474 8.83317 11.6459V12.6875C8.83317 12.8594 8.69255 13 8.52067 13H7.479C7.30713 13 7.1665 12.8594 7.1665 12.6875V11.6459ZM3.83317 8.31254C3.83317 8.14067 3.9738 8.00004 4.14567 8.00004H5.18734C5.35921 8.00004 5.49984 8.14067 5.49984 8.31254V9.35421C5.49984 9.52608 5.35921 9.66671 5.18734 9.66671H4.14567C3.9738 9.66671 3.83317 9.52608 3.83317 9.35421V8.31254ZM3.83317 11.6459C3.83317 11.474 3.9738 11.3334 4.14567 11.3334H5.18734C5.35921 11.3334 5.49984 11.474 5.49984 11.6459V12.6875C5.49984 12.8594 5.35921 13 5.18734 13H4.14567C3.9738 13 3.83317 12.8594 3.83317 12.6875V11.6459ZM12.5832 3.00004H11.3332V1.75004C11.3332 1.52087 11.1457 1.33337 10.9165 1.33337H10.0832C9.854 1.33337 9.6665 1.52087 9.6665 1.75004V3.00004H6.33317V1.75004C6.33317 1.52087 6.14567 1.33337 5.9165 1.33337H5.08317C4.854 1.33337 4.6665 1.52087 4.6665 1.75004V3.00004H3.4165C2.7264 3.00004 2.1665 3.55994 2.1665 4.25004V5.50004H13.8332V4.25004C13.8332 3.55994 13.2733 3.00004 12.5832 3.00004Z"
                                                                fill="currentColor"/>
                                                        </svg>
                                                        <span>{format(new Date(flight[0].flight_segment[flight[0].flight_segment.length - 1].departureDate), "dd/MM/yyyy")}</span>
                                                    </p>
                                                </div>
                                                <div className="icon-circle">
                                                    <svg width="18" height="18" viewBox="0 0 640 512"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM44.81 205.66l88.74 80a62.607 62.607 0 0 0 25.47 13.93l287.6 78.35c26.48 7.21 54.56 8.72 81 1.36 29.67-8.27 43.44-21.21 47.25-35.71 3.83-14.5-1.73-32.71-23.37-54.96-19.28-19.82-44.35-32.79-70.83-40l-97.51-26.56L282.8 30.22c-1.51-5.81-5.95-10.35-11.66-11.91L206.05.58c-10.56-2.88-20.9 5.32-20.71 16.44l47.92 164.21-102.2-27.84-27.59-67.88c-1.93-4.89-6.01-8.57-11.02-9.93L52.72 64.75c-10.34-2.82-20.53 5-20.72 15.88l.23 101.78c.19 8.91 6.03 17.34 12.58 23.25z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                </div>
                                                <div className="itinerary-step-info">
                                                    <p className="title tooltip-linck">
                                                    <span className="tooltip-info">
                                                        <span className="tooltip-header">
                                                            <strong>
                                                                <svg width="18" height="18"
                                                                     viewBox="0 0 640 512"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM44.81 205.66l88.74 80a62.607 62.607 0 0 0 25.47 13.93l287.6 78.35c26.48 7.21 54.56 8.72 81 1.36 29.67-8.27 43.44-21.21 47.25-35.71 3.83-14.5-1.73-32.71-23.37-54.96-19.28-19.82-44.35-32.79-70.83-40l-97.51-26.56L282.8 30.22c-1.51-5.81-5.95-10.35-11.66-11.91L206.05.58c-10.56-2.88-20.9 5.32-20.71 16.44l47.92 164.21-102.2-27.84-27.59-67.88c-1.93-4.89-6.01-8.57-11.02-9.93L52.72 64.75c-10.34-2.82-20.53 5-20.72 15.88l.23 101.78c.19 8.91 6.03 17.34 12.58 23.25z"
                                                                        fill="currentColor"></path>
                                                                </svg>
                                                                <span>{t('search.arrival')}</span>
                                                            </strong>
                                                            <span
                                                                className="site-color">{format(new Date(flight[0].flight_segment[flight[0].flight_segment.length - 1].arrivalDate), "dd/MM/yyyy")}</span>
                                                        </span>
                                                        <span className="tooltip-body">
                                                            <span
                                                                className="small-text site-color">{flight[0].flight_segment[0].airlineName}</span>
                                                            <br/>
                                                            <span>
                                                                <svg width="12" height="12" viewBox="0 0 12 12"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M6 1C3.23859 1 1 3.23857 1 6C1 8.76143 3.23859 11 6 11C8.76141 11 11 8.76141 11 6C11 3.23859 8.76143 1 6 1ZM8.62315 3.37685C9.94266 4.69637 10.0344 6.71316 9.04002 8.1276L3.87238 2.96C5.28778 1.96492 7.30435 2.05808 8.62315 3.37685ZM3.37685 8.62315C2.05734 7.30363 1.96556 5.28683 2.95998 3.8724L8.12762 9.04C6.71224 10.0351 4.69565 9.94194 3.37685 8.62315Z"
                                                                        fill="currentColor"/>
                                                                </svg>
                                                                <span>{t('itinerary.no_reembolsable')}</span>
                                                            </span>
                                                            <span>
                                                                <svg width="10" height="14" viewBox="0 0 10 14"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M8.75 4.49998H1.25C0.559635 4.49998 0 5.05962 0 5.74998V11.5833C0 12.2737 0.559635 12.8333 1.25 12.8333H1.66667V13.25C1.66667 13.4802 1.85312 13.6666 2.08333 13.6666H2.91667C3.14687 13.6666 3.33333 13.4802 3.33333 13.25V12.8333H6.66667V13.25C6.66667 13.4802 6.85312 13.6666 7.08333 13.6666H7.91667C8.14687 13.6666 8.33333 13.4802 8.33333 13.25V12.8333H8.75C9.44036 12.8333 10 12.2737 10 11.5833V5.74998C10 5.05962 9.44036 4.49998 8.75 4.49998ZM8.33333 10.125C8.33333 10.2401 8.2401 10.3333 8.125 10.3333H1.875C1.7599 10.3333 1.66667 10.2401 1.66667 10.125V9.70831C1.66667 9.59321 1.7599 9.49998 1.875 9.49998H8.125C8.2401 9.49998 8.33333 9.59321 8.33333 9.70831V10.125ZM8.33333 7.62498C8.33333 7.74008 8.2401 7.83331 8.125 7.83331H1.875C1.7599 7.83331 1.66667 7.74008 1.66667 7.62498V7.20831C1.66667 7.09321 1.7599 6.99998 1.875 6.99998H8.125C8.2401 6.99998 8.33333 7.09321 8.33333 7.20831V7.62498ZM3.75 1.58331H6.25V3.66665H7.5V1.58331C7.5 0.892948 6.94036 0.333313 6.25 0.333313H3.75C3.05964 0.333313 2.5 0.892948 2.5 1.58331V3.66665H3.75V1.58331Z"
                                                                        fill="currentColor"/>
                                                                </svg>
                                                                <span>{t('itinerary.luggage_included')}</span>
                                                            </span>
                                                            <span>
                                                                <svg width="18" height="18" viewBox="0 0 18 18"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M14 7.33334H11.0237L8.28668 2.54321C8.25018 2.47944 8.19749 2.42644 8.13392 2.38958C8.07035 2.35272 7.99818 2.33331 7.9247 2.33331H6.21896C5.94214 2.33331 5.7424 2.59816 5.81844 2.8643L7.09527 7.33334H4.41661L3.29161 5.83333C3.21296 5.72838 3.08926 5.66666 2.95827 5.66666H1.91686C1.64576 5.66666 1.44681 5.92135 1.51269 6.18437L2.33327 9.00001L1.51269 11.8156C1.44681 12.0787 1.64576 12.3334 1.91686 12.3334H2.95827C3.08952 12.3334 3.21296 12.2716 3.29161 12.1667L4.41661 10.6667H7.09527L5.81844 15.1355C5.7424 15.4016 5.94214 15.6667 6.21896 15.6667H7.9247C8.07418 15.6667 8.2122 15.5865 8.28642 15.4568L11.0237 10.6667H14C14.9206 10.6667 16.5 9.92059 16.5 9.00001C16.5 8.07943 14.9206 7.33334 14 7.33334Z"
                                                                        fill="currentColor"></path>
                                                                </svg>
                                                                {flight[0].flight_segment.length === 1 ?
                                                                    <span>{t('itinerary.without_stops')}</span>
                                                                    :
                                                                    <span>
                                                                        {flight[0].flight_segment.length > 2 ?
                                                                            <>
                                                                                {flight[0].flight_segment.length - 1} {t('itinerary.stops')}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {flight[0].flight_segment.length - 1} {t('itinerary.stop')}
                                                                            </>
                                                                        }
                                                                    </span>
                                                                }
                                                            </span>
                                                        </span>
                                                    </span>

                                                        <span>{t('itinerary.flight_to')} {flight[0].flight_segment[flight[0].flight_segment.length - 1].arrivalCityName}</span>
                                                        <svg width="14" height="14" viewBox="0 0 14 14"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                                  fill="currentColor"></path>
                                                        </svg>
                                                    </p>
                                                    <p className="airport">
                                                        <strong>{flight[0].flight_segment[flight[0].flight_segment.length - 1].arrivalAirport} ({flight[0].flight_segment[flight[0].flight_segment.length - 1].arrivalCityCode})</strong>
                                                    </p>
                                                    <p className="date">
                                                        <svg width="16" height="16" viewBox="0 0 16 16"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M2.1665 13.4167C2.1665 14.1068 2.7264 14.6667 3.4165 14.6667H12.5832C13.2733 14.6667 13.8332 14.1068 13.8332 13.4167V6.33337H2.1665V13.4167ZM10.4998 8.31254C10.4998 8.14067 10.6405 8.00004 10.8123 8.00004H11.854C12.0259 8.00004 12.1665 8.14067 12.1665 8.31254V9.35421C12.1665 9.52608 12.0259 9.66671 11.854 9.66671H10.8123C10.6405 9.66671 10.4998 9.52608 10.4998 9.35421V8.31254ZM10.4998 11.6459C10.4998 11.474 10.6405 11.3334 10.8123 11.3334H11.854C12.0259 11.3334 12.1665 11.474 12.1665 11.6459V12.6875C12.1665 12.8594 12.0259 13 11.854 13H10.8123C10.6405 13 10.4998 12.8594 10.4998 12.6875V11.6459ZM7.1665 8.31254C7.1665 8.14067 7.30713 8.00004 7.479 8.00004H8.52067C8.69255 8.00004 8.83317 8.14067 8.83317 8.31254V9.35421C8.83317 9.52608 8.69255 9.66671 8.52067 9.66671H7.479C7.30713 9.66671 7.1665 9.52608 7.1665 9.35421V8.31254ZM7.1665 11.6459C7.1665 11.474 7.30713 11.3334 7.479 11.3334H8.52067C8.69255 11.3334 8.83317 11.474 8.83317 11.6459V12.6875C8.83317 12.8594 8.69255 13 8.52067 13H7.479C7.30713 13 7.1665 12.8594 7.1665 12.6875V11.6459ZM3.83317 8.31254C3.83317 8.14067 3.9738 8.00004 4.14567 8.00004H5.18734C5.35921 8.00004 5.49984 8.14067 5.49984 8.31254V9.35421C5.49984 9.52608 5.35921 9.66671 5.18734 9.66671H4.14567C3.9738 9.66671 3.83317 9.52608 3.83317 9.35421V8.31254ZM3.83317 11.6459C3.83317 11.474 3.9738 11.3334 4.14567 11.3334H5.18734C5.35921 11.3334 5.49984 11.474 5.49984 11.6459V12.6875C5.49984 12.8594 5.35921 13 5.18734 13H4.14567C3.9738 13 3.83317 12.8594 3.83317 12.6875V11.6459ZM12.5832 3.00004H11.3332V1.75004C11.3332 1.52087 11.1457 1.33337 10.9165 1.33337H10.0832C9.854 1.33337 9.6665 1.52087 9.6665 1.75004V3.00004H6.33317V1.75004C6.33317 1.52087 6.14567 1.33337 5.9165 1.33337H5.08317C4.854 1.33337 4.6665 1.52087 4.6665 1.75004V3.00004H3.4165C2.7264 3.00004 2.1665 3.55994 2.1665 4.25004V5.50004H13.8332V4.25004C13.8332 3.55994 13.2733 3.00004 12.5832 3.00004Z"
                                                                fill="currentColor"/>
                                                        </svg>
                                                        <span>{format(new Date(flight[0].flight_segment[flight[0].flight_segment.length - 1].departureDate), "dd/MM/yyyy")}</span>
                                                    </p>
                                                    <p className="time">
                                                        <span>{t('search.departure')}
                                                            <strong>{flight[0].flight_segment[0].departureTime}</strong></span>
                                                        <span>{t('search.arrival')}
                                                            <strong>{flight[0].flight_segment[flight[1].flight_segment.length - 1].arrivalTime}</strong></span>
                                                    </p>
                                                    <p className="airline">
                                                        <strong>{flight[0].flight_segment[0].airlineName}</strong>
                                                    </p>
                                                    {transfer.state !== undefined && transfer.state == true &&
                                                        <p className="site-color">{t('transfer.taxi_included')}</p>
                                                    }
                                                </div>
                                            </div>

                                            <div className="itinerary-step place">
                                                <div className="icon-circle">
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.2858 5.74507C12.2858 3.37899 10.3661 1.45935 8.00007 1.45935C5.634 1.45935 3.71436 3.37899 3.71436 5.74507C3.71436 7.78376 5.13995 9.49209 7.04769 9.92364V14.4355C7.04769 14.632 7.2084 14.7927 7.40483 14.7927H8.59531C8.79174 14.7927 8.95245 14.632 8.95245 14.4355V9.92364C10.8602 9.49209 12.2858 7.78376 12.2858 5.74507V5.74507ZM8.00007 8.12602C6.68757 8.12602 5.61912 7.05757 5.61912 5.74507C5.61912 4.43257 6.68757 3.36411 8.00007 3.36411C9.31257 3.36411 10.381 4.43257 10.381 5.74507C10.381 7.05757 9.31257 8.12602 8.00007 8.12602Z"
                                                            fill="currentColor"/>
                                                    </svg>
                                                </div>
                                                <div className="itinerary-step-info">
                                                    <p className="title">{flight[0].flight_segment[flight[0].flight_segment.length - 1].arrivalCityName}</p>
                                                    <p>{t('itinerary.destiny')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </Collapse>

                </dl>
                <div style={callUs}>
                    <p><span><strong>{t('msg.call_us')}</strong></span></p>
                    <a href="tel:+34822130034" className="phone-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19.4296 14.1327L15.0546 12.2577C14.8677 12.178 14.6599 12.1612 14.4627 12.2098C14.2654 12.2585 14.0893 12.3698 13.9608 12.5272L12.0233 14.8944C8.98248 13.4607 6.53535 11.0136 5.10165 7.97283L7.46888 6.0353C7.62657 5.90704 7.73818 5.73091 7.78681 5.53355C7.83545 5.33619 7.81846 5.12837 7.73842 4.94153L5.86338 0.566453C5.77554 0.365046 5.62016 0.200605 5.42406 0.101483C5.22796 0.00236143 5.00341 -0.0252272 4.78915 0.0234743L0.726575 0.960991C0.519997 1.00869 0.335688 1.12501 0.203728 1.29095C0.0717689 1.45689 -4.75872e-05 1.66266 2.36575e-08 1.87468C2.36575e-08 11.8944 8.12123 20 18.1253 20C18.3374 20.0001 18.5433 19.9284 18.7093 19.7964C18.8753 19.6644 18.9917 19.4801 19.0394 19.2734L19.9769 15.2108C20.0253 14.9955 19.9971 14.7701 19.8972 14.5733C19.7973 14.3765 19.632 14.2207 19.4296 14.1327Z"
                                fill="currentColor"/>
                        </svg>
                        <span>+34 822 13 00 34</span>
                    </a>
                </div>
                
                <div className="search-result container mobile">
                    <div className="search-result-content">
                        <Box component="div" display="inline">
                            <CartMobile />
                        </Box>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    active: state.flights.isActive,
    flight: state.flights.selected_flight,
    cart: state.cart.products,
    search: state.search.payload,
    transfer: state.extras.selected_transfer,
});


export default connect(mapStateToProps)(withTranslation('common')(ItineraryHeader));
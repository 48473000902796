import {GET_INSOURANCES_INITIALIZE, SET_INSOURANCE, NO_INSOURANCE} from './types';

export const addInsurances = data => {
    // TODO Llamada a la API para devolver seguros
    return{
        type: GET_INSOURANCES_INITIALIZE,
        payload: data
    }
}

export const setInsurance = insurance => {
    return{
        type: SET_INSOURANCE,
        payload: insurance
    }
}

export const noInsurance = () => {
    return{
        type: NO_INSOURANCE,
    }
}
import React from "react";

export const InfoBanner = ({ t }) => {
    return (<>
        <div className="motor-info-banner">
            <p>
                <span className="ico-before" styles="background-image">
                    <img src="https://booking.springhoteles.com/static/media/ico-tag.bb365734.svg"
                        alt="ico-tag"></img>
                </span>
                <span>{t('msg.best_price')}</span>
            </p>
            <p className="title tooltip-linck">
                <span className="ico-before" styles="background-image">
                    <img src="https://booking.springhoteles.com/static/media/ico-payment.8591fc88.svg"
                        alt="ico-pay"></img>
                </span>
                <span>{t('msg.direct_reservation')}</span>
            </p>
            <p>
                <span className="ico-before" styles="background-image">
                    <img src="https://booking.springhoteles.com/static/media/ico-security.822adaa5.svg"
                        alt="ico-sec"></img>
                </span>
                <span>{t('msg.free_cancellation')}</span>
            </p>
        </div>
    </>
    )
}
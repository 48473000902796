import React from "react";
import IcoWarning from "../../static/img/icons/ico-warning.svg";
import IcoInfo from "../../static/img/icons/ico-notice.svg";
import IcoError from "../../static/img/icons/ico-error.svg";
import IcoSuccess from "../../static/img/icons/ico-success.svg";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {logout} from "../../actions/auth";


export function MainMessage(props) {
    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();
    const emailCustomer = useSelector(state => state.callcenter.emailCustomer);
    const customer = useSelector(state => state.callcenter.user);

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleReload = () => {
        window.location.reload(false);
    };

    return (
        <div>
            <div className="system-msg warning-msg">
                <div className="msg-box">
                    <img className="icon icon-warning" src={IcoWarning}/>
                    <div className="text-content">
                        <h5 className="title">{t('contact_centre.active_msg')}</h5>
                        <p>{t('contact_centre.reminder')}.</p>
                    </div>
                </div>
            </div>
            <div className="system-msg notice-msg">
                <div className="msg-box">
                    <img className="icon icon-notice" src={IcoInfo}/>
                    <div className="text-content">
                        <h5 className="title">{t('contact.title')}: {emailCustomer}</h5>
                        {customer == null ?
                            <button onClick={handleReload}>{t('msg.contact_data')}</button>
                            :
                            <h5 className="title">{customer?.first_name} {customer?.surname}, {customer?.country_region_code}</h5>
                        }
                    </div>
                </div>
            </div>
            <div className="system-msg success-msg">
                <div className="msg-box">
                    <img className="icon icon-success" src={IcoSuccess}/>
                    <div className="text-content">
                        <p><a
                            href="/binterna">{t('contact_centre.book_customer')}.</a> {t('words.country')}: {customer?.country_region_code}
                        </p>
                    </div>
                </div>
            </div>
            <div className="system-msg error-msg">
                <div className="msg-box">
                    <img className="icon icon-error" src={IcoError}/>
                    <div className="text-content">
                        <p><a onClick={handleLogout}>{t('contact_centre.end_session')}</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function WarningMessage(props) {
    const {t, i18n} = useTranslation('common');
    const customer = useSelector(state => state.callcenter.user);

    return (
        <div className="system-msg warning-msg">
            <div className="msg-box">
                <img className="icon icon-warning" src={IcoWarning}/>
                <div className="text-content">
                    <h5 className="title">{t('contact_centre.active_msg')} ({customer.e_mail})</h5>
                    <p>{t('contact_centre.reminder')}.</p>
                </div>
            </div>
        </div>
    )
}
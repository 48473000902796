import React, {useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";
import SelectReasons from "./SelectReasons";
import {useDispatch, useSelector} from "react-redux";
import {cancelRoom} from "../../../actions/reservations";

export default function CancelationForm(props) {

    const {t} = useTranslation('common');
    const pmskey = props.pmskey;

    const methods = useForm();
    const dispatch = useDispatch();
    const [sentGTM, setSentGTM] = useState(false);

    const responseStatus = useSelector(state => state.reservations?.response?.status);
    const response = useSelector(state => state.reservations?.response);

    const onSubmit = form => {
        if (form?.description !== null && form?.description !== '') {
            form.pms_key = pmskey;

            dispatch(cancelRoom(form));
        }
        else{
            alert("Seleccione o escriba un motivo");
        }
    };

    /*
    if (responseStatus === "OK" && !sentGTM) {
        new canceledRoomGTM(response.reservation_id, response.room_id, response.pms_key, response.price);
        setSentGTM(true);
    }
    */

    return (
        <>
            {responseStatus === "OK" ?
                <div className="center-text">
                    <h2 className="h1">{t('cancelation.confirmed')}</h2>
                    <p className="title-sentence">{t('cancelation.confirmed_msg', {pmskey: pmskey})}</p>
                    <div className="btns-wrapper">
                        <a href="/myreservations" className="btn">
                            <span>{t('msg.back_web')}</span>
                        </a>
                    </div>
                </div>
                :
                <FormProvider {...methods} >
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <SelectReasons pmskey={pmskey}/>
                    </form>
                </FormProvider>
            }
        </>
    )

}
export const formatPrice = (x, currency) => {
  switch (currency) {
    case 'BRL':
      return x.toFixed(2).replace('.', ',');
    default:
      return x.toFixed(2);
  }
};

export const returnCurrency = (currency) => {
  switch (currency){
    case "EUR":
      return "€";
    default:
      return "€"
  }
}

// export const productsAPI = "http://localhost:8001/api/products";
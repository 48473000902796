import React from "react";
import {useTranslation} from "react-i18next";

export default function LoginForm() {

    const {t} = useTranslation('common');

    return (
        <React.Fragment>
            <dd>
                <div className="input-wrapper max-padding">
                    <p className="site-color"><strong> {t('msg.glad_to_see_you').toUpperCase()} </strong></p>
                    <p className="site-color"> {t('msg.login_advantages')}</p>
                </div>
                <div className="column-inputs">
                    <div className="input-wrapper">
                        <a className="final-btn" href="/login">{t('words.login')}</a>
                        <a href="/password-reset"
                           target="_blank">{t('msg.lost_password')}</a>
                    </div>
                </div>
            </dd>
        </React.Fragment>
    )
}
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from './theme-context';

// eslint-disable-next-line import/prefer-default-export
export class ThemeProvider extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.updateCSSVariables(this.props.variables);
  }

  componentDidUpdate(prevProps) {
    if(this.props.variables === undefined) {
      this.updateCSSVariables(prevProps.variables);
    }
    else if (this.props.variables !== prevProps.variables) {
      this.updateCSSVariables(this.props.variables);
    }
  }

  updateCSSVariables = (variables) => {
    Object.entries(variables).forEach(([prop, value]) => document.documentElement.style.setProperty(prop, value));
  }

  render() {
    return (
      <ThemeContext.Provider value={this.props.theme}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

ThemeProvider.propTypes = {
  variables: PropTypes.object,
  children: PropTypes.node
};
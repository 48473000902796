//import { Flex, Text } from "@chakra-ui/react"


export default function HotelDesc({description}){

    return(
        <div w='100%' p='30px 35px 35px' className="description">
            <p>
            {description}
            </p>
        </div>

    )
}
import {
    CONFIRM_BUTTON,
} from './types';


export const confirmBookingButtonUpdate = data => {
    return {
        type: CONFIRM_BUTTON,
        payload: data,
    };
};
import {GET_INSOURANCES_INITIALIZE, SET_INSOURANCE, NO_INSOURANCE} from "../actions/types";
import insurances_json from './insurances.json'

const initialState = {
    insurances: {},
    selected_insourance: {},
    no_insourances: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_INSOURANCES_INITIALIZE:
            return {
                ...state,
                // insourances: action.payload
                insurances: insurances_json
            };
        case SET_INSOURANCE:
            return {
                ...state,
                selected_insourance: action.payload
            };
        case NO_INSOURANCE:
            return {
                ...state,
                selected_insourance: {},
                no_insourances: true
            };
        default:
            return state;
    }
}

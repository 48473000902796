import React, { useState } from 'react';
import ProductList from './ProductList';
import Card from "@material-ui/core/Card/Card";
import RoomGallery from "../RoomGallery";
import { useTranslation, withTranslation } from "react-i18next";
import { PreviewRoom } from '../../PreviewRoom/PreviewRoom';
import { connect, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { SearchBox } from "../../SearchBox/SearchBox";
import { baseBoardtypesFilter, clubBoardtypesFilter } from "../../../services/shelf/actions";
import { addProduct, addProductDingus } from "../../../services/cart/actions";
import { Link, animateScroll as scroll } from "react-scroll";

const RoomList = ({ products, productsclub, hotel, hotel_chains, provider }) => {
    
    const { t, i18n } = useTranslation('common');

    const upRooms = ["UP", "XT", "VS", "UPS"];
    const [open_popup, setOpen_popup] = useState(false);

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const flightsActive = useSelector(state => state.flights.isActive);

    const arrival_date = new Date(useSelector(state => state.search.payload.arrival));
    const today = new Date(Date.now());

    let roomConfig = "room-config";
    if (isAuthenticated || flightsActive || process.env.REACT_APP_INSTANCE !== "SPRING") {
        roomConfig = "room-config spring-club-user";
    }

    let room_availability = 0; // Variable para comprobar la disponibilidad de las habitaciones y controlar el modal de disponibilidad
    let room_availability_aux = 0; // Variable auxiliar necesaria para la comprobacion de la disponibilidad
    const availability = () => {
        products.map((p) => {
            p.boardtypes.map((pro) => {
                if (pro.availability <= 0) {
                    room_availability++;
                }
                room_availability_aux++;
            })
        })
        return (room_availability_aux - room_availability);
    }
    let count = 0;
    const popup_open = () => {
        count = count + 1;
        return count;
    }

    return (
        <>
            {(arrival_date.getDate() !== today.getDate() && Date.parse(arrival_date) < Date.now()) ?
                <>
                    {popup_open() <= 1 &&
                        <Popup modal key={"popup"} open={true}
                            position="right center">
                            <div id="availability_modal_info" className="availability-modal">
                                <div className="header-navigation">
                                    <a href="/search.php" className="back-link left-link"
                                        title="Volver al buscador">
                                        <svg width="24" height="21" viewBox="0 0 24 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.2926 0L0 10.2857L10.2892 20.5714L11.5012 19.3628L3.28114 11.1428H24V9.42857H3.28114L11.5046 1.20513L10.2926 0Z"
                                                fill="currentColor" />
                                        </svg>
                                    </a>
                                </div>
                                {/* TODO Traduccion */}
                                <div className="header-titles center-text">
                                    <h2 className="title">{t('popup_error.dates_fail')}</h2>
                                    <p>{t('popup_errors.dates_fail_description')}</p>
                                </div>
                                <SearchBox lang={i18n} modal_mode={true} no_availability={true} />
                            </div>
                        </Popup>
                    }
                </>
                :
                <div>
                    
                    {products.map((p, index) => {
                        let roomstyle = "room-card ";
                        if (upRooms.includes(p.translation_code)) {
                            roomstyle += "up-room"
                        }
                        let no_availability = false;
                        if (availability() <= 0) {
                            no_availability = true;
                        }
                        let room_title = "";
                        let room_description = "";
                        if (provider === "DINGUS" || provider === 'ORBIS') {
                            room_title = p.room_title;
                            room_description = p.room_description;
                        } else {
                            room_title = t([`roomtypes.${p.translation_code}`, 'roomtypes.unspecific']);
                            room_description = t([`roomtypes_description.${p.translation_code}`, 'roomtypes_description.unspecific']);
                        }

                        return (
                            <div key={index}>
                                {popup_open() <= 1 && !isAuthenticated &&
                                    <Popup modal key={"popup" + index} open={no_availability}
                                        position="right center">
                                        <div id="availability_modal_info" className="availability-modal">
                                            <div className="header-navigation">
                                                <a href="/" className="back-link left-link"
                                                    title="Volver al buscador">
                                                    <svg width="24" height="21" viewBox="0 0 24 21" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.2926 0L0 10.2857L10.2892 20.5714L11.5012 19.3628L3.28114 11.1428H24V9.42857H3.28114L11.5046 1.20513L10.2926 0Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </a>
                                            </div>

                                            <div className="header-titles center-text">
                                                <h2 className="title">{t('result.header_modal_no_availability')}</h2>
                                                <p>{t('result.text_modal_no_availability_1')}
                                                    <strong>{hotel.toUpperCase()}</strong> {t('result.text_modal_no_availability_2')}
                                                </p>
                                            </div>
                                            <SearchBox lang={i18n} modal_mode={true} no_availability={true} />
                                        </div>
                                    </Popup>
                                }

                                <Card key={"card" + index} className={roomstyle}>
                                    {/*<span className="room-label" styles="background-image:url(assets/img/icons/vulcano/ico-up.svg);">Habitación UP!</span>*/}


                                    <header className="room-card-header">
                                        <div className="img-visor">
                                            <RoomGallery sliderimages={p.images_room} />
                                        </div>

                                        <div className="room-info">
                                            <h2 className="title">
                                                {room_title}
                                            </h2>

                                            <p className="description">
                                                {room_description}
                                            </p>

                                            {/* <PreviewRoom product={p} hotel={hotel}/> */}
                                        </div>
                                    </header>

                                    <div id={"#ancla" + index}></div>

                                    <div
                                        className={open_popup ? roomConfig + " opened-popup title-popup" : roomConfig}>
                                        <div className="title-row">
                                            <div className="title">
                                                <strong
                                                    className="primary-title-font">{t('result.select_board')}:</strong>
                                            </div>
                                            <div className="standar-price">
                                                <p><strong
                                                    className="primary-title-font">{t('result.price_standar')} </strong>
                                                    <Popup modal onOpen={() => {
                                                        setOpen_popup(true);
                                                    }} closeOnDocumentClick onClose={() => {
                                                        setOpen_popup(false);
                                                    }} trigger={
                                                        <a className="primary-title-font motor-modal-link">
                                                            <svg width="14" height="14" viewBox="0 0 14 14"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                    d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                                    fill="currentColor" />
                                                            </svg>
                                                        </a>
                                                    }>
                                                        <div id="modal_standar_selected"
                                                            className="standar-selected-modal center-text">
                                                            <h3>{t("result.standar_price")}</h3>
                                                            <p>
                                                                <svg width="22" height="22" viewBox="0 0 22 22"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                        d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z"
                                                                        fill="#7FB334" />
                                                                </svg>
                                                                <strong>{products[0].boardtypes[0]?.offertext}</strong>
                                                            </p>
                                                            <p>
                                                                {t("result.text_standar_price_2")}
                                                            </p>
                                                            <p>{t("result.text_standar_price_3")}</p>
                                                        </div>
                                                    </Popup>
                                                </p>
                                            </div>
                                            
                                            <div className="club-price">
                                                {!flightsActive && process.env.REACT_APP_INSTANCE === "SPRING" &&
                                                    <>
                                                        <p>
                                                            <strong
                                                                className="primary-title-font">{t('spring.club')} </strong>
                                                            <Popup modal onOpen={() => {
                                                                setOpen_popup(true);
                                                            }} closeOnDocumentClick onClose={() => {
                                                                setOpen_popup(false);
                                                            }} trigger={
                                                                <a className="primary-title-font motor-modal-link">
                                                                    <svg width="14" height="14" viewBox="0 0 14 14"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                                            d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                                            fill="currentColor" />
                                                                    </svg>
                                                                </a>
                                                            }>
                                                                <div className="discount-info-wrapper">
                                                                    <h3>{t("result.spring_club_tariff")}</h3>
                                                                    <ul className="no-list">
                                                                        <li>
                                                                            <svg width="22" height="22"
                                                                                viewBox="0 0 22 22"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd"
                                                                                    clipRule="evenodd"
                                                                                    d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z"
                                                                                    fill="#7FB334" />
                                                                            </svg>
                                                                            <strong>{productsclub[0].boardtypes[0]?.offertext}</strong>
                                                                        </li>
                                                                        <li>
                                                                            <svg width="22" height="22"
                                                                                viewBox="0 0 22 22"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path fillRule="evenodd"
                                                                                    clipRule="evenodd"
                                                                                    d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z"
                                                                                    fill="#7FB334" />
                                                                            </svg>
                                                                            <strong>{t("result.spring_club_promotion")}</strong>
                                                                        </li>
                                                                    </ul>
                                                                    <p>
                                                                        {isAuthenticated || flightsActive || process.env.REACT_APP_INSTANCE !== "SPRING" ?
                                                                            <>
                                                                                {t("result.congratulations")}
                                                                                <br />
                                                                                {t("result.text_club_info")}
                                                                            </>
                                                                            :

                                                                            <>
                                                                                {t("result.text_standar_info")}
                                                                            </>
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </Popup>
                                                        </p>
                                                        {isAuthenticated || flightsActive || process.env.REACT_APP_INSTANCE !== "SPRING" ? null :
                                                            <p className="grey-color">{t('spring.5_club_discount')}</p>}
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        <dl className="room-pensions drop-accordion drop-mobile-accordion">

                                            <ProductList products={p.boardtypes} translate_room={p.translation_code}
                                                productsclub={productsclub[index].boardtypes} room_title={room_title} hotel_chains={hotel_chains} provider={provider} />

                                        </dl>
                                    </div>
                                </Card>

                            </div>)
                            ;
                        // CODIGO COMENTADO: MODAL DE NO DISPONIBILDIAD (DEPRECATED)
                        // <Popup modal open={update_products} closeOnDocumentClick={false}
                        //        position="right center">
                        //     <div id="availability_modal_info" className="availability-modal">
                        //         <div className="header-navigation">
                        //             <a href="/search.php" className="back-link left-link"
                        //                title="Volver al buscador">
                        //                 <svg width="24" height="21" viewBox="0 0 24 21" fill="none"
                        //                      xmlns="http://www.w3.org/2000/svg">
                        //                     <path
                        //                         d="M10.2926 0L0 10.2857L10.2892 20.5714L11.5012 19.3628L3.28114 11.1428H24V9.42857H3.28114L11.5046 1.20513L10.2926 0Z"
                        //                         fill="currentColor"/>
                        //                 </svg>
                        //             </a>
                        //         </div>
                        //
                        //         <div className="header-titles center-text">
                        //             <h1 className="title">{t('result.search_error_title')}</h1>
                        //             <h3>{t("result.search_error_description")}</h3>
                        //         </div>
                        //         <SearchBox lang={i18n} modal_mode={true} no_availability={true}/>
                        //     </div>
                        // </Popup>
                    })
                    }
                </div>
            }
        </>
    )
};

export default connect(null, { baseBoardtypesFilter, clubBoardtypesFilter })(withTranslation('common')(RoomList));


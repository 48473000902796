import {
    SEARCH,
    SEARCH_SUCCESS,
    SEARCH_FAILURE,
    GET_TOKEN,
    SET_TOKEN_EXPIRATION_TIME,
    SET_MULTIHOTEL,
    SET_EXPIRATION_TIME,
    SET_IPSTACK_DATA,
} from '../actions/types.js'

export default function search(state = {}, action = {}) {
    switch (action.type) {
        case SEARCH:
            return {
                ...state,
                searching: true,
                payload: action.payload
            };
        case SEARCH_SUCCESS:
            return {
                ...state,
                searching: false,
                expiration_time: false,
            };
        case SEARCH_FAILURE:
            return {
                ...state,
                searching: false
            };
        case GET_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case SET_TOKEN_EXPIRATION_TIME:
            return {
                ...state,
                token_expiration_time: action.payload
            }
        case SET_MULTIHOTEL:
            return {
                ...state,
                multihotel: action.payload
            }
        case SET_EXPIRATION_TIME:
            return {
                ...state,
                expiration_time: action.payload
            }
        case SET_IPSTACK_DATA:
            return {
                ...state,
                ipstack_data: action.payload
            }
        default:
            return state
    }
}
import React, {Component} from "react";
import {connect} from 'react-redux';
import {login, registerWithEmail} from "../../../actions/auth";
import {updateStep} from "../../../actions/stepProcess";
import {Cookies, withCookies} from "react-cookie";
import {withTranslation} from "react-i18next";
import PropTypes, {instanceOf} from "prop-types";
import RegisterForm from "./RegisterForm";
import Spinner from "../../Spinner";

class Login extends Component {

    state = {
        username: "",
        password: "",
        agency: false,
    };

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
        cookies: instanceOf(Cookies).isRequired
    };

    onSubmit = e => {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password, this.state.agency, this.props.cookies, true);
    };

    onChange = e => this.setState({[e.target.name]: e.target.value});

    render() {

        const {username, password} = this.state;
        const {t, registerWithEmail, emailFlag, updateStep} = this.props;

        return (
            <>
                {!emailFlag ?
                    <div className="search-result container">
                        <div className="search-result-content">
                            <div className="MuiBox-root jss2 search-result-list checkout">
                                <article className="login-form-wrapper">
                                    <div className="form-box">
                                        <h3 className="title">{t('words.access_now')}</h3>
                                        <form className="login-form form-style" onSubmit={this.onSubmit}>
                                            <div className="input-wrapper simple-input">
                                                <label htmlFor="user">{t('words.email')}</label>
                                                <input type="text" id="username" name="username"
                                                       placeholder={t('msg.input_email_spring')}
                                                       value={username}
                                                       onChange={this.onChange}
                                                       required
                                                />
                                            </div>
                                            <div className="input-wrapper simple-input">
                                                <label htmlFor="password">{t('words.password')}</label>
                                                <input type="password" id="password" name="password"
                                                       placeholder={t('msg.input_password')}
                                                       value={password}
                                                       onChange={this.onChange}
                                                       required
                                                />
                                            </div>
                                            <input className="btn btn-primary full-weidth" type="submit"
                                                   value={t('words.login')}/>
                                            <p>No recuerdo la contraseña y <a href="/password-reset" target="_blanck">quiero
                                                restablecerla</a></p>
                                        </form>
                                    </div>
                                    <div className="form-box">
                                        <h4 className="sub-title">{t('msg.new')}</h4>
                                        {/*<a className="btn full-weidth btn-register"*/}
                                        {/*   onClick={() => {*/}
                                        {/*       registerWithEmail(true)*/}
                                        {/*   }}>*/}
                                        {/*    <span>{t('words.register')}</span>*/}
                                        {/*</a>*/}
                                        <a className="btn full-weidth btn-register"
                                           onClick={() => {
                                               updateStep({
                                                   id: 4,
                                                   name: "FORM",
                                                   description: "titles.confirm"
                                               })
                                           }}>
                                            <span>Continuar sin hacer login</span>
                                        </a>
                                    </div>
                                </article>
                            </div>
                        </div>
                        {this.props.isLoading && <Spinner step={"search"}/>}
                    </div>
                    :
                    <RegisterForm/>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    emailFlag: state.auth.registerWithEmail
});

export default connect(
    mapStateToProps, {login, registerWithEmail, updateStep}
)(withCookies(withTranslation('common')(Login)));
import React from "react";
import {useTranslation} from "react-i18next";


export default function AgentCard(props) {
    const {t, i18n} = useTranslation('common');

    return (
        <article className="booking-card">
            <div className="booking-img" style={{backgroundImage: `url("${props.agent.image}")`}}>
                <img src={props.agent.image}/>
            </div>
            <div className="booking-info">
                <div className="principal-info">
                    {/*<p className="title">Agente</p>*/}
                    <p className="h2 locator">{props.agent.name}</p>
                    <p className="info">
                        <strong>{props.agent.agency_name}</strong>
                    </p>
                    <table className="date">
                        <tr>
                            <th></th>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                    </table>
                </div>
                <div className="footer-info">
                    <p className="price">{props.agent.email}</p>
                    <p className="links-wrapper">
                        <a className="right-link">{t('words.modify')}</a>
                    </p>
                </div>
            </div>
        </article>
    )
}
import {React, useState, useEffect} from "react";
import queryString from "query-string";
import Popup from 'reactjs-popup';
import Toggle from 'react-toggle'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { spring40Update } from "../../actions/agency";
import Timer from "../EngineResult/Timer/Timer";
import { format } from 'date-fns';
import { SearchBox } from "../SearchBox/SearchBox";

import ImgSearch from "../../static/img/icons/ico-search.svg";
import BookingProcess from "../EngineResult/BookingProcess/BookingProcess";


export function SearchResult(props) {

    const data = queryString.parse(props.search_query);
    const hotel = props.hotel;
    const products = useSelector(state => state.cart);
    const isModifying = useSelector(state => state.reservations.modification?.isModifying);
    const isSpring40 = useSelector(state => state.auth.agency?.is_spring40);
    const spring40 = useSelector(state => state.agency?.spring40active) || data?.spring40;
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth); //hook para establecer el ancho de pantalla

    const { t, i18n } = useTranslation('common');

    const childs_ages = () => {
        let children_text = "(";
        if (Array.isArray(data?.children_ages)) { //Si hay mas de un niño
            data?.children_ages?.map((children_age, index) => {
                if (index === data.children_ages.length - 1) {
                    // Controlar singular y plural de la ultima edad
                    if (parseInt(children_age) !== 1) {
                        children_text += children_age + " " + t('search.years') + "), ";
                    }
                    else if (parseInt(children_age) === 1) {
                        children_text += children_age + " " + t('search.year') + "), ";
                    }
                } else {
                    children_text += children_age + ", ";
                }
            })
        } else { // Si solo hay un niño
            if (data?.children_ages) {
                // Controlar singular y plural de la ultima edad
                if (parseInt(data.children_ages) !== 1) {
                    children_text += data.children_ages + " " + t('search.years') + "), ";
                }
                else if (parseInt(data.children_ages) === 1) {
                    children_text += data.children_ages + " " + t('search.year') + "), ";
                }
            }
            else {
                children_text = "";
            }
        }
        return children_text;
    };

    const childs_num = () => {
        if (parseInt(data?.room_num_childs) > 0) {
            return data?.room_num_childs + " " + t('person.children')
        }
    };

    
    // Cambiamos el valor booleano de la variable isMobile dependiendo si el ancho mide menos o mas de 741
    // esta variable será la que le pasemos al componente <BookingProcess>
    (width < 741 ? isMobile = true : isMobile = false);    
    
    const handleResize = () => {
        setWidth(window.innerWidth); // Se va actualizando el ancho
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);    // Cada vez que la pantalla sufra un resize se llama a handleResize
    }, []);


    return (
        <>
            {isModifying ?
                <>
                    <div className="current-search-bar site-bg">
                        <div className="container">
                            <div className="current-search">
                                <h1 className="title">{t('search.your')}</h1>
                                <h3 className="search-txt">
                                    {process.env.REACT_APP_INSTANCE}, {data.adults} {t('person.adults')}, {childs_num()} {childs_ages()} {format(new Date(data.arrival), "dd/MM/yyyy")} {t('words.to')} {format(new Date(data.departure), "dd/MM/yyyy")}
                                    {products.products.length > 0 ? ", " + products.products[0].hotel_name : ''}
                                    {data.resident === "true" ? ", " + t('words.resident') : null}
                                </h3>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Popup modal trigger={
                    <>
                        <div className="current-search-bar site-bg">
                            <div className="container">
                                <div className="current-search">
                                    <h1 className="title">{t('search.your')}</h1>
                                    <h3 className="search-txt">
                                        {process.env.REACT_APP_INSTANCE}, {data.adults} {t('person.adults')}, {childs_num()} {childs_ages()} {format(new Date(data.arrival), "dd/MM/yyyy")} {t('words.to')} {format(new Date(data.departure), "dd/MM/yyyy")}
                                        {products.products.length > 0 ? ", " + products.products[0].hotel_name : ''}
                                        {data.resident === "true" ? ", " + t('words.resident') : null}
                                    </h3>
                                </div>
                                <a title={t('search.modify')} className="search-btn ico-search">
                                    <img src={ImgSearch} alt="logo-img" />
                                </a>
                            </div>
                            {isMobile &&
                                <div className="current-search-bar" id="breadcrumb-mobile">
                                    <p>asdf</p>
                                    <BookingProcess process_step={"results"} mobile_detect={isMobile}/> 
                                </div>
                            }
                        </div>
                    </>
                } position="right center">
                    <SearchBox lang={i18n} modal_mode={true} isSpring40={isSpring40} />
                </Popup>
            }
        </>
    )
}
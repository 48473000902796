import React from 'react';

import Product from '../Product';

const ProductList = ({products, translate_room}) => {
  return products.map(p => {
    p.translation_code = translate_room;
    return <Product product={p} translate_room={p.translation_code} key={p.id} />;
  });
};

export default ProductList;
import { GET_HOTEL_PRICES } from '../actions/types.js'

const initialState = {
    hotelprices: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HOTEL_PRICES:
            return{
                ...state,
                hotelprices: action.payload
            };
        default:
            return state;
    }
}
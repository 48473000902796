import React from 'react';
import ProductList from './ProductList';
import Card from "@material-ui/core/Card/Card";
import RoomGallery from "../RoomGallery";
import {useTranslation, withTranslation} from "react-i18next";
import {PreviewRoom} from '../../PreviewRoom/PreviewRoom';
import {useSelector} from "react-redux";


const RoomListAgency = ({products, hotel}) => {

    const {t} = useTranslation('common');
    const isCommissionView = useSelector(state => state.agency.isCommissionView);
    const commisionvalue = Math.round(useSelector(state => state.auth.agency.descuento));
    const upRooms = ["UP", "XT", "VS", "UPS"];

    return (
        <div>
            {products?.length > 0 ?
                products.map(p => {
                    let roomstyle = "room-card ";
                    if(upRooms.includes(p.translation_code)) {
                        roomstyle+= "up-room"
                    }
                    return <>
                        <Card className={roomstyle}>
                            <header className="room-card-header">
                                <div className="img-visor">
                                    <RoomGallery sliderimages={p.images_room}/>
                                </div>

                                <div className="room-info">
                                    <h2 className="title">
                                        {t([`roomtypes.${p.translation_code}`, 'roomtypes.unspecific'])}
                                    </h2>

                                    <p className="description">
                                        {t([`roomtypes_description.${p.translation_code}`, 'roomtypes_description.unspecific'])}
                                    </p>

                                    <PreviewRoom product={p}/>
                                </div>
                            </header>
                            <div className="room-config">
                                <div className="title-row">
                                    <div className="title">
                                        <strong className="primary-title-font">{t('result.select_board')}:</strong>
                                    </div>
                                    <div className="club-price">
                                        <p><strong
                                            className="primary-title-font">{t('msg.pvp').toUpperCase()}</strong>
                                        </p>
                                    </div>
                                    <div className="standar-price">
                                        {isCommissionView ?
                                            <p><strong
                                                className="primary-title-font">{t('words.commission').toUpperCase()} ({commisionvalue}%)</strong>
                                            </p>
                                            :
                                            <p><strong
                                                className="primary-title-font">{t('msg.discount_applied').toUpperCase()}</strong>
                                            </p>
                                        }
                                    </div>
                                </div>

                                <dl className="room-pensions drop-accordion drop-mobile-accordion">

                                    <ProductList products={p.boardtypes} translate_room={p.translation_code}/>

                                </dl>

                                {/*<Extras open={isOpen}/> */}

                            </div>
                        </Card>

                    </>;
                }) : null
            }
        </div>)
};

export default withTranslation('common')(RoomListAgency);



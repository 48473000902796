// App.js
import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {Provider} from 'react-redux';
import store from "./store";

import Register from "./components/accounts/Register";
import LoginPage from "./components/Login/LoginPage";
import LoginPageCallCenter from "./components/LoginCallCenter/LoginPageCallCenter";

import {Provider as AlertProvider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Alerts from "./components/layout/Alerts";
import {loadUser, autoLoadUser} from "./actions/auth";
import {Cookies, withCookies} from 'react-cookie';
import {Helmet, HelmetProvider} from "react-helmet-async";

import CheckOut from "./components/Checkout/checkout";
import Dashboard from "./components/Dashboard";

import "./static/css/style.css";
import '../node_modules/animate.css/animate.css';

import {ThemeProvider} from './theme-provider';
import {AronaTheme} from "./themes/arona-theme";
import {BitacoraTheme} from "./themes/bitacora-theme";
import {VulcanoTheme} from "./themes/vulcano-theme";
import {SpringTheme} from "./themes/spring-theme";
import {OceanBookingTheme} from "./themes/oceanbooking-theme";
import {GfHotelsTheme} from "./themes/gfhotels-theme";
import {BullHotelsTheme} from "./themes/bullhotels-theme";

import BookingEngine from "./components/BookingEngine/BookingEngine";
import Success from "./components/Success/Success";
import {PageSearchBox} from "./components/PageSearchBox/PageSearchBox";
import {withTranslation} from "react-i18next";

import THNScript from "./components/TheHotelsNetwork";
import PasswordRecovery from "./components/PasswordRecovery";
import ModifyPassword from "./components/PasswordRecovery/ModifyPassword";
import BookingDetail from "./components/Dashboard/Bookings/BookingDetail";
import Cancelation from "./components/Dashboard/Cancelation";
import BookingRoom from "./components/Dashboard/Bookings/BookingRoom";
import PdfResponse from './components/EngineResult/PDF/PdfResponse';
import CacheBuster from "react-cache-buster";

const AGENCY_MODE_ACTIVATED = (process.env.REACT_APP_AGENCY_MODE_ACTIVATED === 'true')

// Alert Options
const alertOptions = {
    timeout: 3000,
    position: "middle",
};

class ProtectedRoute extends Component {
    render() {
        const {component: Component, ...props} = this.props
        const auth = store.getState().auth;

        if (AGENCY_MODE_ACTIVATED) {
            return (
                <Route {...props} render={props => (
                    auth.isAuthenticated ?
                        <Component {...props} /> :
                        <Redirect to='/login'/>
                )}
                />
            )
        } else {
            return (
                <Route {...props} render={props => (
                    <Component {...props} />
                )}
                />
            )
        }
    }
}

class App extends Component {

    constructor(props) {
        super(props);
        this.arona = AronaTheme;
        this.bitacora = BitacoraTheme;
        this.vulcano = VulcanoTheme;
        this.spring = SpringTheme;
        this.hotels = OceanBookingTheme;
        this.gfthemes = GfHotelsTheme;
        this.bullhotels = BullHotelsTheme;
        this.state = {
            theme: this.spring,
            hotelName: "spring",
            ip: "",
        };
    }

    handleSelectOnTheme = (themeName) => {
        this.setState({
            theme: this[themeName],
            hotelName: themeName,
        });
    };


    async componentDidMount() {
        process.env.REACT_APP_INSTANCE === "OCEANBOOKING" && this.handleSelectOnTheme("hotels");
        process.env.REACT_APP_INSTANCE === "GFHOTELS" && this.handleSelectOnTheme("gfthemes");
        process.env.REACT_APP_INSTANCE === "SPRING" && this.handleSelectOnTheme("spring");
        process.env.REACT_APP_INSTANCE === "BULLHOTELS" && this.handleSelectOnTheme("bullhotels");

        /*
        const storeRedux = store.getState();

        if (storeRedux.auth.isAuthenticated) {
            store.dispatch(loadUser());
        } else if (this.props.cookies.cookies['sph-auth']) {
            store.dispatch(autoLoadUser(this.props.cookies));
        }
        */
    }

    componentDidUpdate(prevProps, prevState, snapshot): void {
        if (this.state.hotelName !== "spring") {
            THNScript(this.state.hotelName);
        }
    }

    render() {
        const {t} = this.props;
        const isProduction = process.env.REACT_APP_PASS_CHECKOUT === 'true';

        return (
            <CacheBuster
                currentVersion={"0.1.0"}
                isEnabled={isProduction} //If false, the library is disabled.
                isVerboseMode={false} //If true, the library writes verbose logs to console.
                // loadingComponent={<Loading/>} //If not pass, nothing appears at the time of new version check.
                metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
            >
                <HelmetProvider>
                    <Helmet>
                        <title>{process.env.REACT_APP_INSTANCE}</title>
                    </Helmet>
                    <Provider store={store}>
                        <ThemeProvider variables={this.state.theme}>
                            <AlertProvider template={AlertTemplate} {...alertOptions}>
                                <Router>
                                    <Alerts/>
                                    <Switch>
                                        <ProtectedRoute exact path="/binterna" component={PageSearchBox}
                                                        interno={true}/>

                                        {/*RUTAS SIEMPRE ACCESIBLES*/}
                                        <Route exact path="/.well-known/acme-challenge"></Route>
                                        <Route exact path="/password-reset">
                                            <PasswordRecovery/>
                                        </Route>
                                        <Route path="/password-modify/:email/:token">
                                            <ModifyPassword/>
                                        </Route>
                                        <Route exact path="/login" component={LoginPage}/>
                                        <Route exact path="/contact-centre" component={LoginPageCallCenter}/>
                                        <Route exact path="/register" component={Register}/>
                                        <Route exact path="/pdf">
                                            <PdfResponse/>
                                        </Route>
                                        <Route path="/user">
                                            <Dashboard page="userdata"/>
                                        </Route>
                                        <Route exact path="/myreservations">
                                            <Dashboard page="bookings"/>
                                        </Route>
                                        <Route exact path="/myreservations/room/:token">
                                            <BookingRoom/>
                                        </Route>
                                        {/*FIN RUTAS SIEMPRE ACCESIBLES*/}

                                        {/*RUTAS PROTEGIDAS EN MODO AGENCIAS*/}
                                        <ProtectedRoute exact path="/search" component={PageSearchBox}/>
                                        <ProtectedRoute exact path="/" component={PageSearchBox}/>
                                        {/*FIN RUTAS PROTEGIDAS EN MODO AGENCIAS*/}

                                        {/*RUTAS PROTEGIDAS DENTRO DE CADA UNA*/}
                                        <Route path="/:hotelname?" exact render={(props) => {
                                            if (props.match.params.hotelname === "arona" ||
                                                props.match.params.hotelname === "bitacora" ||
                                                props.match.params.hotelname === "vulcano" ||
                                                props.match.params.hotelname === "victoria" ||
                                                props.match.params.hotelname === "noelia" ||
                                                props.match.params.hotelname === "fanabe" ||
                                                props.match.params.hotelname === "isabel" ||
                                                props.match.params.hotelname === "adeje" ||
                                                props.match.params.hotelname === "hotels") {
                                                return <BookingEngine {...props}
                                                                      handleSelectOnThemesInApp={this.handleSelectOnTheme}/>
                                            } else {
                                                return <Redirect to="/"/>
                                            }
                                        }}/>
                                        <Route path="/checkout/:hotelname?" exact render={(props) => (
                                            <CheckOut {...props} handleSelectOnThemesInApp={this.handleSelectOnTheme}/>
                                        )}/>
                                        <Route path="/success/:hotelname?" exact render={(props) => (
                                            <Success {...props} handleSelectOnThemesInApp={this.handleSelectOnTheme}/>
                                        )}/>

                                        {/*RUTAS DESACTIVADAS TEMPORALMENTE

                                <Route exact path="/agency/agents">
                                    <Dashboard page="agents"/>
                                </Route>
                                <Route exact path="/myreservations/detail/:idreservation">
                                    <BookingDetail/>
                                </Route>
                                <Route exact path="/myreservations/cancelation/room/:pmskey">
                                    <Cancelation/>
                                </Route>
                                FIN RUTAS DESACTIVADAS TEMPORALMENTE*/}

                                    </Switch>
                                </Router>
                            </AlertProvider>
                        </ThemeProvider>
                    </Provider>
                </HelmetProvider>
            </CacheBuster>
        );
    }

}

export default withCookies(withTranslation('common')(App));
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { returnCurrency } from "../../../services/util";
import { isMobile } from "react-device-detect";
import Collapse from '@material-ui/core/Collapse';
import {toggleExtrasCart} from "../../../services/cart/actions";

class CartExtras extends Component {

    openCart = () => {
        this.props.toggleExtrasCart(!this.props.cart_extras);
    };

    render() {

        const { t, extras, cart_extras } = this.props;
        const cartProducts = this.props;

        return (
            <>
                {/* TODO Traduccion */}
                <div className="extras-list-header" onClick={this.openCart}>
                    <div className="header-info">
                        <p className="title"><strong>{t('result.extras')} </strong></p>
                        <p className="price"><strong>{parseFloat(extras.total_price).toFixed(2)} {returnCurrency(extras.currency)}</strong></p>
                    </div>
                    <p><strong>{cart_extras ? t('cart.see_less') : t('cart.see_more') }</strong></p>
                </div>

                <Collapse in={cart_extras} >
                    {extras.selected_luggage.map((luggage) => {
                        return (
                            <div className="cart-extra">
                                <p>{luggage.flight_type === "OUTBOUND" ? t('cart.outbound_luggage') : t('cart.inbound_luggage')} {parseInt(luggage.passengerId) + 1}</p>
                                    <p className="price"><strong>{parseFloat(luggage.pricingDetail).toFixed(2)} {returnCurrency("EUR")}</strong></p>
                            </div>
                        )
                    })}

                    {extras.selected_transfer.state &&
                        <div className="cart-extra">
                            <p className="title">Taxi transfer Tenerife Sur</p>
                            {extras.transfer_info.price == 0 ?
                                <p className="price">{t('words.included')}</p>
                                :
                                <p className="price"><strong>{parseFloat(extras.selected_transfer.price).toFixed(2)} {returnCurrency(extras.selected_transfer.currency)}</strong></p>
                            }
                        </div>
                    }
                </Collapse>
            </>
        );
    }
}

const mapStateToProps = state => ({
    extras: state.extras,
    cartProducts: state.cart.products,
    cart_extras: state.cart.cart_extras,
});

export default connect(mapStateToProps, {toggleExtrasCart})(withTranslation('common')(CartExtras));
const VulcanoTheme = {
    '@import':
        "url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap')",

    // FONTS
    '@font-face': [
        {
            fontFamily: 'Stanley',
            src: 'url(../static/font/Stanley-Bold-Italic.ttf',
            fontWeight: 'bold',
            fontStyle: 'italic'
        },
        {
            fontFamily: 'Stanley',
            src: 'url(../static/font/Stanley-Regular-Italic.ttf',
            fontWeight: 'normal',
            fontStyle: 'italic'
        },
        {
            fontFamily: 'Stanley',
            src: 'url(../static/font/Stanley-Bold.ttf',
            fontWeight: 'bold',
            fontStyle: 'normal'
        },
        {
            fontFamily: 'Stanley',
            src: 'url(../static/font/Stanley-Regular.ttf',
            fontWeight: 'normal',
            fontStyle: 'normal'
        },
        {
            fontFamily: 'Brown',
            src: 'url(../static/font/Brown-ThinItalic.ttf',
            fontWeight: 300,
            fontStyle: 'italic'
        },
        {
            fontFamily: 'Brown',
            src: 'url(../static/font/Brown-RegularItalic.ttf',
            fontWeight: 'normal',
            fontStyle: 'italic'
        },
        {
            fontFamily: 'Brown',
            src: 'url(../static/font/Brown-BoldItalic.ttf',
            fontWeight: 'bold',
            fontStyle: 'italic'
        },
        {
            fontFamily: 'Brown',
            src: 'url(../static/font/Brown-Thin.ttf',
            fontWeight: 300,
            fontStyle: 'italic'
        },
        {
            fontFamily: 'Brown',
            src: 'url(../static/font/Brown-Regular.ttf',
            fontWeight: 'normal',
            fontStyle: 'normal'
        },
        {
            fontFamily: 'Brown',
            src: 'url(../static/font/Brown-Bold.ttf',
            fontWeight: 'bold',
            fontStyle: 'normal'
        },
    ],

    '--site-font': "Lato, sans-serif",
    '--site-primary-title-font': "Brown, Stanley, Lato, sans-serif",
    '--site-title-font': "Stanley, Brown, Lato, sans-serif",

    // COLORS
    '--text-color': '#2B2B2B',
    '--text-color-light': '#8B8B8B',
    '--text-color-header': '#FFFFFF',

    '--grey-color': '#979797',
    '--grey-color-light': '#F4F4F4',

    '--green-color': '#84B440',
    '--green-color-light': '#E5F2D3',

    '--red-color': '#CC4531',

    '--site-color': '#004232',
    '--site-color-header': '#004232',
    '--site-color-light': '#F2F5F5',
    '--site-color-hover': '#195446',

    '--site-color-button': '#004232',

    '--site-color-button-seacrh': '#004232',
    '--site-border-color-search': '#004232',
    '--site-color-button-search-hover': '#195446',

    '--site-border-color': '#CCD9D6',

    
    '--site-color-up': '#B9A064',

    '--site-color-spring': '#002257',
};

export { VulcanoTheme };
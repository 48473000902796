import {connect, useSelector} from "react-redux";
import {updateStep} from "../../../actions/stepProcess";

import React, {useState} from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import {CardInput, CardInputProps, createToken, confirmPayment} from '@monei-js/components';
import {returnCurrency} from "../../../services/util";
import {useTranslation} from "react-i18next";
import {red} from "@material-ui/core/colors";
import lock_svg from "../../../static/img/lock.png";
import LockIcon from '@material-ui/icons/Lock';
import {paymentResponse} from "../../../actions/pkgData";
import {addPaymentInfoGTM} from "../../GTMComponent/GTMComponent";
import {format} from 'date-fns';
import Collapse from '@material-ui/core/Collapse';


export default function Resume() {

    const travelers = useSelector(state => state.travelers.payload);

    const [isOpen, setisOpen] = useState(true);
    const {t, i18n} = useTranslation('common');


    return <>
        <div className="checkout-content">
            <div className="checkout-elements form-style max-space color-label">
                <dl className="checkout-box" id="resume-passengers">
                    <dt onClick={() => setisOpen(!isOpen)}>
                        <h2 className="title">
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7 8C9.07129 8 10.75 6.32129 10.75 4.25C10.75 2.17871 9.07129 0.5 7 0.5C4.92871 0.5 3.25 2.17871 3.25 4.25C3.25 6.32129 4.92871 8 7 8ZM9.625 8.9375H9.13574C8.48535 9.23633 7.76172 9.40625 7 9.40625C6.23828 9.40625 5.51758 9.23633 4.86426 8.9375H4.375C2.20117 8.9375 0.4375 10.7012 0.4375 12.875V14.0938C0.4375 14.8701 1.06738 15.5 1.84375 15.5H12.1562C12.9326 15.5 13.5625 14.8701 13.5625 14.0938V12.875C13.5625 10.7012 11.7988 8.9375 9.625 8.9375Z"
                                    fill="currentColor"></path>
                            </svg>
                            <span>{t('cart.passengers')}</span>
                        </h2>
                    </dt>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        {travelers.map((traveler, index) => {
                            return (<>
                                    {traveler.is_main_contact ?
                                        <dd>
                                            <h3 className="inputs-box-title">{t('words.titular')}</h3>
                                            <div className="fila">
                                                <div>
                                                    <p>{t('words.first_name')}</p>
                                                    <p>{traveler.name + ' ' + traveler.surname + ' ' + traveler.second_surname}</p>
                                                </div>
                                                <div>
                                                    <p>{t('words.residence_country')}</p>
                                                    <p>{traveler.id_residence}</p>
                                                </div>
                                            </div>
                                            <div className="fila">
                                                <div>
                                                    <p>{t('words.born')}</p>
                                                    <p>{format(new Date(traveler.birth_date), "dd/MM/yyyy")}</p>
                                                </div>
                                                <div>
                                                    <p>{t('words.born')}</p>
                                                    <p>{traveler.gender == "MALE" ? t('words.man') : t('words.woman')}</p>
                                                </div>
                                            </div>
                                            <div className="fila">
                                                <div>
                                                    <p>{t('words.email')}</p>
                                                    <p>{traveler.email}</p>
                                                </div>
                                                <div>
                                                    <p>{t('words.mobile_phone')}</p>
                                                    <p>{traveler.phone}</p>
                                                </div>
                                            </div>

                                            {/*Poner aqui dni y pasaporte*/}

                                            <div className="fila">
                                                <div>
                                                    <p>{t('words.address')}</p>
                                                    <p>{traveler.address}</p>
                                                </div>
                                                <div>
                                                    <p>{t('words.country')}</p>
                                                    <p>{traveler.country_id}</p>
                                                </div>
                                            </div>
                                            <div className="fila">
                                                <div>
                                                    <p>{t('words.province')}</p>
                                                    <p>{traveler.province}</p>
                                                </div>
                                                <div>
                                                    <p>{t('words.city')}</p>
                                                    <p>{traveler.city}</p>
                                                </div>
                                            </div>
                                            <div className="fila">
                                                <div>
                                                    <p>{t('words.postal_code')}</p>
                                                    <p>{traveler.postalcode}</p>
                                                </div>
                                            </div>
                                        </dd>
                                        :
                                        <dd>
                                            <h3 className="inputs-box-title">{!traveler.is_child ? t('person.Adult') : t('person.Child')}</h3>
                                            <div className="fila">
                                                <div>
                                                    <p>{t('words.first_name')}</p>
                                                    <p>{traveler.name + ' ' + traveler.surname + ' ' + traveler.second_surname}</p>
                                                </div>
                                                <div>
                                                    <p>{t('words.residence_country')}</p>
                                                    <p>{traveler.id_residence}</p>
                                                </div>
                                            </div>
                                            <div className="fila">
                                                <div>
                                                    <p>{t('words.born')}</p>
                                                    <p>{format(new Date(traveler.birth_date), "dd/MM/yyyy")}</p>
                                                </div>
                                                <div>
                                                    <p>{t('words.gender')}</p>
                                                    <p>{traveler.gender == "MALE" ? t('words.man') : t('words.woman')}</p>
                                                </div>
                                            </div>


                                            {/*Poner aqui dni y pasaporte*/}


                                        </dd>
                                    }
                                </>
                            )
                        })}
                    </Collapse>
                </dl>
            </div>
        </div>
    </ >

}

import {
    LOAD_CART,
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    CHANGE_PRODUCT_QUANTITY,
    REMOVE_CART,
    TOGGLE_FLIGHT_CART,
    TOGGLE_HOTEL_CART,
    TOGGLE_EXTRAS_CART,
} from './actionTypes';
import {updateCart} from "../total/actions";
import {calculateTotalPrice} from "../../actions/pkgData";

export const loadCart = products => ({
    type: LOAD_CART,
    payload: products
});

export const addProduct = product => {
    return {
        type: ADD_PRODUCT,
        payload: product
    }
};

export const addProductDingus = (old_product, new_product, search, selected_flight) => dispatch => {
    new_product.reservation = search;
    new_product.quantity = 1;
    new_product.springclub = true;
    new_product.id = new_product.id + "-" + Date.now();
    dispatch(loadCart([new_product]))
    dispatch(removeProduct(old_product[0]));
    dispatch(updateCart([new_product]));
    dispatch(calculateTotalPrice())
};

export const removeProduct = product => {
    return {
        type: REMOVE_PRODUCT,
        payload: product
    }
};

export const changeProductQuantity = product => ({
    type: CHANGE_PRODUCT_QUANTITY,
    payload: product
});

export const removeCart = () => dispatch => {
    dispatch(removeCartFinal())
    dispatch(calculateTotalPrice())
};

// Esta funcion se crea para poder hacer un paso previo de calcular el precio total al eliminar el carrito
// ya que si no se eliminaria el producto pero no se recalcula el precio, y Redux no permite hacer un dispatch
// y un return en la misma funcion
export const removeCartFinal = () => ({type: REMOVE_CART})

// Funciones para plegar o desplegar cada parte del carrito
export const toggleFlightCart = state => {
    return {
        type: 'TOGGLE_FLIGHT_CART',
        payload: state
    }
};

export const toggleHotelCart = state => {
    return {
        type: 'TOGGLE_HOTEL_CART',
        payload: state
    }
};

export const toggleExtrasCart = state => {
    return {
        type: 'TOGGLE_EXTRAS_CART',
        payload: state
    }
};
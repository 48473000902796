function generateChildsAges(num_childs_0, num_childs_1, num_childs_2) {
    // Genera la edad de los niños en formato array y calcula los bebés
    let children_array = []
    let baby = 0

    if (num_childs_0 !== undefined) {
        num_childs_0 = parseInt(num_childs_0)
        if (num_childs_0 >= 0 && num_childs_0 < 2) {
            baby++
        } else {

            children_array.push(num_childs_0)
        }
    }
    if (num_childs_1 !== undefined) {
        num_childs_1 = parseInt(num_childs_1)
        if (num_childs_1 >= 0 && num_childs_1 < 2) {
            baby++
        } else {
            children_array.push(num_childs_1)
        }
    }
    if (num_childs_2 !== undefined) {
        num_childs_2 = parseInt(num_childs_2)
        if (num_childs_2 >= 0 && num_childs_2 < 2) {
            baby++
        } else {
            children_array.push(num_childs_2)
        }
    }

    return [children_array, baby]
}

export function dingusConversionFromCheckout(form, products, lang = 'en') {
    // Conversión de los datos recibidos del form a Dingus

    // Comporbar que los hoteles de los products son los mismos
    let hotels_equal = products.every((product, i, products) => product.reservation.hc === products[0].reservation.hc)

    // Comprobar que las fechas de los productos sean las mismas
    let arrival_dates_equal = products.every((product, i, products) => product.reservation.arrival === products[0].reservation.arrival)
    let departure_dates_equal = products.every((product, i, products) => product.reservation.departure === products[0].reservation.departure)

    if (![hotels_equal, arrival_dates_equal, departure_dates_equal].every(Boolean)) {
        return false
    }

    let rooms_dingus_data = products.map(function (product) {
        let creation_data = {
            'adults': parseInt(product.reservation.adults),
            'room_code': product.room,
            'meal_code': product.boardtype,
            'rate_code': product.ratecode,
        }
        if(parseInt(product.reservation.room_num_childs) > 0) {
            const childs = generateChildsAges(
                product.reservation.room_num_childs_0,
                product.reservation.room_num_childs_1,
                product.reservation.room_num_childs_2
            )
            if(childs[0].length) {
                creation_data.children_ages = childs[0]
            }
            if(childs[1] > 0) {
                creation_data.baby = childs[1]
            }
        }

        return creation_data
    })

    // Creamos la estructura de datos que espera recibir Dingus
    return {
        // TODO: quitar hotel code fijo
        "hotel_code": "DG2023",
        // "hotel_code": products[0].reservation.hc,
        "hotel_city_code": "",
        "language": lang,
        "start_date": products[0].reservation.arrival,
        "end_date": products[0].reservation.departure,
        "rooms": rooms_dingus_data,
        "customer": {
            "name": form.firstName.toUpperCase(),
            "surname": form.lastName.toUpperCase(),
            "doc_type": "",
            "doc_id": "",
            "phone": form.phone,
            "email": form.email,
            "country": form.country.toUpperCase(),
            "stateprov": form.region.toUpperCase()
        }
    }
}
import {
    SEARCH_RESPONSE,
    EXTRAS_RESPONSE,
    CHECKOUT_RESPONSE,
    SET_TOTAL_PRICE,
    SET_PAY_NOW,
    SET_PAY_IN_HOTEL,
    SET_TEMPORAL_PKG_ID,
    PAYMENT_RESPONSE,
} from './types';
import axios from "axios";
import {getPaymentId, setStatus} from "./payment";
import {useState} from "react";
import store from "../store";
import {updateStep} from "./stepProcess";
import {purchaseGTM} from "../components/GTMComponent/GTMComponent";
import {toggleExtrasCart, toggleFlightCart, toggleHotelCart} from "../services/cart/actions";
import {invalidToken} from "./auth";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;
const API_URL_POST = process.env.REACT_APP_PACKAGE_API_URL_POST;


const searchStepData = (cartProducts, flights) => {
    let hotel_room_package = [];
    cartProducts.map((product) => {
        let children_ages = [];
        if (product.reservation.room_num_childs > 1){
            product.reservation.children_ages.map((child) => {
                children_ages.push(parseInt(child));
            });
        } else if (product.reservation.room_num_childs === '1'){
            children_ages.push(parseInt(product.reservation.children_ages));
        }
        let room_data = {
            "hotel_id": product.hotelcode,
            "arrival": product.reservation.arrival,
            "departure": product.reservation.departure,
            "roomtype_code": product.room,
            "boardtype_code": product.boardtype,
            "ratecode": product.ratecode,
            "promotion": product.promotion,
            "adults": parseInt(product.reservation.adults),
            "children_ages": children_ages,
            "canary_resident": (product.reservation.resident === 'true' ? 1 : 0),
            "provider_code": product.provider,
            "agency_discount": "0.00",
            "agency_discount_currency": "EUR",
            "total_price": product.pricewithoffer.toString(),
            "total_price_currency": "EUR",
            "price_paid": "0.00",
            "price_paid_currency": "EUR"
        }
        hotel_room_package.push(room_data)
    })

    let selected_flight = flights.selected_flight;
    if (flights.isActive)
        return {"hotel_room_package": hotel_room_package, "flight_package": selected_flight}
    else
        return {"hotel_room_package": hotel_room_package}
}

export const searchResponse = (cartProducts, flights, token) => async (dispatch) => {
    let payload = searchStepData(cartProducts, flights);

    const request = {
        "type": "SEARCH_RESULT",
        "action": "CONTINUE",
        "token": token,
        "payload": payload
    }
    await axios.post(BASEURL + API_URL_POST, request)
        .then(function (response) {
            dispatch(searchSave(response.data));
        })
        .catch(function (error) {
            error.response.data.status = "KO"
            dispatch(searchSave(error.response.data));
        });
}

const searchSave = (data) => ({type: SEARCH_RESPONSE, payload: data});

export const extrasResponse = (token, selected_luggage) => async (dispatch) => {
    const request = {
        "type": "EXTRAS",
        "action": "CONTINUE",
        "token": token,
        "payload": {
            tansfer: {},
            luggages: (selected_luggage.length < 1 ? {} : selected_luggage)
        }
    }
    await axios.post(BASEURL + API_URL_POST, request)
        .then(function (response) {
            dispatch(extrasSave(response.data));
        })
        .catch(function (error) {
            error.response.data.status = "KO";
            dispatch(extrasSave(error.response.data));
        });
}

const extrasSave = (data) => ({type: EXTRAS_RESPONSE, payload: data});

export const checkoutResponse = (data, package_id, amount, flights, agency_max_date = false, currency = "EUR") => (dispatch) => {
    const request = {
        "type": "CHECKOUT",
        "action": "CONTINUE",
        "token": data.token,
        "payload": {
            insurance: false,
            travelers_package: data.payload
        }
    }
    dispatch(getPaymentId(package_id, amount, currency));
    axios.post(BASEURL + API_URL_POST, request)
        .then(function (response) {
            dispatch(checkoutSave(response.data));
            if ((!flights || process.env.REACT_APP_INTERNAL === 'true') && !agency_max_date){
                dispatch(paymentResponse(data.token))
                dispatch(updateStep({
                    id: 6,
                    name: "SUCCESS",
                    description: "titles.confirm",
                }))
            }
        })
        .catch(function (error) {
            if(error.response.status === 401){
                if(error.response.data.detail === 'Invalid token.'){
                    dispatch(invalidToken(true));
                }
            }
            console.error(error);
        });
}

const checkoutSave = (data) => ({type: CHECKOUT_RESPONSE, payload: data});

export const paymentResponse = (token) => (dispatch) => {
    const request = {
        "type": "PAYMENT",
        "action": "CONTINUE",
        "token": token,
        "payload": {}
    }
    dispatch(setStatus("PENDING_API_RESPONSE"));
    axios.post(BASEURL + API_URL_POST, request)
        .then(function (response) {
            dispatch(paymentSave(response.data));
            dispatch(setStatus("COMPLETE"));
            dispatch(updateStep({
                id: 6,
                name: "SUCCESS",
                description: "titles.confirm"
            }))
            new purchaseGTM();
        })
        .catch(function (error) {
            console.error(error);
            dispatch(updateStep({
                id: 7,
                name: "ERROR",
                description: "titles.confirm",
            }))
        });
}

const paymentSave = (data) => ({type: PAYMENT_RESPONSE, payload: data});



export const setTemporalPkgId = (id) => ({type: SET_TEMPORAL_PKG_ID, payload: id})

export const calculateTotalPrice = () => dispatch => {

    const state = store.getState();

    let inbound_price = 0;
    let outbound_price = 0;
    let room_price = 0;
    let transfer_price = 0;
    let luggage_price = 0;

    if (state.cart.products !== undefined && state.cart.products.length > 0) {
        room_price = parseFloat(state.cart.products[0].pricewithoffer);
    }

    if (state.flights.isActive) {
        if (state.flights.selected_flight !== undefined && state.flights.selected_flight !== null) {
            inbound_price = parseFloat(state.flights.selected_flight[0].totalPrice);
            outbound_price = parseFloat(state.flights.selected_flight[1].totalPrice);
        }
        if (state.extras.selected_transfer?.state) {
            transfer_price = parseFloat(state.extras.selected_transfer.price)
        }
        if (state.extras.selected_luggage.length > 0) {
            state.extras.selected_luggage.map((luggage) => {
                luggage_price = luggage_price + parseFloat(luggage.pricingDetail)
            })
        }
        dispatch(setPayNow(parseFloat((inbound_price + outbound_price + luggage_price).toFixed(2))));
        dispatch(setPayInHotel(parseFloat((room_price).toFixed(2))));
    }
    dispatch(setTotalPriceSearchResult(parseFloat((inbound_price + outbound_price + room_price + transfer_price + luggage_price).toFixed(2))));
}

export const setPayNow = (pay_now) => ({type: SET_PAY_NOW, payload: pay_now});
export const setPayInHotel = (pay_in_hotel) => ({type: SET_PAY_IN_HOTEL, payload: pay_in_hotel});
export const setTotalPriceSearchResult = (total_price) => ({type: SET_TOTAL_PRICE, payload: total_price});
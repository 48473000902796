import store from "../../../store";
export function timeDiff(time1, time2) {
    /** Diferencia entre la hora de fin y la hora de inicio **/

    let splitTime1 = time1.split(":");
    let splitTime2 = time2.split(":");
    let date1 = new Date(null, null, null, splitTime1[0], splitTime1[1]);
    let date2 = new Date(null, null, null, splitTime2[0], splitTime2[1]);
    let result = Math.abs(date1 - date2);
    let seconds = Math.floor(result / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    minutes = minutes % 60;
    hours = hours % 24;
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    return (`${hours}:${minutes}`);
}

export function getDaysBetweenArrivalAndDeparture() {
    /** Devuelve el número de días entr el arrival y el departure **/

    const storeRedux = store.getState();
    const search = storeRedux.search.payload;

    let arrival_date = +new Date(search.arrival);
    let departure_date = +new Date(search.departure);
    return (Math.abs(departure_date - arrival_date) / (1000 * 3600 * 24))
}

export function getLeadTime() {
    /** calcula la diferencia entre la fecha de hoy y la fecha de salida **/
    const storeRedux = store.getState();
    const search = storeRedux.search.payload;

    let today = +new Date();
    let arrival_date = +new Date(search.arrival);
    return parseFloat((Math.abs(arrival_date - today) / (1000 * 3600 * 24)).toFixed(2));
}

import React, {useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PhoneInput from "react-phone-input-2";
import {confirmBookingButtonUpdate} from "../../../../actions/checkout";

import {checkoutGTM} from "../../../GTMComponent/GTMComponent";

import IcoWarning from "../../../../static/img/icons/ico-warning.svg";
import IcoInfo from "../../../../static/img/icons/ico-notice.svg";


function TranslatePoliticiesPages(language){
    switch(language){
        case "es":
            return process.env.REACT_APP_POLICIES_URL_ES;
        case "en":
            return "https://springhoteles.com/en/declaration-on-cookies-2/";
        case "fr":
            return "https://springhoteles.com/fr/politique-reservations-et-annulations/";
        case "nl":
            return "https://springhoteles.com/nl/annuleringsvoorwaarden/";
        case "de":
            return "https://springhoteles.com/de/allgemeine-geschaeftsbedingungen/";
        default:
            return "https://springhoteles.com/politica-reserva-y-cancelacion/";
    }
}

export default function AgencyForm() {
    const {t, i18n} = useTranslation('common');

    const dispatch = useDispatch();

    const callCentreActive = useSelector(state => state.callcenter.callCenterActive);
    let agency = useSelector(state => state.auth.agency);
    const customer = useSelector(state => state.callcenter.user);

    if (callCentreActive) {
        agency = customer;
    }

    const [phone, setPhone] = useState();
    const [endForm, setEndForm] = useState();

    const {register, control} = useFormContext(); // retrieve all hook methods

    const cancellation_policies = TranslatePoliticiesPages(i18n.language);

    //new checkoutGTM(1);

    if (endForm) {
        // new checkoutGTM(2);
    }

    return (
        <React.Fragment>
            <dl className="checkout-box">
                <dd>
                    <div className="column-inputs">
                        <div className="input-wrapper">
                            <label htmlFor="name">{t('words.first_name')}</label>
                            <input
                                type="text"
                                id="agencyName"
                                name="agencyName"
                                defaultValue={agency.name}
                                disabled
                            />
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="email">{t('words.email')}</label>
                            <input
                                type="text"
                                id="emailAgency"
                                name="emailAgency"
                                defaultValue={agency.correo_electronico}
                                disabled
                            />
                        </div>
                    </div>
                </dd>

            </dl>

            <dd>
                <div className="column-inputs" styles="display:none;">
                    <h2>{t('agencies.customer_data_text')}</h2>
                    <div className="system-msg notice-msg">
                        <div className="msg-box">
                            <img className="icon icon-notice" src={IcoInfo}/>
                            <div className="text-content">
                                <p>{t('agencies.customer_data_msg')}.</p>
                            </div>
                        </div>
                    </div>


                    <div className="column-inputs">
                        <div className="input-wrapper">
                            <label htmlFor="name">{t('words.first_name')} <span>({t('words.required')})</span></label>
                            <input type="text" id="firstName" name="firstName" ref={register} required/>
                        </div>

                        <div className="input-wrapper">
                            <label htmlFor="surname">{t('words.last_name')} <span>({t('words.required')})</span></label>
                            <input type="text" id="lastName" name="lastName" ref={register} required/>
                        </div>
                    </div>

                    <div className="column-inputs">
                        <div className="input-wrapper">
                            <label htmlFor="email">{t('words.email')} <span>({t('words.required')})</span></label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                ref={register}
                                required
                            />
                        </div>
                        <div className="input-wrapper phone-wrapper">
                            <div className="input-wrapper">
                                <label htmlFor="phone">{t('words.mobile_phone')} <span>({t('words.required')})</span></label>
                                <Controller
                                    control={control}
                                    name="phone"
                                    defaultValue=""
                                    required
                                    render={({onChange}) => (
                                        <PhoneInput
                                            id="phone"
                                            name="phone"
                                            ref={register}
                                            country={'gb'}
                                            preferredCountries={['ie', 'es', 'gb']}
                                            value={phone}
                                            onChange={p => {
                                                setPhone(p);
                                                onChange(p);
                                            }}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="column-inputs">
                        <div className="input-wrapper">
                            <input
                                type="checkbox"
                                value="true"
                                id="privacy_policy"
                                name="privacy_policy"
                                required
                                ref={register}
                            />
                            <label htmlFor="privacy_policy"><a
                                href={i18n.language === 'es' ? "https://springhoteles.com/spring-hoteles/politica-de-privacidad/" : "https://springhoteles.com/en/spring-hoteles/politica-de-privacidad/"}>
                                {t('msg.privacy_policy')}.
                            </a><span>({t('words.required')})</span></label>
                        </div>
                        <div className="input-wrapper">
                            <input
                                type="checkbox"
                                value="true"
                                id="user_acceptance"
                                name="user_acceptance"
                                required
                                ref={register}
                                onChange={() => {
                                    setEndForm(true);
                                    dispatch(confirmBookingButtonUpdate(true));
                                }}
                            />
                            <label htmlFor="user_acceptance"><a
                                href={cancellation_policies} target={"_blank"}>
                                {t('msg.user_acceptance')}.
                            </a><span>({t('words.required')})</span>
                            </label>
                        </div>
                    </div>
                </div>
            </dd>

            <div className="system-msg warning-msg">
                <div className="msg-box">
                    <img className="icon icon-warning" src={IcoWarning}/>
                    <div className="text-content">
                        <h5 className="title">{t('agencies.payment_msg_title')}</h5>
                        <p>{t('agencies.payment_msg_text')}.</p>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useScript from "../../SearchBox/useScript";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import FlightsModal from "./FlightsModal/FlightsModal";
import Popup from "reactjs-popup";
import { setActivateFlights } from "../../../services/flights/actions";
import { removeCart, removeCartFinal } from '../../../services/cart/actions';
import PopUpInfo from "./PoUpInfo";
import { SearchBox } from "../../SearchBox/SearchBox";
import queryString from "query-string";
import AirlineLogo from "./AirlineLogo";
import { format } from 'date-fns';

const Flights = ({ setActivateFlights }) => {

    const { t, i18n } = useTranslation('common');
    const { register, handleSubmit } = useForm();
    let history = useHistory();

    const url = new URL(window.location.href);
    const airport = url.searchParams.get('airport');
    const show_airport_ref = useRef(null);
    const show_airport_label_ref = useRef(null);
    const hotel = useSelector(state => state.search.payload.hotel);
    const [no_flights_search, setNo_flights_search] = useState(true);
    const [value, setValue] = useState(''); //se usa para el useEffect
    const is_activate_flights = useSelector(state => state.flights.isActive);
    const is_fail_flights = useSelector(state => state.flights.isFail);
    const search = useSelector(state => state.search.payload);

    const selected_flight = useSelector(state => state.flights.selected_flight);
    const selected_outbound_flight = (selected_flight !== null ? selected_flight[1] : null);
    const selected_inbound_flight = (selected_flight !== null ? selected_flight[0] : null);
    const [free_transfer, setFreeTransfer] = useState(false);

    const [free_luggage_outbound, setFreeLuggageOutbound] = useState(false);
    const [free_luggage_inbound, setFreeLuggageInbound] = useState(false);

    const dispatch = useDispatch();

    const statusScript = useScript(process.env.REACT_APP_FLIGHT_SCRIPT_IATA);
    let airportObj = undefined;
    if (statusScript === "ready") {
        airportObj = window.AirportInput("autocomplete-airport-1");
    }

    function goToUrlResult(data, no_package = false) {
        let url = window.location.href;
        const query_parsed = queryString.parse(url.split("?")[1]);
        if (no_package) {
            query_parsed.airport = "null";
        } else {
            query_parsed.airport = data.airport.substring(0, 3);
        }
        url = queryString.stringify(query_parsed);
        history.push("hotels?" + url);
        window.location.reload(false);
    }

    function addFlights() {
        if (is_activate_flights) {
            setActivateFlights(false);
            dispatch(removeCart());
            goToUrlResult(null, true);
        } else {
            setActivateFlights(true);
        }
    }

    function Transfer() {
        let arrival_date = +new Date(search.arrival);
        let departure_date = +new Date(search.departure);
        let days = (Math.abs(departure_date - arrival_date) / (1000 * 3600 * 24));
        setFreeTransfer(days >= 6);
    }

    const onSubmit = data => {
        goToUrlResult(data);
    }

    useEffect(() => {
        Transfer();
        setFreeLuggageOutbound(selected_outbound_flight?.luggages_included);
        setFreeLuggageInbound(selected_inbound_flight?.luggages_included);
        removeCart()
        if (airport !== "null") {
            setActivateFlights(true);
        }
    },)

    return (
        <div className="MuiBox-root jss2 search-result-list">
            <div className="add-flights-wrapper">
                <div className="list-header">
                    <h2 className="list-title">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14 7.33334H11.0237L8.28668 2.54321C8.25018 2.47944 8.19749 2.42644 8.13392 2.38958C8.07035 2.35272 7.99818 2.33331 7.9247 2.33331H6.21896C5.94214 2.33331 5.7424 2.59816 5.81844 2.8643L7.09527 7.33334H4.41661L3.29161 5.83333C3.21296 5.72838 3.08926 5.66666 2.95827 5.66666H1.91686C1.64576 5.66666 1.44681 5.92135 1.51269 6.18437L2.33327 9.00001L1.51269 11.8156C1.44681 12.0787 1.64576 12.3334 1.91686 12.3334H2.95827C3.08952 12.3334 3.21296 12.2716 3.29161 12.1667L4.41661 10.6667H7.09527L5.81844 15.1355C5.7424 15.4016 5.94214 15.6667 6.21896 15.6667H7.9247C8.07418 15.6667 8.2122 15.5865 8.28642 15.4568L11.0237 10.6667H14C14.9206 10.6667 16.5 9.92059 16.5 9.00001C16.5 8.07943 14.9206 7.33334 14 7.33334Z"
                                fill="currentColor" />
                        </svg>
                        <span> {t('flights.your')}</span>
                    </h2>
                </div>

                {process.env.REACT_APP_ONLY_PACKAGES === "false"  &&
                    <div className="add-flights-select-wrapper">
                        <input type="checkbox" id="addFlights" name="addFlights" />
                        <label htmlFor="addFlights"
                            className={"add-flights-select" + (is_activate_flights ? " selected" : "")}
                            onClick={() => {
                                addFlights();
                                removeCartFinal();
                            }}
                            ref={show_airport_label_ref}>
                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 1.42L10.59 0L4 6.59L1.42 4.02L0 5.43L4 9.42L12 1.42Z"
                                    fill="currentColor" />
                            </svg>
                        </label>
                        <span className="add-flights-text">
                            {t('flights.add_flight')}
                            <strong> {t("flights.discount_flight")}</strong></span>
                    </div>
                }
                <div className={"MuiCollapse-container " + (is_activate_flights ? "MuiCollapse-entered" : "")}
                    ref={show_airport_ref}
                    style={{ display: is_activate_flights ? 'inline' : 'none' }}
                >
                    <div className="MuiCollapse-wrapperInner">
                        <div className="add-flights-form">
                            <div className="input-wrapper">
                                <form className="add-flights-form" onSubmit={handleSubmit(onSubmit)}>
                                    <label htmlFor="airport">{t("flights.origin_airport")}</label>
                                    <div className="input-group">
                                        <input
                                            className={"airport"}
                                            name="airport"
                                            type="text"
                                            ref={register}
                                            placeholder={t('flights.origin_airport')}
                                            id="autocomplete-airport-1"
                                            autoComplete="off"
                                            defaultValue={airport !== "null" ? airport : ""}
                                        />
                                        <input className="btn" type="submit"
                                            value={(airport !== "null" ? t('flights.change_airport') : t('flights.search_flight'))} />
                                    </div>
                                </form>

                                {no_flights_search ?
                                    airport !== "null" &&
                                    <div
                                        className="MuiPaper-root MuiCard-root flight-wrapper  MuiPaper-elevation1 MuiPaper-rounded">

                                        <article className="flight-card outbound selected">
                                            <header className="flight-card-header">
                                                <p className="type">
                                                    <svg width="18" height="18" viewBox="0 0 640 512"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM80.55 341.27c6.28 6.84 15.1 10.72 24.33 10.71l130.54-.18a65.62 65.62 0 0 0 29.64-7.12l290.96-147.65c26.74-13.57 50.71-32.94 67.02-58.31 18.31-28.48 20.3-49.09 13.07-63.65-7.21-14.57-24.74-25.27-58.25-27.45-29.85-1.94-59.54 5.92-86.28 19.48l-98.51 49.99-218.7-82.06a17.799 17.799 0 0 0-18-1.11L90.62 67.29c-10.67 5.41-13.25 19.65-5.17 28.53l156.22 98.1-103.21 52.38-72.35-36.47a17.804 17.804 0 0 0-16.07.02L9.91 230.22c-10.44 5.3-13.19 19.12-5.57 28.08l76.21 82.97z"
                                                            fill="currentColor" />
                                                    </svg>
                                                    <span>{t('search.departure')}</span>
                                                </p>
                                                <p className="date">
                                                    {(selected_outbound_flight !== undefined && selected_outbound_flight !== null) && format(new Date(selected_outbound_flight?.flight_segment[0].arrivalDate), "dd/MM/yyyy")}
                                                    {selected_outbound_flight?.supplier === "OrbisFlight" &&
                                                        <>
                                                            <br />
                                                            <span className="promoted">{t('multihotel.promoted')}</span>
                                                        </>
                                                    }
                                                </p>
                                                <p className="airline">
                                                    <AirlineLogo
                                                        airline_code={selected_outbound_flight?.flight_segment[0].airlineCode}
                                                        airline_name={selected_outbound_flight?.flight_segment[0].airlineName} />
                                                </p>
                                            </header>
                                            <div className="flight-info">
                                                <ul className="included">
                                                    <Popup
                                                        trigger={
                                                            <a href="#" className="info-link">
                                                                <li className="luggage" title="Equipaje facturado">
                                                                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8.75 4.49998H1.25C0.559635 4.49998 0 5.05962 0 5.74998V11.5833C0 12.2737 0.559635 12.8333 1.25 12.8333H1.66667V13.25C1.66667 13.4802 1.85312 13.6666 2.08333 13.6666H2.91667C3.14687 13.6666 3.33333 13.4802 3.33333 13.25V12.8333H6.66667V13.25C6.66667 13.4802 6.85312 13.6666 7.08333 13.6666H7.91667C8.14687 13.6666 8.33333 13.4802 8.33333 13.25V12.8333H8.75C9.44036 12.8333 10 12.2737 10 11.5833V5.74998C10 5.05962 9.44036 4.49998 8.75 4.49998ZM8.33333 10.125C8.33333 10.2401 8.2401 10.3333 8.125 10.3333H1.875C1.7599 10.3333 1.66667 10.2401 1.66667 10.125V9.70831C1.66667 9.59321 1.7599 9.49998 1.875 9.49998H8.125C8.2401 9.49998 8.33333 9.59321 8.33333 9.70831V10.125ZM8.33333 7.62498C8.33333 7.74008 8.2401 7.83331 8.125 7.83331H1.875C1.7599 7.83331 1.66667 7.74008 1.66667 7.62498V7.20831C1.66667 7.09321 1.7599 6.99998 1.875 6.99998H8.125C8.2401 6.99998 8.33333 7.09321 8.33333 7.20831V7.62498ZM3.75 1.58331H6.25V3.66665H7.5V1.58331C7.5 0.892948 6.94036 0.333313 6.25 0.333313H3.75C3.05964 0.333313 2.5 0.892948 2.5 1.58331V3.66665H3.75V1.58331Z" fill="currentColor" />
                                                                    </svg>
                                                                    &nbsp;
                                                                    <span>{t('luggage.baggage_information')}</span>
                                                                </li>
                                                            </a>
                                                        }
                                                        modal
                                                        nested
                                                    >{close => (
                                                        <PopUpInfo close={close}
                                                            stops={selected_outbound_flight?.stops}
                                                            flight_segment={selected_outbound_flight?.flight_segment}
                                                            outbound={true}
                                                            luggages_included={selected_outbound_flight?.luggages_included}
                                                        />
                                                    )}
                                                    </Popup>

                                                    {/* {free_transfer &&
                                                        <li class="taxi" title="Taxi al hotel incluido">
                                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.3643 5.62606L11.7913 3.41669C11.5413 2.50002 10.708 1.83335 9.79134 1.83335H9.49967V1.00002C9.49967 0.539864 9.1265 0.166687 8.66634 0.166687H5.33301C4.87285 0.166687 4.49967 0.539864 4.49967 1.00002V1.83335H4.20801C3.29134 1.83335 2.45801 2.50002 2.20801 3.41669L1.63509 5.62606C0.89056 5.79273 0.333008 6.45497 0.333008 7.25002V8.50002C0.333008 9.11539 0.670247 9.6469 1.16634 9.93544V11C1.16634 11.4602 1.53952 11.8334 1.99967 11.8334H2.83301C3.29316 11.8334 3.66634 11.4602 3.66634 11V10.1667H10.333V11C10.333 11.4602 10.7062 11.8334 11.1663 11.8334H11.9997C12.4598 11.8334 12.833 11.4602 12.833 11V9.93544C13.3291 9.64663 13.6663 9.11512 13.6663 8.50002V7.25002C13.6663 6.45497 13.1088 5.79273 12.3643 5.62606ZM2.83301 8.50002C2.37285 8.50002 1.99967 8.12684 1.99967 7.66669C1.99967 7.20653 2.37285 6.83335 2.83301 6.83335C3.29316 6.83335 3.66634 7.20653 3.66634 7.66669C3.66634 8.12684 3.29316 8.50002 2.83301 8.50002ZM3.36816 5.58335L3.81608 3.85523C3.87415 3.64273 4.06582 3.50002 4.20827 3.50002H9.7916C9.93405 3.50002 10.1257 3.64273 10.1783 3.83492L10.6312 5.58335H3.36816ZM11.1663 8.50002C10.7062 8.50002 10.333 8.12684 10.333 7.66669C10.333 7.20653 10.7062 6.83335 11.1663 6.83335C11.6265 6.83335 11.9997 7.20653 11.9997 7.66669C11.9997 8.12684 11.6265 8.50002 11.1663 8.50002Z" fill="currentColor" />
                                                            </svg>
                                                        </li>
                                                    } */}
                                                </ul>
                                                <div className="extra-info">
                                                    <Popup
                                                        trigger={
                                                            <a href="#" className="info-link">
                                                                <svg width="14" height="14" viewBox="0 0 14 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                        d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                                        fill="currentColor" />
                                                                </svg>
                                                            </a>
                                                        }
                                                        modal
                                                        nested
                                                    >{close => (
                                                        <PopUpInfo close={close}
                                                            stops={selected_outbound_flight?.stops}
                                                            flight_segment={selected_outbound_flight?.flight_segment}
                                                            outbound={true}
                                                            luggages_included={selected_outbound_flight?.luggages_included} />
                                                    )}
                                                    </Popup>
                                                </div>
                                            </div>
                                            <div className="flight-itinerary">
                                                <div className="flight-departure">
                                                    <p className="flight-departure-time">
                                                        <strong>{selected_outbound_flight?.flight_segment[0].departureTime}</strong>
                                                    </p>
                                                    <p className="flight-departure-airport">{selected_outbound_flight?.flight_segment[0].departureCityName} - {selected_outbound_flight?.flight_segment[0].departureAirport} ({selected_outbound_flight?.flight_segment[0].departureCode})</p>
                                                </div>
                                                {selected_outbound_flight?.stops >= 1 &&
                                                    <div className="flight-stops">
                                                        <Popup
                                                            trigger={
                                                                <a><strong>{selected_outbound_flight?.stops} {t("itinerary.stop")}</strong></a>
                                                            }
                                                            modal
                                                            nested
                                                        >{close => (
                                                            <PopUpInfo close={close}
                                                                stops={selected_outbound_flight?.stops}
                                                                flight_segment={selected_outbound_flight?.flight_segment}
                                                                outbound={true}
                                                                luggages_included={selected_outbound_flight?.luggages_included} />
                                                        )}
                                                        </Popup>
                                                    </div>

                                                }
                                                {selected_outbound_flight?.stops < 1 &&
                                                    <div className="flight-stops">
                                                        <strong>{t("itinerary.direct")}</strong>
                                                    </div>
                                                }
                                                <div className="flight-arrival">
                                                    <p className="flight-arrival-time">
                                                        <strong>{selected_outbound_flight?.flight_segment[selected_outbound_flight?.stops]?.arrivalTime}</strong>
                                                    </p>
                                                    <p className="flight-arrival-airport">{selected_outbound_flight?.flight_segment[selected_outbound_flight?.stops]?.arrivalCityName} - {selected_outbound_flight?.flight_segment[selected_outbound_flight?.stops]?.arrivalAirport} ({selected_outbound_flight?.flight_segment[selected_outbound_flight?.stops]?.arrivalCode})</p>
                                                </div>
                                            </div>
                                        </article>

                                        <article className="flight-card inbound selected">
                                            <header className="flight-card-header">
                                                <p className="type">
                                                    <svg width="18" height="18" viewBox="0 0 640 512"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM44.81 205.66l88.74 80a62.607 62.607 0 0 0 25.47 13.93l287.6 78.35c26.48 7.21 54.56 8.72 81 1.36 29.67-8.27 43.44-21.21 47.25-35.71 3.83-14.5-1.73-32.71-23.37-54.96-19.28-19.82-44.35-32.79-70.83-40l-97.51-26.56L282.8 30.22c-1.51-5.81-5.95-10.35-11.66-11.91L206.05.58c-10.56-2.88-20.9 5.32-20.71 16.44l47.92 164.21-102.2-27.84-27.59-67.88c-1.93-4.89-6.01-8.57-11.02-9.93L52.72 64.75c-10.34-2.82-20.53 5-20.72 15.88l.23 101.78c.19 8.91 6.03 17.34 12.58 23.25z"
                                                            fill="currentColor" />
                                                    </svg>
                                                    <span>{t('search.arrival')}</span>
                                                </p>
                                                <p className="date">
                                                    {(selected_inbound_flight !== undefined && selected_inbound_flight !== null) && format(new Date(selected_inbound_flight?.flight_segment[0].arrivalDate), "dd/MM/yyyy")}
                                                    {selected_inbound_flight?.supplier === "OrbisFlight" &&
                                                        <>
                                                            <br />
                                                            <span className="promoted">{t('multihotel.promoted')}</span>
                                                        </>
                                                    }
                                                </p>
                                                <p className="airline">
                                                    <AirlineLogo
                                                        airline_code={selected_inbound_flight?.flight_segment[0].airlineCode}
                                                        airline_name={selected_inbound_flight?.flight_segment[0].airlineName} />
                                                </p>
                                            </header>
                                            <div className="flight-info">
                                                <ul className="included">
                                                    <Popup
                                                        trigger={
                                                            <a href="#" className="info-link">
                                                                <li className="luggage" title="Equipaje facturado">
                                                                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8.75 4.49998H1.25C0.559635 4.49998 0 5.05962 0 5.74998V11.5833C0 12.2737 0.559635 12.8333 1.25 12.8333H1.66667V13.25C1.66667 13.4802 1.85312 13.6666 2.08333 13.6666H2.91667C3.14687 13.6666 3.33333 13.4802 3.33333 13.25V12.8333H6.66667V13.25C6.66667 13.4802 6.85312 13.6666 7.08333 13.6666H7.91667C8.14687 13.6666 8.33333 13.4802 8.33333 13.25V12.8333H8.75C9.44036 12.8333 10 12.2737 10 11.5833V5.74998C10 5.05962 9.44036 4.49998 8.75 4.49998ZM8.33333 10.125C8.33333 10.2401 8.2401 10.3333 8.125 10.3333H1.875C1.7599 10.3333 1.66667 10.2401 1.66667 10.125V9.70831C1.66667 9.59321 1.7599 9.49998 1.875 9.49998H8.125C8.2401 9.49998 8.33333 9.59321 8.33333 9.70831V10.125ZM8.33333 7.62498C8.33333 7.74008 8.2401 7.83331 8.125 7.83331H1.875C1.7599 7.83331 1.66667 7.74008 1.66667 7.62498V7.20831C1.66667 7.09321 1.7599 6.99998 1.875 6.99998H8.125C8.2401 6.99998 8.33333 7.09321 8.33333 7.20831V7.62498ZM3.75 1.58331H6.25V3.66665H7.5V1.58331C7.5 0.892948 6.94036 0.333313 6.25 0.333313H3.75C3.05964 0.333313 2.5 0.892948 2.5 1.58331V3.66665H3.75V1.58331Z" fill="currentColor" />
                                                                    </svg>
                                                                    &nbsp;
                                                                    <span>{t('luggage.baggage_information')}</span>
                                                                </li>
                                                            </a>
                                                        }
                                                        modal
                                                        nested
                                                    >{close => (
                                                        <PopUpInfo close={close}
                                                            stops={selected_inbound_flight?.stops}
                                                            flight_segment={selected_inbound_flight?.flight_segment}
                                                            outbound={false}
                                                            luggages_included={selected_inbound_flight?.luggages_included} />
                                                    )}
                                                    </Popup>

                                                    {/* {free_transfer &&
                                                        <li class="taxi" title="Taxi al hotel incluido">
                                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.3643 5.62606L11.7913 3.41669C11.5413 2.50002 10.708 1.83335 9.79134 1.83335H9.49967V1.00002C9.49967 0.539864 9.1265 0.166687 8.66634 0.166687H5.33301C4.87285 0.166687 4.49967 0.539864 4.49967 1.00002V1.83335H4.20801C3.29134 1.83335 2.45801 2.50002 2.20801 3.41669L1.63509 5.62606C0.89056 5.79273 0.333008 6.45497 0.333008 7.25002V8.50002C0.333008 9.11539 0.670247 9.6469 1.16634 9.93544V11C1.16634 11.4602 1.53952 11.8334 1.99967 11.8334H2.83301C3.29316 11.8334 3.66634 11.4602 3.66634 11V10.1667H10.333V11C10.333 11.4602 10.7062 11.8334 11.1663 11.8334H11.9997C12.4598 11.8334 12.833 11.4602 12.833 11V9.93544C13.3291 9.64663 13.6663 9.11512 13.6663 8.50002V7.25002C13.6663 6.45497 13.1088 5.79273 12.3643 5.62606ZM2.83301 8.50002C2.37285 8.50002 1.99967 8.12684 1.99967 7.66669C1.99967 7.20653 2.37285 6.83335 2.83301 6.83335C3.29316 6.83335 3.66634 7.20653 3.66634 7.66669C3.66634 8.12684 3.29316 8.50002 2.83301 8.50002ZM3.36816 5.58335L3.81608 3.85523C3.87415 3.64273 4.06582 3.50002 4.20827 3.50002H9.7916C9.93405 3.50002 10.1257 3.64273 10.1783 3.83492L10.6312 5.58335H3.36816ZM11.1663 8.50002C10.7062 8.50002 10.333 8.12684 10.333 7.66669C10.333 7.20653 10.7062 6.83335 11.1663 6.83335C11.6265 6.83335 11.9997 7.20653 11.9997 7.66669C11.9997 8.12684 11.6265 8.50002 11.1663 8.50002Z" fill="currentColor" />
                                                            </svg>
                                                        </li>
                                                    } */}
                                                </ul>
                                                <div className="extra-info">
                                                    <Popup
                                                        trigger={
                                                            <a href="#" className="info-link">
                                                                <svg width="14" height="14" viewBox="0 0 14 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                        d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                                        fill="currentColor" />
                                                                </svg>
                                                            </a>
                                                        }
                                                        modal
                                                        nested
                                                    >{close => (
                                                        <PopUpInfo close={close}
                                                            stops={selected_inbound_flight?.stops}
                                                            flight_segment={selected_inbound_flight?.flight_segment}
                                                            outbound={false}
                                                            luggages_included={selected_inbound_flight?.luggages_included} />
                                                    )}
                                                    </Popup>
                                                </div>
                                            </div>
                                            <div className="flight-itinerary">
                                                <div className="flight-departure">
                                                    <p className="flight-departure-time">
                                                        <strong>{selected_inbound_flight?.flight_segment[0].departureTime}</strong>
                                                    </p>
                                                    <p className="flight-departure-airport">{selected_inbound_flight?.flight_segment[0].departureCityName} - {selected_inbound_flight?.flight_segment[0].departureAirport} ({selected_inbound_flight?.flight_segment[0].departureCode})</p>
                                                </div>
                                                {selected_inbound_flight?.stops >= 1 &&
                                                    <div className="flight-stops">
                                                        <Popup
                                                            trigger={
                                                                <a><strong>{selected_inbound_flight?.stops} {t("itinerary.stop")}</strong></a>
                                                            }
                                                            modal
                                                            nested
                                                        >{close => (
                                                            <PopUpInfo close={close}
                                                                stops={selected_inbound_flight?.stops}
                                                                flight_segment={selected_inbound_flight?.flight_segment}
                                                                outbound={false}
                                                                luggages_included={selected_inbound_flight?.luggages_included} />
                                                        )}
                                                        </Popup>
                                                    </div>
                                                }
                                                {selected_inbound_flight?.stops < 1 &&
                                                    <div className="flight-stops">
                                                        <strong>{t("itinerary.direct")}</strong>
                                                    </div>
                                                }
                                                <div className="flight-arrival">
                                                    <p className="flight-arrival-time">
                                                        <strong>{selected_inbound_flight?.flight_segment[selected_inbound_flight?.stops]?.arrivalTime}</strong>
                                                    </p>
                                                    <p className="flight-arrival-airport">{selected_inbound_flight?.flight_segment[selected_inbound_flight?.stops].arrivalCityName} - {selected_inbound_flight?.flight_segment[selected_inbound_flight?.stops].arrivalAirport} ({selected_inbound_flight?.flight_segment[selected_inbound_flight?.stops].arrivalCode})</p>
                                                </div>
                                            </div>
                                        </article>

                                        <div className="selected-flight-footer">
                                            <Popup
                                                trigger={
                                                    <div className="btn btn-select">
                                                        <svg width="18" height="19" viewBox="0 0 18 19"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M10.0179 4.73165L13.7684 8.48214L5.62433 16.6262L2.28047 16.9953C1.83283 17.0448 1.45462 16.6663 1.50442 16.2187L1.87648 12.8725L10.0179 4.73165ZM16.088 4.17327L14.327 2.41228C13.7777 1.86298 12.8868 1.86298 12.3375 2.41228L10.6808 4.06898L14.4313 7.81947L16.088 6.16277C16.6373 5.61317 16.6373 4.72257 16.088 4.17327Z"
                                                                fill="currentColor" />
                                                        </svg>
                                                        <span>{t('flights.change_flights')}</span>
                                                    </div>
                                                }
                                                modal
                                                nested
                                            >{close => (
                                                <FlightsModal close={close} />
                                            )}
                                            </Popup>
                                            {/* <button className="btn btn-select">
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M10.0179 4.73165L13.7684 8.48214L5.62433 16.6262L2.28047 16.9953C1.83283 17.0448 1.45462 16.6663 1.50442 16.2187L1.87648 12.8725L10.0179 4.73165ZM16.088 4.17327L14.327 2.41228C13.7777 1.86298 12.8868 1.86298 12.3375 2.41228L10.6808 4.06898L14.4313 7.81947L16.088 6.16277C16.6373 5.61317 16.6373 4.72257 16.088 4.17327Z"
                                                                fill="currentColor"/>
                                                        </svg>
                                                        <span>Cambiar vuelos</span>
                                                </button> */}
                                            <div className="price-box">
                                                {/* TODO si quitamos este DIV se agranda el boton de cambio de vuelos */}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="no-flights">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.7266 19.2913L17.8324 15.3971C17.6567 15.2214 17.4184 15.1237 17.1684 15.1237H16.5318C17.6098 13.7449 18.2504 12.0107 18.2504 10.1242C18.2504 5.63636 14.614 2 10.1262 2C5.63832 2 2.00195 5.63636 2.00195 10.1242C2.00195 14.612 5.63832 18.2484 10.1262 18.2484C12.0127 18.2484 13.7469 17.6079 15.1257 16.5298V17.1665C15.1257 17.4165 15.2233 17.6547 15.3991 17.8305L19.2932 21.7246C19.6604 22.0918 20.2541 22.0918 20.6173 21.7246L21.7227 20.6193C22.0898 20.2521 22.0898 19.6584 21.7266 19.2913ZM10.1262 15.1237C7.36471 15.1237 5.12665 12.8896 5.12665 10.1242C5.12665 7.36276 7.36081 5.12469 10.1262 5.12469C12.8876 5.12469 15.1257 7.35885 15.1257 10.1242C15.1257 12.8857 12.8915 15.1237 10.1262 15.1237Z"
                                                fill="currentColor" />
                                        </svg>
                                        <div className="info-wrapper">
                                            <p>Actualmente no hay vuelos para el destino y la fecha que nos has
                                                indicado. Puedes continuar tu reserva de habitación y te
                                                avisaremos
                                                en
                                                cuanto haya un vuelo disponible.
                                            </p>
                                            <div className="input-wrapper">
                                                <input type="checkbox" id="alert_flights" name="alert_flights"
                                                    value="true" />
                                                <label htmlFor="alert_flights">Quiero que me avisen si hay un
                                                    vuelo
                                                    disponible</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { setActivateFlights })(Flights)
import store from "../../../store";
import {getDaysBetweenArrivalAndDeparture, getLeadTime, timeDiff} from "./timeGTM";

function countBabies() {
    const children_ages = store.getState().search?.payload?.children_ages;
    let babies = 0;
    if(Array.isArray(children_ages)){
        children_ages?.map(children => {
            if (parseInt(children) < 2) {
                babies++;
            }
        })
    } else{
        if (parseInt(children_ages) < 2) {
            babies++;
        }
    }
    return babies
}

export function getOcuppationStr() {
    const search = store.getState().search?.payload
    const babies_num = countBabies()
    return search.adults + "-" + (parseInt(search.room_num_childs) - babies_num) + "-" + babies_num
}

export function getAgesChilds(search) {

    let edades = "";
    if (search.children_ages !== undefined) {
        for (let i = 0; i < search.children_ages.length; i++) {
            if (i === search.children_ages.length - 1) {
                edades += search.children_ages[i];
            } else {
                edades += search.children_ages[i] + "-";
            }
        }
        return edades;
    } else {
        return edades;
    }
}

export function convertProductHotelRoomToItems(product) {
    /** Convierte a información de un hotel a items del datalayer **/

    const storeRedux = store.getState();
    const search = storeRedux.search.payload;

    let items = [];
    let productCount = 0;
    // const room_name = product.room_title?.toUpperCase()

    product.boardtypes.map(board => {
            items.push(
                {
                    "item_name": board.room,        // Nombre de la habitación
                    "item_id": board.id,                // Código del producto único que identifica el avión, el hotel, el extra o el aeropuerto
                    "price": board.pricewithoffer,      // precio del producto
                    "discount": "",                     // Descuento del producto
                    "coupon": search.promotion,                       // Cupón de descuento o promocode.
                    "item_brand": board.hotel_name,     // Nombre del hotel o aerolinea o empresa que ofrece el producto
                    "item_category": "rooms",           // rooms, flights o extras
                    "item_category2": board.ratecode,   // tipo de tarifa que se esté aplicando al artículo (“No Reembolsable”, “Cancelación Gratis”)
                    "item_category3": "",               // vuelos directos (true o false)
                    "item_category4": "",               // sentido (INBOUND, OUTBOUND O TWO-WAY)
                    "item_category5": "",
                    "item_variant": board.boardtype_text,                 // Régimen de la habitación o Aeropuerto de origen
                    "item_list_name": "Availability Room",               // Nombre del listado: Availability Room, Availability Flight, Extras (para view_item_list), Add to Cart, Remove From Cart, Begin Checkout, Add Payment Info, Purchase
                    "item_list_id": "availability_room",                 // id del listado:
                    "index": productCount,              // Posición del hotel, vuelo
                    "quantity": 1,                      // Cantidad
                    "availability": board.availability,         // Disponibilidad
                    "location_id": "",                  // Id de la localización en Google Maps
                    "origin": "",                        // Codigo IATA del aeropuerto de salida
                    "destination": "",                   // Codigo IATA del aeropuerto de llegada
                    "start_date": search.arrival,                   // Fecha inicio del servicio
                    "end_date": search.departure,                     // Fecha fin del servicio
                    "google_business_vertical": "hotel_rental",     // vertical del producto: ‘hotel_rental’,‘flights’ o ‘custom’
                    "id": board.hotelcode,                            // codigo id del hotel
                    "flightcode": "",                   // codigo del vuelo
                    "ocuppation": getOcuppationStr(),                   // adultos-niños-bebes
                    "resident": false,                     // descuento residente (true o false)
                    "leadtime": getLeadTime(),                       // diferencia entre fecha de la reserva y fecha inicio servicio
                    "duration": getDaysBetweenArrivalAndDeparture(),                       // número de noches reservadas o tiempo de vuelo
                    "child_ages": getAgesChilds(search),                    // edad1-edad2-edad3...
                    "id_chain": "",                      // TODO: nombre de la cadena hotelera
                    "start_hour": "",                    // hora salida del vuelo
                    "end_hour": ""                       // hora llegada del vuelo
                }
            );
            productCount += 1;
        }
    )
    return items;
}

export function convertProductHotelToItems(product) {
    /** Convierte a información de un producto de hotel (boardtype) a un item del datalayer **/

    const storeRedux = store.getState();
    const search = storeRedux.search.payload;

    return {
        "item_name": product?.room,        // Nombre de la habitación
        "item_id": product?.id,                // Código del producto único que identifica el avión, el hotel, el extra o el aeropuerto
        "price": product?.pricewithoffer,      // precio del producto
        "discount": "",                     // Descuento del producto
        "coupon": "",                       // TODO: Cupón de descuento o promocode.
        "item_brand": product?.hotel_name,     // Nombre del hotel o aerolinea o empresa que ofrece el producto
        "item_category": "rooms",           // rooms, flights o extras
        "item_category2": product?.ratecode,   // tipo de tarifa que se esté aplicando al artículo (“No Reembolsable”, “Cancelación Gratis”)
        "item_category3": "",               // vuelos directos (true o false)
        "item_category4": "",               // sentido (INBOUND, OUTBOUND O TWO-WAY)
        "item_category5": "",
        "item_variant": product?.boardtype_text,                 // Régimen de la habitación o Aeropuerto de origen
        "item_list_name": "Availability Room",               // Nombre del listado: Availability Room, Availability Flight, Extras (para view_item_list), Add to Cart, Remove From Cart, Begin Checkout, Add Payment Info, Purchase
        "item_list_id": "availability_room",                 // id del listado:
        "index": 0,              // Posición del hotel, vuelo
        "quantity": 1,                      // Cantidad
        "availability": product?.availability,         // Disponibilidad
        "location_id": "",                  // Id de la localización en Google Maps
        "origin": "",                        // Codigo IATA del aeropuerto de salida
        "destination": "",                   // Codigo IATA del aeropuerto de llegada
        "start_date": search.arrival,                   // Fecha inicio del servicio
        "end_date": search.departure,                     // Fecha fin del servicio
        "google_business_vertical": "hotel_rental",     // vertical del producto: ‘hotel_rental’,‘flights’ o ‘custom’
        "id": product?.hotelcode,                            // codigo id del hotel
        "flightcode": "",                   // codigo del vuelo
        "ocuppation": getOcuppationStr(),                   // adultos-niños-bebes
        "resident": false,                     // descuento residente (true o false)
        "leadtime": getLeadTime(),                       // diferencia entre fecha de la reserva y fecha inicio servicio
        "duration": getDaysBetweenArrivalAndDeparture(),                       // número de noches reservadas o tiempo de vuelo
        "child_ages": getAgesChilds(search),                    // edad1-edad2-edad3...
        "id_chain": "",                      // TODO: nombre de la cadena hotelera
        "start_hour": "",                    // hora salida del vuelo
        "end_hour": ""                       // hora llegada del vuelo
    }

}

export function convertFlightsToItems(flights_array) {
    /** Convierte los vuelos en items del datalayer **/

    const storeRedux = store.getState();
    const search = storeRedux.search.payload;
    let items = []

    flights_array?.map(flight => {
            items.push(
                {
                    "item_name": flight.flight_segment[flight.flight_segment.length - 1].arrivalAirport,    // Nombre del producto (nombre del aeropuerto de destino)
                    "item_id": flight.arrivalCode,                      // Código del producto único que identifica el avión, el hotel, el extra o el aeropuerto (codigo del aeropuerto de destino)
                    "price": parseFloat(flight.totalPrice),                        // precio del producto
                    "discount": "",                     // Descuento del producto
                    "coupon": "",                       // Cupón de descuento o promocode.
                    "item_brand": flight.flight_segment[0].airlineName,                   // Nombre del hotel o aerolinea o empresa que ofrece el producto
                    "item_category": "flights",         // rooms, flights o extras
                    "item_category2": "No Reembolsable",               // tipo de tarifa que se esté aplicando al artículo (“No Reembolsable”, “Cancelación Gratis”)
                    "item_category3": flight.flight_segment.length === 1,               // vuelos directos (true o false)
                    "item_category4": flight.type,               // sentido (INBOUND, OUTBOUND O TWO-WAY)
                    "item_category5": "",
                    "item_variant": flight.departureCode,                 // Régimen de la habitación o Aeropuerto de origen
                    "item_list_name": "Availability Flight",               // Nombre del listado: Availability Room, Availability Flight, Extras (para view_item_list), Add to Cart, Remove From Cart, Begin Checkout, Add Payment Info, Purchase
                    "item_list_id": "availability_flight",                 // id del listado
                    "index": 0,              // Posición
                    "quantity": 1,                       // Cantidad
                    "location_id": "",                  // Id de la localización en Google Maps
                    "origin": flight.departureCode,                        // Codigo IATA del aeropuerto de salida
                    "destination": flight.arrivalCode,                   // Codigo IATA del aeropuerto de llegada
                    "start_date": search.arrival,        // Fecha inicio del servicio
                    "end_date": search.departure,        // Fecha fin del servicio
                    "google_business_vertical": "flights",     // vertical del producto: ‘hotel_rental’,‘flights’ o ‘custom’
                    "id": "",                            // codigo id
                    "flightcode": flight.flight_segment[0].flightCode,                  // codigo del vuelo
                    "ocuppation": getOcuppationStr(),                   // adultos-niños-bebes
                    "resident": false,                     // TODO: descuento residente (true o false)
                    "leadtime": getLeadTime(),                       // diferencia entre fecha de la reserva y fecha inicio servicio
                    "duration": timeDiff(flight.flight_segment[flight.flight_segment.length - 1].arrivalTime, flight.flight_segment[0].departureTime),    // número de noches reservadas o tiempo de vuelo
                    "child_ages": getAgesChilds(search),                    // edad1-edad2-edad3...
                    "id_chain": "",                      // nombre de la cadena hotelera
                    "start_hour": flight.flight_segment[0].departureTime,                    // hora salida del vuelo
                    "end_hour": flight.flight_segment[flight.flight_segment.length - 1].arrivalTime,                       // hora llegada del vuelo
                }
            );
        }
    );
    return items;
}

export function cartToItems() {
    const storeRedux = store.getState()
    const cart_products = storeRedux.cart.products;
    const selected_flight = storeRedux.flights?.selected_flight;
    let items = []

    /* Se añaden los hoteles */
    cart_products.map(product => {
            /* Añade los productos de tipo hotel a impressions */
            items.push(convertProductHotelToItems(product))
        }
    )

    /* Se añaden los vuelos */
    items.push(...convertFlightsToItems(selected_flight))

    return items
}

export function convertProductExtrasToItems(extras) {
    /**
     * Convierte los extras recibidos en items del datalayer
     */
    const storeRedux = store.getState();
    const search = storeRedux.search.payload;

    let items = [];
    let productCount = 0;

    // TODO: Hacerlo adaptable a extras de tipo Transfer

    
    extras[productCount]?.map(extra => {
            items.push(
                {
                    "item_name": extra?.baggageName,        // Nombre del extra
                    "item_id": extra?.baggageName.replaceAll(' ','-').substring(0, extra?.baggageName.length - 3),                // Código del producto único que identifica el avión, el hotel, el extra o el aeropuerto
                    "price": extra?.pricingDetail,      // precio del producto
                    "discount": "",                     // Descuento del producto
                    "coupon": "",                       // TODO: Cupón de descuento o promocode.
                    "item_brand": "",     // Nombre del hotel o aerolinea o empresa que ofrece el producto
                    "item_category": "extras",           // rooms, flights o extras
                    "item_category2": extra?.type,   // tipo de tarifa que se esté aplicando al artículo (“No Reembolsable”, “Cancelación Gratis”)
                    "item_category3": "",               // vuelos directos (true o false)
                    "item_category4": extra?.flight_type,               // sentido (INBOUND, OUTBOUND O TWO-WAY)
                    "item_category5": "",
                    "item_variant": "",                 // Régimen de la habitación o Aeropuerto de origen
                    "item_list_name": "Extras",               // Nombre del listado: Availability Room, Availability Flight, Extras (para view_item_list), Add to Cart, Remove From Cart, Begin Checkout, Add Payment Info, Purchase
                    "item_list_id": "extras",                 // id del listado:
                    "index": productCount,              // Posición del hotel, vuelo
                    "quantity": 1,                      // Cantidad
                    "availability": "",         // Disponibilidad
                    "location_id": "",                  // Id de la localización en Google Maps
                    "origin": "",                        // Codigo IATA del aeropuerto de salida
                    "destination": "",                   // Codigo IATA del aeropuerto de llegada
                    "start_date": search.arrival,                   // Fecha inicio del servicio
                    "end_date": search.departure,                     // Fecha fin del servicio
                    "google_business_vertical": "custom",     // vertical del producto: ‘hotel_rental’,‘flights’ o ‘custom’
                    "id": "",                            // codigo id del hotel
                    "flightcode": "",                   // codigo del vuelo
                    "ocuppation": getOcuppationStr(),                   // adultos-niños-bebes
                    "resident": false,                     // descuento residente (true o false)
                    "leadtime": getLeadTime(),                       // diferencia entre fecha de la reserva y fecha inicio servicio
                    "duration": "",                       // número de noches reservadas o tiempo de vuelo
                    "child_ages": getAgesChilds(search),                    // edad1-edad2-edad3...
                    "id_chain": "",                      // TODO: nombre de la cadena hotelera
                    "start_hour": "",                    // hora salida del vuelo
                    "end_hour": ""                       // hora llegada del vuelo
                }
            );
            productCount += 1;
        }
    )
    return items;
}

export function convertSelectedExtrasToItems(extras) {
    /**
     * Convierte los extras seleccionados en items del datalayer
     */
    const storeRedux = store.getState();
    const search = storeRedux.search.payload;

    let items = [];
    let productCount = 0;

    // TODO: Hacerlo adaptable a extras de tipo Transfer

    
    extras.map(extra => {
            items.push(
                {
                    "item_name": extra?.baggageName,        // Nombre del extra
                    "item_id": extra?.baggageName.replaceAll(' ','-').substring(0, extra?.baggageName.length - 3),                // Código del producto único que identifica el avión, el hotel, el extra o el aeropuerto
                    "price": extra?.pricingDetail,      // precio del producto
                    "discount": "",                     // Descuento del producto
                    "coupon": "",                       // TODO: Cupón de descuento o promocode.
                    "item_brand": "",     // Nombre del hotel o aerolinea o empresa que ofrece el producto
                    "item_category": "extras",           // rooms, flights o extras
                    "item_category2": extra?.type,   // tipo de tarifa que se esté aplicando al artículo (“No Reembolsable”, “Cancelación Gratis”)
                    "item_category3": "",               // vuelos directos (true o false)
                    "item_category4": extra?.flight_type,               // sentido (INBOUND, OUTBOUND O TWO-WAY)
                    "item_category5": "",
                    "item_variant": "",                 // Régimen de la habitación o Aeropuerto de origen
                    "item_list_name": "Extras",               // Nombre del listado: Availability Room, Availability Flight, Extras (para view_item_list), Add to Cart, Remove From Cart, Begin Checkout, Add Payment Info, Purchase
                    "item_list_id": "extras",                 // id del listado:
                    "index": productCount,              // Posición del hotel, vuelo
                    "quantity": 1,                      // Cantidad
                    "availability": "",         // Disponibilidad
                    "location_id": "",                  // Id de la localización en Google Maps
                    "origin": "",                        // Codigo IATA del aeropuerto de salida
                    "destination": "",                   // Codigo IATA del aeropuerto de llegada
                    "start_date": search.arrival,                   // Fecha inicio del servicio
                    "end_date": search.departure,                     // Fecha fin del servicio
                    "google_business_vertical": "custom",     // vertical del producto: ‘hotel_rental’,‘flights’ o ‘custom’
                    "id": "",                            // codigo id del hotel
                    "flightcode": "",                   // codigo del vuelo
                    "ocuppation": getOcuppationStr(),                   // adultos-niños-bebes
                    "resident": false,                     // descuento residente (true o false)
                    "leadtime": getLeadTime(),                       // diferencia entre fecha de la reserva y fecha inicio servicio
                    "duration": "",                       // número de noches reservadas o tiempo de vuelo
                    "child_ages": getAgesChilds(search),                    // edad1-edad2-edad3...
                    "id_chain": "",                      // TODO: nombre de la cadena hotelera
                    "start_hour": "",                    // hora salida del vuelo
                    "end_hour": ""                       // hora llegada del vuelo
                }
            );
            productCount += 1;
        }
    )
    return items;
}
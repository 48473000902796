import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {CustomView, isMobile} from "react-device-detect";
import Popup from 'reactjs-popup';
import {addCartGTM} from '../../../GTMComponent/GTMComponent';

import {addProduct, addProductDingus} from '../../../../services/cart/actions';
import {Carousel} from '../../../Carousel';
import {PreviewRoom} from "../../../PreviewRoom/PreviewRoom";

const blockMobile = {
    display: 'block',
};

function addStandarProduct(product, addProduct, addProductDingus, search, flights_active, productsCart, selected_flight, room_title, hotel_chains, provider, springclub = false) {
    product.springclub = springclub;
    product.room_title = room_title
    product.hotel_chains = hotel_chains
    product.provider = provider
    
    if (flights_active)
        addProductDingus(productsCart, product, search, selected_flight)
    else
        addProduct(product);
    
    new addCartGTM();
}

const Product = ({product, productclub, addProduct, addProductDingus, room_title, hotel_chains, provider}) => {

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const flightsActive = useSelector(state => state.flights.isActive);
    const productCart = useSelector(state => state.cart.products);
    const search = useSelector(state => state.search.payload);
    const selected_flight = useSelector(state => state.flights.selected_flight)
    const totalFlightsPrice = useSelector(state => state.flights.totalFlightsPrice)

    let defaultExpanded = false;

    const [OpenChristmasPopup, setOpenChristmasPopup] = useState(false);
    const closeChristmasModal = () => setOpenChristmasPopup(false);

    if (!isAuthenticated) {
        if (product.boardtype === "MP" || product.boardtype === "TIP+UP") {
            defaultExpanded = true;
        }
    } else {
        defaultExpanded = true;
    }


    const {t} = useTranslation('common');

    function ChistmasOffer() {
        if (productclub.availability <= 0) {
            return (
                <button className="btn btn-green btn-select" disabled>
                    <span className="to-select">{t('result.no_availability')}</span>
                </button>
            )
        } else if (product.christmas_offer) {
            return (
                <Popup modal trigger={
                    <button className="btn btn-green btn-select">
                        <span className="to-select">{t('result.add')}</span>
                    </button>
                }>
                    {close => (
                        <div id="modal_standar_selected"
                             className="standar-selected-modal center-text">
                            <h3>Oferta especial</h3>
                            <p>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z"
                                          fill="#7FB334"/>
                                </svg>
                                <strong>Oferta de Navidad</strong>
                            </p>
                            <p>
                                <strong>{t("result.text_standar_price_1")}</strong>
                                <br/>
                                {t("result.text_standar_price_2")}
                            </p>
                            <p>{t("result.text_standar_price_3")}</p>
                            <div className="column-btns">
                                <button className="btn btn-primary full-weidth"
                                        onClick={() => {
                                            addStandarProduct(productclub, addProduct, addProductDingus, search, flightsActive, productCart, selected_flight, room_title, hotel_chains, provider, true);
                                            close();
                                        }}>
                                    Continuar
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            )
        } else {
            return (
                <button className="btn btn-green btn-select"
                        onClick={() => addStandarProduct(productclub, addProduct, addProductDingus, search, flightsActive, productCart, selected_flight, room_title, hotel_chains, provider, true)}>
                    <span className="to-select">{t('result.add')}</span>
                </button>
            )
        }
    }

    return (
        <>
            <CustomView condition={isMobile === true}>
                <button>
                    <dt className="opened show_alltime">
                        <h3 className="title"> {product.boardtype_text} </h3>
                        <p className="price primary-title-font">
                            <strong>{productclub.pricewithoffer.toFixed(2)}{productclub.currencyFormat}</strong>
                        </p>
                        <p className="info">
                            {/*<span className="grey-color">{product.clubText}</span>*/}
                        </p>
                    </dt>
                </button>
                <section>

                    <dd className="show_alltime" style={blockMobile}>
                        <div className="pension-standar">
                            <div className="pension-info">
                                <p><strong className="primary-title-font">{t('result.price_standar')}</strong>
                                </p>
                            </div>

                            <p className="pension-price-info">
                                <span
                                    className="original-price">{flightsActive ?
                                    <>
                                        {productCart.length > 0 && (parseFloat(product?.pricewithoutoffer) < parseFloat(productCart[0]?.pricewithoutoffer) ? "-" : "+")}
                                        {Math.abs(productCart.length > 0 ? (parseFloat(productclub.pricewithoutoffer) - parseFloat(productCart[0]?.pricewithoutoffer)) : parseFloat(productclub.pricewithoutoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                    </>
                                    :
                                    product?.pricewithoutoffer.toFixed(2)}
                                    {product?.currencyFormat}</span>
                                <strong
                                    className="pension-price"> {flightsActive ?
                                    <>
                                        {productCart.length > 0 && (parseFloat(product?.pricewithoffer) < parseFloat(productCart[0]?.pricewithoffer) ? "-" : "+")}
                                        {Math.abs(productCart.length > 0 ? (parseFloat(productclub.pricewithoffer) - parseFloat(productCart[0]?.pricewithoffer)) : parseFloat(productclub.pricewithoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                    </>
                                    :
                                    product?.pricewithoffer.toFixed(2)}
                                    {product.currencyFormat} </strong>
                            </p>
                            {product.availability <= 0 ? <button className="btn btn-green btn-select" disabled>
                                    <span className="to-select">{t('result.no_availability')}</span>
                                </button>
                                :
                                (!isAuthenticated &&
                                    <Popup modal trigger={
                                        <button className="btn btn-green btn-select">
                                            <span className="to-select">{t('result.add')}</span>
                                        </button>
                                    }>
                                        {close => (
                                            <div id="modal_standar_selected"
                                                 className="standar-selected-modal center-text">
                                                <h3>{t("result.standar_price")}</h3>
                                                <p>
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z"
                                                              fill="#7FB334"/>
                                                    </svg>
                                                    <strong>{product.offertext}</strong>
                                                </p>
                                                <p>
                                                    <strong>{t("result.text_standar_price_1")}</strong>
                                                    <br/>
                                                    {t("result.text_standar_price_2")}
                                                </p>
                                                <p>{t("result.text_standar_price_3")}</p>
                                                <div className="column-btns">
                                                    <button className="btn btn-primary full-weidth"
                                                            onClick={() => {
                                                                addStandarProduct(productclub, addProduct, addProductDingus, search, flightsActive, productCart, selected_flight, room_title, hotel_chains, provider);
                                                                close();
                                                            }}>
                                                        {t("result.save_with_spring_club")}
                                                    </button>
                                                    <button className="btn btn-outline-primary full-weidth"
                                                            onClick={() => {
                                                                addStandarProduct(product, addProduct, addProductDingus, search, flightsActive, productCart, selected_flight, room_title, hotel_chains, provider, true);
                                                                close();
                                                            }}>
                                                        {t("result.want_pay_more")}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>)}
                        </div>

                        <div className="pension-club">
                            <div className="mobile-info">
                                <p><strong className="primary-title-font">{t('spring.club')}</strong></p>
                                <p className="grey-color">{t('spring.club_discount')}</p>
                            </div>

                            <p className="pension-price-info">
                                <span
                                    className="original-price">{flightsActive ?
                                    <>
                                        {productCart.length > 0 && (parseFloat(product?.pricewithoutoffer) < parseFloat(productCart[0]?.pricewithoutoffer) ? "-" : "+")}
                                        {Math.abs(productCart.length > 0 ? (parseFloat(productclub.pricewithoutoffer) - parseFloat(productCart[0]?.pricewithoutoffer)) : parseFloat(productclub.pricewithoutoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                    </>
                                    :
                                    productclub.pricewithoutoffer.toFixed(2)}
                                    {productclub.currencyFormat}</span>
                                <strong
                                    className="pension-price"> {flightsActive ?
                                    <>
                                        {parseFloat(productclub.pricewithoffer) < parseFloat(productCart[0]?.pricewithoffer) ? "-" : "+"}
                                        {Math.abs(productCart.length > 0 ? (parseFloat(productclub.pricewithoffer) - parseFloat(productCart[0]?.pricewithoffer)) : parseFloat(productclub.pricewithoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                    </>
                                    :
                                    productclub.pricewithoffer.toFixed(2)}{productclub.currencyFormat} </strong>
                            </p>
                            {ChistmasOffer()}
                        </div>
                    </dd>

                    {product.boardtype === "TIP+UP" && <>
                        <PreviewRoom product={[product]} isUP={true} isMobile={true}/>
                        <br/><br/>
                        <div className="pension-up-wrapper">
                            <strong className="title">{t('msg.access_boardtype_up')}:</strong>
                            <Carousel product={product} isMobile={true}/>
                        </div>
                    </>}
                </section>

            </CustomView>
            <CustomView condition={isMobile === false}>

                <dt className="opened show_alltime">
                    <h3 className="title">{product.boardtype_text}</h3>
                    <p className="info">
                        {/*<span className="grey-color">{product.ratecode}</span>*/}
                    </p>
                </dt>
                <dd className="show_alltime">
                    <div className="pension-standar">
                        <p className="pension-price-info">
                             <span
                                 className="original-price">{flightsActive ?
                                 <>
                                     {productCart.length > 0 && (parseFloat(product?.pricewithoutoffer) < parseFloat(productCart[0]?.pricewithoutoffer) ? "-" : "+")}
                                     {Math.abs(productCart.length > 0 ? (parseFloat(productclub.pricewithoutoffer) - parseFloat(productCart[0]?.pricewithoutoffer)) : parseFloat(productclub.pricewithoutoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                 </>
                                 :
                                 product?.pricewithoutoffer.toFixed(2)}
                                 {product?.currencyFormat}</span>
                            <strong
                                className="pension-price"> {flightsActive ?
                                <>
                                    {productCart.length > 0 && (parseFloat(product?.pricewithoffer) < parseFloat(productCart[0]?.pricewithoffer) ? "-" : "+")}
                                    {Math.abs(productCart.length > 0 ? (parseFloat(productclub.pricewithoffer) - parseFloat(productCart[0]?.pricewithoffer)) : parseFloat(productclub.pricewithoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                </>
                                :
                                product?.pricewithoffer.toFixed(2)}
                                {product.currencyFormat} </strong>
                        </p>
                        {product.availability <= 0 ?
                            <button className="btn btn-green btn-select" disabled>
                                <span className="to-select">{t('result.no_availability')}</span>
                            </button>
                            :
                            (!isAuthenticated &&
                                <Popup modal trigger={
                                    <button className="btn btn-green btn-select">
                                        <span className="to-select">{t('result.add')}</span>
                                    </button>
                                }>
                                    {close => (
                                        <div id="modal_standar_selected"
                                             className="standar-selected-modal center-text">
                                            <h3>{t("result.standar_price")}</h3>
                                            <p>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM15.768 9.14C15.8558 9.03964 15.9226 8.92274 15.9646 8.79617C16.0065 8.6696 16.0227 8.53591 16.0123 8.40298C16.0018 8.27005 15.9648 8.14056 15.9036 8.02213C15.8423 7.90369 15.758 7.79871 15.6555 7.71334C15.5531 7.62798 15.4346 7.56396 15.3071 7.52506C15.1796 7.48616 15.0455 7.47316 14.9129 7.48683C14.7802 7.50049 14.6517 7.54055 14.5347 7.60463C14.4178 7.66872 14.3149 7.75554 14.232 7.86L9.932 13.019L7.707 10.793C7.5184 10.6108 7.2658 10.51 7.0036 10.5123C6.7414 10.5146 6.49059 10.6198 6.30518 10.8052C6.11977 10.9906 6.0146 11.2414 6.01233 11.5036C6.01005 11.7658 6.11084 12.0184 6.293 12.207L9.293 15.207C9.39126 15.3052 9.50889 15.3818 9.63842 15.4321C9.76794 15.4823 9.9065 15.505 10.0453 15.4986C10.184 15.4923 10.32 15.4572 10.4444 15.3954C10.5688 15.3337 10.6791 15.2467 10.768 15.14L15.768 9.14Z"
                                                          fill="#7FB334"/>
                                                </svg>
                                                <strong>{productclub.offertext}</strong>
                                            </p>
                                            <p>
                                                <strong>{t("result.text_standar_price_1")}</strong>
                                                <br/>
                                                {t("result.text_standar_price_2")}
                                            </p>
                                            <p>{t("result.text_standar_price_3")}</p>
                                            <div className="column-btns">
                                                <button className="btn btn-primary full-weidth"
                                                        onClick={() => {
                                                            addStandarProduct(productclub, addProduct, addProductDingus, search, flightsActive, productCart, selected_flight, room_title, hotel_chains, provider);
                                                            setOpenChristmasPopup(true);
                                                            close();
                                                        }}>
                                                    {t("result.save_with_spring_club")}
                                                </button>
                                                <button className="btn btn-outline-primary full-weidth"
                                                        onClick={() => {
                                                            addStandarProduct(product, addProduct, addProductDingus, search, flightsActive, productCart, selected_flight, room_title, hotel_chains, provider, true);
                                                            setOpenChristmasPopup(true);
                                                            close();
                                                        }}>
                                                    {t("result.want_pay_more")}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </Popup>)}
                    </div>
                    <div style={{display: (OpenChristmasPopup && product.christmas_offer) ? 'inline' : 'none'}}>
                        <Popup modal open={OpenChristmasPopup && product.christmas_offer}
                               onClose={closeChristmasModal}>
                            {close => (
                                <div id="modal_standar_selected"
                                     className="standar-selected-modal center-text">
                                    <h3><strong>{t('result.header_christmas_modal')}</strong></h3>
                                    <p>
                                        {t("result.text_christmas_modal")}
                                    </p>
                                    <div className="column-btns">
                                        <button className="btn btn-primary full-weidth"
                                                onClick={() => {
                                                    close();
                                                }}>
                                            {t('result.understood')}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>

                    <div className="pension-club">
                        <p className="pension-price-info">
                            <span className="original-price">
                                {flightsActive ?
                                    <>
                                        {productCart.length > 0 && (parseFloat(product?.pricewithoutoffer) < parseFloat(productCart[0]?.pricewithoutoffer) ? "-" : "+")}
                                        {Math.abs(productCart.length > 0 ? (parseFloat(productclub.pricewithoutoffer) - parseFloat(productCart[0]?.pricewithoutoffer)) : parseFloat(productclub.pricewithoutoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                    </>
                                    :
                                    productclub.pricewithoutoffer.toFixed(2)
                                }
                                {productclub.currencyFormat}</span>
                            <strong
                                className="pension-price"> {flightsActive ?
                                <>
                                    {productCart.length > 0 && (parseFloat(product?.pricewithoffer) < parseFloat(productCart[0]?.pricewithoffer) ? "-" : "+")}
                                    {Math.abs(productCart.length > 0 ? (parseFloat(productclub.pricewithoffer) - parseFloat(productCart[0]?.pricewithoffer)) : parseFloat(productclub.pricewithoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                </>
                                :
                                productclub.pricewithoffer.toFixed(2)}{productclub.currencyFormat} </strong>
                        </p>
                        {product.availability <= 0 ?
                            <button className="btn btn-green btn-select" disabled>
                                <span className="to-select">{t('result.no_availability')}</span>
                            </button>
                            :
                            <>
                                {product?.id !== productCart[0]?.id && productclub?.id !== productCart[0]?.id ?
                                    <button className="btn btn-green btn-select"
                                            onClick={() => {
                                                addStandarProduct(productclub, addProduct, addProductDingus, search, flightsActive, productCart, selected_flight, room_title, hotel_chains, provider, true);
                                                setOpenChristmasPopup(true);
                                            }}>
                                        <span className="to-select">{t('result.add')}</span>
                                    </button>
                                    :
                                    <button className="btn btn-green btn-selected">
                                        <span className="to-select">{t('result.added')}</span>
                                    </button>
                                }
                            </>
                        }
                    </div>
                </dd>

                {product.boardtype === "TIP+UP" && <>
                    <PreviewRoom product={[product]} isUP={true}/>
                    <div className="pension-up-wrapper">
                        <strong className="title">{t('msg.access_boardtype_up')}:</strong>
                        <Carousel product={product}/>
                    </div>
                </>}

            </CustomView>
        </>);
};

Product.propTypes = {
    product: PropTypes.object.isRequired, addProduct: PropTypes.func.isRequired
};

export default connect(null, {addProduct, addProductDingus})(Product);

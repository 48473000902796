import {
    GET_FLIGHTS,
    SET_FLIGHT,
    ID_COMBINED,
    ID_COMBINABLE_OUTBOUND,
    ID_COMBINABLE_INBOUND,
    SET_ACTIVATE_FLIGHTS,
    FLIGHT_DONE,
    FLIGHT_FAIL,
    SET_FLIGHT_TOTAL_PRICE
} from './actionTypes';
import axios from "axios";
import { calculateTotalPrice } from "../../actions/pkgData";
import { setTokenExpirationTime } from "../../actions/search";
import {impressionsGTM, unavailabilityGTM} from "../../components/GTMComponent/GTMComponent";
import store from "../../store";
import {removeLuggage} from "../../actions/extras";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;
const API_URL_POST = process.env.REACT_APP_PACKAGE_API_URL_POST;

const timeout = process.env.REACT_APP_TIMEOUT;

export const GetFlightsAPI = (search, token, lang = "eng") => (dispatch) => {
    const ipstack_data = store.getState().search.ipstack_data;
    let children_ages = [];
    if (search.children_ages !== undefined) {
        if (search.children_ages.length < 2)
            children_ages.push(parseInt(search.children_ages))
        else
            search.children_ages.map((children) => {
                children_ages.push(parseInt(children));
            })
    }
    let request = {
        type: "SEARCH_RESULT",
        action: "GET_FLIGHTS",
        token: token,
        payload: {
            departureLocation: search.airport,
            destinationLocation: "TFS,TFN",
            departureDate: search.arrival,
            returnDate: search.departure,
            maxResults: parseInt(process.env.REACT_APP_MAX_FLIGHTS_RESULTS),
            language: lang,
            currency: process.env.REACT_APP_FLIGHTS_CURRENCY,
            passengers: {
                adults: parseInt(search.adults),
                children: parseInt(search.room_num_childs !== undefined ? search.room_num_childs : 0),
                ages: children_ages
            },
            isOneWay: false,
            filters: {
                minPrice: 0,
                maxPrice: 0,
                departureAfter: "",
                departureBefore: "",
                arrivalAfter: "",
                arrivalBefore: "",
                excludeAirlines: [],
                excludeAirports: [],
                onlyAirlines: [],
                departureAirports: [],
                arrivalAirports: []
            },
            sortOption: "DURATION-ASC",
            nonStopOnly: false,
            withFreeLuggageOnly: false,
            specialDiscount: "NONE",
            operationMode: process.env.REACT_APP_OPERATION_MODE,
            debugMode: true,
            start: 0,
            internalCall: true,
            discounts: search.promotion === undefined ? [] : [search.promotion],
            // clientData: {
            //     ip: ipstack_data.ip,
            //     agent: "",
            //     origin: ipstack_data.country_name,
            //     country: ipstack_data.country_code,
            //     domain: process.env.REACT_APP_DJANGO_IP,
            // },
        }
    }
    if (request.token !== undefined && request.token !== null)
        axios.post(BASEURL + API_URL_POST, request, { timeout: timeout })
            .then(function (response) {
                dispatch(getFlights(response.data));
                dispatch(selectFlight( true, null, response.data))
                dispatch(setTokenExpirationTime(response.data.session_token_expiration_time))
                window.localStorage.setItem('session_token_expiration_time', response.data.session_token_expiration_time);
                new impressionsGTM();
                return dispatch(updateDone())
            })
            .catch(function (error) {
                new unavailabilityGTM();
                return dispatch(updateFail(error.message));
            });
}

export const getFlights = (flights) => {
    return {
        type: GET_FLIGHTS,
        payload: flights
    }
}
const updateDone = () => ({ type: FLIGHT_DONE });
const updateFail = (error) => ({ type: FLIGHT_FAIL, payload: error })

export const selectFlight = (default_package = false, selected_flight = null, flights = null) => (dispatch) => {
    if (default_package) {
        let default_flight = flights.combinations[0];
        let odd_taxes = (default_flight?.taxes * 100) % 2;
        let odd_total_price = (default_flight?.totalPrice * 100) % 2;
        let inbound_taxes = (default_flight?.taxes > 0 ? (default_flight?.taxes / 2 + odd_taxes) : 0);
        let outbound_taxes = (default_flight?.taxes > 0 ? (default_flight?.taxes / 2) : 0);
        let inbound_total_price = (default_flight?.totalPrice > 0 ? (default_flight?.totalPrice / 2 + odd_total_price) : 0);
        let outbound_total_price = (default_flight?.totalPrice > 0 ? (default_flight?.totalPrice / 2 + odd_total_price) : 0);

        if (flights.combinations.length < 1) {
            inbound_taxes = flights.inbound[0].rates[0].taxes;
            outbound_taxes = flights.outbound[0].rates[0].taxes;
            inbound_total_price = flights.inbound[0].rates[0].totalPrice;
            outbound_total_price = flights.outbound[0].rates[0].totalPrice;
            default_flight = [];
            default_flight.outbound = flights.outbound[0];
            default_flight.inbound = flights.inbound[0];
        }

        selected_flight = [
            {
                type: default_flight.inbound.type,
                session_token: flights.sessionToken,
                token: default_flight.token,
                departureCode: default_flight.inbound.departureCode,
                arrivalCode: default_flight.inbound.arrivalCode,
                stops: default_flight.inbound.stops,
                taxes: inbound_taxes.toString(),
                taxes_currency: default_flight.totalPrice_currency,
                totalPrice: inbound_total_price.toString(),
                totalPrice_currency: default_flight.totalPrice_currency,
                flight_segment: default_flight.inbound.flight_segment,
                flight_extra: [],
                luggages_included: default_flight.inbound.luggages_included,
                taxesP: default_flight.taxesP,
                supplier: default_flight.supplier,
            },
            {
                type: default_flight.outbound.type,
                session_token: flights.sessionToken,
                token: default_flight.token,
                departureCode: default_flight.outbound.departureCode,
                arrivalCode: default_flight.outbound.arrivalCode,
                stops: default_flight.outbound.stops,
                taxes: outbound_taxes.toString(),
                taxes_currency: default_flight.totalPrice_currency,
                totalPrice: outbound_total_price.toString(),
                totalPrice_currency: default_flight.totalPrice_currency,
                flight_segment: default_flight.outbound.flight_segment,
                flight_extra: [],
                luggages_included: default_flight.outbound.luggages_included,
                taxesP: default_flight.taxesP,
                supplier: default_flight.supplier,
            }
        ]
    }
    dispatch(setFlightTotalPrice(parseFloat(selected_flight[0].totalPrice) + parseFloat(selected_flight[1].totalPrice)));
    dispatch(setFlight(selected_flight))
    dispatch(calculateTotalPrice());
    const state = store.getState();

    if(state.extras.selected_luggage.length > 0){
        state.extras.selected_luggage = [];
    }
}

const setFlight = (selected_flight) => ({ type: SET_FLIGHT, payload: selected_flight })
const setFlightTotalPrice = (totalPrice) => ({ type: SET_FLIGHT_TOTAL_PRICE, payload: parseFloat(totalPrice.toFixed(2)) })

export const setIdCombined = flight_token => {
    return {
        type: ID_COMBINED,
        payload: flight_token
    }
}

export const setIdCombinableInbound = flight_token => {
    return {
        type: ID_COMBINABLE_INBOUND,
        payload: flight_token
    }
}

export const setIdCombinableOutbound = flight_token => {
    return {
        type: ID_COMBINABLE_OUTBOUND,
        payload: flight_token
    }
}

export const setActivateFlights = state => {
    return {
        type: SET_ACTIVATE_FLIGHTS,
        payload: state
    }
}
import React, { Component } from "react";
import { connect } from "react-redux";
import { setIdCombined } from "../../../../services/flights/actions";
import vueling from "../../../../static/img/airline-logos/VY.png";
import PopUpInfo from "../../../EngineResult/Flights/PoUpInfo";
import Popup from "reactjs-popup";
import { withTranslation } from "react-i18next";
import AirlineLogo from "../../../EngineResult/Flights/AirlineLogo";
import { returnCurrency } from "../../../../services/util";
import { format } from 'date-fns';

class BookingFlights extends Component {

    Transfer() {
        let arrival_date = +new Date(this.props.search.arrival);
        let departure_date = +new Date(this.props.search.departure);
        let days = (Math.abs(departure_date - arrival_date) / (1000 * 3600 * 24));
        return days >= 6;
    }

    render() {



        const { t, flights } = this.props;

        const outbound_last_stop = flights != undefined ? flights[0].stops : '';
        const inbound_last_stop =  flights != undefined ? flights[1].stops : '';
        console.log(flights)
        return (

            <section className="flight-card combined">
                {flights != undefined &&
                    <>

                        <div className="flights">
                            {/*Vuelo de ida*/}
                            <article className="flight-card outbound selected">
                                <header className="flight-card-header">
                                    <p className="type">
                                        <svg width="18" height="18" viewBox="0 0 640 512"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM80.55 341.27c6.28 6.84 15.1 10.72 24.33 10.71l130.54-.18a65.62 65.62 0 0 0 29.64-7.12l290.96-147.65c26.74-13.57 50.71-32.94 67.02-58.31 18.31-28.48 20.3-49.09 13.07-63.65-7.21-14.57-24.74-25.27-58.25-27.45-29.85-1.94-59.54 5.92-86.28 19.48l-98.51 49.99-218.7-82.06a17.799 17.799 0 0 0-18-1.11L90.62 67.29c-10.67 5.41-13.25 19.65-5.17 28.53l156.22 98.1-103.21 52.38-72.35-36.47a17.804 17.804 0 0 0-16.07.02L9.91 230.22c-10.44 5.3-13.19 19.12-5.57 28.08l76.21 82.97z"
                                                fill="currentColor" />
                                        </svg>
                                        <span>{t('cart.outbound')}</span>
                                    </p>
                                    <p className="date"><strong>{format(new Date(flights[0].flight_segment[0].arrivalDate), "dd/MM/yyyy")}</strong></p>
                                    <p className="airline">
                                        <AirlineLogo airline_code={flights[0].flight_segment[0].airlineCode} airline_name={flights[0].flight_segment[0].airlineName} />
                                    </p>
                                </header>

                                <div className="flight-info">
                                    <ul class="included">
                                        <Popup
                                            trigger={
                                                <a href="#" className="info-link">
                                                    <li className="luggage" title="Equipaje facturado">
                                                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.75 4.49998H1.25C0.559635 4.49998 0 5.05962 0 5.74998V11.5833C0 12.2737 0.559635 12.8333 1.25 12.8333H1.66667V13.25C1.66667 13.4802 1.85312 13.6666 2.08333 13.6666H2.91667C3.14687 13.6666 3.33333 13.4802 3.33333 13.25V12.8333H6.66667V13.25C6.66667 13.4802 6.85312 13.6666 7.08333 13.6666H7.91667C8.14687 13.6666 8.33333 13.4802 8.33333 13.25V12.8333H8.75C9.44036 12.8333 10 12.2737 10 11.5833V5.74998C10 5.05962 9.44036 4.49998 8.75 4.49998ZM8.33333 10.125C8.33333 10.2401 8.2401 10.3333 8.125 10.3333H1.875C1.7599 10.3333 1.66667 10.2401 1.66667 10.125V9.70831C1.66667 9.59321 1.7599 9.49998 1.875 9.49998H8.125C8.2401 9.49998 8.33333 9.59321 8.33333 9.70831V10.125ZM8.33333 7.62498C8.33333 7.74008 8.2401 7.83331 8.125 7.83331H1.875C1.7599 7.83331 1.66667 7.74008 1.66667 7.62498V7.20831C1.66667 7.09321 1.7599 6.99998 1.875 6.99998H8.125C8.2401 6.99998 8.33333 7.09321 8.33333 7.20831V7.62498ZM3.75 1.58331H6.25V3.66665H7.5V1.58331C7.5 0.892948 6.94036 0.333313 6.25 0.333313H3.75C3.05964 0.333313 2.5 0.892948 2.5 1.58331V3.66665H3.75V1.58331Z" fill="currentColor" />
                                                        </svg>
                                                        &nbsp;
                                                        <span>{t('luggage.baggage_information')}</span>
                                                    </li>
                                                </a>
                                            }
                                            modal
                                            nested
                                        >{close => (
                                            <PopUpInfo
                                                close={close}
                                                stops={flights[0].stops}
                                                flight_segment={flights[0].flight_segment}
                                                outbound={true}
                                                luggages_included="1 maleta de ida"
                                            />
                                        )}
                                        </Popup>
                                    </ul>
                                    <div className="extra-info">
                                        <Popup
                                            trigger={
                                                <a href="#" className="info-link">
                                                    <svg width="14" height="14" viewBox="0 0 14 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </a>
                                            }
                                            modal
                                            nested
                                        >{close => (
                                            <PopUpInfo 
                                                close={close} 
                                                stops={flights[0].stops} 
                                                flight_segment={flights[0].flight_segment} 
                                                outbound={true} 
                                                luggages_included="1 maleta de ida" />
                                        )}
                                        </Popup>
                                    </div>
                                </div>

                                <div className="flight-itinerary">
                                    <div className="flight-departure">
                                        <p className="flight-departure-time"><strong>{flights[0].flight_segment[0].departureTime.slice(0, 5)}</strong></p>
                                        <p className="flight-departure-airport">{flights[0].flight_segment[0].departureCityName} - {flights[0].flight_segment[0].departureAirport} ({flights[0].flight_segment[0].departureCode})</p>
                                    </div>
                                    <div className="flight-stops" style={{ display: outbound_last_stop > 0 ? 'inline' : 'none' }}>
                                        <Popup
                                            trigger={
                                                <a><strong>{outbound_last_stop} {t("itinerary.stop")} </strong></a>
                                            }
                                            modal
                                            nested
                                        >{close => (
                                            <PopUpInfo 
                                                close={close} 
                                                stops={flights[0].stops} 
                                                flight_segment={flights[0].flight_segment} 
                                                outbound={true} 
                                                luggages_included="1 maleta de ida" />
                                        )}
                                        </Popup>
                                    </div>
                                    <div className="flight-stops" style={{ display: outbound_last_stop === 0 ? 'inline' : 'none' }}>
                                        <strong>{t("itinerary.direct")}</strong>
                                    </div>
                                    <div className="flight-arrival">
                                        <p className="flight-arrival-time"><strong>{flights[0].flight_segment[outbound_last_stop].arrivalTime.slice(0,5)}</strong></p>
                                        <p className="flight-arrival-airport">{flights[0].flight_segment[outbound_last_stop].arrivalCityName} - {flights[0].flight_segment[outbound_last_stop].arrivalAirport} ({flights[0].flight_segment[outbound_last_stop].arrivalCode})</p>
                                    </div>
                                </div>
                            </article>

                            {/*Vuelo de vuelta*/}
                            <article className="flight-card inbound selected">
                                <header className="flight-card-header">
                                    <p className="type">
                                        <svg width="18" height="18" viewBox="0 0 640 512"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM44.81 205.66l88.74 80a62.607 62.607 0 0 0 25.47 13.93l287.6 78.35c26.48 7.21 54.56 8.72 81 1.36 29.67-8.27 43.44-21.21 47.25-35.71 3.83-14.5-1.73-32.71-23.37-54.96-19.28-19.82-44.35-32.79-70.83-40l-97.51-26.56L282.8 30.22c-1.51-5.81-5.95-10.35-11.66-11.91L206.05.58c-10.56-2.88-20.9 5.32-20.71 16.44l47.92 164.21-102.2-27.84-27.59-67.88c-1.93-4.89-6.01-8.57-11.02-9.93L52.72 64.75c-10.34-2.82-20.53 5-20.72 15.88l.23 101.78c.19 8.91 6.03 17.34 12.58 23.25z"
                                                fill="currentColor" />
                                        </svg>
                                        <span>{t('cart.inbound')}</span>
                                    </p>
                                    <p className="date"><strong>{format(new Date(flights[1].flight_segment[0].arrivalDate), "dd/MM/yyyy")}</strong></p>
                                    <p className="airline">
                                        <AirlineLogo airline_code={flights[1].flight_segment[0].airlineCode} airline_name={flights[1].flight_segment[0].airlineName} />
                                    </p>
                                </header>

                                <div className="flight-info">
                                    <ul class="included">
                                        <Popup
                                            trigger={
                                                <a href="#" className="info-link">
                                                    <li className="luggage" title="Equipaje facturado">
                                                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.75 4.49998H1.25C0.559635 4.49998 0 5.05962 0 5.74998V11.5833C0 12.2737 0.559635 12.8333 1.25 12.8333H1.66667V13.25C1.66667 13.4802 1.85312 13.6666 2.08333 13.6666H2.91667C3.14687 13.6666 3.33333 13.4802 3.33333 13.25V12.8333H6.66667V13.25C6.66667 13.4802 6.85312 13.6666 7.08333 13.6666H7.91667C8.14687 13.6666 8.33333 13.4802 8.33333 13.25V12.8333H8.75C9.44036 12.8333 10 12.2737 10 11.5833V5.74998C10 5.05962 9.44036 4.49998 8.75 4.49998ZM8.33333 10.125C8.33333 10.2401 8.2401 10.3333 8.125 10.3333H1.875C1.7599 10.3333 1.66667 10.2401 1.66667 10.125V9.70831C1.66667 9.59321 1.7599 9.49998 1.875 9.49998H8.125C8.2401 9.49998 8.33333 9.59321 8.33333 9.70831V10.125ZM8.33333 7.62498C8.33333 7.74008 8.2401 7.83331 8.125 7.83331H1.875C1.7599 7.83331 1.66667 7.74008 1.66667 7.62498V7.20831C1.66667 7.09321 1.7599 6.99998 1.875 6.99998H8.125C8.2401 6.99998 8.33333 7.09321 8.33333 7.20831V7.62498ZM3.75 1.58331H6.25V3.66665H7.5V1.58331C7.5 0.892948 6.94036 0.333313 6.25 0.333313H3.75C3.05964 0.333313 2.5 0.892948 2.5 1.58331V3.66665H3.75V1.58331Z" fill="currentColor" />
                                                        </svg>
                                                        &nbsp;
                                                        <span>{t('luggage.baggage_information')}</span>
                                                    </li>
                                                </a>
                                            }
                                            modal
                                            nested
                                        >{close => (
                                            <PopUpInfo
                                                close={close}
                                                stops={flights[1].stops}
                                                flight_segment={flights[1].flight_segment}
                                                outbound={false}
                                                luggages_included="1 maleta de ida"
                                            />
                                        )}
                                        </Popup>
                                    </ul>
                                    <div className="extra-info">
                                        <Popup
                                            trigger={
                                                <a href="#" className="info-link">
                                                    <svg width="14" height="14" viewBox="0 0 14 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M7 0C3.13428 0 0 3.13541 0 7C0 10.8669 3.13428 14 7 14C10.8657 14 14 10.8669 14 7C14 3.13541 10.8657 0 7 0ZM7 3.10484C7.65473 3.10484 8.18548 3.6356 8.18548 4.29032C8.18548 4.94505 7.65473 5.47581 7 5.47581C6.34527 5.47581 5.81452 4.94505 5.81452 4.29032C5.81452 3.6356 6.34527 3.10484 7 3.10484ZM8.24194 10.6129C8.42899 10.6129 8.58065 10.4612 8.58065 10.2742V9.59677C8.58065 9.40972 8.42899 9.25806 8.24194 9.25806H7.90323V6.43548C7.90323 6.24843 7.75157 6.09677 7.56452 6.09677H5.75806C5.57101 6.09677 5.41935 6.24843 5.41935 6.43548V7.1129C5.41935 7.29996 5.57101 7.45161 5.75806 7.45161H6.09677V9.25806H5.75806C5.57101 9.25806 5.41935 9.40972 5.41935 9.59677V10.2742C5.41935 10.4612 5.57101 10.6129 5.75806 10.6129H8.24194Z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </a>
                                            }
                                            modal
                                            nested
                                        >{close => (
                                            <PopUpInfo 
                                                close={close} 
                                                stops={flights[1].stops} 
                                                flight_segment={flights[1].flight_segment} 
                                                outbound={false} 
                                                luggages_included="1 maleta de ida" />
                                        )}
                                        </Popup>
                                    </div>
                                </div>

                                <div className="flight-itinerary">
                                    <div className="flight-departure">
                                        <p className="flight-departure-time"><strong>{flights[1].flight_segment[0].departureTime.slice(0, 5)}</strong></p>
                                        <p className="flight-departure-airport">{flights[1].flight_segment[0].departureCityName} - {flights[1].flight_segment[0].departureAirport} ({flights[1].flight_segment[0].departureCode})</p>
                                    </div>
                                    <div className="flight-stops" style={{ display: inbound_last_stop > 0 ? 'inline' : 'none' }}>
                                        <Popup
                                            trigger={
                                                <a><strong>{inbound_last_stop} {t("itinerary.stop")} </strong></a>
                                            }
                                            modal
                                            nested
                                        >{close => (
                                            <PopUpInfo 
                                                close={close} 
                                                stops={flights[1].stops} 
                                                flight_segment={flights[1].flight_segment} 
                                                outbound={false} 
                                                luggages_included="1 maleta de ida" />
                                        )}
                                        </Popup>
                                    </div>
                                    <div className="flight-stops" style={{ display: inbound_last_stop === 0 ? 'inline' : 'none' }}>
                                        <strong>{t("itinerary.direct")}</strong>
                                    </div>
                                    <div className="flight-arrival">
                                        <p className="flight-arrival-time"><strong>{flights[1].flight_segment[inbound_last_stop].arrivalTime.slice(0,5)}</strong></p>
                                        <p className="flight-arrival-airport">{flights[1].flight_segment[inbound_last_stop].arrivalCityName} - {flights[1].flight_segment[inbound_last_stop].arrivalAirport} ({flights[1].flight_segment[inbound_last_stop].arrivalCode})</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </>

                }
            </section>
        );
    }
}

const mapStateToProps = state => ({
    // flights: state.reservations.package?.flight_package,
    combined_token: state.flights.combined_token,
    search: state.search.payload,
});

export default connect(mapStateToProps, { setIdCombined })(withTranslation('common')(BookingFlights));
import React from "react";
import airEuropa from "../../../static/img/airline-logos/UX.png";
import vueling from "../../../static/img/airline-logos/VY.png";
import easyjet from "../../../static/img/airline-logos/U2.png";
import kml from "../../../static/img/airline-logos/KL.png";
import ryanair from "../../../static/img/airline-logos/FR.png";
import lufthansa from "../../../static/img/airline-logos/LH.png";
import britishAirways from "../../../static/img/airline-logos/BA.png";
import tapPortugal from "../../../static/img/airline-logos/TPC.png";
import binter from "../../../static/img/airline-logos/NT.png";
import iberia from "../../../static/img/airline-logos/IB.png";
import jet2 from "../../../static/img/airline-logos/LS.png";
import tui from "../../../static/img/airline-logos/BY.png";
import aerLingus from "../../../static/img/airline-logos/EI.png";
import airFrance from "../../../static/img/airline-logos/AF.png";
import volotea from "../../../static/img/airline-logos/V7.png";

const AirlineLogo = ({airline_code, airline_name}) => {

    let logo = "";
    switch (airline_code) {
        case("UX"):
            logo = airEuropa;
            break;
        case("U2"):
            logo = easyjet;
            break;
        case("KL"):
            logo = kml;
            break;
        case("LH"):
            logo = lufthansa;
            break;
        case("BA"):
            logo = britishAirways;
            break;
        case("TP"):
            logo = tapPortugal;
            break;
        case("NT"):
            logo = binter;
            break;
        case("VY"):
            logo = vueling;
            break;
        case("FR"):
            logo = ryanair;
            break;
        case("IB"):
            logo = iberia;
            break;
        case("LS"):
            logo = jet2;
            break;
        case("BY"):
            logo = tui;
            break;
        case("EI"):
            logo = aerLingus;
            break;
        case("AF"):
            logo = airFrance;
            break;
        case("V7"):
            logo = volotea;
            break;
    }
    return (<>
            {logo === "" ? airline_name : <img alt={airline_name} src={logo}/>}
        </>
    )
}
export default AirlineLogo;
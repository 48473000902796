import {
    LOAD_CART,
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    CHANGE_PRODUCT_QUANTITY,
    REMOVE_CART,
    ADD_PRODUCT_DINGUS,
    TOGGLE_FLIGHT_CART,
    TOGGLE_HOTEL_CART,
    TOGGLE_EXTRAS_CART
} from './actionTypes';

const initialState = {
    cart_flights: true,
    cart_hotel: true,
    cart_extras: true,
    products: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_CART:
            return {
                ...state,
                products: action.payload
            };
        case ADD_PRODUCT:
            return {
                ...state,
                productToAdd: Object.assign({}, action.payload)
            };
        case ADD_PRODUCT_DINGUS:
            return {
                ...state,
                products: action.payload
            };
        case REMOVE_PRODUCT:
            return {
                ...state,
                productToRemove: Object.assign({}, action.payload)
            };
        case CHANGE_PRODUCT_QUANTITY:
            return {
                ...state,
                productToChange: Object.assign({}, action.payload)
            };
        case REMOVE_CART:
            return {
                ...state,
                products: [],
                productToAdd: {},
                productToRemove: {}
            };
        case TOGGLE_FLIGHT_CART:
            return {
                ...state,
                cart_flights: action.payload
            };
        case TOGGLE_HOTEL_CART:
            return {
                ...state,
                cart_hotel: action.payload
            };
        case TOGGLE_EXTRAS_CART:
            return {
                ...state,
                cart_extras: action.payload
            };
        default:
            return state;
    }
}

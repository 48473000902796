import {CONFIRM_BUTTON} from "../actions/types";

const initialState = {
    confirmBookingButton: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CONFIRM_BUTTON:
            return {
                ...state,
                confirmBookingButton: action.payload
            };
        default:
            return state;
    }
}
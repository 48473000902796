import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { format } from 'date-fns';
import {removeCart, toggleHotelCart} from "../../../services/cart/actions";
import { isMobile } from "react-device-detect";
import Collapse from '@material-ui/core/Collapse';

class CartProduct extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        removeProduct: PropTypes.func.isRequired,
        changeProductQuantity: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            product: this.props.product,
            indexHab: this.props.indexHab,
            isMouseOver: false,
            roomName: "",
        };
    };

    handleMouseOver = () => {
        this.setState({ isMouseOver: true });
    };

    handleMouseOut = () => {
        this.setState({ isMouseOver: false });
    };

    handleOnIncrease = () => {
        const { changeProductQuantity } = this.props;
        const { product } = this.state;
        product.quantity = product.quantity + 1;
        changeProductQuantity(product);
    };

    handleOnDecrease = () => {
        const { changeProductQuantity } = this.props;
        const { product } = this.state;
        product.quantity = product.quantity - 1;
        changeProductQuantity(product);
    };

    childs_ages = (t, reservation) => {
        let children_text = "(";

        if (parseInt(reservation?.room_num_childs) === 1) {
            // Controlar el singular y el plural
            if (parseInt(reservation.children_ages) !== 1) {
                return "(" + reservation.children_ages + " " + t('search.years') + ")";
            }
            else if (parseInt(reservation.children_ages) === 1) {
                return "(" + reservation.children_ages + " " + t('search.year') + ")";
            }
        }

        else if (parseInt(reservation?.room_num_childs) > 1) {

            reservation?.children_ages?.map((children_age, index) => {
                if (index === reservation.children_ages.length - 1) {
                    // Controlar el singular y el plural
                    if (parseInt(children_age) !== 1) {
                        children_text += children_age + " " + t('search.years') + "), ";
                    } else if (parseInt(children_age) === 1) {
                        children_text += children_age + " " + t('search.year') + "), ";
                    }
                } else {
                    children_text += children_age + ", ";
                }
            });

            return children_text;
        }
    };

    componentDidMount() {
        this.roomNameDingus();
    }

    roomNameDingus = () => {
        const { product } = this.state;
        this.props.products?.map((hotel) => {
            if (hotel.hotel === product.hotelcode) {
                hotel.products.map((hotel_room) => {
                    if (hotel_room.room === product.room) {
                        this.setState({ roomName: hotel_room.room_title });
                    }
                })
            }
        })
    }

    openCart = () => {
        this.props.toggleHotelCart(!this.props.cart_hotel)
    };

    render() {
        const { removeProduct, flightsActive, removeCart, cart_hotel } = this.props;
        const { product, roomName } = this.state;
        const classes = ['cart-room'];
        const { t } = this.props;
        const cart = this.props;

        //Funcion que calcula la diferencia en noches entre dos fechas
        const nightDiff = (dateString1, dateString2) => {
            let date1 = new Date(dateString1);
            let date2 = new Date(dateString2);
            let nightDifference = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
            return nightDifference;
        }
        // console.log(product)

        if (!!this.state.isMouseOver) {
            classes.push('shelf-item--mouseover');
        }

        return (
            <div className={classes.join(' ')}>
                <div className="cart-room-header" onClick={this.openCart}>
                    <h5>{t('spring.room')} {!flightsActive && this.state.indexHab}</h5>
                    <p><strong>{cart_hotel ? t('cart.see_less') : t('cart.see_more')}</strong></p>
                    {!flightsActive &&
                        <p className="price">
                            {/*{!this.props.isAgency &&
                                <span className="old" title="sin descuento">
                              {product.pricewithoutoffer}{product.currencyFormat}
                            </span>
                            }*/}
                            <strong className="final" title="final">
                                {product.pricewithoffer.toFixed(2)}{product.currencyFormat}
                            </strong>
                        </p>
                    }
                    {this.props.cartEdit ? null :
                        <a onClick={() => {
                            removeProduct(product)
                            flightsActive && removeCart()
                        }}
                            className="remove-room close-icon"
                            title={t('cart.delete')}>
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M14.3182 2.66666H12.9545H10.9091V0.666656C10.9091 0.298656 10.6043 0 10.2273 0H4.77273C4.39569 0 4.09092 0.298656 4.09092 0.666656V2.66666H2.04546H0.681809C0.304774 2.66666 0 2.96531 0 3.33331C0 3.70131 0.304742 4 0.681809 4H1.36362V14C1.36362 15.1027 2.28136 16 3.40908 16H11.5909C12.7186 16 13.6364 15.1027 13.6364 14V4H14.3182C14.6952 4 15 3.70134 15 3.33334C15 2.96534 14.6953 2.66666 14.3182 2.66666ZM5.45454 1.33334H9.54546V2.66669H5.45454V1.33334ZM11.5909 14.6667C11.9673 14.6667 12.2727 14.3673 12.2727 14H12.2728V4H10.2273H4.77273H2.72727V14C2.72727 14.3673 3.03271 14.6667 3.40908 14.6667H11.5909Z"
                                    fill="currentColor" />
                                <path
                                    d="M5.5 6C5.2235 6 5 6.28508 5 6.63636V12.3636C5 12.7149 5.2235 13 5.5 13C5.7765 13 6 12.7149 6 12.3636V6.63636C6 6.28508 5.7765 6 5.5 6Z"
                                    fill="currentColor" />
                                <path
                                    d="M9.5 6C9.2235 6 9 6.28508 9 6.63636V12.3636C9 12.7149 9.2235 13 9.5 13C9.7765 13 10 12.7149 10 12.3636V6.63636C10 6.28508 9.7765 6 9.5 6Z"
                                    fill="currentColor" />
                            </svg>
                        </a>
                    }
                </div>
                <Collapse in={isMobile ? (cart.length < 1 ? false : cart_hotel) : cart_hotel}
                    timeout="auto" unmountOnExit>
                    <div className="cart-room-info">

                        <p>
                            <strong>{this.props.cart[0].room_title}</strong> - {product.hotel_name}
                        </p>
                        <p>{product.boardtype_text}
                        </p>
                        <p>
                            <strong>{format(new Date(product.reservation?.arrival), "dd/MM/yyyy")}</strong> {t('words.to')}
                            <strong> {format(new Date(product.reservation?.departure), "dd/MM/yyyy")}</strong>
                        </p>
                        <p>{nightDiff(product.reservation?.arrival, product.reservation?.departure)} {t('search.nights')}</p>
                        <p>{t('person.adults').charAt(0).toUpperCase() + t('person.adults').slice(1)}: {product.reservation?.adults}</p>

                        {product.reservation?.room_num_childs !== "0" ?
                            <p>{t('person.children').charAt(0).toUpperCase() + t('person.children').slice(1)}: {product.reservation?.room_num_childs} {this.childs_ages(t, product.reservation)}</p>
                            // <p>{t('person.children').charAt(0).toUpperCase() + t('person.children').slice(1)}: {product.reservation?.room_num_childs}</p>
                            : null
                        }
                        {product.reservation?.resident !== "false" && product.reservation?.resident !== undefined ?
                            <p>{t('words.resident')}</p>
                            : null
                        }
                        {product.christmas_offer === true ?
                            <p><strong>{t('cart.christmas_offer')}</strong></p> : null
                        }
                    </div>
                </Collapse>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAgency: state.auth.isAgency,
    flightsActive: state.flights.isActive,
    products: state.shelf.productsClub,
    cart: state.cart.products,
    cart_hotel: state.cart.cart_hotel,
});

export default connect(mapStateToProps, { removeCart, toggleHotelCart })(withTranslation('common')(CartProduct));

import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import LogoVulcano from "../../static/img/logo-vulcano.svg";
import LogoArona from "../../static/img/logo-arona.svg";
import LogoBitacora from "../../static/img/logo-bitacora.svg";
import LogoSpring from "../../static/img/logo-spring.svg";
import LogoOcean from "../../static/img/oceanbooking.png";
import LogoProOcean from "../../static/img/prooceanbooking.png";
import LogoGF from "../../static/img/gfhotels_footer.png";
import LogoBull from "../../static/img/logo-bullhotels.png";
import {GrMail} from 'react-icons/gr';
import {AiFillEye} from 'react-icons/ai';
import Spinner from "../Spinner";
import {logout} from "../../actions/auth";

export class Header extends Component {

    static propTypes = {
        auth: PropTypes.object.isRequired
    };


    changeLanguage(i18n, lng) {
        i18n.changeLanguage(lng);
    }


    render() {

        const {isAuthenticated, user, isAgency, agency} = this.props.auth;

        const authLink = () => {
            if (isAgency) {
                return <>
                    <li>
                        <p><strong>{agency ? `${agency.name_of_point_sale?.substring(0, 15)}` : "Agency"}</strong></p>
                    </li>
                </>
            } else {
                return <div className="user-link">
                    <strong>{t('words.reservations')} ({user ? `${user.first_name?.substring(0, 10)}` : `${this.props.auth.profile.user.username}` })</strong>
                </div>

            }
        };

        const height = {
            height: "35px"
        }

        const logoImg = () => {
            try {
                if (this.props.hotel === 'arona') {
                    return <img src={LogoArona} alt="arona" className="header-logo"/>
                } else if (this.props.hotel === 'bitacora') {
                    return <img src={LogoBitacora} alt="bitacora" className="header-logo"/>
                } else if (this.props.hotel === 'vulcano') {
                    return <img src={LogoVulcano} alt="vulcano" className="header-logo"/>
                } else if (process.env.REACT_APP_AGENCY_MODE_ACTIVATED === 'true') {
                    return <img src={LogoProOcean} alt="ocenabooking" className="header-logo"/>
                } else if (process.env.REACT_APP_INSTANCE === 'OCEANBOOKING') {
                    return <img src={LogoOcean} alt="ocenabooking" className="header-logo"/>
                } else if (process.env.REACT_APP_INSTANCE === 'GFHOTELS') {
                    return <img src={LogoGF} alt="GFHotels" className="header-logo spring-logo"/>
                } else if (process.env.REACT_APP_INSTANCE === 'SPRING') {
                    return <img src={LogoSpring} alt="Spring" className="header-logo"/>
                } else if (process.env.REACT_APP_INSTANCE === 'BULLHOTELS') {
                    return <img src={LogoBull} alt="Bullhotels" className="header-logo"/>
                }
                return <img src={LogoProOcean} alt="Spring" className="header-logo spring-logo"/>
            } catch (e) {
                return <img src={LogoProOcean} alt="Spring" className="header-logo spring-logo"/>

            }
        };

        // const hotelUrl = (t, lang) => {
        //     try {
        //         if (this.props.hotel === 'arona') {
        //             return <a className="go-hotel" target="_blank"
        //                       href={"https://www.springhoteles.com/" + lang + "/arona-gran-hotel-tenerife"}>{t('discover.hotel')}</a>
        //         }
        //         if (this.props.hotel === 'bitacora') {
        //             return <a className="go-hotel" target="_blank"
        //                       href={"https://www.springhoteles.com/" + lang + "/hotel-bitacora-tenerife"}>{t('discover.hotel')}</a>
        //         }
        //         if (this.props.hotel === 'vulcano') {
        //             return <a className="go-hotel" target="_blank"
        //                       href={"https://www.springhoteles.com/" + lang + "/hotel-vulcano-tenerife"}>{t('discover.hotel')}</a>
        //         }
        //         return <a className="go-hotel" target="_blank"
        //                   href={"https://www.springhoteles.com/" + lang + "/"}>{t('discover.hotel')}</a>
        //     } catch (e) {
        //     }
        // };

        const redirectHome = () => {
            //return this.props.hotel
            return process.env.REACT_APP_AGENCY_URL_REDIRECT;
        };

        const screen = () => {
            console.log('screen')
        }

        const {t, i18n} = this.props;

        return (
                <div id="root">
                    <header id="motor_header">
                        <div className="container">

                            {this.props.isModifying &&
                                <a className="go-hotel"
                                href={"/myreservations/room/" + this.props.pmskey}>{t('modification.to_cancel')}</a>
                                
                            }

                            <a href={redirectHome()} target="_blank">
                                {logoImg()}
                            </a>
                            <nav
                                className={"right-nav " + ((this.props.flightError || this.props.productsError) && 'agency-no-dispo')}>
                                <ul>
                                    {(process.env.REACT_APP_AGENCY_MODE_ACTIVATED === 'false' ?
                                            <li className="phone-link">
                                                <a href="mailto:info@oceanbooking.com" className="mail-icon">
                                                    <GrMail styles={"font-size:22px"}/>
                                                    <span>info@oceanbooking.com</span>
                                                </a>
                                            </li>
                                            :
                                            <li className="phone-link">
                                                <a href="mailto:b2b@oceanbooking.com" className="mail-icon">
                                                    <GrMail styles={"font-size:22px"}/>
                                                    <span>b2b@oceanbooking.com</span>
                                                </a>
                                            </li>
                                    )}
                                    <li className="phone-link">
                                        <a href="tel:+34822130034" className="phone-icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M19.4296 14.1327L15.0546 12.2577C14.8677 12.178 14.6599 12.1612 14.4627 12.2098C14.2654 12.2585 14.0893 12.3698 13.9608 12.5272L12.0233 14.8944C8.98248 13.4607 6.53535 11.0136 5.10165 7.97283L7.46888 6.0353C7.62657 5.90704 7.73818 5.73091 7.78681 5.53355C7.83545 5.33619 7.81846 5.12837 7.73842 4.94153L5.86338 0.566453C5.77554 0.365046 5.62016 0.200605 5.42406 0.101483C5.22796 0.00236143 5.00341 -0.0252272 4.78915 0.0234743L0.726575 0.960991C0.519997 1.00869 0.335688 1.12501 0.203728 1.29095C0.0717689 1.45689 -4.75872e-05 1.66266 2.36575e-08 1.87468C2.36575e-08 11.8944 8.12123 20 18.1253 20C18.3374 20.0001 18.5433 19.9284 18.7093 19.7964C18.8753 19.6644 18.9917 19.4801 19.0394 19.2734L19.9769 15.2108C20.0253 14.9955 19.9971 14.7701 19.8972 14.5733C19.7973 14.3765 19.632 14.2207 19.4296 14.1327Z"
                                                    fill="currentColor"/>
                                            </svg>
                                            <span>+34 822 13 00 34</span>
                                        </a>
                                    </li>

                                    <li className="user-link">
                                        <a className="user-icon"
                                        href={(isAuthenticated ? "/myreservations" : "/login")}>
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <title>User</title>
                                                <g id="Success" stroke="none" strokeWidth="1" fill="none"
                                                fillRule="evenodd">
                                                    <g id="Success---Desktop"
                                                    transform="translate(-1147.000000, -20.000000)">
                                                        <g id="Group-3"
                                                        transform="translate(1147.000000, 16.000000)">
                                                            <g id="Group-2"
                                                            transform="translate(0.000000, 4.000000)">
                                                                <rect id="Rectangle" x="0" y="0" width="24"
                                                                    height="24"></rect>
                                                                <path
                                                                    d="M12.2828283,10.9090909 C16.7610714,10.9090909 20.4218381,14.7316589 20.5657103,19.1919192 C20.5691483,19.62118 20.2345592,19.9743724 19.8106387,19.9986671 L19.7641221,20 L4.80808081,20 C4.37531406,20 4.02200427,19.6598043 4.00098895,19.2322506 L4,19.1919192 C4,14.6174394 7.70834854,10.9090909 12.2828283,10.9090909 Z M12.2828283,12.5252525 C8.92935528,12.5252525 6.1541911,15.0012869 5.68578419,18.2249072 L5.6722979,18.3228198 L5.66464646,18.3838384 L18.9008081,18.3838384 L18.8942541,18.3293093 C18.4798442,15.1194186 15.7834398,12.6298927 12.4917746,12.5284675 L12.3918467,12.5261267 L12.2828283,12.5252525 Z M12.2828283,0 C14.8489998,0 16.9292929,2.08029308 16.9292929,4.64646465 C16.9292929,7.21263621 14.8489998,9.29292929 12.2828283,9.29292929 C9.71665672,9.29292929 7.63636364,7.21263621 7.63636364,4.64646465 C7.63636364,2.08029308 9.71665672,0 12.2828283,0 Z M12.2828283,1.61616162 C10.6092381,1.61616162 9.25252525,2.9728745 9.25252525,4.64646465 C9.25252525,6.3200548 10.6092381,7.67676768 12.2828283,7.67676768 C13.9564184,7.67676768 15.3131313,6.3200548 15.3131313,4.64646465 C15.3131313,2.9728745 13.9564184,1.61616162 12.2828283,1.61616162 Z"
                                                                    id="Combined-Shape" fill="currentColor"
                                                                    fillRule="nonzero"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span>
                                                    {isAuthenticated ?
                                                        authLink()
                                                        :
                                                        t('words.login')
                                                    }
                                                </span>
                                        </a>
                                    </li>
                                    {isAuthenticated &&
                                        <li>
                                            <a className="logout-link"
                                            onClick={() => this.props.logout(this.props.cookies)}>
                                                {/*<span>{t('words.logout')}</span>*/}
                                                <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
                                                    <g id="Área-Privada" stroke="none" stroke-width="1" fill="none"
                                                    fill-rule="evenodd">
                                                        <g id="APriv---Reservas---3-reservas---Desktop"
                                                        transform="translate(-1297.000000, -131.000000)"
                                                        fill="currentColor"
                                                        fill-rule="nonzero">
                                                            <g id="Group-6"
                                                            transform="translate(1197.000000, 123.000000)">
                                                                <g id="Group-4"
                                                                transform="translate(91.000000, 0.000000)">
                                                                    <g id="logout"
                                                                    transform="translate(9.000000, 8.000000)">
                                                                        <path
                                                                            d="M11.4234182,4.09173333 C11.1394909,3.80775758 10.6789333,3.80770909 10.3949091,4.09168485 C10.1109333,4.37570909 10.1108848,4.8361697 10.3949091,5.12019394 L12.5473455,7.27272727 L3.63646061,7.27272727 C3.23481212,7.27272727 2.90918788,7.59835152 2.90918788,8 C2.90918788,8.40164848 3.23481212,8.72727273 3.63646061,8.72727273 L12.5472485,8.72727273 L10.3949091,10.8796121 C10.1108848,11.1636364 10.1108848,11.624097 10.3949091,11.9081212 C10.5369212,12.0501333 10.7230545,12.1211152 10.9091879,12.1211152 C11.0953212,12.1211152 11.2814545,12.0501333 11.4234182,11.9081212 L14.8172606,8.51427879 C15.1012848,8.23030303 15.1012848,7.76979394 14.8172606,7.4857697 L11.4234182,4.09173333 Z"
                                                                            id="Path"></path>
                                                                        <path
                                                                            d="M7.03030303,16 C7.43195152,16 7.75757576,15.6743758 7.75757576,15.2727273 C7.75757576,14.8710788 7.43195152,14.5454545 7.03030303,14.5454545 L1.45454545,14.5454545 L1.45454545,1.45454545 L7.03030303,1.45454545 C7.43195152,1.45454545 7.75757576,1.12892121 7.75757576,0.727272727 C7.75757576,0.325624242 7.43195152,0 7.03030303,0 L0.727272727,0 C0.325624242,0 0,0.325624242 0,0.727272727 L0,15.2727273 C0,15.6743758 0.325624242,16 0.727272727,16 L7.03030303,16 Z"
                                                                            id="Path"></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>
                                        </li>
                                    }

                                    <li className="select-lang">
                                        <select className="lang-options" value={i18n.language} id="lang" style={{width: 70}}
                                                onChange={(event) => this.changeLanguage(i18n, event.target.value)}>
                                            <option value={i18n.language} title="default"
                                                    disabled>{i18n.language.toUpperCase()}</option>
                                            <option value='en' title="English">EN</option>
                                            <option value='es' title="Spanish">ES</option>
                                            <option value='fr' title="French">FR</option>
                                            <option value='de' title="German">DE</option>
                                            <option value='nl' title="Dutch">NL</option>
                                        </select>
                                    </li>
                                    <li className="phone-link">
                                        <a className="mail-icon">
                                            <span id="screensharing"></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </header>
                </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    isModifying: state.reservations.modification?.isModifying,
    pmskey: state.reservations.room?.pms_resid_key,
    flightError: state.flights.isFail.state,
    productsError: state.shelf.isFail.state,
});

export default connect(
    mapStateToProps, {logout}
)(withTranslation('common')(Header));

import React from "react";
import {useTranslation} from "react-i18next";
import AgentsList from "./AgentsList";
import AgentRegisterForm from "./AgentRegisterForm";

export default function AgentsPage(props) {
    const {t, i18n} = useTranslation('common');

    return(
        <>
            <AgentRegisterForm/>
            <AgentsList/>
        </>
    )
}


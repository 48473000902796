import {CALLCENTER_LOGIN, CALLCENTER_LOGOUT, CALLCENTER_USER_LOADED} from "../actions/types";

const initialState = {
    callCenterActive: false,
    emailCustomer: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CALLCENTER_LOGIN:
            return {
                ...state,
                callCenterActive: true,
                emailCustomer: action.payload
            };
        case CALLCENTER_USER_LOADED:
            return {
                ...state,
                user: action.payload
            };
        case CALLCENTER_LOGOUT:
            return {
                ...state,
                callCenterActive: false,
                emailCustomer: null,
                user: null
            };
        default:
            return state;
    }
}
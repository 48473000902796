import {
  SEARCH,
  SEARCH_SUCCESS,
} from '../actions/types.js'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const submit = async values => {
  // Acción al enviar el formulario
  await sleep(1); // Default 200
  /*
  Validación de campos
  if (values.firstName === "John") {
    throw { firstName: "No John's Allowed!" };
  }
  */

  /*
  Mensaje de datos enviados
  window.alert(JSON.stringify(values, 0, 2));
   */
};

/** This is to mimic the behavior of one of the various Redux async middlewares */
const asyncSubmissionMiddleware = store => (next: Next) => (
  action: Action
): State => {
  if (action && action.type === SEARCH) {
    submit(action.payload).then(
      () => store.dispatch({ type: SEARCH_SUCCESS }),
      errors => {
        // NOTE!! We are passing REGISTER_SUCCESS here because 🏁 Final Form expects
        // submit errors to come back in a *resolved* promise.
        store.dispatch({ type: SEARCH_SUCCESS, payload: errors });
      }
    );
  }
  return next(action);
};

export default asyncSubmissionMiddleware;
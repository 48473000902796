import React, {useRef, useState} from "react";
import DatePicker, {setDefaultLocale} from "react-datepicker";
import {useForm, Controller} from "react-hook-form";
import queryString from "query-string";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createSearch} from "../../actions/search";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

import LogoSpring from "../../static/img/logo-spring-search.svg";
import Collapse from "@material-ui/core/Collapse/Collapse";
import useScript from "./useScript";
import {useHistory} from "react-router-dom";
import {setModifyRoom} from "../../actions/reservations";
import moment from 'moment'
import Toggle from "react-toggle";
import {spring40Update} from "../../actions/agency";
import Popup from "reactjs-popup";


export function SearchBox(props) {

    let history = useHistory();

    const show_airport_ref = useRef(null);

    function goToUrlResult(url) {
        history.push(url);
    }

    const modal_mode = props.modal_mode;
    const no_availability = props.no_availability;
    const dispatch = useDispatch();
    const arrivalRedux = useSelector(state => state.search.payload?.arrival);
    const departureRedux = useSelector(state => state.search.payload?.departure);

    const hotelRedux = useSelector(state => state.search.payload?.hotel);
    const adultsRedux = useSelector(state => state.search.payload?.adults);
    const childrenRedux = useSelector(state => state.search.payload?.room_num_childs);
    const children_agesRedux = useSelector(state => state.search.payload?.children_ages);
    const promotionRedux = useSelector(state => state.search.payload?.promotion);

    const {t, i18n} = useTranslation('common', props.i18nlang);
    if (i18n.language === 'es') {
        setDefaultLocale(es);
    }
    const {register, handleSubmit, control} = useForm();

    const arrival = () => {
        const dt = new Date();
        if (arrivalRedux !== undefined) {
            const arrival_data = (arrivalRedux).split("-");
            return new Date(
                parseInt(arrival_data[0]),
                parseInt(arrival_data[1]) - 1,
                parseInt(arrival_data[2]))
        } else {
            return new Date(dt.setDate((dt.getDate() + 7)))
        }
    };
    const departure = () => {
        const dt = new Date();
        if (departureRedux !== undefined) {
            const departure_data = (departureRedux).split("-");
            return new Date(
                parseInt(departure_data[0]),
                parseInt(departure_data[1]) - 1,
                parseInt(departure_data[2]))
        } else {
            return new Date(dt.setDate((dt.getDate() + 14)))
        }
    };
    const [startDate, setStartDate] = useState(arrival);
    const [endDate, setEndDate] = useState(departure);

    const modal_arrive_date = (date, part) => {
        let format_date = date.split("/");
        return (new Date(format_date[2], format_date[2], format_date[2]));
    }

    const [numDays, setNumDays] = useState(0);
    const [start_Date, setStart_Date] = useState(null);
    const [end_Date, setEnd_Date] = useState(null);
    // const [start_Date, setStart_Date] = useState(null);
    // const [end_Date, setEnd_Date] = useState(null);
    const [openStart, setOpenStart] = useState(false);
    const [openEnd, setOpenEnd] = useState(false);
    const [openAll, setOpenAll] = useState(false);

    // Validaciones del formulario, las hacemos a mano porque el atributo "required" no funciona
    const [validate_hotel, setValidate_hotel] = useState(true);
    const [validate_arrival, setValidate_arrival] = useState(true);
    const [validate_departure, setValidate_departure] = useState(true);
    const [validate_flight, setValidate_flight] = useState(true);
    const [validate_room_num_childs_0, setValidate_room_num_childs_0] = useState(true);
    const [validate_room_num_childs_1, setValidate_room_num_childs_1] = useState(true);
    const [validate_room_num_childs_2, setValidate_room_num_childs_2] = useState(true);
    const [search_flight, setSearch_flight] = useState(true);

    const onChangeDateEnd = (endDate) => {
        if (start_Date != null) {
            const oneDay = 1000 * 60 * 60 * 24;
            const diffDays = new Date(endDate)?.getTime() - new Date(start_Date)?.getTime();
            const days = Math.round(diffDays / oneDay);
            if (days > 0) setNumDays(days); else setNumDays(0);
        }

        setEnd_Date(endDate);
        setOpenEnd(false);
    };

    const [hotelOption, setHotelOption] = useState((modal_mode ? hotelRedux : null));
    const [countryOption, setCountryOption] = useState();

    const [numAdults, setNumAdults] = useState((modal_mode ? parseInt(adultsRedux) : 0));
    const [numChilds, setNumChilds] = useState((modal_mode ? parseInt(childrenRedux) : 0));

    const [promoCodeActive, setPromoCodeActive] = useState(false);

    const statusScript = useScript(process.env.REACT_APP_FLIGHT_SCRIPT_IATA);
    let airportObj = undefined;
    if (statusScript === "ready") {
        airportObj = window.AirportInput("autocomplete-airport-1");
    }

    const DatepickerInput = ({...props}) => (
        <input type="text" {...props} readOnly/>
    );

    const aronaCombinations = props => {
        let result = [];

        result.push(
            <div className="input-wrapper select-wrapper" key={result.length}>
                <label htmlFor="adults">{t('search.Num adults')}</label>
                <select id="adults" name="adults"
                        control={control}
                        ref={register}
                        onChange={e => {
                            setNumAdults(parseInt(e.target.value));
                            setNumChilds(0);
                        }}
                        defaultValue={numAdults ? numAdults : 0}
                >
                    <>
                        <option value={parseInt(2)}>2 {t('search.adults')}</option>
                        <option value={parseInt(1)}>1 {t('search.adult')}</option>
                        <option value={parseInt(3)}>3 {t('search.adults')}</option>
                    </>
                </select>
            </div>
        );

        return result
    };

    const vulcanoCombinations = props => {
        let result = [];
        const maxPersonsVulcano = 3;

        result.push(
            <div className="input-wrapper select-wrapper" key={result.length}>
                <label htmlFor="adults">{t('search.Num adults')}</label>
                <select id="adults" name="adults"
                        control={control}
                        ref={register}
                        onChange={e => {
                            setNumAdults(parseInt(e.target.value));
                            setNumChilds(0);
                        }}
                        defaultValue={numAdults ? numAdults : 0}
                >
                    {(numChilds) === 1 ?
                        <>
                            <option value={parseInt(2)}>2 {t('search.adults')}</option>
                            <option value={parseInt(1)}>1 {t('search.adult')}</option>
                        </>
                        :
                        <>
                            <option value={parseInt(2)}>2 {t('search.adults')}</option>
                            <option value={parseInt(1)}>1 {t('search.adult')}</option>
                            <option value={parseInt(3)}>3 {t('search.adults')}</option>
                        </>
                    }
                </select>
            </div>
        );

        if (maxPersonsVulcano > numAdults || isNaN(numAdults)) {
            result.push(
                <div className="input-wrapper select-wrapper" key={result.length}>
                    <label htmlFor="room_num_childs">{t('search.Num children')}</label>
                    <select id="room_num_childs" name="room_num_childs" className="select-childs"
                            control={control}
                            ref={register}
                            onChange={e => setNumChilds(parseInt(e.target.value))}
                            defaultValue={numChilds ? numChilds : 0}
                    >
                        {(numAdults) === 2 ?
                            <>
                                <option value="0">0 {t('search.children')}</option>
                                <option value="1">1 {t('search.children')}</option>
                            </>
                            :
                            <>
                                <option value="0">0 {t('search.children')}</option>
                                <option value="1">1 {t('search.children')}</option>
                                <option value="2">2 {t('search.children')}</option>
                            </>
                        }
                    </select>
                </div>
            )
        }

        return result
    };

    const vulcanoChilds = props => {
        let result = [];
        const ages_vulcano = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        for (let i = 0; i < numChilds; i++) {
            let flag_error = true;
            if (i == 0) flag_error = validate_room_num_childs_0;
            else if (i == 1) flag_error = validate_room_num_childs_1;
            result.push(
                <div className="input-wrapper select-wrapper" key={i}>
                    <label>{t('search.age')} {t('search.children')} {i + 1}</label>
                    <select className={flag_error ? "select-childs" : "error select-childs"} id={"room_num_childs_" + i}
                            name={"room_num_childs_" + i}
                            control={control}
                            ref={register}
                            onChange={e => {
                                if (i == 0) setValidate_room_num_childs_0(true);
                                else if (i == 1) flag_error = setValidate_room_num_childs_1(true);
                            }}
                    >
                        <option value="" disabled>elegir edad</option>
                        {ages_vulcano.map((age, index) => {
                            return (
                                <option key={index} value={age}>{age} {t('search.years')}</option>
                            )
                        })}
                    </select>
                    <span style={{display: flag_error ? 'none' : 'inline'}}
                          className="error-msg-red">{t('search.empty_age_children_error')}</span>

                </div>
            );
        }

        if (numChilds > 0) {
            result.push(
                <p key={result.length}>*{t('search.child_msg')}</p>
            )
        }

        return result
    };

    const bitacoraCombinations = props => {
        let result = [];

        result.push(
            <div className="input-wrapper select-wrapper" key={result.length}>
                <label htmlFor="adults">{t('search.Num adults')}</label>
                <select id="adults" name="adults"
                        control={control}
                        ref={register}
                        onChange={e => {
                            setNumAdults(parseInt(e.target.value));
                            setNumChilds(0);
                        }}
                        value={numAdults ? numAdults : 0}
                >
                    {((numChilds) === 0) || ((numChilds) === 1) ?
                        <>
                            <option value={parseInt(2)}>2 {t('search.adults')}</option>
                            <option value={parseInt(1)}>1 {t('search.adults')}</option>
                            <option value={parseInt(3)}>3 {t('search.adults')}</option>
                        </>
                        :
                        null
                    }
                    {(numChilds) === 2 ?
                        <>
                            <option value={parseInt(2)}>2 {t('search.adults')}</option>
                            <option value={parseInt(1)}>1 {t('search.adults')}</option>
                        </>
                        :
                        null
                    }
                    {(numChilds) === 3 ?
                        <>
                            <option value={parseInt(1)}>1 {t('search.adults')}</option>
                        </>
                        :
                        null
                    }
                </select>
            </div>
        );

        result.push(
            <div className="input-wrapper select-wrapper" key={result.length}>
                <label htmlFor="room_num_childs">{t('search.Num children')}</label>
                <select id="room_num_childs" name="room_num_childs" className="select-childs"
                        control={control}
                        ref={register}
                        onChange={e => {
                            setNumChilds(parseInt(e.target.value));
                        }}
                        defaultValue={numChilds ? numChilds : 0}
                >
                    <option value="0">0 {t('search.children')}</option>
                    <option value="1">1 {t('search.children')}</option>
                    <option value="2">2 {t('search.children')}</option>
                    <option value="3">3 {t('search.children')}</option>
                </select>
            </div>
        );


        return result
    };

    const bitacoraChilds = props => {
        let result = [];
        const ages_bita = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        for (let i = 0; i < numChilds; i++) {
            let flag_error = true;
            if (i === 0) flag_error = validate_room_num_childs_0;
            else if (i === 1) flag_error = validate_room_num_childs_1;
            else if (i === 2) flag_error = validate_room_num_childs_2;
            if (numChilds > 0) {
                result.push(
                    <div className="input-wrapper select-wrapper" key={i}>
                        <label>{t('search.age')} {t('search.children')} {i + 1}</label>
                        <select className={flag_error ? "select-childs" : "error select-childs"}
                                id={"room_num_childs_" + i}
                                name={"room_num_childs_" + i}
                                control={control}
                                ref={register}
                                onChange={e => {
                                    if (i === 0) setValidate_room_num_childs_0(true);
                                    else if (i === 1) flag_error = setValidate_room_num_childs_1(true);
                                    else if (i === 2) flag_error = setValidate_room_num_childs_2(true);
                                }}
                                defaultValue={(modal_mode && children_agesRedux ? children_agesRedux[i] : null)}
                        >
                            <option value="" disabled>elegir edad</option>
                            {ages_bita.map((age, index) => {
                                return (
                                    <option key={index} value={age}>{age} {t('search.years')}</option>
                                )
                            })}
                        </select>
                        <span style={{display: flag_error ? 'none' : 'inline'}}
                              className="error-msg-red">{t('search.empty_age_children_error')}</span>
                    </div>
                );
            }
        }

        if (numChilds > 0) {
            result.push(
                <p key={result.length}>*{t('search.child_msg')}</p>
            )
        }

        return result
    };

    const onSubmit = data => {

        let validation = true; // Variable para comprobar la validacion. Antes se hacia directamente con los "validate_'componente'", pero daba fallos al usarse el props.

        data.children_ages = []; // Guardamos en Redux la edad de los niños (en caso de haberlos) para autocompletar el formulario en el futuro

        // Validamos los datos que nos vienen del formulario con el objeto "data". Se tienen que hacer uno a uno.
        // Si no tiene valor se pone al false para que nos indique el error en el formulario
        if (data.hotel === 'default') {
            setValidate_hotel(false);
            validation = false;
        }
        if (data.arrival === "Invalid Date" || data.arrival === '') {
            setValidate_arrival(false);
            validation = false;
        }
        if (data.departure === "Invalid Date" || data.departure === '') {
            setValidate_departure(false);
            validation = false;
        }
        if (data.room_num_childs_0 === "") {
            setValidate_room_num_childs_0(false);
            validation = false;
        } else if (data.room_num_childs_0 !== undefined) {
            data.children_ages.push(data.room_num_childs_0);
            delete data['room_num_childs_0'];
        }
        if (data.room_num_childs_1 === "") {
            setValidate_room_num_childs_1(false);
            validation = false;
        } else if (data.room_num_childs_1 !== undefined) {
            data.children_ages.push(data.room_num_childs_1);
            delete data['room_num_childs_1'];
        }
        if (data.room_num_childs_2 === "") {
            setValidate_room_num_childs_2(false);
            validation = false;
        } else if (data.room_num_childs_2 !== undefined) {
            data.children_ages.push(data.room_num_childs_2);
            delete data['room_num_childs_2'];
        }
        if (search_flight && data.from === '') {
            setValidate_flight(false);
            validation = false;
        }

        if (validation) {
            // Formateamos las fechas de "dd/mm/YYYY" a "YYYY-mm-dd" para pasarlas por la url al backend
            const arrival_split = data.arrival.split("/");
            data.arrival = arrival_split[2] + "-" + arrival_split[1] + "-" + arrival_split[0];

            const departure_split = data.departure.split("/");
            data.departure = departure_split[2] + "-" + departure_split[1] + "-" + departure_split[0];

            dispatch(createSearch(data));
            let url = "/";
            switch (data.hotel) {
                case 'arona':
                    data.hc = "DG2029";
                    break;
                case 'bitacora':
                    data.hc = "DG2023";
                    break;
                case 'vulcano':
                    data.hc = "DG2024";
                    break;
                case 'victoria':
                    data.hc = "VI";
                    break;
                case 'noelia':
                    data.hc = "NOELIA";
                    break;
                case 'fanabe':
                    data.hc = "FANABE";
                    break;
                case 'isabel':
                    data.hc = "ISABEL";
                    break;
                case 'adeje':
                    data.hc = "ADEJE";
                    break;
                case undefined:
                    break;
            }
            url += 'hotels?';
            if (data.from !== '') url += "&airport=" + data.from.substring(0, 3) + "&";
            else url += "&airport=null&";
            delete data['from'];
            url += queryString.stringify(data);

            if ("modification" in props) {
                dispatch(setModifyRoom(props.pmskey));
                url += "&changeRoomId=" + props.pmskey.toString();
            }
            if (modal_mode) window.location.href = url;
            else goToUrlResult(url);
        }
    };

    function addFlights(data) {

        if (!data.currentTarget.classList.contains("selected")) {
            data.currentTarget.className = data.currentTarget.className + ' selected';
            show_airport_ref.current.classList.add('MuiCollapse-entered');
            setSearch_flight(true);

        } else {
            data.currentTarget.classList.remove("selected");
            show_airport_ref.current.classList.remove('MuiCollapse-entered');
            setSearch_flight(false);
        }
    }

    return (
        <section id="motor_search_box">
            {props.withHeader && <header className="search-header">
                <div className="search-header-content">
                    {props.interno ?
                        <h3 className="h2">WEB INTERNA</h3>
                        :
                        <>
                            <h3 className="h2">{t('reservation.now')}</h3>
                        </>
                    }
                </div>
            </header>
            }
            <article className="search-content">
                {/*<ocean-scout-navbar language="es" chain="spring" domain="localhost:3000"></ocean-scout-navbar>*/}

                <form className="form-style" onSubmit={handleSubmit(onSubmit)}>

                    <div id="rooms_config">
                        <div className="room-config" num_room="1">

                            <div className="column-inputs also-mobile">
                                {bitacoraCombinations()?.map((value, index) => value)}
                            </div>
                            {bitacoraChilds()?.map((value, index) => value)}

                        </div>
                    </div>
                    <div className="column-inputs">

                        <div className="input-wrapper simple-input">
                            <label htmlFor="inbound_date">{t('search.arrival')}</label>
                            <input className={validate_arrival ? "" : "error"} type="text" readOnly=""
                                   autoComplete="off"
                                   id="arrival"
                                   name="arrival"
                                   placeholder={t('search.date_arrival')}
                                   onClick={() => {
                                       setOpenStart(true);
                                       setOpenEnd(false);
                                   }}
                                   defaultValue={start_Date ? moment(start_Date).format('DD/MM/YYYY') : ""}
                                   ref={register}
                            />
                            <span className="error-msg-red"
                                  style={{display: validate_arrival ? 'none' : 'inline'}}>{t('search.empty_date_error')}</span>

                            <Collapse in={openStart} timeout="auto" unmountOnExit>
                                <DatePicker
                                    selected={start_Date}
                                    onChange={(date) => {
                                        setStart_Date(date);
                                        setOpenEnd(true);
                                        setTimeout(function () { //Start the timer
                                            setOpenStart(false);
                                        }.bind(this), 400)
                                        setValidate_arrival(true);
                                    }}
                                    selectsStart
                                    minDate={new Date()}
                                    startDate={start_Date}
                                    endDate={end_Date}
                                    maxDate={end_Date}
                                    customInput={<DatepickerInput/>}
                                    inline
                                />
                            </Collapse>
                        </div>
                        <div className="input-wrapper simple-input">
                            <label htmlFor="outbound_date">{t('search.departure')}</label>
                            <input className={validate_departure ? "" : "error"} type="text" readOnly=""
                                   autoComplete="off"
                                   id="departure"
                                   name="departure"
                                   placeholder={t('search.date_departure')}
                                   onClick={() => {
                                       setOpenStart(false);
                                       setOpenEnd(true);
                                   }}
                                   defaultValue={end_Date ? moment(end_Date).format('DD/MM/YYYY') : ''}
                                   ref={register}
                            />
                            <span className="error-msg-red"
                                  style={{display: validate_departure ? 'none' : 'inline'}}>{t('search.empty_date_error')}</span>
                            <Collapse in={openEnd} timeout="auto" unmountOnExit>
                                <DatePicker
                                    selected={end_Date}
                                    onChange={(date) => {
                                        onChangeDateEnd(date);
                                        setValidate_departure(true);
                                    }}
                                    selectsEnd
                                    startDate={start_Date}
                                    minDate={(start_Date) ? start_Date : new Date()}
                                    endDate={end_Date}
                                    customInput={<DatepickerInput/>}
                                    inline
                                />
                            </Collapse>
                        </div>
                    </div>

                    <div className="add-flights-wrapper">
                        <div className="MuiCollapse-container" ref={show_airport_ref}
                             style={{display: search_flight ? 'inline' : 'none'}}>
                            <div className="MuiCollapse-wrapper">
                                <div className="add-flights-form">
                                    <div className="input-wrapper">
                                        <label htmlFor="airport">{t("flights.origin_airport")}</label>
                                        <input
                                            className={validate_flight ? "" : "error"}
                                            label="From"
                                            name="from"
                                            type="text"
                                            control={control}
                                            ref={register}
                                            placeholder={t('flights.origin_airport')}
                                            id="autocomplete-airport-1"
                                            autoComplete="off"
                                            onFocus={() => {
                                                setValidate_flight(true)
                                            }}
                                        />
                                        <span className="error-msg-red"
                                              style={{display: validate_flight ? 'none' : 'inline'}}>{t('flights.empty_airport_error')}</span>
                                        {/*Esta parte esta comentada hasta que se validen bien estos campos en backend*/}
                                        {/*<input type="checkbox" id="direct_flight" name="direct_flight"*/}
                                        {/*       control={control}*/}
                                        {/*       ref={register}*/}
                                        {/*       checked={directFlight}*/}
                                        {/*       onChange={() => setDirectFlight(!directFlight)}*/}
                                        {/*/>*/}
                                        {/*<label htmlFor="direct_flight">{t('flights.direct_flights')}</label>*/}
                                        {/*<input type="checkbox" id="suitcase" name="suitcase"*/}
                                        {/*       control={control}*/}
                                        {/*       ref={register}*/}
                                        {/*       checked={suitcase}*/}
                                        {/*       onChange={() => setSuitcase(!suitcase)}*/}
                                        {/*/>*/}
                                        {/*<label htmlFor="canary_resident">{t('flights.include_package')}</label>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span
                        className="num-nights">{t('search.nights_selected')}: <strong>{numDays} {t('search.nights')}</strong></span>

                    <div className="pormotion-drop">
                        <span className="pormotion-drop-button"
                              onClick={() => setPromoCodeActive(!promoCodeActive)}>{t('search.promotion')}</span>
                        <Collapse in={promoCodeActive} timeout="auto" unmountOnExit>
                            <input type="text" id="promotion" name="promotion"
                                   control={control}
                                   ref={register}
                                   defaultValue={promotionRedux}
                            />
                        </Collapse>
                    </div>

                    <div className="column-inputs also-mobile">
                        {props.interno ?
                            <div className="input-wrapper select-wrapper">
                                <label htmlFor="num_rooms">País Cliente</label>
                                <select id="lcode" name="lcode"
                                        control={control}
                                        ref={register}
                                        onChange={e => {
                                            setCountryOption(e.target.value);
                                        }}
                                        defaultValue="other"
                                >
                                    <option value="other">OTRO PAÍS</option>
                                    <option value="SE">ESPAÑA</option>
                                    <option value="BG">UK</option>
                                </select>
                            </div>
                            :
                            null
                        }
                    </div>

                    {/* <div className="input-wrapper checkbox-group">
                        <input type="checkbox" value="true" id="resident" name="resident"
                               control={control}
                               ref={register}
                        />
                        <label htmlFor="canary_resident">{t('search.resident')}.</label>
                        {/*<label htmlFor="canary_resident">{t('search.resident')}. <a*/}
                    {/*    href="https://www.springhoteles.com/es/spring-hoteles-tenerife">{t('search.more info')}</a></label>*/}
                    {/*</div> */}

                    <input className="btn btn-primary full-weidth" id="search-button" type="submit"
                           value={search_flight ? t('flights.search_holidays') : t('search.see rooms')}/>
                </form>

            </article>
        </section>
    )
}
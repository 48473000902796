import React, {useEffect, useState} from 'react'

//comps
import HotelInfo from './HotelInfo';
import HotelDesc from './HotelDesc';
import HotelMap from './HotelMap';
import RoomList from "../RoomList";
import RoomGallery from '../RoomGallery';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {returnCurrency} from "../../../services/util";

export default function CardComp({products, productsclub, hotel_name}) {
    const [tab, setTab] = useState(Array(products.length).fill(''));
    const productCart = useSelector(state => state.cart.products)
    const totalFlightsPrice = useSelector(state => state.flights.totalFlightsPrice)
    const search = useSelector(state => state.search.payload);

    const {t} = useTranslation('common');

    function changeTab(index, value) {
        const newtab = Array(products.length).fill('');
        newtab[index] = value;
        setTab(newtab);
    }

    useEffect(()=>{
        if(search.promoted !== undefined)
            changeTab(0,'room')
    },[]);

    return (
        <>
            {productsclub.map((hotel, index) => {
                return (
                    <div key={index}  className="multihotel">

                        {/* Image y title little desc*/}
                        <HotelInfo title={hotel['hotel_name']} short_description={hotel.short_description}
                                   hotel_stars={hotel.hotel_stars} hotel_images={hotel.hotel_images}
                                   address={hotel.address} promoted={hotel.promoted}/>

                        
                        {/* Mini navbar */}
                        <div className="flex-menu">
                            {/* navitems */}
                            <div p='0 12px' justify='space-evenly' className="menu">
                                <a
                                      onClick={() => tab[index] !== 'room' ? changeTab(index, 'room') : changeTab(index, '')}
                                      style={{
                                          borderBottom: (tab[index] === 'room' ? '2px solid var(--site-color)' : ''),
                                          color: (tab[index] === 'room' ? 'var(--site-color)' : ''),
                                          fontWeight: (tab[index] === 'room' ? 'bold' : '')
                                      }}>{t('multihotel.room')}</a>
                                <a
                                      onClick={() => tab[index] !== 'desc' ? changeTab(index, 'desc') : changeTab(index, '')}
                                      style={{
                                          borderBottom: (tab[index] === 'desc' ? '2px solid var(--site-color)' : ''),
                                          color: (tab[index] === 'desc' ? 'var(--site-color)' : ''),
                                          fontWeight: (tab[index] === 'desc' ? 'bold' : '')
                                      }}>{t('multihotel.description')}</a>
                                {/* <Link onClick={() => tab[index !== 'map' ? tab[index] = 'map' : tab[index] = ''} style={{borderBottom: (tab[index === 'map' ? '1px solid red' : ''), color: (tab[index === 'map' ? 'var(--site-color)' : ''), fontWeight: (tab[index === 'map' ? 'bold' : '')}}>Mapa</Link> */}

                                <a
                                      onClick={() => tab[index] !== 'pics' ? changeTab(index, 'pics') : changeTab(index, '')}
                                      style={{
                                          borderBottom: (tab[index] === 'pics' ? '2px solid var(--site-color)' : ''),
                                          color: (tab[index] === 'pics' ? 'var(--site-color)' : ''),
                                          fontWeight: (tab[index] === 'pics' ? 'bold' : '')
                                      }}>{t('multihotel.images')}</a>
                            </div>
                            {/* Price and button */}
                            <div gap='1em' align='center' p='8px 24px 8px 0'>
                                <p className='pricetext'>
                                    {productCart.length >= 1 && (parseFloat(hotel.products[0].boardtypes[0]?.pricewithoffer) < parseFloat(productCart[0]?.pricewithoffer) ? "-" : "+")}
                                    {Math.abs(productCart.length > 0 ? (parseFloat(hotel.products[0].boardtypes[0]?.pricewithoffer) - parseFloat(productCart[0]?.pricewithoffer)) : parseFloat(hotel.products[0].boardtypes[0]?.pricewithoffer) + parseFloat(totalFlightsPrice)).toFixed(2)}
                                    {returnCurrency("EUR")}</p>
                                <button variant='brandPrimary' ml='20px'
                                        className={tab[index] !== '' ? "choose-hotel btn-selected" : "choose-hotel btn-select"}
                                        onClick={() => {
                                            (tab[index] !== 'room' ? changeTab(index, 'room') : changeTab(index, ''))
                                        }}>{tab[index] !== '' ? t('multihotel.close') : t('multihotel.see_rooms')}</button>

                            </div>
                        </div>


                        {/* Selectable View */}

                        {/* Rooms */}
                        {tab[index] === 'room' && (
                            <RoomList products={products[index].products}
                                      productsclub={hotel.products}
                                      hotel={hotel_name} hotel_chains={hotel.hotel_chains} provider={hotel.provider} />
                        )
                        }
                        {/* Desc */}
                        {tab[index] === 'desc' &&
                            <HotelDesc description={hotel.description}/>
                        }

                        {/* Map */}
                        {tab[index] === 'map' &&
                            <HotelMap position={[43.505, -0.09]}/>
                        }
                        {/* Pics */}
                        {tab[index] === 'pics' &&
                            // <HotelPics images={hotel['products'][0]['images_room']}/>
                            <RoomGallery sliderimages={hotel.hotel_images}/>
                        }

                    </div>
                )
            })
            }

        </>
    )
}
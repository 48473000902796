import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';
import AirlineLogo from "./AirlineLogo";

export default function PopUpInfo({ stops, flight_segment, outbound, luggages_included }) {

    const { t } = useTranslation('common');

    return (
        <div className="flight-info-popup-wrapper">
            <div className="flight-info-popup-header">
                <p className="title">{t('details.title')}</p>
            </div>

            <div className="flight-info-popup-content">
                <div className="flight-info">
                    <div className="flight-info-segments">
                        <div className="flight-info-header">
                            <div className="flight-type-wrapper">
                                {outbound ?
                                    <>
                                        <p className="type">
                                            <svg width="18" height="18" viewBox="0 0 640 512"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM80.55 341.27c6.28 6.84 15.1 10.72 24.33 10.71l130.54-.18a65.62 65.62 0 0 0 29.64-7.12l290.96-147.65c26.74-13.57 50.71-32.94 67.02-58.31 18.31-28.48 20.3-49.09 13.07-63.65-7.21-14.57-24.74-25.27-58.25-27.45-29.85-1.94-59.54 5.92-86.28 19.48l-98.51 49.99-218.7-82.06a17.799 17.799 0 0 0-18-1.11L90.62 67.29c-10.67 5.41-13.25 19.65-5.17 28.53l156.22 98.1-103.21 52.38-72.35-36.47a17.804 17.804 0 0 0-16.07.02L9.91 230.22c-10.44 5.3-13.19 19.12-5.57 28.08l76.21 82.97z"
                                                    fill="currentColor" />
                                            </svg>
                                            <span>{t('details.outbound')}</span>
                                        </p>
                                        <div className="airline">
                                            <AirlineLogo airline_code={flight_segment[0].airlineCode} airline_name={flight_segment[0].airlineName} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <p className="type">
                                            <svg width="18" height="18" viewBox="0 0 640 512"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM44.81 205.66l88.74 80a62.607 62.607 0 0 0 25.47 13.93l287.6 78.35c26.48 7.21 54.56 8.72 81 1.36 29.67-8.27 43.44-21.21 47.25-35.71 3.83-14.5-1.73-32.71-23.37-54.96-19.28-19.82-44.35-32.79-70.83-40l-97.51-26.56L282.8 30.22c-1.51-5.81-5.95-10.35-11.66-11.91L206.05.58c-10.56-2.88-20.9 5.32-20.71 16.44l47.92 164.21-102.2-27.84-27.59-67.88c-1.93-4.89-6.01-8.57-11.02-9.93L52.72 64.75c-10.34-2.82-20.53 5-20.72 15.88l.23 101.78c.19 8.91 6.03 17.34 12.58 23.25z"
                                                    fill="currentColor" />
                                            </svg>
                                            <span>{t('details.inbound')}</span>
                                        </p>
                                        <div className="airline">
                                            <AirlineLogo airline_code={flight_segment[0].airlineCode} airline_name={flight_segment[0].airlineName} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        {flight_segment.map((flight, index) => {
                            return (
                                <div className="flight-segment">
                                    <div className="flight-segment-header">
                                        <p className="title">{index + 1}º {t('details.flight')}</p>
                                        <p className="flight-id">{t('details.flight')} {flight.flightCode}</p>
                                    </div>
                                    <div className="flight-segment-itinerary">
                                        <div className="flight-segment-outbound">
                                            <span>{flight.departureTime} - {format(new Date(flight.departureDate), "dd/MM/yyyy")}</span>
                                            <br />
                                            <span><strong>{flight.departureAirport}</strong> - {flight.departureCityName}</span>
                                            <br />
                                            <span>Terminal {flight.departureTerminal}</span>
                                        </div>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14 7.33334H11.0237L8.28668 2.54321C8.25018 2.47944 8.19749 2.42644 8.13392 2.38958C8.07035 2.35272 7.99818 2.33331 7.9247 2.33331H6.21896C5.94214 2.33331 5.7424 2.59816 5.81844 2.8643L7.09527 7.33334H4.41661L3.29161 5.83333C3.21296 5.72838 3.08926 5.66666 2.95827 5.66666H1.91686C1.64576 5.66666 1.44681 5.92135 1.51269 6.18437L2.33327 9.00001L1.51269 11.8156C1.44681 12.0787 1.64576 12.3334 1.91686 12.3334H2.95827C3.08952 12.3334 3.21296 12.2716 3.29161 12.1667L4.41661 10.6667H7.09527L5.81844 15.1355C5.7424 15.4016 5.94214 15.6667 6.21896 15.6667H7.9247C8.07418 15.6667 8.2122 15.5865 8.28642 15.4568L11.0237 10.6667H14C14.9206 10.6667 16.5 9.92059 16.5 9.00001C16.5 8.07943 14.9206 7.33334 14 7.33334Z"
                                                fill="currentColor"></path>
                                        </svg>
                                        <div className="flight-segment-inbound">
                                            <span>{flight.arrivalTime} - {format(new Date(flight.arrivalDate), "dd/MM/yyyy")}</span>
                                            <br />
                                            <span><strong>{flight.arrivalAirport}</strong> - {flight.arrivalCityName}</span>
                                            <br />
                                            <span>Terminal {flight.arrivalTerminal}</span>
                                        </div>
                                    </div>
                                    {luggages_included === false ?
                                        <div className="flight-segment-luggage">
                                            <p className="title">{t('details.luggage')}</p>
                                            <div>
                                                <p><strong>{t('details.luggage_title')}</strong></p>
                                                <p>{t('details.luggage_description')}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className="flight-segment-luggage">
                                            <p className="title">{t('details.luggage')}</p>
                                            <div>
                                                <p><strong>{t('details.luggage_included_title')}</strong></p>
                                                <p>{t('details.luggage_included_description')}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })}

                    </div>


                </div>
            </div>
        </div>
    )
}


import React, {useState} from "react";
import InBoundFlights from "../CombinableFlights/InBoundFlights";
import OutBoundFlights from "../CombinableFlights/OutBoundFlights";
import CombinedFlights from "../CombinedFlights/CombinedFlights";
import {connect, useSelector} from "react-redux";
import {selectFlight} from "../../../../services/flights/actions";
import {useTranslation} from "react-i18next";
import {addCartGTM} from "../../../GTMComponent/GTMComponent";

function checkSaveButton(combined_flights, combinable_flights, combined_token, combinable_inbound_token, combinable_outbound_token) {
    if (combined_flights && combined_token != null) {
        return false;
    } else return !(combinable_flights && combinable_inbound_token != null && combinable_outbound_token != null);
}

function createStructure(combined_flight, inbound_flight, outbound_flight, combined, sessionToken){
    let odd_taxes = 0;
    let odd_total_price = 0;
    if(combined){
        odd_taxes = (combined_flight.taxes*100)%2;
        odd_total_price = (combined_flight.totalPrice*100)%2;
    }
    return [
        {
            type: (combined ? combined_flight.inbound.type : inbound_flight.type),
            session_token: sessionToken,
            token: (combined ? combined_flight.token : inbound_flight.rates[0].token),
            departureCode: (combined ? combined_flight.inbound.departureCode : inbound_flight.departureCode),
            arrivalCode: (combined ? combined_flight.inbound.arrivalCode : inbound_flight.arrivalCode),
            stops: (combined ? combined_flight.inbound.stops : inbound_flight.stops),
            taxes: (combined ? (combined_flight.taxes > 0 ? (combined_flight.taxes/2 + odd_taxes).toString() : "0") : inbound_flight.rates[0].taxes.toString()),
            taxes_currency: (combined ? combined_flight.taxes_currency : inbound_flight.rates[0].taxes_currency),
            totalPrice: (combined ? (combined_flight.totalPrice > 0 ? (combined_flight.totalPrice/2 + odd_total_price).toString() : "0") : inbound_flight.rates[0].totalPrice.toString()),
            totalPrice_currency: (combined ? combined_flight.totalPrice_currency : inbound_flight.rates[0].totalPrice_currency),
            flight_segment: (combined ? combined_flight.inbound.flight_segment : inbound_flight.flight_segment),
            flight_extra: [],
            luggages_included: (combined ? combined_flight.inbound.luggages_included : inbound_flight.luggages_included),
            taxesP: (combined ? combined_flight.taxesP : inbound_flight.rates[0].taxesP),
            supplier: (combined ? combined_flight.supplier : inbound_flight.rates[0].supplier),
        },
        {
            type: (combined ? combined_flight.outbound.type : outbound_flight.type),
            session_token: sessionToken,
            token: (combined ? combined_flight.token : outbound_flight.rates[0].token),
            departureCode: (combined ? combined_flight.outbound.departureCode : outbound_flight.departureCode),
            arrivalCode: (combined ? combined_flight.outbound.arrivalCode : outbound_flight.arrivalCode),
            stops: (combined ? combined_flight.outbound.stops : outbound_flight.stops),
            taxes: (combined ? (combined_flight.taxes > 0 ? (combined_flight.taxes/2 + odd_taxes).toString() : "0") : outbound_flight.rates[0].taxes.toString()),
            taxes_currency: (combined ? combined_flight.taxes_currency : outbound_flight.rates[0].taxes_currency),
            totalPrice: (combined ? (combined_flight.totalPrice > 0 ? (combined_flight.totalPrice/2 + odd_total_price).toString() : "0") : outbound_flight.rates[0].totalPrice.toString()),
            totalPrice_currency: (combined ? combined_flight.totalPrice_currency : outbound_flight.rates[0].totalPrice_currency),
            flight_segment: (combined ? combined_flight.outbound.flight_segment : outbound_flight.flight_segment),
            flight_extra: [],
            luggages_included: (combined ? combined_flight.outbound.luggages_included : outbound_flight.luggages_included),
            taxesP: (combined ? combined_flight.taxesP : outbound_flight.rates[0].taxesP),
            supplier: (combined ? combined_flight.supplier : inbound_flight.rates[0].supplier),
        }
    ]
}

function changeSelectedFlight(selectFlight, flights, combined_token, combinable_inbound_token, combinable_outbound_token, combined_flights, sessionToken, productsCart, extras) {
    if (combined_flights) {
        flights.combinations.map((flight) => {
            if (combined_token === flight.token) {
                let selected_flight = createStructure(flight, null, null, true, sessionToken)
                selectFlight(false, selected_flight);
            }
        })
    } else {
        let inbound = {};
        let outbound = {};
        Object.keys(flights.inbound).map((flight_number) => {
            if (combinable_inbound_token === flights.inbound[flight_number].rates[0].token) {
                inbound = flights.inbound[flight_number]
            }
        })
        Object.keys(flights.outbound).map((flight_number) => {
            if (combinable_outbound_token === flights.outbound[flight_number].rates[0].token) {
                outbound = flights.outbound[flight_number]
            }
        })
        let selected_flight = createStructure(null, inbound, outbound, false, sessionToken)
        selectFlight(false, selected_flight);
    }
    new addCartGTM()
}

export const FlightsModal = ({selectFlight, close}) => {

    const [combined_flights, setCombined_flights] = useState(true);
    const [combinable_flights, setCombinable_flights] = useState(false);
    const flights = useSelector(state => state.flights.flights);
    const combined_token = useSelector(state => state.flights.combined_token);
    const combinable_inbound_token = useSelector(state => state.flights.combinable_inbound_token);
    const combinable_outbound_token = useSelector(state => state.flights.combinable_outbound_token);
    const package_selected_combined_token = useSelector(state => state.flights.selected_flight[0].token);
    const sessionToken = useSelector(state => state.flights.flights.sessionToken);
    const productsCart = useSelector(state => state.cart.products);
    const extras = useSelector(state => state.extras);

    const {t} = useTranslation('common');
    
    return (
        <div className="select-flights-popup-wrapper">

            {/* TODO Traduccion */}
            <div className="select-flights-popup-header">
                <h1 className="title">{t('flights.select')}</h1>
            </div>

            <div className="select-flights-popup-content">
                <div className="flights-type-selector">
                    <div className={"combined-selector" + (combined_flights ? " selected" : "")}>
                        <p className="type">
                                <span onClick={() => {
                                    setCombined_flights(true);
                                    setCombinable_flights(false);
                                }}>{t('flights.combined')}</span>
                            {/* TODO Traduccion */}
                        </p>
                    </div>
                    <div className={"combinable-selector" + (combinable_flights ? " selected" : "")}>
                        <p className="type">
                                <span onClick={() => {
                                    setCombined_flights(false);
                                    setCombinable_flights(true);
                                }}>{t('flights.combinable')}</span>
                            {/* TODO Traduccion */}
                        </p>
                    </div>
                </div>

                {combined_flights &&
                    <>
                        <div className="combined-wrapper" styles={"display:block;"}>
                            <div className="flights-wrapper roundTrip-wrapper" styles={"display:block;"}>
                                {/* TODO Traduccion */}
                                <p className="title">{t('flights.recommendations')}</p>
                                <div className="flights-list-wrapper">
                                    {Object.keys(flights.combinations).map((flight_number, index) => {
                                        if (flights.combinations[flight_number].token !== package_selected_combined_token)
                                            return (
                                                <div key={index}>
                                                    <CombinedFlights combinedFlight={flights.combinations[flight_number]} index={index}/>
                                                </div>
                                            )
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                }
                {combinable_flights &&
                    <>
                        <div className="combinable-wrapper" styles="display:none;">
                            <div className="flights-wrapper inbound-wrapper" styles="display:block;">
                                <p className="title">{t('flights.outbound')}</p>
                                <div className="flights-list-wrapper">
                                    {flights.outbound.map((flight, index) => {
                                        return (
                                            <div key={index}>
                                                <OutBoundFlights flight={flight}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="flights-wrapper outbound-wrapper" styles="display:block;">
                                <p className="title">{t('flights.inbound')}</p>
                                <div className="flights-list-wrapper">
                                    {flights.inbound.map((flight, index) => {
                                        return (
                                            <div key={index}>
                                                <InBoundFlights flight={flight}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            {/* TODO Traduccion*/}
            <div className="select-flights-popup-footer">
                <button className="btn btn-select btn-next hidden" disabled>
                    <span>Selecciona</span>
                </button>

                <button className="btn btn-select btn-prev hidden">
                    <span>{t('flights.outbound')}</span>
                </button>

                <button className="btn btn-select btn-save"
                        disabled={checkSaveButton(combined_flights, combinable_flights, combined_token, combinable_inbound_token, combinable_outbound_token)}
                        onClick={() => {
                            changeSelectedFlight(selectFlight, flights, combined_token, combinable_inbound_token, combinable_outbound_token, combined_flights, sessionToken, productsCart, extras);
                            close();
                        }}>
                    <span>{t('words.save')}</span>
                </button>
            </div>
        </div>
    )
}

export default connect(null, {selectFlight})(FlightsModal);
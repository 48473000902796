import {
    GET_FLIGHTS,
    SET_FLIGHT,
    ID_COMBINED,
    ID_COMBINABLE_INBOUND,
    ID_COMBINABLE_OUTBOUND,
    SET_ACTIVATE_FLIGHTS,
    FLIGHT_DONE,
    FLIGHT_FAIL,
    SET_FLIGHT_TOTAL_PRICE
} from './actionTypes';

const initialState = {
    flights: [],
    totalFlightsPrice: 0,
    selected_flight: null,
    combined_token: null,
    combinable_inbound_token: null,
    combinable_outbound_token: null,
    isActive: false,
    isDone: false,
    isFail: {
        state: false,
        error: ""
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FLIGHTS:
            return {
                ...state,
                flights: action.payload,
            };
        case SET_FLIGHT:
            return {
                ...state,
                selected_flight: action.payload,
            };
        case SET_FLIGHT_TOTAL_PRICE:
            return {
                ...state,
                totalFlightsPrice: action.payload,
            };
        case ID_COMBINED:
            return {
                ...state,
                combined_token: action.payload,
            };
        case ID_COMBINABLE_INBOUND:
            return {
                ...state,
                combinable_inbound_token: action.payload,
            };
        case ID_COMBINABLE_OUTBOUND:
            return {
                ...state,
                combinable_outbound_token: action.payload,
            };
        case SET_ACTIVATE_FLIGHTS:
            return {
                ...state,
                isActive: action.payload
            }
        case FLIGHT_DONE:
            return {
                ...state,
                isDone: true
            }
        case FLIGHT_FAIL:
            return {
                ...state,
                isFail: {
                    state: true,
                    error: action.payload
                }
            }
        default:
            return state;
    }
}

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import {checkoutGTM} from "../../GTMComponent/GTMComponent";

export default function ClubBanner(params) {

    const {t} = useTranslation('common');

    const {register, errors} = useFormContext();

    const [endForm, setEndForm] = useState();

    if(endForm) {
        //new checkoutGTM(3);
    }

    return (
        <div className="spring-club-banner">
            {/*<img src="assets/img/logo-spring-simple.svg" alt="Grupo Spring"/>*/}

            <div className="discount-corner">
                <strong>-5%</strong>
            </div>

            <div className="header">
                <h3>{t('bloque_azul_contrasena.titulo_bloque_azul_contrasena')}</h3>

                <p>{t('bloque_azul_contrasena.parrafo_contenido_azul_contrasena')}</p>
            </div>

            <div className="column-inputs">
                <div className="input-wrapper">
                    <label htmlFor="sprint_password">{t('bloque_azul_contrasena.contrasena')} {params.required ?
                        <span>({t('words.required')})</span> : null}</label>
                    <input
                        type="password"
                        id="spring_password"
                        name="spring_password"
                        placeholder={t('bloque_azul_contrasena.contrasena_1')}
                        ref={register}
                        required={params.required}
                    />
                </div>
                <div className="input-wrapper">
                    <label
                        htmlFor="sprint_password_confirm">{t('bloque_azul_contrasena.contrasena_confirmar')} {params.required ?
                        <span>({t('words.required')})</span> : null}</label>
                    <input
                        type="password"
                        id="spring_password_confirm"
                        name="spring_password_confirm"
                        placeholder={t('bloque_azul_contrasena.contrasena_confirmar_1')}
                        ref={register}
                        required={params.required}
                    />
                </div>
            </div>
            <div className="input-wrapper">
                <input
                    type="checkbox"
                    value="true"
                    id="spring_club_acceptance"
                    name="spring_club_acceptance"
                    ref={register}
                    required={params.required}
                    onChange={(val) => {
                        setEndForm(true);
                    }}
                />
                <label htmlFor="spring_club_acceptance">
                    {t('bloque_azul_contrasena.acepto_newsletter')}
                </label>
            </div>
        </div>
    )
}
import {
    FETCH_EXTRA_PRODUCTS,
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_CLUB,
    UPDATE_PRODUCTS,
    SET_PROVIDER,
    ROOMS_FAIL
} from './actionTypes';
import axios from 'axios';
import {impressionsGTM, unavailabilityGTM} from "../../components/GTMComponent/GTMComponent";
import {addProduct, loadCart} from "../cart/actions";
import {updateCart} from "../total/actions";
import {calculateTotalPrice} from "../../actions/pkgData";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;
const API_URL_POST = process.env.REACT_APP_PACKAGE_API_URL_POST;

const timeout = process.env.REACT_APP_TIMEOUT;

const multihotel_hotels = process.env.REACT_APP_MULTIHOTEL_HOTELS;

const promoted_hotels = process.env.REACT_APP_PROMOTED_HOTELS;

const only_package_hotels = process.env.REACT_APP_ONLY_PACKAGE_CHAINS;

export const dingusRooms = (search, token, isAuthenticated, active_flights = false, lang = "en") => (dispatch, getState) => {
    const store = getState();

    let children_ages = [];
    if (search.children_ages !== undefined) {
        if (search.children_ages.length < 2)
            children_ages.push(parseInt(search.children_ages))
        else
            search.children_ages.map((children) => {
                children_ages.push(parseInt(children));
            })
    }
    let hotel_code = (search.hotel === undefined ? multihotel_hotels.split(",") : [search.hotel]);

    let hotel_code_only_package = only_package_hotels.split(",");

    if(search.airport === "null"){
        hotel_code = hotel_code.filter(hotel=> !hotel_code_only_package.includes(hotel))
    }

    const request = {
        type: "SEARCH_RESULT",
        action: "GET_HOTEL_ROOMS",
        token: token,
        payload: {
            hotel_code: hotel_code,
            hotel_city_code: "",
            language: lang,
            start_date: search.arrival,
            end_date: search.departure,
            is_package: (search.airport !== "null"), // Comentado porque para la demo este parámetro tiene que ser true
            rate_code: [],
            room_code: [],
            meal_code: [],
            country_code: store.search.ipstack_data?.country_code,
            promotion: search.promotion === undefined ? "" : search.promotion,
            rooms: [
                {
                    adults: parseInt(search.adults),
                    children_ages: children_ages,
                    babies: 0
                }
            ]
        }
    }
    if (request.token !== undefined && request.token !== null)
        axios.post(BASEURL + API_URL_POST, request, {timeout: timeout})
            .then(function (response) {
                if (response.data[0].products.length < 1 || response.data[0].products[0].boardtypes < 1) {
                    return dispatch(updateFail("NO_DISPO"));
                }
                if (search.multihotel) {
                    let hotel_aux;
                    let index_aux
                    if (search.promoted !== undefined) {
                        response.data.map((hotel, index) => {
                            if (hotel.external_api_code === search.promoted) {
                                hotel_aux = hotel;
                                index_aux = index;
                            }
                        })
                        if (hotel_aux !== undefined) {
                            hotel_aux.promoted = true;
                            response.data.splice(index_aux, 1);
                            response.data = [hotel_aux].concat(response.data);
                        } else {
                            response.data[0].promoted = true;
                        }
                    } else {
                        let array_promoted_hotels = promoted_hotels.split("-");
                        let hotel_promoted = array_promoted_hotels[Math.floor(Math.random() * 3)]
                        response.data.map((hotel, index) => {
                            if (hotel.hotel === hotel_promoted) {
                                hotel_aux = hotel;
                                index_aux = index;
                            }
                        })
                        if (hotel_aux !== undefined) {
                            hotel_aux.promoted = true;
                            response.data.splice(index_aux, 1);
                            response.data = [hotel_aux].concat(response.data);
                        } else {
                            response.data[0].promoted = true;
                        }
                    }
                }
                if (active_flights || isAuthenticated) {
                    dispatch(updateProducts(response.data));
                    dispatch(updateProductsClub(response.data));
                } else {
                    if (process.env.REACT_APP_INSTANCE === "SPRING") {
                        dispatch(baseBoardtypesFilter(response.data));
                        dispatch(clubBoardtypesFilter(response.data));
                    } else {
                        // dispatch(updateProducts(response.data));
                        // dispatch(updateProductsClub(response.data));
                        dispatch(clubBoardtypesFilter(response.data));
                    }
                    new impressionsGTM();
                }
                dispatch(setProvider(response.data[0].provider));

                // Código para seleccionar una habitación por defecto en vuelo + hotel
                if (search.promoted !== undefined) {
                    let product = response.data[0].products[0].boardtypes[0];
                    product.reservation = search;
                    product.quantity = 1;
                    product.springclub = true;
                    product.id = product.id + "-" + Date.now();
                    product.room_title = response.data[0].products[0].room_title
                    product.hotel_chains = response.data[0].hotel_chains
                    dispatch(addProduct(product))
                    dispatch(loadCart([product]));
                    dispatch(updateCart([product]));
                    dispatch(calculateTotalPrice());
                }

                return dispatch(updateDone());
            })
            .catch(function (error) {
                console.log("error:", error);
                if (error.response?.status === 404) {
                    new unavailabilityGTM();
                    return dispatch(updateFail(error.response.data.error));
                }
                new unavailabilityGTM();
                return dispatch(updateFail(error));
            });
}

const createExtrasUrl = (date, adults, childs) => {
    return axios.get(BASEURL + '/fareharbor-api/booking/', {
        params: {
            date: date,
            adults: adults,
            childs: childs,
            company_name: 'elprado-eur',
            itempk: '20580',
        }
    });
};

export const fetchExtraProducts = (search, callback) => dispatch => {

    if (search !== undefined) {
        let arrival_date = new Date(search.arrival);
        let departure_date = new Date(search.departure);
        let date = new Date();

        if ((departure_date.getDate() - arrival_date.getDate()) > 1) {
            // Si se queda más de un día le ofrecemos la excursión al día siguiente de la llegada
            date.setDate(arrival_date.getDate() + 1);
            date = date.toISOString().split('T')[0]


            return createExtrasUrl(date, search.adults, search.room_num_childs)
                .then(res => {
                    let extraProducts = res.data.availabilities;

                    if (!!callback) {
                        callback();
                    }

                    return dispatch({
                        type: FETCH_EXTRA_PRODUCTS,
                        payload: extraProducts
                    });
                })
                .catch(err => {
                    console.log('Could not fetch extra products. Try again later.', err);
                });
        }
    }
};

export const baseBoardtypesFilter = (products) => (dispatch) => {
    let total_products = [];
    let base_product = {};
    products.map((hotel) => {
        if (hotel.hotel_chains === 'SPRING HOTELES') {
            base_product = {
                hotel: hotel.hotel,
                hotel_name: hotel.hotel_name,
                description: hotel.description,
                short_description: hotel.short_description,
                address: hotel.address,
                hotel_stars: hotel.hotel_stars,
                category_code: hotel.category_code,
                max_child_age: hotel.max_child_age,
                min_child_age: hotel.min_child_age,
                latitude: hotel.latitude,
                longitude: hotel.longitude,
                hotel_chains: hotel.hotel_chains,
                hotel_images: hotel.hotel_images,
                time: hotel.time,
                provider: hotel.provider,
                products: [],
            }
            hotel.products.map((product, index) => {
                let boardtypes_base = [];
                product.boardtypes.map((boardtype) => {
                    if (!boardtype.offertext.includes("BASE")) {
                        boardtypes_base.push(boardtype);
                    }
                })
                base_product.products[index] = {
                    boardtypes: boardtypes_base,
                    room: product.room,
                    images_room: product.images_room,
                    images_carousel: product.images_carousel,
                    translation_code: product.translation_code,
                    room_title: product.room_title,
                    room_description: product.room_description,
                };
            })
            total_products.push(base_product);
        } else {
            total_products.push(hotel);
        }
    })
    return dispatch(updateProducts(total_products))
};

export const clubBoardtypesFilter = (products) => (dispatch) => {
    let total_products_club = [];
    let club_product = {};
    products.map((hotel) => {
        if (hotel.hotel_chains === 'SPRING HOTELES') {
            club_product = {
                hotel: hotel.hotel,
                hotel_name: hotel.hotel_name,
                description: hotel.description,
                short_description: hotel.short_description,
                address: hotel.address,
                hotel_stars: hotel.hotel_stars,
                category_code: hotel.category_code,
                max_child_age: hotel.max_child_age,
                min_child_age: hotel.min_child_age,
                latitude: hotel.latitude,
                longitude: hotel.longitude,
                hotel_chains: hotel.hotel_chains,
                hotel_images: hotel.hotel_images,
                time: hotel.time,
                provider: hotel.provider,
                products: [],
            }
            hotel.products.map((product, index) => {
                let boardtypes_club = [];
                product.boardtypes.map((boardtype) => {
                    if (boardtype.offertext.includes("BASE")) {
                        boardtypes_club.push(boardtype);
                    }
                })
                club_product.products[index] = {
                    boardtypes: boardtypes_club,
                    room: product.room,
                    images_room: product.images_room,
                    images_carousel: product.images_carousel,
                    translation_code: product.translation_code,
                    room_title: product.room_title,
                    room_description: product.room_description,
                };
            })
            total_products_club.push(club_product);
        } else {
            total_products_club.push(hotel);
        }
    })
    dispatch(updateProducts(total_products_club))
    return dispatch(updateProductsClub(total_products_club))
};

const updateProducts = (products) => ({type: FETCH_PRODUCTS, payload: products});
const updateProductsClub = (productsClub) => ({type: FETCH_PRODUCTS_CLUB, payload: productsClub});
const setProvider = (provider) => ({type: SET_PROVIDER, payload: provider});
const updateDone = () => ({type: UPDATE_PRODUCTS});
const updateFail = (error) => ({type: ROOMS_FAIL, payload: error})
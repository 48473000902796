import { ALERTS_CREATE_DASHBOARD, ALERTS_DELETE_DASHBOARD } from '../actions/types.js'

export default function alerts(state = {}, action = {}) {
  switch (action.type) {
    case ALERTS_CREATE_DASHBOARD:
      return {
        ...state,
        dashboard: {
          msg: action.payload?.msg,
          msgType: action.payload?.msgType,
        }
      };
    case ALERTS_DELETE_DASHBOARD:
    default:
      return state
  }
}
import {
    SET_AMOUNT,
    SET_CURRENCY,
    SET_PAYMENT_ID,
    SET_PAYMENT_TOKEN,
    SET_STATUS,
} from './types';
import axios from "axios";

const PAYMENT_URL_POST = process.env.REACT_APP_PAYMENT_URL_POST;
const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;


export const getPaymentId = (package_id, amount, currency) => (dispatch) => {
    const request = {
        "order_id": package_id,
        "amount": parseInt((amount*100).toFixed(0)),
        "currency": currency,
    }
    axios.post(BASEURL + PAYMENT_URL_POST, request)
        .then(function (response) {
            dispatch(setAmount(response.data.amount));
            dispatch(setCurrency(response.data.currency));
            dispatch(setPaymentId(response.data.payment_id));
            dispatch(setStatus(response.data.status));
        })
        .catch(function (error) {
            console.error(error);
        });
}

const setAmount = (amount) => ({type: SET_AMOUNT, payload: amount});
const setCurrency = (currency) => ({type: SET_CURRENCY, payload: currency});
const setPaymentId = (payment_id) => ({type: SET_PAYMENT_ID, payload: payment_id});
export const setStatus = (status) => ({type: SET_STATUS, payload: status});
const setPaymentToken = (payment_token) => ({type: SET_PAYMENT_TOKEN, payload: payment_token});

import React, {Component} from "react";
import {connect} from 'react-redux';
import {addTravelers} from "../../../actions/travelers";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import {updateStep} from "../../../actions/stepProcess";
import Insurances from "./Insurances";
import {addInsurances} from "../../../actions/insurances";
import {withTranslation} from "react-i18next";
import {checkoutResponse, paymentResponse} from "../../../actions/pkgData";
import ItineraryHeader from "./ItineraryHeader";
import HotelHeader from "./HotelHeader";
import CreditCard from "./CreditCard";
import DatePicker from "react-datepicker";
import travelers_json from "./travelers.json";
import one_traveler from "./one_travelers.json";
import travelers_with_childrens_json from "./travelers_with_childrens.json";
import {beginCheckoutGTM} from "../../GTMComponent/GTMComponent";
import axios from "axios";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;
const INTERNAL = process.env.REACT_APP_INTERNAL;
const AGENCIES = process.env.REACT_APP_AGENCY_MODE_ACTIVATED;

class ReserveForm extends Component {

    constructor(props) {
        super(props);
        this.ageError = React.createRef();
        this.emailError = React.createRef();
        this.token_ref = React.createRef();
        this.state = {
            pax: parseInt(this.props.search.adults) + (this.props.search.room_num_childs !== undefined ? parseInt(this.props.search.room_num_childs) : 0),
            name: {},
            surName: {},
            lastName: {},
            gender: {},
            birth_date: [],
            residency_country: {},
            document: {},
            expedition_country: {},
            expiration_date: {},
            expedition_date: {},
            address_country: '',
            region: '',
            city: '',
            address: '',
            postal_code: '',
            email: '',
            phone: '',
            email_confirmation: '',
            validate_age: {},
            validate_expiration: {},
            validate_expedition: {},
            validate_email: true,
            pass_checkout: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.setDataFromToken = this.setDataFromToken.bind(this);
    }

    selectCountry(val, index, type) {
        if (type === 0)
            this.setState({residency_country: {...this.state.residency_country, [index]: val}});
        if (type === 1)
            this.setState({expedition_country: {...this.state.expedition_country, [index]: val}});
        if (type === 2)
            this.setState({address_country: val});
    }

    selectRegion(val) {
        this.setState({region: val});
    }

    setPhone(formattedValue) {
        let phone = "";
        formattedValue.split(' ').map((phone_fragment, index) => {
            if (index > 0)
                phone = phone.concat(phone_fragment)
        })
        this.setState({phone: phone})
        this.setState({phone_country_code: formattedValue.split(' ')[0].replace('+', '')})
    }

    handlePassport(e, index) {
        let regex = /[^A-Za-z\d]/s;
        if (!regex.test(e.target.value)) {
            this.setState({document: {...this.state.document, [index]: e.target.value}});
        }
    }

    handleGender(gender, index) {
        this.setState({gender: {...this.state.gender, [index]: gender}});
    }

    handleOnlyLetters(e, index) {
        let regex = /[^A-Za-z\s]/s;
        if (!regex.test(e.target.value)) {
            switch (e.target.name) {
                case ("name_passanger" + index):
                    this.setState({name: {...this.state.name, [index]: e.target.value}});
                    break;
                case ("surname_passanger" + index):
                    this.setState({surName: {...this.state.surName, [index]: e.target.value}});
                    break;
                case ("secondLastName_passanger" + index):
                    this.setState({lastName: {...this.state.lastName, [index]: e.target.value}});
                    break;
            }
        }
    }

    handleEmailConfirmation(e) {
        if (e !== this.state.email) {
            this.setState({validate_email: false});
        } else {
            this.setState({validate_email: true});
        }
    }

    handleAddress(e, index) {
        let regex = /[^A-Za-z\/.,\d\s]/s;
        if (!regex.test(e.target.value)) {
            switch (e.target.name) {
                case ("address_passanger"):
                    this.setState({address: e.target.value});
                    break;
                case ("city_passanger"):
                    this.setState({city: e.target.value});
                    break;
            }
        }
    }

    handlePostalCode(e, index) {
        let regex = /[^A-Za-z\d]/s;
        if (!regex.test(e.target.value)) {
            this.setState({postal_code: e.target.value});
        }
    }

    birthDateValidation(e, index, children = false, children_age = -1) {
        let date = new Date(e)
        let date_diff = new Date(Date.now() - date)
        let ages = date_diff.getUTCFullYear() - 1970
        if ((index === 1 && ages < 18) || (children && ages !== children_age)) {
            this.setState({validate_age: {...this.state.validate_age, [index]: false}})
            this.ageError.current.style.display = "inline"
        } else {
            this.setState({validate_age: {...this.state.validate_age, [index]: true}})
            this.ageError.current.style.display = "none"
            this.state.birth_date[index] = date;
        }
    }

    expirationDateValidation(e, index) {
        let date = new Date(e)
        let date_now = new Date(Date.now())
        if (date < date_now) {
            this.setState({validate_expiration: {...this.state.validate_expiration, [index]: true}});
        } else {
            this.setState({validate_expiration: {...this.state.validate_expiration, [index]: false}});
            this.setState({expiration_date: {...this.state.expiration_date, [index]: date}});
        }
    }

    expeditionDateValidation(e, index) {
        let date = new Date(e)
        let date_now = new Date(Date.now())
        if (date > date_now) {
            this.setState({validate_expedition: {...this.state.validate_expedition, [index]: true}});
        } else {
            this.setState({validate_expedition: {...this.state.validate_expedition, [index]: false}});
            this.setState({expedition_date: {...this.state.expedition_date, [index]: date}});
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    checkAgencyDates(arrival) {
        let arrival_date = new Date(arrival);
        let max_date = new Date();
        max_date.setDate(max_date.getDate() + 15);
        return (arrival_date < max_date);
    };

    checkValidateFields(index) {
        let fields = [];
        let validate = true;
        if (this.props.flights.isActive) {
            if (!this.state.validate_age[index]) {
                fields.push("birth_date")
                validate = false;
            }
        }
        if (this.state.validate_email === false) {
            fields.push("email")
            validate = false;
        }
        if (this.state.phone === undefined || this.state.phone === "") {
            fields.push("phone")
            validate = false;
        }
        return {state: validate, fields: fields}
    }

    handleSubmit(event) {
        event.preventDefault();
        const {
            name,
            surName,
            lastName,
            birth_date,
            residency_country,
            document,
            expedition_country,
            expiration_date,
            expedition_date,
            address_country,
            region,
            address,
            city,
            postal_code,
            validate_age,
            validate_expiration,
            validate_expedition,
            pass_checkout,
            pax,
            phone,
            phone_country_code,
        } = this.state;
        const {t} = this.props;

        if (pass_checkout) {
            if (this.props.flights.isActive && INTERNAL === "false") {
                this.props.updateStep({
                    id: 5,
                    name: "PAYMENT",
                    description: "Confirma tu reserva",
                });
            }
            this.props.checkoutResponse({
                token: this.props.token,
                payload: this.props.flights.isActive ? travelers_json : one_traveler
            }, this.props.pkgData.temporal_pkg_id, this.props.pkgData.pay_now, this.props.flights.isActive)
            this.props.addTravelers(travelers_json);
        } else {
            let travelers = [];
            for (let index = 1; index <= (this.props.search.airport === "null" ? 1 : pax); index++) {
                let validate = this.checkValidateFields(index);
                if (!validate.state) {
                    alert('Revise los datos del pasajero ' + index + ', los campos: ' + validate.fields.map((field) => {
                        return t('checkout_form.' + field)
                    }))
                    return 0;
                }
                let children = (index - parseInt(this.props.search.adults)) > 0
                let formated_expiration_date = (expiration_date[index] instanceof Date ? expiration_date[index].toISOString().split('T')[0] : new Date(expiration_date[1]).toISOString().split('T')[0])
                let traveler = {};
                traveler["user"] = ""
                traveler["is_child"] = children
                traveler["is_main_contact"] = (index === 1)
                traveler["email"] = (this.props.user?.e_mail !== '' && this.props.user?.e_mail !== undefined ? this.props.user?.email : event.target.elements["email"].value)
                traveler["name"] = name[index]
                traveler["surname"] = surName[index]
                traveler["id_residence"] = residency_country[index]
                traveler["birth_date"] = (birth_date[index] instanceof Date ? birth_date[index].toISOString().split('T')[0] : new Date(birth_date[1]).toISOString().split('T')[0])
                traveler["country_id"] = address_country
                if (event.target.elements["gender_passanger" + index].value === 'sra')
                    traveler["gender"] = "FEMALE"
                else
                    traveler["gender"] = "MALE"

                if (this.props.flights.isActive) {
                    traveler["id_number"] = document[index]
                    traveler["id_issuance"] = expedition_country[index]
                    traveler["id_expiration"] = formated_expiration_date
                    traveler["id_issuance_date"] = formated_expiration_date
                    traveler["country_id"] = residency_country[index]
                    traveler["passport_number"] = document[index]
                    traveler["passport_issuance"] = expedition_country[index]
                    traveler["passport_residence"] = expedition_country[index]
                    traveler["passport_issuance_date"] = (this.state["document_passenger" + (index + 1)] === "passport" ? (expedition_date[index] instanceof Date ? expedition_date[index].toISOString().split('T')[0] : new Date(expedition_date[1]).toISOString().split('T')[0]) : formated_expiration_date)
                    traveler["passport_expiration"] = formated_expiration_date
                }
                traveler["second_surname"] = (lastName[index] ? lastName[index] : "")
                traveler["phone"] = phone

                if (this.props.flights.isActive) {
                    traveler["phone_country_code"] = phone_country_code
                    traveler["address"] = address
                    traveler["city"] = city
                    traveler["intCode"] = phone_country_code
                }

                traveler["province"] = region
                traveler["postalcode"] = postal_code
                traveler["type"] = children ? "C" : "A"
                traveler["order"] = index - 1

                travelers.push(traveler);
            }
            let agency_max_date = (AGENCIES === "true" && this.checkAgencyDates(this.props.search.arrival));
            if ((INTERNAL === "false" && this.props.flights.isActive) || agency_max_date) {
                this.props.updateStep({
                    id: 5,
                    name: "PAYMENT",
                    description: "Confirma tu reserva",
                });
            }
            this.props.checkoutResponse({
                token: this.props.token,
                payload: travelers
            }, this.props.pkgData.temporal_pkg_id, (AGENCIES === "true" && agency_max_date ? this.props.pkgData.total_price : this.props.pkgData.pay_now), this.props.flights.isActive, agency_max_date)
            this.props.addTravelers(travelers);
        }
    }

    componentDidMount() {
        for (let i = 1; i <= this.state.pax; i++) {
            this.state.birth_date[i] = Date.now();
            this.state.document[i] = "";
            this.state.name[i] = "";
            this.state.surName[i] = "";
            this.state.lastName[i] = "";
            this.state.expiration_date[i] = Date.now();
            this.state.expedition_date[i] = Date.now();
            this.state.validate_age[i] = (i !== 1);
            this.state.validate_expiration[i] = false;
            this.state.validate_expedition[i] = false;
        }
        new beginCheckoutGTM();
    }

    PaxDataForm(pax) {

        const {
            residency_country,
            expedition_country,
            address_country,
            region,
            validate_age,
            validate_expiration,
            validate_expedition,
            pass_checkout,
            birth_date,
            expiration_date,
            expedition_date,
        } = this.state;
        const {user, flights} = this.props
        const {t} = this.props;

        let result = [];
        let children_ages = {};
        if (this.props.search.room_num_childs > 0 && this.props.search.children_ages.length >= 1) {
            this.props.search.children_ages.map((age, index) => {
                children_ages[index + 1] = age;
            })
        }
        let travelers = pax;
        if (!flights.isActive) {
            travelers = 1;
        }
        for (let index = 1; index <= travelers; index++) {
            let children = (index - parseInt(this.props.search.adults)) > 0;
            let children_index = index - parseInt(this.props.search.adults);

            result.push(
                <dl className="checkout-box" key={index}>
                    <dt>
                        <h2 className="title">
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7 8C9.07129 8 10.75 6.32129 10.75 4.25C10.75 2.17871 9.07129 0.5 7 0.5C4.92871 0.5 3.25 2.17871 3.25 4.25C3.25 6.32129 4.92871 8 7 8ZM9.625 8.9375H9.13574C8.48535 9.23633 7.76172 9.40625 7 9.40625C6.23828 9.40625 5.51758 9.23633 4.86426 8.9375H4.375C2.20117 8.9375 0.4375 10.7012 0.4375 12.875V14.0938C0.4375 14.8701 1.06738 15.5 1.84375 15.5H12.1562C12.9326 15.5 13.5625 14.8701 13.5625 14.0938V12.875C13.5625 10.7012 11.7988 8.9375 9.625 8.9375Z"
                                    fill="currentColor"></path>
                            </svg>
                            {flights.isActive &&
                                <span>{t('words.passenger')} {index} {children ? t('words.child') + " (" + children_ages[children_index] + " " + t('search.years') + " )" : ""}</span>

                            }
                        </h2>
                        {index === 1 &&
                            <p className="right-info">{t('words.titular')}</p>
                        }
                    </dt>
                    <dd>
                        <h3 className="inputs-box-title">{t('words.personal_data')}</h3>
                        <div className="inputs-box">
                            <div className="column-inputs">
                                <div className="input-wrapper simple-input">
                                    <label htmlFor={"name_passanger" + index}>{t('words.first_name')}</label>
                                    <input type="text" id={"name_passanger" + index}
                                           name={"name_passanger" + index}
                                           placeholder={t('words.placeholder_name')}
                                           required={!pass_checkout}
                                           value={this.state.name[index]}
                                           onChange={(e) => {
                                               this.handleOnlyLetters(e, index);
                                           }}/>
                                </div>
                                <div className="input-wrapper simple-input">
                                    <label htmlFor={"surname_passanger" + index}>{t('words.first_surname')}</label>
                                    <input type="text" id={"surname_passanger" + index}
                                           name={"surname_passanger" + index}
                                           placeholder={t('words.placeholder_first_surname')} required={!pass_checkout}
                                           value={this.state.surName[index]}
                                           onChange={(e) => {
                                               this.handleOnlyLetters(e, index);
                                           }}/>
                                </div>
                            </div>
                            <div className="column-inputs">
                                <div className="input-wrapper inline-radio">
                                    <div className="radio-group">
                                        <input id={"gender_passanger" + index + "_sra"}
                                               type="radio"
                                               name={"gender_passanger" + index}
                                               value="FEMALE"
                                               onChange={() => {
                                                   this.handleGender("FEMALE", index)
                                               }}
                                               checked={"FEMALE" === this.state.gender[index]}/>
                                        <label1
                                            htmlFor={"gender_passanger" + index + "_sra"}>{t('words.mrs')}</label1>
                                    </div>
                                    <div className="radio-group">
                                        <input id={"gender_passanger" + index + "_sr"}
                                               type="radio"
                                               name={"gender_passanger" + index}
                                               value="MALE"
                                               checked={this.state.gender[index] === "MALE"}
                                               onChange={() => {
                                                   this.handleGender("MALE", index)
                                               }}
                                        />
                                        <label
                                            htmlFor={"gender_passanger" + index + "_sr"}>{t('words.mr')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="column-inputs">
                                {!flights.isActive ? ''
                                    :
                                    <div className="input-wrapper simple-input">
                                        <label htmlFor={"birthday_passanger" + index}>{t('words.born')}</label>
                                        <DatePicker
                                            placeholder="DD/MM/AAAA"
                                            selected={birth_date[index]}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onChange={(e) => {
                                                this.birthDateValidation(e, index, children, children_ages[children_index])
                                            }}
                                        />
                                        {index === 1 &&
                                            <span ref={this.ageError}
                                                  style={{display: !validate_age[index] ? 'inline' : 'none'}}
                                                  className="error-msg-red">{t('msg.old_passenger')}</span>
                                        }
                                        {children &&
                                            <span ref={this.ageError}
                                                  style={{display: !validate_age[index] ? 'inline' : 'none'}}
                                                  className="error-msg-red">La edad del niño debe ser de {children_ages[children_index]} años</span>
                                        }
                                    </div>
                                }
                                {!flights.isActive ? ''
                                    :
                                    <div className="input-wrapper simple-input select-wrapper">
                                        <label
                                            htmlFor={"residency_country" + index}>{t('words.residence_country')} </label>
                                        <CountryDropdown
                                            required={!pass_checkout}
                                            value={residency_country[index]}
                                            valueType="short"
                                            onChange={(val) => {
                                                this.selectCountry(val, index, 0)
                                            }}
                                            priorityOptions={["GB", "ES", "IE", "DE", "FR", "BE", "IT"]}
                                        />
                                    </div>
                                }
                            </div>

                            {!flights.isActive ?

                                index === 1 &&
                                <div className="column-inputs">
                                    <div className="input-wrapper simple-input">
                                        <label htmlFor="phone">{t('words.mobile_phone')}</label>
                                        <PhoneInput
                                            id="phone"
                                            name="phone"
                                            country={(this.state.residency_country[1] !== undefined ? this.state.residency_country[1].toLowerCase() : 'gb')}
                                            preferredCountries={['ie', 'es', 'gb']}
                                            value={"+" + this.state.phone_country_code + this.state.phone}
                                            onChange={(value, data, event, formattedValue) => (this.setPhone(formattedValue))}
                                        />
                                    </div>
                                </div>

                                :
                                <div className="column-inputs">
                                    <div className="input-wrapper simple-input select-wrapper">
                                        <label htmlFor={"select-document" + index}>{t('words.document_type')}</label>
                                        <select type="text" id={"select-document" + index}
                                                name={"document_passenger" + index}
                                                placeholder={t('words.placeholder_dni')} required={!pass_checkout}
                                                onChange={(e) => {
                                                    this.onChange(e)
                                                }}>
                                            <option selected disabled hidden>{t('words.select_document')}</option>
                                            <option value="dni">DNI</option>
                                            <option value="passport">{t('words.passport')}</option>
                                        </select>
                                    </div>
                                </div>
                            }

                            {this.state["document_passenger" + index] === "passport" &&
                                <>
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input">
                                            <label htmlFor={"document_passenger" + index}>{t('words.passport')}</label>
                                            <input type="text" id={"document_passenger" + index}
                                                   name={"document_passenger" + index}
                                                   placeholder={t('words.placeholder_passport')}
                                                   required={!pass_checkout}
                                                   value={this.state.document[index]}
                                                   onChange={(e) => {
                                                       this.handlePassport(e, index);
                                                   }}/>
                                        </div>
                                        <div className="input-wrapper simple-input select-wrapper">
                                            <label
                                                htmlFor={"expedition_country" + index}>{t('words.expedition_country')}</label>
                                            <CountryDropdown
                                                required={!pass_checkout}
                                                value={expedition_country[index]}
                                                valueType="short"
                                                onChange={(val) => {
                                                    this.selectCountry(val, index, 1)
                                                }}
                                                priorityOptions={["GB", "ES", "IE", "DE", "FR", "BE", "IT"]}
                                            />
                                        </div>
                                    </div>
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input">
                                            <label
                                                htmlFor={"documentDate_passanger" + index}>{t('words.expiration')}</label>
                                            <DatePicker
                                                placeholder="DD/MM/AAAA"
                                                selected={expiration_date[index] !== undefined ? expiration_date[index] : Date.now()}
                                                // selected={expiration_date[index - 1] !== undefined ? expiration_date[index - 1] : null}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onChange={(e) => {
                                                    this.expirationDateValidation(e, index);
                                                }}
                                            />
                                            {validate_expiration[index] &&
                                                <span style={{display: 'inline'}} className="error-msg-red">La fecha de expiración debe ser posterior a la fecha de hoy</span>
                                            }
                                        </div>

                                        <div className="input-wrapper simple-input">
                                            <label
                                                htmlFor={"documentDate_passanger" + index}>{t('words.expedition')}</label>
                                            <DatePicker
                                                placeholder="DD/MM/AAAA"
                                                selected={expedition_date[index] !== undefined ? expedition_date[index] : Date.now()}
                                                // selected={expiration_date[index] !== undefined ? expiration_date[index] : null}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onChange={(e) => {
                                                    this.expeditionDateValidation(e, index);
                                                }}
                                            />
                                            {validate_expedition[index] &&
                                                <span style={{display: 'inline'}} className="error-msg-red">La fecha de expedicion debe ser anterior a la fecha de hoy</span>
                                            }
                                        </div>
                                    </div>
                                    {index === 1 &&
                                        <div className="column-inputs">
                                            <div className="input-wrapper simple-input">
                                                <label htmlFor="phone">{t('words.mobile_phone')}</label>
                                                <PhoneInput
                                                    id="phone"
                                                    name="phone"
                                                    country={(this.state.residency_country[1] !== undefined ? this.state.residency_country[1].toLowerCase() : 'gb')}
                                                    preferredCountries={['ie', 'es', 'gb']}
                                                    value={"+" + this.state.phone_country_code + this.state.phone}
                                                    onChange={(value, data, event, formattedValue) => (this.setPhone(formattedValue))}
                                                />
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {this.state["document_passenger" + index] === "dni" &&
                                <>
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input">
                                            <label htmlFor={"document_passenger" + index}>{t('words.dni')}</label>
                                            <input type="text" id={"document_passenger" + index}
                                                   name={"document_passenger" + index}
                                                   placeholder={t('words.placeholder_dni')}
                                                   required={!pass_checkout}
                                                   value={this.state.document[index]}
                                                   onChange={(e) => {
                                                       this.handlePassport(e, index);
                                                   }}/>
                                        </div>
                                        <div className="input-wrapper simple-input select-wrapper">
                                            <label
                                                htmlFor={"expedition_country" + index}>{t('words.expedition_country')}</label>
                                            <CountryDropdown
                                                required={!pass_checkout}
                                                value={expedition_country[index]}
                                                valueType="short"
                                                onChange={(val) => {
                                                    this.selectCountry(val, index, 1)
                                                }}
                                                priorityOptions={["GB", "ES", "IE", "DE", "FR", "BE", "IT"]}
                                            />
                                        </div>
                                    </div>
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input">
                                            <label
                                                htmlFor={"documentDate_passanger" + index}>{t('words.expiration')}</label>
                                            <DatePicker
                                                placeholder="DD/MM/AAAA"
                                                selected={expiration_date[index] !== undefined ? expiration_date[index] : Date.now()}
                                                // selected={expiration_date[index] !== undefined ? expiration_date[index] : null}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onChange={(e) => {
                                                    this.expirationDateValidation(e, index);
                                                }}
                                            />
                                            {validate_expiration[index] &&
                                                <span style={{display: 'inline'}} className="error-msg-red">La fecha de expiración debe ser posterior a la fecha de hoy</span>
                                            }
                                        </div>
                                        {index === 1 &&
                                            <div className="input-wrapper simple-input">
                                                <label htmlFor="phone">{t('words.mobile_phone')}</label>
                                                <PhoneInput
                                                    id="phone"
                                                    name="phone"
                                                    country={(this.state.residency_country[1] !== undefined ? this.state.residency_country[1].toLowerCase() : 'gb')}
                                                    preferredCountries={['ie', 'es', 'gb']}
                                                    value={"+" + this.state.phone_country_code + this.state.phone}
                                                    onChange={(value, data, event, formattedValue) => (this.setPhone(formattedValue))}
                                                />
                                            </div>
                                        }
                                    </div>
                                </>
                            }


                            {index === 1 &&
                                <div className="column-inputs">
                                    <div className="input-wrapper simple-input">
                                        <label htmlFor={"email"}>{t('words.email')}</label>
                                        <input type="text" id={"email"}
                                               name={"email"}
                                               placeholder={t('words.placeholder_email')} required={!pass_checkout}
                                               onChange={(e) => {
                                                   this.setState({email: e.target.value})
                                               }}
                                               value={this.state.email}/>
                                    </div>
                                    <div className="input-wrapper simple-input">
                                        <label htmlFor={"confirm_email"}>{t('words.confirm_email')}</label>
                                        <input type="text" id={"confirm_email"}
                                               name={"confirm_email"}
                                               placeholder={t('words.placeholder_email')} required={!pass_checkout}
                                               value={this.state.email_confirmation}
                                               onChange={(e) => {
                                                   this.setState({email_confirmation: e.target.value})
                                                   this.handleEmailConfirmation(e.target.value)
                                               }}/>
                                        <span ref={this.emailError}
                                              style={{display: this.state.validate_email ? 'none' : 'inline'}}
                                              className="error-msg-red">El email no coincide</span>
                                    </div>
                                </div>
                            }
                        </div>
                        {index === 1 &&
                            <>
                                <h3 className="inputs-box-title">{t('words.postal_direction')}</h3>

                                <div className="inputs-box">
                                    {!flights.isActive ? ''
                                        :
                                        <div className="input-wrapper simple-input">
                                            <label htmlFor={"address_passanger"}>{t('words.address')}</label>
                                            <input type="text" id={"address_passanger"}
                                                   name={"address_passanger"}
                                                   placeholder={t('words.placeholder_address')}
                                                   required={!pass_checkout}
                                                   value={this.state.address}
                                                   onChange={(e) => {
                                                       this.handleAddress(e, index);
                                                   }}/>
                                        </div>
                                    }
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input select-wrapper">
                                            <label htmlFor={"address_country"}>{t('words.residence_country')}</label>
                                            <CountryDropdown
                                                required={!pass_checkout}
                                                value={address_country}
                                                valueType="short"
                                                onChange={(val) => this.selectCountry(val, index, 2)}
                                                priorityOptions={["GB", "ES", "IE", "DE", "FR", "BE", "IT"]}
                                            />
                                        </div>
                                        <div className="input-wrapper simple-input select-wrapper">
                                            <label htmlFor="province">{t('words.province')}</label>
                                            <RegionDropdown
                                                country={address_country}
                                                countryValueType="short"
                                                value={region}
                                                onChange={(val) => this.selectRegion(val)}/>
                                        </div>
                                    </div>
                                    <div className="column-inputs">
                                        {!flights.isActive ? ''
                                            :
                                            <div className="input-wrapper simple-input">
                                                <label htmlFor={"city_passanger"}>{t('words.city')}</label>
                                                <input type="text" id={"city_passanger"}
                                                       name={"city_passanger"}
                                                       placeholder={t('words.ej') + " Madrid"} required={!pass_checkout}
                                                       value={this.state.city}
                                                       onChange={(e) => {
                                                           this.handleAddress(e, index);
                                                       }}/>
                                            </div>
                                        }
                                        <div className="input-wrapper simple-input">
                                            <label htmlFor={"postcode_passanger"}>{t('words.postal_code')}</label>
                                            <input type="text" id={"postcode_passanger" + index}
                                                   name={"postcode_passanger"}
                                                   placeholder={t('words.ej') + " 28027"} required={!pass_checkout}
                                                   onChange={(e) => {
                                                       this.handlePostalCode(e, index);
                                                   }}
                                                   value={this.state.postal_code}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </dd>
                </dl>
            )
        }

        return result;
    }

    dataFromToken(token) {
        const setDataFromToken = this.setDataFromToken;
        axios.post(BASEURL + "/packages-api/packages/get_package_data/", {token: token})
            .then(function (response) {
                    setDataFromToken(response.data.traveler_packages)
                }
            )
            .catch(
                function (error) {
                    console.log(error);
                    return false;
                }
            )
        ;
    }

    setDataFromToken(data) {
        const {
            pax
        } = this.state;
        if (data.length > 0) {
            if (pax === data.length) {
                data.map((traveler, index) => {
                        this.setState({name: {...this.state.name, [index + 1]: traveler.name}});
                        this.setState({surName: {...this.state.surName, [index + 1]: traveler.surname}});
                        this.setState({lastName: {...this.state.lastName, [index + 1]: traveler.second_surname}});
                        this.birthDateValidation(traveler.birth_date, index + 1);
                        this.expeditionDateValidation(traveler.passport_issuance_date, index + 1);
                        this.expirationDateValidation(traveler.passport_expiration, index + 1);
                        this.setState({document: {...this.state.document, [index + 1]: traveler.id_number}});
                        this.selectCountry(traveler.country_id, index + 1, 0);
                        this.selectCountry(traveler.id_residence, index + 1, 1);
                        this.setState({gender: {...this.state.gender, [index + 1]: traveler.gender}});
                        if (index === 0) {
                            this.setState({email: traveler.email});
                            this.setState({email_confirmation: traveler.email});
                            this.selectCountry(traveler.id_issuance, index + 1, 2);
                            this.selectRegion(traveler.province)
                            this.setState({address: traveler.address});
                            this.setState({city: traveler.city});
                            this.setState({phone: traveler.phone});
                            this.setState({phone_country_code: traveler.phone_country_code});
                        }
                        this.setState({address: traveler.address});
                        this.setState({postal_code: traveler.postalcode});
                    }
                )
            }
        }
    }

    render() {

        const {flights} = this.props;
        const {pax, pass_checkout} = this.state;
        const {t} = this.props;

        return (
            <div className="MuiBox-root jss2 checkout">
                <div className="checkout-content">
                    <div className="checkout-elements form-style max-space color-label">

                        {flights.isActive &&
                            <ItineraryHeader/>
                        }

                        {process.env.REACT_APP_PASS_CHECKOUT === 'true' &&
                            <>

                                <dl className="checkout-box">
                                    <dd>
                                        <div className="input-box">
                                            <div className="column-inputs">
                                                <div className="input-wrapper simple-input">
                                                    <label htmlFor="token">Token</label>
                                                    <input ref={this.token_ref} type="text" placeholder="Token"/>
                                                </div>
                                                <div className="input-wrapper simple-input">
                                                    <label htmlFor="token">&nbsp;</label>
                                                    <button
                                                        onClick={() => this.dataFromToken(this.token_ref.current.value)}>Get
                                                        data
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>


                                {flights.isActive ?
                                    <div className="add-flights-select-wrapper">
                                        <input type="checkbox" id="addFlights" name="addFlights"/>
                                        <label htmlFor="addFlights"
                                               className={"add-flights-select" + (pass_checkout ? " selected" : "")}
                                               onClick={() => {
                                                   if (pass_checkout)
                                                       this.setState({pass_checkout: false})
                                                   else
                                                       this.setState({pass_checkout: true})
                                               }}
                                        >
                                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 1.42L10.59 0L4 6.59L1.42 4.02L0 5.43L4 9.42L12 1.42Z"
                                                      fill="currentColor"/>
                                            </svg>
                                        </label>
                                        <span
                                            className="add-flights-text"><strong>*Modo test, si quieres pasar sin rellenar datos (dos adultos)</strong></span>
                                    </div>
                                    :
                                    <div className="add-flights-select-wrapper">
                                        <input type="checkbox" id="addFlights" name="addFlights"/>
                                        <label htmlFor="addFlights"
                                               className={"add-flights-select" + (pass_checkout ? " selected" : "")}
                                               onClick={() => {
                                                   if (pass_checkout)
                                                       this.setState({pass_checkout: false})
                                                   else
                                                       this.setState({pass_checkout: true})
                                               }}
                                        >
                                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 1.42L10.59 0L4 6.59L1.42 4.02L0 5.43L4 9.42L12 1.42Z"
                                                      fill="currentColor"/>
                                            </svg>
                                        </label>
                                        <span
                                            className="add-flights-text"><strong>*Modo test, si quieres pasar sin rellenar datos</strong></span>
                                    </div>
                                }
                            </>
                        }
                        <form className="register-form form-style" onSubmit={this.handleSubmit}>

                            {this.PaxDataForm(pax)}

                            {/*{Object.keys(insurances.insurances).length > 0 && <Insurances/>}*/}

                            {!flights.isActive && process.env.REACT_APP_AGENCY_MODE_ACTIVATED === 'false' ?
                                <CreditCard/>
                                :
                                ''
                            }

                            <br></br>

                            <div className="checkbox-wrapper">
                                <div className="checkbox-group">
                                    <input id="privacy_policy" type="checkbox" name="privacy_policy"
                                           required={!pass_checkout}/>
                                    <label htmlFor="privacy_policy">
                                        <a href={process.env.REACT_APP_INSTANCE === "SPRING" ? "https://springhoteles.com/en/spring-hoteles/politica-de-privacidad/" : "https://oceanbooking.com/privacy-policy.php"}
                                           target="_blanck">{t('msg.privacy_policy', {pmskey: process.env.REACT_APP_INSTANCE})}</a>
                                        <span className="required">({t('words.required')})</span>
                                    </label>
                                </div>
                                <div className="checkbox-group terminos-y-condiciones">
                                    <input id="user_acceptance" type="checkbox"
                                           name="user_acceptance" required={!pass_checkout}/>
                                    <label htmlFor="user_acceptance">
                                        <a href={process.env.REACT_APP_INSTANCE === "SPRING" ? "https://springhoteles.com/en/spring-hoteles/politica-de-privacidad/" : "https://oceanbooking.com/general-conditions.php"}
                                           target="_blanck">{t('msg.user_acceptance', {pmskey: process.env.REACT_APP_INSTANCE})}</a>
                                        <span className="required">({t('words.required')})</span>
                                    </label>
                                </div>
                            </div>

                            <br></br>

                            <button type="submit"
                                    className="final-btn">{flights.isActive ? t('words.confirm_data') : t('words.confirm_reserve')}</button>

                        </form>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth?.user,
    search: state.search.payload,
    flights: state.flights,
    insurances: state.insurances,
    token: state.search.token,
    pkgData: state.pkgData,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    addTravelers,
    updateStep,
    addInsurances,
    checkoutResponse,
    paymentResponse,
})(withTranslation('common')(ReserveForm));
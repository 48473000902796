import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {CookiesProvider} from 'react-cookie';
import {ClearBrowserCacheBoundary} from 'react-clear-browser-cache';
import {ErrorBoundary} from 'react-error-boundary'
import * as Sentry from "@sentry/react";

import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import common_nl from "./translations/nl/common.json";
import common_de from "./translations/de/common.json";

import TagManager from "react-gtm-module";

const lang_options = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: {path: '/', sameSite: 'strict'}
};


i18next.use(LanguageDetector).init({
    // NAV utiliza ISO 639-2
    interpolation: {escapeValue: false},  // React already does escaping
    detection: lang_options,
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        es: {
            common: common_es
        },
        fr: {
            common: common_fr
        },
        nl: {
            common: common_nl
        },
        de: {
            common: common_de
        },
    },
    fallbackLng: "en" // Si no detecta lenguaje carga este
});


const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
                <I18nextProvider i18n={i18next}>
                    <CookiesProvider>
                        <App/>
                    </CookiesProvider>
                </I18nextProvider>
            </ClearBrowserCacheBoundary>
        </ErrorBoundary>,
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

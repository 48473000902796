import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import Popup from "reactjs-popup";
import {SearchBox} from "../../SearchBox/SearchBox";
import Spinner from "../../Spinner";
import {login} from "../../../actions/auth";

class PopUpError extends Component {

    constructor(props) {
        super();
        this.state = {
            username: '',
            password: '',
        }
    }

    flightsErrors = (t) => {
        if (this.props.flights.flights.inbound?.length < 1 && this.props.flights.flights.outbound?.length < 1) {
            return (<>
                    <h3>
                        {t('popup_errors.flight_with_no_dispo', {
                            airport: this.props.search.airport,
                            arrival: this.props.search.arrival,
                            departure: this.props.search.departure
                        })}.
                    </h3>
                </>
            )
        }
        if (this.props.flights.isFail.state) {
            return (<>
                    <h3>
                        {t('popup_errors.search_error')}.
                    </h3>
                </>
            )
        }
    }

    roomsError = (t) => {
        if (this.props.productsIsFail.error === "NO_DISPO") {
            return (<>
                    <h3>
                        {t('popup_errors.rooms_with_no_dispo', {
                            hotel: this.props.search.hotel,
                            arrival: this.props.search.arrival,
                            departure: this.props.search.departure
                        })}.
                    </h3>
                </>
            )
        }
    }

    extrasError = (t) => {
        if (this.props.extrasIsFail.error.data?.error === "token time expired") {
            return (<>
                    <h3>
                        {t('popup_errors.session_token_expiration')}.
                    </h3>
                </>
            )
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const username = e.target.username.value;
        const password = e.target.password.value;
        await this.props.login(username, password, false, null, false, true);
    }

    onChange = e => this.setState({[e.target.name]: e.target.value});

    invalidToken = (t) => {
        const {username, password} = this.state;
        if (this.props.invalid_token) {
            return (<>
                    <h3>
                        {t('popup_errors.invalid_token_description')}
                    </h3>
                    <form className="login-form form-style" onSubmit={this.onSubmit}>
                        <div className="input-wrapper">
                            <label htmlFor="user">E-mail</label>
                            <input type="text" id="username" name="username"
                                   placeholder={t('msg.input_email_spring')}
                                   value={username}
                                   onChange={this.onChange}
                                   required
                            />
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="password">{t('bloque_azul_contrasena.contrasena')}</label>
                            <input type="password" id="password" name="password"
                                   placeholder={t('msg.input_password')}
                                   value={password}
                                   onChange={this.onChange}
                                   required
                            />
                        </div>
                        <input className="btn btn-primary full-weidth" type="submit"
                               value={t('loginpage.login')}/>
                    </form>
                </>
            )
        }
    }

    checkErrors = (t) => {
        if (this.props.flightsIsFail.state) {
            return this.flightsErrors(t);
        } else if (this.props.productsIsFail.state) {
            return this.roomsError(t)
        } else if (this.props.extrasIsFail.state) {
            return this.extrasError(t)
        } else if (this.props.invalid_token) {
            return this.invalidToken(t)
        }

    }

    render() {
        const {i18n, t, invalid_token} = this.props;

        return (
            <Popup modal key={"popup"} open={true} closeOnDocumentClick={false}
                   position="right center">
                <div id="availability_modal_info" className="availability-modal">
                    <div className="header-navigation">
                        <a href="/" className="back-link left-link"
                           title={t('search.back_to_search_page')}>
                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.2926 0L0 10.2857L10.2892 20.5714L11.5012 19.3628L3.28114 11.1428H24V9.42857H3.28114L11.5046 1.20513L10.2926 0Z"
                                    fill="currentColor"/>
                            </svg>
                        </a>
                    </div>

                    <div className="header-titles center-text">
                        <h2 className="title">
                            {!invalid_token ?
                                <>
                                    {t('search.with_error')}
                                </>
                                :
                                <>
                                    {t('popup_errors.invalid_token_title')}
                                </>
                            }
                        </h2>
                        <p>{this.checkErrors(t)}</p>
                    </div>
                    {!invalid_token &&
                        <SearchBox lang={i18n} modal_mode={true} no_availability={true}/>
                    }
                </div>
            </Popup>

        );
    }
}

const mapStateToProps = state => ({
    flights: state.flights,
    flightsIsDone: state.flights.isDone,
    flightsIsFail: state.flights.isFail,
    productsIsFail: state.shelf.isFail,
    extrasIsFail: state.extras.extrasIsFail,
    search: state.search.payload,
    invalid_token: state.auth.invalid_token,
});

export default connect(
    mapStateToProps,
    {login}
)(withTranslation('common')(PopUpError));
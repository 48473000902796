import React from 'react';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import AlertTitle from "@material-ui/lab/AlertTitle";

export function Maintenance() {
    const {t, i18n} = useTranslation('common');

    return (
        <section className="search-result container">
            <div className="search-result-header">
                <div>
                    <div className="short-info">
                        <Alert severity="error"><AlertTitle><p>{t('msg.maintenance_mode')}</p></AlertTitle></Alert>
                    </div>
                </div>
            </div>
        </section>
    );
}
import React from 'react';
import {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {BsFillClockFill} from "react-icons/bs";
import {timeDiff, getLeadTime} from "../../GTMComponent/utils/timeGTM";
import {setExpirationTime} from '../../../actions/search';
import Popup from 'reactjs-popup';
import PopUpExpire from "./PopUpExpire";
import {format} from "date-fns";

function Timer() {

    const expirationTime = useSelector(state => state.search.token_expiration_time); // variable para la fecha de expiracion del token
    const [minutes, setMinutes] = useState(parseInt(timeDiff(format(Date.parse(expirationTime), 'kk:mm'), format(new Date(), 'kk:mm')).split(":")[1])); // variable para los minutos del contador
    const [seconds, setSeconds] = useState(0); // variable para los segundos del contador
    const dispatch = useDispatch();
    const [time, setTime] = useState(50); // variable para la diferencia de tiempo entre el momento actual y el momento en el que se creo el token

    // Actualizamos la cuenta atras cada segundo una ve vez que el dom se renderice
    useEffect(() => {
        let today = format(new Date(), 'kk:mm'); // Obtenemos la fecha actual en formato 'kk:mm
        let format_expirationTime = format(Date.parse(expirationTime), 'kk:mm'); // Obtenemos la fecha de expiracion del token en formato 'kk:mm'
        // setMinutes(parseInt(timeDiff("19:05", today).split(":")[1])); // Obtenemos la diferencia de tiempo entre el momento actual y el momento en el que se creo el token
        let sampleInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(sampleInterval);
                    dispatch(setExpirationTime(true));
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);


        return () => {
            clearInterval(sampleInterval);
        };

    });

    return (
        <>
            {minutes < 5 &&
                <div className="timer">
                    {(minutes === 0 && seconds == 0) ?
                        <>
                            <p>La sesión ha expirado</p>
                            <PopUpExpire/>
                        </>
                        :
                        <p>
                            <BsFillClockFill/> Expiración
                            en: <span>{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                            {/* Escribimos el tiempo en notacion 00:00 */}
                        </p>
                    }
                </div>
            }
        </>
    );

};

export default Timer;
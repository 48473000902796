import {
    SEARCH_RESPONSE,
    EXTRAS_RESPONSE,
    CHECKOUT_RESPONSE,
    PAYMENT_RESPONSE,
    SET_TOTAL_PRICE,
    SET_PAY_NOW,
    SET_PAY_IN_HOTEL,
    SET_TEMPORAL_PKG_ID,
} from "../actions/types";

const initialState = {
    search_response: {},
    extras_response: {},
    checkout_response: {},
    payment_response: {},
    pay_now: 0,
    pay_in_hotel: 0,
    total_price: 0,
    temporal_pkg_id: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SEARCH_RESPONSE:
            return {
                ...state,
                search_response: action.payload
            };
        case EXTRAS_RESPONSE:
            return {
                ...state,
                extras_response: action.payload
            };
        case CHECKOUT_RESPONSE:
            return {
                ...state,
                checkout_response: action.payload
            };
        case PAYMENT_RESPONSE:
            return {
                ...state,
                payment_response: action.payload
            };
        case SET_TOTAL_PRICE:
            return {
                ...state,
                total_price: action.payload
            };
        case SET_PAY_NOW:
            return {
                ...state,
                pay_now: action.payload
            };
        case SET_PAY_IN_HOTEL:
            return {
                ...state,
                pay_in_hotel: action.payload
            };
        case SET_TEMPORAL_PKG_ID:
            return {
                ...state,
                temporal_pkg_id: action.payload
            };
        default:
            return state;
    }
}

import React from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image, Font } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import OceanBookingLogo from "../../../static/img/pdf/Oceanbooking.png";
import Sello from "../../../static/img/pdf/sello.png";

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 15,
    padding: 15,
    paddingRight: 50,
    paddingBottom: 50,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  title: {
    fontSize: 20,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 10,
  },
  subtitle: {
    fontSize: 13,
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: 10,
  },
  text: {
    fontSize: 10,
    margin: 10,
  },
  textRecibed: {
    fontSize: 10,
    margin: 10,
    textAlign: 'right',
  },
  span: {
    fontWeight: 'bold',
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 5,
    marginTop: 5,
    fontSize: 10,
    textAlign: 'left',
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    margin: 10,
  },
  tableCol2: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  table3: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 10,
  },
  tableCol3: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell3: {
    margin: 5,
    marginTop: 5,
    fontSize: 10,
    textAlign: 'center',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
    fontWeight: 'normal',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    marginTop: 200,
  },
  imageOcean: {
    marginVertical: 15,
    marginHorizontal: 100,
    textAlign: 'right',
  },
  imageSello: {
    width: 150,
    height: 150,
  }
});


// Create Document Component
export default function PdfResponse() {

  return (
    <PDFViewer style={styles.viewer} fileName="myPdf.pdf">
      {/* Start of the document*/}

      <Document>
        {/*render a single page*/}

        <Page size="A4" style={styles.section}>
          <View fixed>
            <Image style={styles.imageOcean} src={OceanBookingLogo} />
          </View>
          <Text style={styles.text}>Expediente/Reserva nº ............. </Text>
          <Text style={styles.title}>Contrato de viajes combinado</Text>

          <Text style={styles.text}>
            Según lo dispuesto en el Real Decreto Legislativo 1/2007, de 16 de noviembre, de conformidad con la
            redacción del Real Decreto-ley 23/2018, de 21 de diciembre, de transposición de la Directiva de Viajes
            Combinados y Servicios de Viaje Vinculados se formaliza el presente contrato entre:
          </Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: 'bold' }}>De una parte:</Text> OceanBooking con título de licencia I-AV-0003878.1 domiciliada en Avda. Antonio Dominguez Alfonso,8 –Playa de las Américas (Arona) 38650 Tenerife,
            provista de CIF A38033502, teléfono 0034 922 961 110 y correo electrónico <i><a href="mailto:info@oceanbooking.com">info@oceanbooking.com</a></i>, como Agencia minorista/organizadora.
          </Text>
          <Text style={styles.text}>
            Y de otra parte: (en calidad de consumidor-contratante principal) D./Dña
            ......, con DNI Nº/pasaporte ....., con domicilio en la calle ......, de ........,
            teléfono fijo……………………, teléfono móvil .........., e-mail ......... con nacionalidad ......., en nombre propio y en representación de las demás personas incluidas en la reserva. [Ver Anexo en el que se relacionan la totalidad de los integrantes de la reserva].
          </Text>
          <Text style={styles.text}>
            Ambas partes de común acuerdo expresan su voluntad de formalizar un viaje combinado sobre la base de las siguientes condiciones y estipulaciones.
          </Text>
          <Text style={styles.text}>
            Integrantes del viaje: Nº de adultos: ........ Nº de niños: .........
          </Text>
          <Text style={styles.text}>
            Idioma mayoritario del servicio de destino: español
          </Text>
          <Text style={styles.subtitle}>
            Agencia mayorista-organizadora interviniente en el viaje combinado:
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  Nombre: {"\n"}{"\n"} OceanBooking
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Dirección: {"\n"}{"\n"} Avda. Antonio Dominguez Alfonso,8 – Playa de las Américas (Arona) 38650 Tenerife</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Teléfono: {"\n"}{"\n"} 0034 922 961 110</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Correo electrónico: {"\n"}{"\n"} info@oceanbooking.com</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Código de identificación: {"\n"}{"\n"} I-AV-0003878.1</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Localizador{"\n"}{"\n"} ............</Text>
              </View>
            </View>
          </View>
          <Text style={styles.title}>Condiciones particulares</Text>
          <Text style={styles.text}>1.- Descripción del viaje:</Text>
          <View style={styles.table2}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}> Duración total del viaje: ..........</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Numero de noches: ...........</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Fecha inicio del viaje: ..........</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Lugar de salida: ..............</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Hora de presentación: ..............</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{''}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Fecha final del viaje: ............</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Lugar de regreso: ............</Text>
              </View>
            </View>
          </View>
          <Text style={styles.text}> 2.- Destinos e Itinerario: </Text>
          <Text style={styles.text}>
            Según Folleto que obra en poder del cliente. {"\n"}
            Según Itinerario/Búsqueda Online seleccionada en la web. {"\n"}
            Según Oferta/Bono de viaje o servicios enviado electrónicamente al cliente. {"\n"}
          </Text>
          <Text style={styles.text}> 3.- Medios de transporte:</Text>
          <Text style={styles.text}>
            Avión: Categoría:
            Business
            Turista
            Otros
            No modificable/No reembolsable
          </Text>
          <Text style={styles.text}>
            Escalas: No  Sí, en: ................. Ida (), Vuelta ()
          </Text>
          <Text style={styles.text}>
            Tren: Categoría:.........................{"\n"}
            Autobús/Traslados: Categoría:.........................{"\n"}
            Taxi/Traslados: Categoría: ...............{"\n"}
            Buque: Categoría ...................{"\n"}
            Otros: ....................{"\n"}
          </Text>
          <Text style={styles.text}>Expediente/Reserva nº ...............</Text>
          <Text style={styles.text}>Lugar, fecha y hora de salida/regreso y duración según itinerario y bonos de viaje.</Text>
          <Text style={styles.text}>4.- Alojamientos: clasificación y categoría:</Text>
          <Text style={styles.text}>1.- Nombre ............ Dirección.......... Clasificación/Categoría............. Fecha llegada:............... Fecha salida: ...........</Text>
          <Text style={styles.text}>
            Régimen de estancia: {"\n"}
            Tipo de Habitación:
          </Text>
          <Text style={styles.text}>5.- Visitas/Excursiones: incluidas en el viaje combinado: Ninguna  Si  	a)………………………………………………. b)………………………………………………</Text>
          <Text style={styles.text}>6.- Precio del viaje:....... Depósito a cuenta entregado:........</Text>
          <Text style={styles.text}>
            Forma de pago: {"\n"}
            Al contado a firma contrato.{"\n"}
            Transferencia a cuenta bancaria IBAN_____________________________________{"\n"}
            Tarjeta Bancaria.{"\n"}
            Aplazado: Calendario de Pagos 1º Fecha........ Importe......... 2º Fecha......... Importe.........
          </Text>
          <Text style={styles.text}>7.- Tasas:...........</Text>
          <Text style={styles.text}>8.- Gastos de gestión: a) por reserva: ................ por modificación de la reserva…………… c) por cancelación……………..</Text>
          <Text style={styles.text}>9.- Gastos de anulación/cancelación/penalización: (Elegir opción)</Text>
          <Text style={styles.text}>
            El 5 por ciento del precio total del viaje contratado, si la resolución se produce entre los dos meses y quince días inmediatamente
            anteriores a la fecha prevista de realización del viaje; el 15 por ciento si se produce entre los quince y tres días anteriores,
            y el 50 por ciento en el supuesto de que el incumplimiento citado se produzca en las 48 horas anteriores. Los billetes de avión son no canjeables/no reembolsables desde el momento de confirmación de servicios.
          </Text>
          <Text style={styles.text}>
            El importe equivalente al precio del viaje combinado menos el ahorro de costes y los ingresos derivados de la utilización alternativa
            de los servicios de viaje.  El organizador o, en su caso, el minorista deberán facilitar al viajero que lo solicite una justificación
            del importe de la penalización.
          </Text>
          <Text style={styles.text}>Billetes de avión no canjeables/no reembolsables en el momento de confirmación de servicios.</Text>
          <Text style={styles.text}>10.- Costes adicionales: a) Propinas……………………………. Otros:……………………………..</Text>
          <Text style={styles.text}>11.- Seguro de viaje:</Text>
          <Text style={styles.text}>
            Información sobre la suscripción de un seguro facultativo que cubra los gastos originados en caso de que el viajero decida poner fin al contrato o los gastos de asistencia, incluidos los de repatriación,
            en caso de accidente, enfermedad o fallecimiento, según las condiciones generales de la póliza contratada:
          </Text>
          <Text style={styles.text}>
            De Asistencia: 	 No  Si. Aseguradora (Nombre y dirección:………………………….){"\n"}
            De Cancelación: No  Si. Aseguradora (Nombre y dirección:………………………….){"\n"}
            Otros:……………………………………………………..
          </Text>
          <Text style={styles.text}>
            12.- Documentación Administrativa y Vacunas: El cliente ha sido informado de los documentos necesarios para entrada,
            estancia y salida en el lugar de destino, y tránsito,  sean mayores o menores de edad (visados, y autorizaciones), así  como, en su
            caso, de las vacunas necesarias, y de la obligación de su obtención y posesión previamente a la salida.
          </Text>
          <Text style={styles.text}>
            13.- Movilidad reducida del viajero y/o discapacidad:{"\n"}
            No. Si algún pasajero necesitase asistencia o servicios especiales debe ponerse en contacto con OceanBooking para que se revise la disponibilidad y diferencia de tarifa, si la hubiera, en caso de cambio de categoría.<br />
            Si
          </Text>
          <Text style={styles.text}>14.- Solicitudes o necesidades especiales a confirmar………………………………………………………………………………..</Text>
          <Text style={styles.text}>
            Estas condiciones particulares se complementan con los datos y fechas que constan en la documentación de viaje y que se entrega al cliente previa salida del viaje.
          </Text>
          <Text style={styles.text}>
            Así mismo, el CLIENTE DECLARA que ha recibido previamente el formulario con la información precontractual relativa al viaje combinado
            que aquí se reproduce, referente a destino, períodos de estancia, fechas, número de pernoctaciones incluidas, los medios de transporte,
            sus características y categorías, los puntos, fechas y horas de salida y de regreso, la duración, ubicación del alojamiento, las principales
            características, con las comidas previstas, visitas, excursiones u otros servicios incluidos, tamaño aproximado del grupo y el mínimo de
            personas necesarias, el idioma del servicio, nombre comercial, la dirección completa del organizador y, en su caso, del minorista, así como
            el número de teléfono y la dirección de correo electrónico de ambos, con el precio total del viaje combinado con todos los impuestos incluidos
            y, en su caso, todas las comisiones, recargos y otros costes adicionales, así como las modalidades de pago, e información general sobre los
            requisitos de pasaporte y visado, trámites sanitarios, capacidad resolutoria con penalización, y posibilidad de suscribir seguro facultativo
            de cancelación y asistencia, que cubra eventuales  gastos en estos supuestos, incluidos los de repatriación, en caso de accidente, enfermedad
            o fallecimiento, así como la información exigida por la normativa vigente en materia de protección de datos de carácter personal.
          </Text>
          <Text style={styles.text}>
            DECLARA también que han sido entregadas las Condiciones Generalesde aplicación al presente viaje que
            figuran en el Folleto/página web/oferta denominada ............... sobre el que ha elegido los servicios correspondientes a <i>Vuelo + Hotel</i>.,
            que obra en poder del cliente, y forma parte íntegramente del presente contrato.
          </Text>
          <Text style={styles.text}>
            PRECIO: El precio  del viaje ha sido calculado también en base al cambio de divisa, tarifas de transporte,
            coste de combustibles o de otras fuentes energéticas, impuestos, tasas y recargos turísticos, de aterrizaje y de embarque o
            desembarque en puertos y aeropuertos; y los tipos de cambio de divisa aplicables al viaje combinado en la fecha de la solicitud
            de servicios, que pueden variar.
          </Text>
          <Text style={styles.text}>
            Así, expresamente el organizador/minorista se reserva el derecho de modificar el precio dado, por cualquier incremento/reducción
            en la citada cotización del precio que podrá revisarse y repercutirse al cliente de acuerdo con la normativa vigente, y las condiciones
            generales incluidas en el folleto, oferta o anexos mencionados en las condiciones particulares del presente contrato hasta veinte días
            antes de la fecha de salida, salvo la reducción que será hasta inicio del viaje, según el artículo 158 RDL 1/2007.
          </Text>
          <Text style={styles.text}>
            Si el incremento excediera del 8% del precio del viaje, podrá el consumidor resolver el contrato sin pagar penalización, o aceptar
            un viaje combinado sustitutivo, cuestión que deberá comunicar en las 72 horas siguientes a ser informado, y de no hacerlo, se entenderá
            que opta por el resolver en contrato sin penalización alguna.
          </Text>
          <Text style={styles.text}>
            MÍNIMO DE PARTICIPANTES: 
          </Text>
          <Text style={styles.text}>
            Viaje de Grupo: No Si {"\n"}
            Número aproximado ............. Número mínimo* de participantes: .............. antes del día .............{"\n"}
            Idioma de la prestación de los servicios ..............................{"\n"}
          </Text>
          <Text style={styles.text}>
            La realización del presente viaje requiere la participación de un mínimo de .......... personas.
            Si por alguna causa no se alcanzase el mínimo exigido, la agencia podrá cancelar los servicios contratados de acuerdo con la normativa
            vigente, informando al consumidor con antelación a la fecha de inicio del viaje en los siguientes términos:
            El organizador, y en su caso, el minorista podrá cancelar el contrato del viaje combinado, procediendo a la devolución de los pagos
            realizados, si la cancelación se produce 20 días naturales antes del inicio del viaje si la duración del mismo es de más de 6 días, o
            de antes de los 7 días previos a la salida si el viaje dura entre 2 y 6 días, y de 48 horas si el viaje dura menos de 2 días. Teniendo derecho 
            el pasajero al reembolso de los pagos realizados, pero no así a indemnización adicional. 
          </Text>
          <Text style={styles.text}>
            CESIÓN DE LA RESERVA: El viajero podrá ceder el contrato de viaje combinado a una persona que reúna todas las condiciones
            aplicables a ese contrato, si bien, deberá ser comunicado previamente al organizador o, en su caso, al minorista, en un soporte duradero,
            con una antelación de al menos siete días naturales al inicio del viaje combinado.¡
          </Text>
          <Text style={styles.text}>
            El cedente del contrato y el cesionario responderán solidariamente de la cantidad pendiente de pago del precio acordado, así como de
            cualquier comisión, recargo u otros costes adicionales derivados de la cesión. El organizador o, en su caso, el minorista informarán
            al cedente acerca de los costes efectivos de la cesión.
          </Text>
          <Text style={styles.text}>
            AUTORIZACIÓN Y DOCUMENTACIÓN NECESARIA: Los documentos tanto personales como familiares de todos los pasajeros (niños incluidos) deberán
            estar en regla y el cliente habrá de comprobar que el pasaporte, y/o visado o DNI son los exigidos por la reglamentación del país que va
            a visitar. Consulte en las embajadas y consulados correspondientes tanto los requisitos como tiempos necesarios de obtención. Serán por
            su cuenta la obtención del pasaporte o cualquier otro requisito exigido de entrada en cada país así como la obtención de los correspondientes
            visados y/o autorizaciones (p.ej. ESTA, eTA, etc….), incluido tránsitos por terceros países.
          </Text>
          <Text style={styles.text}>
            En caso de ser rechazada por alguna autoridad la documentación o sea denegada la entrada en el país por no cumplir los requisitos que se exigen
            o por no poseer la misma, no será considerado como "anulación por fuerza mayor" y la agencia no será responsable de los gastos adicionales ni
            hará devolución del precio del viaje. Los menores de 18 años deben llevar un permiso escrito firmado por sus padres o tutores otorgando ante la
            autoridad competente, en previsión de que el mismo pueda ser solicitado por cualquier autoridad. Los menores y bebés deben llevar DNI o pasaporte,
            además de otros documentos si fuera necesario (p. ej: visado). De igual manera, para el caso de que el menor no viaje acompañado, se facilita el
            teléfono del hotel en destino.
          </Text>
          <Text style={styles.text}>
            Ante la imposibilidad manifiesta de lograr una información actualizada de todos y cada uno de los países, y de los requisitos de entrada/salida/tránsito,
            que además por su propia soberanía pueden variar sin previo aviso,  para cada uno de los nacionales de cada país, las partes acuerdan que el viajero acudirá
            al Ministerio de Asuntos Exteriores de España, y/o al consulado/embajada del país de destino y/o tránsito para verificar y obtener la documentación de viaje
            (visados, autorizaciones, etc…)  y sanitaria necesaria.
          </Text>
          <Text style={styles.text}>
            MODIFICACIÓN VIAJE COMBINADO POR EL ORGANIZADOR: El organizador podrá modificar unilateralmente el contrato de viaje combinado, siempre que
            los cambios sean insignificantes. De ser un cambio sustancial, o incrementarse en más del 8% el importe del precio, el viajero podrá en un plazo de 3 días
            desde la comunicación de este hecho, aceptar el cambio propuesto o resolver el contrato sin penalización. Transcurrido dicho plazo se entenderá no que acepta
            la modificación realizada.
          </Text>
          <Text style={styles.text}>
            RESOLUCIÓN/CANCELACIÓN/DESISTIMIENTO DEL CONTRATO: En cualquier momento anterior al inicio del viaje combinado el viajero podrá resolver el contrato en cuyo caso
            el organizador, o, en su caso, el minorista podrán exigirle que pague  una penalización que consistirá, a su elección, en:
          </Text>
          <Text style={styles.text}>
            El 5 por ciento del precio total del viaje contratado, si la resolución se produce entre los dos meses y quince días
            inmediatamente anteriores a la fecha prevista de realización del viaje; el 15 por ciento si se produce entre los quince y tres días anteriores,
            y el 50 por ciento en el supuesto de que el incumplimiento citado se produzca en las 48 horas anteriores.
          </Text>
          <Text style={styles.text}>
            El importe equivalente al precio del viaje combinado menos el ahorro de costes y los ingresos derivados de la utilización alternativa de los servicios de viaje.
            El organizador o, en su caso, el minorista deberán facilitar al viajero que lo solicite una justificación del importe de la penalización.
          </Text>
          <Text style={styles.text}>
            No obstante lo dispuesto en el apartado anterior, cuando concurran circunstancias inevitables y extraordinarias en el lugar de destino o en las inmediaciones que afecten de forma
            significativa a la ejecución del viaje combinado o al transporte de pasajeros al lugar de destino, el viajero tendrá derecho a resolver el contrato antes del inicio del mismo sin
            pagar ninguna penalización. En este caso, el viajero tendrá derecho al reembolso completo de cualquier pago realizado, pero no a una compensación adicional.
          </Text>
          <Text style={styles.text}>
            El organizador y, en su caso, el minorista podrán cancelar el contrato y reembolsar al viajero la totalidad de los pagos que este haya realizado,
            pero no será responsable de compensación adicional alguna si:
          </Text>
          <Text style={styles.text}>
            a) el número de personas inscritas para el viaje combinado es inferior al número mínimo especificado en el contrato y el organizador o, en su caso, el minorista notifican al viajero la cancelación dentro del plazo fijado más arriba.
          </Text>
          <Text style={styles.text}>
            b) el organizador se ve en la imposibilidad de ejecutar el contrato por circunstancias inevitables y extraordinarias y se notifica la cancelación al viajero sin demora indebida antes del inicio del viaje combinado.
          </Text>
          <Text style={styles.text}>
            De no presentarse a la salida, el cliente está obligado al pago del importe total del viaje, abonando las cantidades pendientes, en su caso.
          </Text>
          <Text style={styles.text}>
            El viajero, con arreglo al artículo 97.1.l y 103.l del RDL 1/2007, carece del derecho de desistimiento del contrato de viaje combinado,
            salvo cuando los contratos de viaje combinado se celebrara celebrados fuera del establecimiento mercantil, que dispondrá de un plazo de
            catorce días para ejercer su derecho desistimiento, sin necesidad de justificación.
          </Text>
          <Text style={styles.text}>
            RESPONSABILIDAD AGENCIA MINORISTA/ORGANIZADOR: 
          </Text>
          <Text style={styles.text}>
            Los organizadores y los minoristas de viajes combinados responderán frente al viajero del correcto cumplimiento de los servicios de viaje incluidos 
            en el contrato en función de las obligaciones que les correspondan por su ámbito de gestión del viaje combinado, con independencia de que estos servicios 
            los deban ejecutar ellos mismos u otros prestadores. 
          </Text>
          <Text style={styles.text}>
            □ LA AGENCIA actúa como mera agencia mediadora en la venta de un producto organizado por la mayorista arriba indicada, por lo que su labor se limita a 
            ofrecer la información veraz, tramitar la reserva del producto a través del organizador, emitir la documentación correspondiente, tramitar, en su caso 
            las quejas y reclamaciones que reciba del consumidor, pero no es responsable de las incidencias acaecidas en destino durante la prestación de los servicios del organizador. 
          </Text>
          <Text style={styles.text}>
            □ LA AGENCIA actúa como organizadora del viaje combinado arriba descrito por lo que responde de forma solidaria frente al viajero del correcto cumplimiento de 
            los servicios de viaje incluidos en el contrato, con independencia de que estos servicios los deban ejecutar ellos mismos u otros prestadores. Quien responda ante 
            el viajero tendrá el derecho de repetición frente al empresario al que le sea imputable el incumplimiento o cumplimiento defectuoso del contrato en función de su 
            respectivo ámbito de gestión del viaje combinado. 
          </Text>
          <Text style={styles.text}>
            No obstante lo anterior, el viajero podrá dirigir las reclamaciones por el incumplimiento o cumplimiento defectuoso de los servicios que integran el viaje combinado 
            indistintamente ante organizadores o minoristas, que quedarán obligados a informar sobre el régimen de responsabilidad existente, tramitar la reclamación de forma directa 
            o mediante remisión a quien corresponda en función del ámbito de gestión, así como a informar de la evolución de la misma al viajero aunque esté fuera de su ámbito de gestión, 
            La falta de gestión de la reclamación por parte del minorista supondrá que deberá responder de forma solidaria con el organizador frente al viajero del correcto cumplimiento de 
            las obligaciones del viaje combinado que correspondan al organizador por su ámbito de gestión. De igual modo, la falta de gestión de la reclamación por parte del organizador 
            supondrá que deberá responder de forma solidaria con el minorista frente al viajero del correcto cumplimiento de las obligaciones del viaje combinado que correspondan al minorista 
            por su ámbito de gestión. 
          </Text>
          <Text style={styles.text}>
            El organizador y el minorista  están obligados a prestar asistencia adecuada y sin demora indebida al viajero si se halla en dificultades de conformidad con el artículo 163.2,  
            sin perjuicio del derecho del organizador y, en su caso, el minorista a facturar un recargo razonable por dicha asistencia si la dificultad se ha originado intencionadamente o 
            por negligencia del viajero. En todo caso, es obligación del viajero la comunicación de toda falta de conformidad advertida durante la ejecución del viaje combinado de conformidad 
            con el artículo 161.2. Así mismo en caso de circunstancias inevitables y extraordinarias, si resultara imposible garantizar el retorno del viajero según lo convenido en el contrato, 
            el organizador o, en su caso, el minorista asumirán el coste del alojamiento por un período no superior a tres noches por viajero, a razón de lo estipulado en el 161.7 
          </Text>
          <Text style={styles.text}>
            El viajero tendrá derecho a una reducción del precio adecuada por cualquier periodo durante el cual haya habido falta de conformidad, a menos que el organizador o el minorista 
            demuestren que la falta de conformidad es imputable al viajero. El viajero tendrá derecho a recibir una indemnización adecuada del organizador o, en su caso, del minorista por 
            cualquier daño o perjuicio que sufra como consecuencia de cualquier falta de conformidad. El viajero no tendrá derecho a una indemnización por daños y perjuicios si el organizador o, 
            en su caso, el minorista demuestran que la falta de conformidad es:  
          </Text>
          <Text style={styles.text}>
            a) imputable al viajero, b) imputable a un tercero ajeno a la prestación de los servicios contratados e imprevisible o inevitable, o c) debida a circunstancias inevitables y extraordinarias. 
          </Text>
          <Text style={styles.text}>
            En la medida en que los convenios internacionales que vinculan a la Unión Europea limiten el alcance o las condiciones del pago de indemnizaciones por parte de prestadores de 
            servicios de viaje incluidos en un viaje combinado, las mismas limitaciones se aplicarán a los organizadores y minoristas. Así, se limita la indemnización que debe pagar el 
            organizador o el minorista (siempre que esa limitación no se aplique a los daños corporales o perjuicios causados de forma intencionada o por negligencia) al triple del precio 
            total del viaje. 
          </Text>
          <Text style={styles.text}>
            PUNTO DE CONTACTO EN DESTINO: El viajero puede ponerse en contacto en destino con el organizador/ representante local en calle................ y teléfono.................,
            correo electrónico ................, datos indicados a efectos de localización de los menores que viajes solos.
          </Text>
          <Text style={styles.text}>
            PROCEDIMIENTOS INTERNOS DE TRAMITACIÓN DE RECLAMACIONES: {"\n"}
            Independientemente del derecho de asistencia en destino, y la obligación del cliente de constatar en destino la prestación defectuosa/incumplimiento contractual,
            el cliente podrá presentar la correspondiente reclamación por escrito ante la agencia minorista, quien deberá dar traslado de la misma al mayorista/proveedor
            correspondiente en el plazo de 7 días. En caso de que el mayorista/proveedor no conteste en el plazo de otros 7 días, la agencia comunicará dicha situación al cliente,
            quien quedará en libertad para ejercitar los derechos que la normativa le ampara ante los organismos correspondientes.
          </Text>
          <Text style={styles.text}>
            Conforme a lo dispuesto en el RDL 1/2007, la Agencia declara expresamente que actúa únicamente como intermediaria entre viajeros y las entidades o personas
            llamadas a facilitar los servicios que constan en la documentación entregada, o sea; empresas de transportes, hoteles restaurantes, etc. Toda reclamación
            será tratada por la agencia con la mayor celeridad posible, realizando todas las averiguaciones posibles sobre las incidencias comunicadas por los clientes,
            informando a los mismos de la posibilidad de presentar la correspondiente hoja de reclamación, y/o acudir a los mecanismos de solución de conflictos extrajudiciales
            existentes.
          </Text>
          <Text style={styles.text}>Las acciones derivadas de los derechos reconocidos en el RDL 1/2007 referente a Viajes Combinados prescribirán a los dos años.</Text>
          <Text style={styles.text}>
            PROTECCIÓN DE DATOS: En cumplimiento de lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, 
            relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos,y a la  
            Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD), así como la normativa que 
            fuera de aplicación, en su caso, en el momento de la recogida de sus datos personales, OCEANBOOKING le ha proporcionado la información básica respecto 
            de su tratamiento. Puede consultar la información adicional y detallada sobre Protección de Datos en (hipervínculo a la política privacidad de la agencia) 
            Igualmente, le informamos de que OCEANBOOKING es el Responsable del tratamiento de datos y que los recaba con la finalidad principal de gestionar su solicitud, 
            en base a la aplicación de medidas precontractuales para la ejecución de un futuro contrato suscrito entre las partes. Usted tiene derecho a acceder, a rectificar, 
            a limitar el tratamiento, a suprimir sus datos y a solicitar la portabilidad de sus datos, tal y como se explica en la información adicional, mediante comunicación 
            a booking@oceanbooking.com, ubicados en C. Antonio Domínguez Alfonso, 8, 38650 Santa Cruz de Tenerife.  En todo caso, usted podrá presentar una reclamación ante 
            la Agencia Española de Protección de Datos especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos. La dirección de la Agencia es 
            C/ Jorge Juan 6, 28001, Madrid, y su página web www.agpd.es. 
          </Text>
          <Text style={styles.text}>
            En prueba de su conformidad, las partes firman el presente contrato en, Santa Cruz de Tenerife, a ..... de ..... de ......
          </Text>
          <View style={styles.table3}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell3}>
                  La Agencia
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell3}>El viajero</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell3}>
                  <View>
                    <Image style={styles.imageSello} src={Sello} />
                  </View>
                </Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell3}>
                  D/Dña........{"\n"}
                  DNI..........
                </Text>
              </View>
            </View>
          </View>

          {/*Salto de pagina */}
          <Text break style={styles.title}>
            Anexo I - Certificado
          </Text>
          <Text style={styles.text}>
            Expediente/Reserva nº .........
          </Text>
          <Text style={styles.text}>
            Al amparo de lo establecido en el artículo 155.2.c)  Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la
            Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, y Decreto 89/2010, de 22 de julio Decreto 89/2010, de 22 de julio Canarias.
          </Text>
          <Text style={styles.text}>
            La AGENCIA DE VIAJES OceanBooking, con CIF A38033502,  título de licencia I-AV-0003878.1, y domicilio en Avda. Antonio Dominguez Alfonso,8 –Playa de las Américas (Arona)
            38650 Tenerife, con teléfono 0034 922 961 110, y correo electrónico info@oceanbooking.com;
          </Text>
          <Text style={styles.subtitle}>
            Certifica:
          </Text>
          <Text style={styles.text}>
            Que ha suscrito una garantía de protección frente a la insolvencia con  Markel España, que está plenamente vigente, por un importe de cien mil Euros (100.000,00 Euros).
          </Text>
          <Text style={styles.text}>
            DATOS CONTACTO MARKEL ESPAÑA:{"\n"}
            Markel Insurance SE , Sucursal en España{"\n"}
            Plaza Pablo Ruiz Picasso, 1, Planta 35. Edificio Torre Picasso{"\n"}
            E-mail: siniestros.spain@markel.com{"\n"}
            28020 Madrid{"\n"}
            Centralita: +34 917 886 150 / Fax: +34 917 886 160
          </Text>
          <Text style={styles.text}>
            Si se denegaren servicios debido a la insolvencia de LA AGENCIA DE VIAJES OceanBooking, los viajeros podrán
            ponerse en contacto con dicha entidad a fin de que puedan ejercer su derecho a reclamar directamente a la
            persona que sea garante de la responsabilidad contractual adquirida o, en su caso, con la autoridad competente
            (datos de contacto, entre otros nombre, dirección completa, correo electrónico y número de teléfono).
          </Text>
          <Text style={styles.text}>
            Y para que conste a los efectos oportunos extiende el presente certificado en Santa Cruz de Tenerife, a .......... de ......... de ..............
          </Text>
          <Text style={styles.text}>
            SERVICIO DE ATENCIÓN AL CLIENTE:{"\n"}
            D. Julián Olivares Monteagudo ESTUDIO JURÍDICO{"\n"}
            Teléfono:{"\n"}
            atencionclientemarkel@gmail.com{"\n"}
            C/ Serrano, 76, 6º Derecha 28006 - Madrid{"\n"}
            Titular del Servicio:{"\n"}
            Fax: 91 556 27 74{"\n"}{"\n"}
          </Text>
          <Text style={styles.text}>
            Fdo.:________________________{"\n"}
            <View>
              <Image style={styles.imageSello} src={Sello} />
            </View>
          </Text>
          <Text style={styles.textRecibed}>
            Recibido:{"\n"}
            D/Dña ...................{"\n"}
            DNI.................{"\n"}
            Reserva nº ................{"\n"}{"\n"}
            Fdo.:________________________{"\n"}{"\n"}
            D/Dña ......................{"\n"}
          </Text>

          {/*Salto de pagina */}
          <Text break style={styles.title}>
            Anexo II - Formulario
          </Text>
          <Text style={styles.subtitle}>
            Formulario de información normalizada para contratos de viaje combinado
          </Text>
          <Text style={styles.text}>
            La combinación de servicios de viaje que se le ofrece es un viaje combinado en el sentido del texto refundido de la Ley General
            para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, aprobado por Real Decreto Legislativo 1/2007, de 16 de noviembre.
          </Text>
          <Text style={styles.text}>
            De igual manera, si usted celebra un contrato con la empresa antes de que se cumplan veinticuatro horas de la recepción de la confirmación
            de la reserva enviada por la empresa mayorista, el servicio de viaje ofrecido por minorista  y mayorista constituirá un viaje combinado
            en el sentido del texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, aprobado
            por Real Decreto Legislativo 1/2007, de 16 de noviembre.
          </Text>
          <Text style={styles.text}>
            Además, como exige la legislación, la(s) empresa(s) mayorista/minorista está(n) cubierta(s) por una garantía para reembolsarle los pagos
            realizados y, si el transporte está incluido en el viaje, asegurar su repatriación en caso de que incurra(n) en insolvencia.
          </Text>
          <Text style={styles.text}>
            Principales derechos en virtud del texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes
            complementarias, aprobado por Real Decreto Legislativo 1/2007, de 16 de noviembre:
          </Text>
          <Text style={styles.text}>– Los viajeros recibirán toda la información esencial sobre el viaje combinado antes de celebrar el contrato de viaje combinado.</Text>
          <Text style={styles.text}>– Siempre habrá como mínimo un empresario responsable de la correcta ejecución de todos los servicios de viaje incluidos en el contrato.</Text>
          <Text style={styles.text}>– Se proporcionará a los viajeros un número de teléfono de emergencia o los datos de un punto de contacto donde puedan contactar con el organizador y, en su caso, con el minorista.</Text>
          <Text style={styles.text}>– Los viajeros podrán ceder el viaje combinado a otra persona, con un preaviso razonable y, en su caso, con sujeción al pago de gastos adicionales.</Text>
          <Text style={styles.text}>
            – El precio del viaje combinado solo se podrá aumentar si se producen gastos específicos (por ejemplo, en los precios de combustible) y está
            expresamente estipulado en el contrato, y en ningún caso en los últimos veinte días anteriores al inicio del viaje combinado. Si el aumento
            de precio excede del ocho por ciento del precio del viaje combinado, el viajero podrá poner fin al contrato. Si el organizador se reserva el
            derecho de aumentar el precio, el viajero tendrá derecho a una reducción del precio si disminuyen los gastos correspondientes.
          </Text>
          <Text style={styles.text}>
            – Los viajeros podrán poner fin al contrato sin pagar ninguna penalización y obtener el reembolso completo de todos los pagos realizados si se
            modifica significativamente alguno de los elementos esenciales del viaje combinado que no sea el precio. Si el empresario responsable del viaje
            combinado lo cancela antes de su inicio, los viajeros tendrán derecho al reembolso de los pagos realizados y, cuando proceda, a una compensación.
          </Text>
          <Text style={styles.text}>
            – En circunstancias excepcionales, por ejemplo en caso de que en el lugar de destino existan graves problemas de seguridad que puedan afectar al
            viaje combinado, los viajeros podrán poner fin al contrato antes del inicio del viaje combinado, sin pagar ninguna penalización.
          </Text>
          <Text style={styles.text}>
            – Además, los viajeros podrán poner fin al contrato en cualquier momento antes del inicio del viaje combinado mediante el pago de una penalización
            por terminación que sea adecuada y justificable.
          </Text>
          <Text style={styles.text}>
            – Si, después del inicio del viaje combinado, no pueden prestarse elementos significativos del mismo, deberán ofrecerse al viajero fórmulas alternativas
            adecuadas, sin coste adicional. Los viajeros podrán poner fin al contrato sin pagar ninguna penalización en caso de no ejecución de los servicios cuando
            ello afecte sustancialmente a la ejecución del viaje combinado y el organizador y, en su caso, el minorista no consigan solucionar el problema.
          </Text>
          <Text style={styles.text}>
            - Los viajeros también tendrán derecho a una reducción del precio y/o a una indemnización por daños y perjuicios en caso de no ejecución o ejecución incorrecta de los servicios de viaje.
          </Text>
          <Text style={styles.text}>
            – El organizador y el minorista deberán proporcionar asistencia al viajero en caso de que este se encuentre en dificultades.
          </Text>
          <Text style={styles.text}>
            – Si el organizador o el minorista incurren en insolvencia se procederá al reembolso de los pagos. En caso de que el organizador o, en su caso, el minorista
            incurran en insolvencia después del inicio del viaje combinado y este incluya el transporte, se garantizará la repatriación de los viajeros. XY ha suscrito
            una garantía de protección frente a la insolvencia con YZ [la entidad garante en caso de insolvencia -por ejemplo, un fondo de garantía o una compañía de seguros-].
            Si se deniegan servicios debido a la insolvencia de XY, los viajeros podrán ponerse en contacto con dicha entidad o, en su caso, con la autoridad competente
            (datos de contacto, entre otros, nombre, dirección completa, correo electrónico y número de teléfono).
          </Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `Ayuda: spring@springhoteles.com | +34 822 030 006 (24/h)           Página ${pageNumber} de ${totalPages}`} fixed />
        </Page>
      </Document>
    </PDFViewer>
  );
}

import React, {useEffect, useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import useAxios from "axios-hooks";
import {Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Cart from "../../Cart";

import PersonalForm from "./personalForm/personalForm";
import Spinner from "../../Spinner";
import {checkoutGTM, successGTM} from "../../GTMComponent/GTMComponent";
import Popup from "reactjs-popup";
import UserForm from "./userForm/userForm";
import {confirmBookingButtonUpdate} from "../../../actions/checkout";
import {checkAvailability} from "../../../actions/reservations";
import IcoError from "../../../static/img/icons/ico-error.svg"
import AgencyForm from "./agencyForm/agencyForm";
import AgentForm from "./agentForm/agentForm";
import {dingusConversionFromCheckout} from '../../HotelProvider/index';

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;

export default function CheckoutForm(props) {

    const {t, i18n} = useTranslation('common');

    const methods = useForm();
    const dispatch = useDispatch();

    const hotel = props.hotel;

    const user = useSelector(state => state.auth);
    const products = useSelector(state => state.cart.products);
    const confirmBookingButton = useSelector(state => state.checkout.confirmBookingButton);

    let totalprice = useSelector(state => state.total.data.totalPrice);
    totalprice = Math.round((totalprice) * 100) / 100;

    const callCenterActive = useSelector(state => state.callcenter.callCenterActive);
    const customer = useSelector(state => state.callcenter.user);

    const availabilityRedux = useSelector(state => state.reservations?.checkAvailability);

    useEffect(() => {
        if(products.length) dispatch(checkAvailability(products));
    }, []);

    const onSubmit = form => {
        if (user.isAuthenticated && callCenterActive) {
            form.email = customer.e_mail;
            if (!form.phone) {
                // Si no ha modificado el tlfno enviamos el que ya tenemos
                form.phone = customer.mobile_phone_no_field;
            }
        } else if (user.isAuthenticated && user.isAgency) {
            form.emailAgency = user.agency.correo_electronico;
            form.isAgency = "true";
        } else if (user.isAuthenticated && user.user?.rol === 'agent') {
            form.emailAgency = user.user.agency_email;
            form.isAgency = "true";
        } else if (user.isAuthenticated && !user.isAgency) {
            form.email = user.user.e_mail;
            if (!form.phone) {
                form.phone = user.user.mobile_phone_no_field;
            }
        }

        // Comprobamos que el provider es el mismo en todos los productos
        let provider_equal = products.every((product, i, products) => product.provider === products[0].provider)
        if (provider_equal && products[0].provider === 'NAV') {
            sendNavForm(form);
        }
        else if (provider_equal && products[0].provider === 'DINGUS') {
            sendDingusForm(form);
        }
        else {
            return false;
        }

        // Evento de Checkout de GTM
        //new checkoutGTM(5);
    };

    const [{data, loading, error, response}, executeRequest] = useAxios(
        {
            // url: BASEURL + '/api/2/order/create',
            url: {},
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            data: {}
        },
        {manual: true}
    );

    function sendNavForm(form) {
        const getCountryISO3 = require("country-iso-2-to-3");
        form.country = getCountryISO3(form.country);

        const body = JSON.stringify({form, user, products, totalprice, language: i18n.language});
        executeRequest(
            {
                data: body,
                url: BASEURL + '/api/2/order/create',
            }
        );
    }

    function sendDingusForm(form) {
        const dingus_creation_data = dingusConversionFromCheckout(form, products, i18n.language);

        const body = JSON.stringify(dingus_creation_data);
        executeRequest(
            {
                data: body,
                url: BASEURL + '/dingus-api/reservation/create/',
            }
        );
    }

    if (loading) {
        dispatch(confirmBookingButtonUpdate(false));
    }

    /*
    if (data?.reservationStatus !== undefined) {
        successGTM(
            products,
            totalprice,
            data?.reservationEngineNumber,
            data?.reservationStatus,
            data?.pms_reservations_str
        )
    }
    */

    return (
        <React.Fragment>
            {(data?.error !== undefined) &&
            <Popup open={true} position="right center" closeOnDocumentClick>
                <div className="system-msg">
                    <div className="msg-box">
                        <div className="text-content">
                            <img className="icon" src={IcoError}/>
                            <h5 className="title">Error: {data?.error}</h5>
                            <p><strong>More info:</strong> {data?.description}</p>
                            <p>{data?.dirname}, {data?.fname}, {data?.line}</p>
                        </div>
                    </div>
                </div>
            </Popup>
            }

            {error &&
            <Popup open={true} position="right center" closeOnDocumentClick>
                <div className="system-msg">
                    <div className="msg-box">
                        <div className="text-content">
                            <img className="icon" src={IcoError}/>
                            <h5 className="title">Error</h5>
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            </Popup>
            }

            {(availabilityRedux === false) &&
            <Popup open={true} position="right center">
                <div className="system-msg">
                    <div className="msg-box">
                        <div className="text-content">
                            <img className="icon" src={IcoError}/>
                            <h5 className="title">Availability Error</h5>
                            <p>{error}</p>
                        </div>
                    </div>
                </div>
            </Popup>
            }

            {loading && <Spinner step={"checkout"}/>}

            <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {(data?.reservationStatus !== undefined) ?
                        <Redirect to={{
                            pathname: '/success/' + hotel,
                            state: {
                                reservationEngineNumber: data?.reservationEngineNumber,
                                status: data?.reservationStatus,
                                pms_reservations_str: data?.pms_reservations_str,
                                hotel: hotel,
                                products: products,
                                totalprice: totalprice
                            }
                        }}
                        />

                        :

                        <div className="checkout-content">
                            <aside className="checkout-aside">
                                <Cart cartEdit="false" buttonDisabled="true" isCheckoutView={true}/>
                            </aside>
                            <div className="checkout-elements form-style max-space color-label">
                                <dl className="checkout-box">
                                    <dt>
                                        {user.isAuthenticated && (user.isAgency || user.user?.rol === "agent") ?
                                            <h2>{t('msg.reservation_data')}</h2>
                                            :
                                            <h2>{t('reservation.personal_data')}</h2>
                                        }
                                    </dt>

                                    {user.isAuthenticated && user.isAgency && <AgencyForm/>}

                                    {user.isAuthenticated && (user.user?.rol === "agent") && <AgentForm/>}

                                    {user.isAuthenticated && !user.isAgency && (user.user?.rol === "user") &&
                                    <UserForm/>}

                                    {!user.isAuthenticated && <PersonalForm/>}

                                    {(totalprice === 0) || (confirmBookingButton === false) ?
                                        <button type="submit" className="final-btn" disabled>
                                            <span
                                                className="ico-arrow">{t('reservation.confirm_only').toUpperCase()}</span>
                                        </button>
                                        :
                                        <button type="submit" className="final-btn">
                                            <span
                                                className="ico-arrow">{t('reservation.confirm_only').toUpperCase()}</span>
                                        </button>
                                    }

                                </dl>
                            </div>
                        </div>
                    }

                </form>
            </FormProvider>
        </React.Fragment>
    )
};
import React, { Component } from "react";
import {withTranslation} from "react-i18next";

import VIconTag from "../../static/img/icons/vulcano/ico-tag.svg"
import VIconPay from "../../static/img/icons/vulcano/ico-payment.svg"
import VIconSec from "../../static/img/icons/vulcano/ico-security.svg"
import AIconTag from "../../static/img/icons/arona/ico-tag.svg"
import AIconPay from "../../static/img/icons/arona/ico-payment.svg"
import AIconSec from "../../static/img/icons/arona/ico-security.svg"
import BIconTag from "../../static/img/icons/bitacora/ico-tag.svg"
import BIconPay from "../../static/img/icons/bitacora/ico-payment.svg"
import BIconSec from "../../static/img/icons/bitacora/ico-security.svg"


export class HotelBannerAdvantages extends Component {

    render() {

        const iconPayImg = () => {
            try {
                if (this.props.hotel === 'arona') {
                    return <img src={AIconPay} alt="ico-pay"/>
                }
                if (this.props.hotel === 'bitacora') {
                    return <img src={BIconPay} alt="ico-pay"/>
                }
                if (this.props.hotel === 'vulcano') {
                    return <img src={VIconPay} alt="ico-pay"/>
                }
                return <div/>
            }
             catch (e) {
                return {}
            }
        };

        const iconSecImg = () => {
            try {
                if (this.props.hotel === 'arona') {
                    return <img src={AIconSec} alt="ico-sec"/>
                }
                if (this.props.hotel === 'bitacora') {
                    return <img src={BIconSec} alt="ico-sec"/>
                }
                if (this.props.hotel === 'vulcano') {
                    return <img src={VIconSec} alt="ico-sec"/>
                }
                return <div />
            } catch (e) {
                return {}
            }
        };

        const iconTagImg = () => {
            try {
                if (this.props.hotel === 'arona') {
                    return <img src={AIconTag} alt="ico-tag"/>
                }
                if (this.props.hotel === 'bitacora') {
                    return <img src={BIconTag} alt="ico-tag"/>
                }
                if (this.props.hotel === 'vulcano') {
                    return <img src={VIconTag} alt="ico-tag"/>
                }
                return <div />
            } catch (e) {
                return {}
            }
        };


        const {t} = this.props;

        return(
            <section className="search-result only-desktop container">
                <div className="search-result-header">
                    <div className="motor-info-banner">
                        <p>
                            <span className="ico-before" styles="background-image">{iconTagImg()}</span>
                            <span className="grey-color"><strong>{t('advantages.best_price')}</strong></span>
                        </p>
                        <p>
                            <span className="ico-before" styles="background-image">{iconPayImg()}</span>
                            <span className="grey-color"><strong>{t('advantages.pay_on_hotel')}</strong></span>
                        </p>
                        <p>
                            <span className="ico-before" styles="background-image">{iconSecImg()}</span>
                            <span className="grey-color"><strong>{t('advantages.secure')}</strong></span>
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation('common')(HotelBannerAdvantages);
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import LoginPage from "../../Login/LoginPage";
import CancelationForm from "./CancelationForm";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";


export class Cancelation extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
    };

    render() {
        const {t} = this.props;
        const {isAuthenticated} = this.props.auth;
        const pmskey = this.props.match.params.pmskey;

        return (
            <>
                {isAuthenticated ?
                    <>
                        <Header hotel="spring"/>
                        <CancelationForm pmskey={pmskey}/>
                        <Footer hotel="spring"/>
                    </>
                    :
                    <LoginPage/>
                }
            </>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps
)(withRouter(withTranslation('common')(Cancelation)));
import React, {Component} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {SearchBox} from "../SearchBox/SearchBox";
import {withTranslation} from "react-i18next";


export class PageSearchBox extends Component {

    changeLanguage(i18n, lng) {
        i18n.changeLanguage(lng);
    };

    render() {
        const AGENCY_MODE_ACTIVATED = (process.env.REACT_APP_AGENCY_MODE_ACTIVATED === 'true');
        const {i18n} = this.props;

        if (AGENCY_MODE_ACTIVATED) {
            return (
                window.location.replace(process.env.REACT_APP_AGENCY_URL_REDIRECT)
            )
        } 
        else {
            return (
                <React.Fragment>
                    <Header hotel="spring"/>
                    <SearchBox lang={i18n} interno={this.props.interno} withHeader={true}/>
                    <Footer hotel="spring"/>
                </React.Fragment>
            );
        }
    }
}

export default withTranslation('common')(PageSearchBox);
import { FaStar } from 'react-icons/fa';
import { SiGooglemaps } from 'react-icons/si';
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";

export default function HotelInfo({ title, short_description, hotel_stars, hotel_images, address, promoted }) {

    const golden_stars = Array(...Array(parseInt(hotel_stars)).keys());
    const grey_stars = Array(...Array(parseInt(5 - hotel_stars)).keys());
    const {t} = useTranslation('common');

    const hotelURL = useSelector(state => state.search.payload);

    const getPromoted = () => {
        if (hotelURL?.hotel){
            return '';
        }
        else {
            if(promoted) {
                return <p className="promoted"><span>{t('multihotel.promoted')}</span></p>
            }
        }
    }

    return (
        <div className="info-hotel">

            <div>
                <img src={(hotel_images.length === 0 ? process.env.REACT_APP_HOTEL_DEFAULT_IMAGE : hotel_images[0])} />
            </div>

            <div>

                {getPromoted()}
                
                {/* Title */}
                <h2>{title}</h2>
                <div>
                    <div className="valoration">
                        {golden_stars.map((star, index) => {
                            return (
                                <FaStar key={index} className="golden-star" />
                            )
                        })}
                        {(5 - hotel_stars === 1 ?
                            <FaStar className="grey-star" />
                            :
                            grey_stars.map((star, index) => {
                                return (
                                    <FaStar key={index} className="grey-star" />
                                )
                            }))}
                    </div>
                </div>
                <div as={'div'}>
                    <div className="localization">
                        <p>
                            <SiGooglemaps />
                            <span>{address}</span>
                        </p>
                    </div>
                </div>
                <p> {short_description} </p>

            </div>

        </div>
    )
}
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

import LogoSpring from "../../static/img/logo-spring-full.svg";
import Konami from "react-konami-code";
import LogoOcean from "../../static/img/oceanbooking.png";
import LogoProOcean from "../../static/img/prooceanbooking.png";
import LogoGF from "../../static/img/gfhotels_footer.png";
import Facebook from "../../static/img/icons/social/facebook.png";
import Instagram from "../../static/img/icons/social/instagram.png";
import Whatsapp from "../../static/img/icons/social/whatsapp.png";
import {Fontawesome} from "@fortawesome/fontawesome-svg-core";
import LogoArona from "../../static/img/logo-arona.svg";
import LogoBitacora from "../../static/img/logo-bitacora.svg";
import LogoVulcano from "../../static/img/logo-vulcano.svg";
import LogoBull from "../../static/img/logo-bullhotels.png";

export class Footer extends Component {

    render() {

        const logoImg = () => {
            try {
                if (this.props.hotel === 'arona') {
                    return <img src={LogoArona} alt="arona" className="header-logo" />
                }
                else if (this.props.hotel === 'bitacora') {
                    return <img src={LogoBitacora} alt="bitacora" className="header-logo" />
                }
                else if (this.props.hotel === 'vulcano') {
                    return <img src={LogoVulcano} alt="vulcano" className="header-logo" />
                }
                else if (process.env.REACT_APP_AGENCY_MODE_ACTIVATED === 'true') {
                    return <img src={LogoProOcean} alt="ocenabooking" className="header-logo" />
                }
                else if (process.env.REACT_APP_INSTANCE === 'OCEANBOOKING') {
                    return <img src={LogoOcean} alt="ocenabooking" className="header-logo" />
                }
                else if (process.env.REACT_APP_INSTANCE === 'GFHOTELS') {
                    return <img src={LogoGF} alt="GFHotels" className="header-logo spring-logo" />
                }
                else if (process.env.REACT_APP_INSTANCE === 'SPRING') {
                    return <img src={LogoSpring} alt="Spring" className="header-logo" />
                }
                else if (process.env.REACT_APP_INSTANCE === 'BULLHOTELS') {
                    return <img style={{ width: 150 }} src={LogoBull} alt="Bullhotels" className="header-logo" />
                }
                return <img src={LogoProOcean} alt="Spring" className="header-logo spring-logo" />
            } catch (e) {
                return <img src={LogoProOcean} alt="Spring" className="header-logo spring-logo" />

            }
        };

        const redirectHome = () => {
            return "https://oceanbooking.com/"
        };

        return (
            <footer id="motor_footer">
                <nav className="footer-nav">
                    <div className="desktop-container">
                        <div className="container group-logo-wrapper">
                                <a href={redirectHome()} target="_blank">
                                    {logoImg()}
                                </a>
                                <Konami code={[72, 69, 67, 84, 79, 82, 70, 73]}>
                                    <br/><br/>
                                    <h4 className="nav-title container">{'Made with ❤️ by Héctor Fig. and Noel Pad.'}</h4>
                                </Konami>
                        </div>

                        <div className="rrss">
                            <ul>
                                <li className="facebook">
                                    <a href="https://www.facebook.com/Oceanbooking" target="_blank"><img src={Facebook} alt="facebook" width="30px"/></a>
                                </li>
                                <li className="whatsapp">
                                    <a href="tel:+34822130034" ><img src={Whatsapp} alt="whatsapp" width="30px"/></a>
                                </li>
                                <li className="instagram">
                                    <a href="https://www.instagram.com/oceanbooking" target="_blank"><img src={Instagram} alt="instagram" width="30px"/></a>
                                </li>
                                <li>
                                    <button style={{border: "none", boxShadow: "none"}} onClick={() => {throw Error("Error para probar sentry")}}></button>

                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </footer>
        );
    }
}

export default withTranslation('common')(Footer);
import axios from 'axios';
import {tokenConfig} from './auth';
import {
    AGENCY_COMMISSIONVIEW_UPDATE, GET_AGENTS, AGENT_REGISTER,
    AGENT_REGISTER_ERROR, AGENT_REGISTER_CLEAR, AGENT_SPRING40
} from './types';

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;

export const commissionViewUpdate = data => {
    return {
        type: AGENCY_COMMISSIONVIEW_UPDATE,
        payload: data,
    };
};

export const spring40Update = data => {
    return {
        type: AGENT_SPRING40,
        payload: data,
    };
};

export const clearAgentRegister = () => {
    return {
        type: AGENT_REGISTER_CLEAR
    };
};

export const getAgents = () => (dispatch, getState) => {
    let url = '/api/agency/agents-list';

    axios
        .get(BASEURL + url, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: GET_AGENTS,
                payload: res.data,
            });
        })
};

export const registerAgent = (form) => (dispatch, getState) => {
    axios
        .post(BASEURL + '/api/auth/agency/register/agent', form, tokenConfig(getState))
        .then(res => {
            if(res.data.status === "KO"){
               dispatch({
                    type: AGENT_REGISTER_ERROR,
                    payload: res.data
                });
            }
            else {
                dispatch({
                    type: AGENT_REGISTER,
                    payload: res.data
                });
            }
        })
        .catch(err => console.error(err));
};

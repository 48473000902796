import {
    UPDATE_STEP_PROCESS
} from './types';
import store from "../store";

export const updateStep = stepData =>{
    const state = store.getState();

    if(stepData.id === 1) {
        state.cart.cart_flights = true;
        state.cart.cart_hotel = true;
    }
    else if(stepData.id === 2) {
        state.cart.cart_flights = false;
        state.cart.cart_hotel = false;
        state.cart.cart_extras = true;
    }
    else if(stepData.id === 4) {
        state.cart.cart_flights = false;
        state.cart.cart_hotel = false;
        state.cart.cart_extras = false;
    }

    return {
        type: UPDATE_STEP_PROCESS,
        payload: stepData,
    };
}
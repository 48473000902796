export const GET_HOTEL_PRICES= "GET_HOTEL_PRICES";
export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const GET_TOKEN = "GET_TOKEN";
export const SET_TOKEN_EXPIRATION_TIME = "SET_TOKEN_EXPIRATION_TIME";
export const SET_TEMPORAL_PKG_ID = "SET_TEMPORAL_PKG_ID";
export const SET_MULTIHOTEL = "SET_MULTIHOTEL";
export const SET_EXPIRATION_TIME = "SET_EXPIRATION_TIME";
export const SET_IPSTACK_DATA = "SET_IPSTACK_DATA";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_AGENCY = "LOGIN_SUCCESS_AGENCY";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_MODIFY_INFO = "USER_MODIFY_INFO";
export const RECOVER_PASS = "RECOVER_PASS";
export const CONFIRM_PASS = "CONFIRM_PASS";
export const INVALID_TOKEN = "INVALID_TOKEN";

export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const GET_RESERVATIONS = "GET_RESERVATIONS";
export const GET_RESERVATION_DETAIL = "GET_RESERVATION_DETAIL";
export const GET_RESERVATION_ROOM = "GET_RESERVATION_ROOM";
export const CANCEL_ROOM = "CANCEL_ROOM";

export const AGENCY_COMMISSIONVIEW_UPDATE= "AGENCY_COMMISSIONVIEW_UPDATE";

export const CONFIRM_BUTTON= "CONFIRM_BUTTON";

export const CALLCENTER_LOGIN = "CALLCENTER_LOGIN";
export const CALLCENTER_LOGOUT = "CALLCENTER_LOGOUT";
export const CALLCENTER_USER_LOADED = "CALLCENTER_USER_LOADED";

export const GET_AGENTS = "GET_AGENTS";
export const AGENT_REGISTER = "AGENT_REGISTER";
export const AGENT_REGISTER_ERROR = "AGENT_REGISTER_ERROR";
export const AGENT_REGISTER_CLEAR = "AGENT_REGISTER_CLEAR";
export const AGENT_SPRING40 = "AGENT_SPRING40";

export const GET_AVAILABILITY = "GET_AVAILABILITY";
export const NO_AVAILABILITY = "NO_AVAILABILITY";

export const SET_ROOM_MODIFICATION = "SET_ROOM_MODIFICATION";
export const UNSET_ROOM_MODIFICATION = "UNSET_ROOM_MODIFICATION";

export const ALERTS_CREATE_DASHBOARD = "ALERTS_CREATE_DASHBOARD";
export const ALERTS_DELETE_DASHBOARD = "ALERTS_DELETE_DASHBOARD";

export const TRANSFER_REQUEST = "TRANSFER_REQUEST";

export const UPDATE_STEP_PROCESS = "UPDATE_STEP_PROCESS";

export const ADD_LUGGAGE = "ADD_LUGGAGE";
export const REMOVE_LUGGAGE = "REMOVE_LUGGAGE";
export const ADD_TRANSFER = "ADD_TRANSFER";
export const REMOVE_TRANSFER = "REMOVE_TRANSFER";
export const ADD_TRANSFER_INFO = "ADD_TRANSFER_INFO";
export const ADD_LUGGAGE_INFO = "ADD_LUGGAGE_INFO";
export const EXTRAS_IS_DONE = "EXTRAS_IS_DONE";
export const ADD_EXTRAS_TOTAL_PRICE = "ADD_TOTAL_PRICE";

export const ADD_TRAVELERS = "ADD_TRAVELERS";

export const REGISTER_WITH_EMAIL = "REGISTER_WITH_EMAIL";

export const GET_INSOURANCES_INITIALIZE =  "GET_INSOURANCES_INITIALIZE";
export const SET_INSOURANCE =  "SET_INSOURANCE";
export const NO_INSOURANCE =  "NO_INSOURANCE";

export const SEARCH_RESPONSE = "SEARCH_RESPONSE";
export const EXTRAS_RESPONSE = "EXTRAS_RESPONSE";
export const CHECKOUT_RESPONSE = "CHECKOUT_RESPONSE";
export const SET_TOTAL_PRICE = "SET_TOTAL_PRICE";
export const SET_PAY_NOW = "SET_PAY_NOW";
export const SET_PAY_IN_HOTEL = "SET_PAY_IN_HOTEL";
export const EXTRAS_IS_FAIL = "EXTRAS_IS_FAIL";
export const NO_EXTRAS = "NO_EXTRAS";

export const SET_AMOUNT = "SET_AMOUNT";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_PAYMENT_ID = "SET_PAYMENT_ID";
export const SET_PAYMENT_TOKEN = "SET_PAYMENT_TOKEN";
export const SET_STATUS = "SET_STATUS";
export const PAYMENT_RESPONSE = "PAYMENT_RESPONSE";
import React, {useRef, useState} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {passwordConfirm} from "../../actions/auth";
import {passwordConfirmResponse} from "../../actions/auth";
import {passwordResetResponse} from "../../actions/auth";

function ModifyPassword(props) {

    const {t} = useTranslation('common');
    const methods = useForm();
    const password = useRef({});
    password.current = methods.watch("password", "");

    const [datasent, setDatasent] = useState(false);

    let params_received = useParams();

    const onSubmit = form => {
        setDatasent(true);
        delete form["repeat_password"];
        delete form["email"];
        form["token"] = params_received.token;
        props.passwordConfirm(form);
    };

    return (
        <React.Fragment>
            <Header hotel="spring"/>

            <main className="container apriv-page">
                <section className="modify-password-wrapper">
                    <header className="modify-password-header">
                        <h1 className="title">{t('pswd.modify_pswd')}</h1>
                    </header>

                    <form onSubmit={methods.handleSubmit(onSubmit)}
                          className="modify-password-block form-style site-bg-light">
                        <div className="inputs-group">
                            <div className="column-inputs">
                                <div className="input-wrapper">
                                    <label htmlFor="email">{t('words.email')} <span>({t('words.required')})</span></label>
                                    <input type="email" id="email" name="email"
                                           defaultValue={params_received.email}
                                           placeholder="name@email.com" required
                                           ref={methods.register({required: true})}
                                    />
                                </div>
                            </div>
                            <div className="column-inputs">
                                <div className="input-wrapper">
                                    <label htmlFor="new_password">{t('pswd.new_pswd')} <span>({t('words.required')})</span></label>
                                    <input type="password" id="new_password" name="password" required
                                           ref={methods.register({required: true})}
                                    />
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="confirm_password">{t('pswd.repeat_new_pswd')} <span>({t('words.required')})</span></label>
                                    <input type="password" id="confirm_password" name="repeat_password" required
                                           ref={methods.register(
                                               {
                                                   required: true,
                                                   validate: value =>
                                                       value === password.current || "The passwords do not match"
                                               })
                                           }
                                    />
                                    {methods.errors.repeat_password && methods.errors.repeat_password.message}
                                </div>
                            </div>
                        </div>
                        <div className="form-footer">
                            {datasent ?
                                <input type="submit" value={t('pswd.save_pswd')} className="btn btn-primary" disabled/>
                                :
                                <input type="submit" value={t('pswd.save_pswd')} className="btn btn-primary"/>
                            }
                        </div>
                    </form>
                </section>
            </main>

            <Footer hotel="spring"/>
        </React.Fragment>
    );
}

export default connect(null, {passwordConfirm})(ModifyPassword);
import React from "react";
import {useTranslation} from "react-i18next";
import { IoAirplane } from "react-icons/io5";
import { FaBed } from "react-icons/fa";
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Popup from "reactjs-popup";
import BookingFlights from "../BookingFlights/BookingFlights";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;

export default function BookingCard(props) {
    const {t, i18n} = useTranslation('common');

    const date = (prop) => {
        let date = prop?.replace("-", "/");
        return new Date(date).toLocaleString().split(" ")[0]
    };


    const css_reservation_status = (status = props.reservation.status.text) => {
        if (status === 'confirmed' || status === 'completed') {
            return 'label confirmed'
        } else if (status === 'canceled') {
            return 'label cancelled'
        } else if (status === 'pending') {
            return 'label'
        } else {
            return 'label'
        }
    };

    const numChilds = () => {
        return props.reservation.hotel_room_package[0].children_ages.length
    };

    const roomImage = BASEURL + props.reservation.roomimage;
    const url_reservation = "/myreservations/room/" + props.reservation.token;

    const flights_exists = props.reservation.flight_package.length > 0;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const backgroundImage = {
        backgroundImage : props.reservation?.hotel_room_package[0].hotel_images[0]
    }

    return (
        <article className="booking-card">
            <p className={css_reservation_status()}>
                <span>
                {t([`reservation_status.${props.reservation.status.text}`, 'reservation_status.pending'])}
                </span>
            </p>
            <div className="booking-img" style={{ backgroundImage:`url(${props.reservation?.hotel_room_package[0].hotel_images[0]})` }}>
            </div>
            <div className="booking-info">
                <div className="principal-info">
                        <p className="title">{props.reservation.hotel_room_package[0].roomtype_name + " - " + props.reservation.hotel_room_package[0].hotel_name}</p>
                    <a href={url_reservation}
                       className="h2 locator">{t('reservation.reservation_id_short')}: {props.reservation.booking_locator}</a>
                    <table className="date">
                        <tr>
                            <th>{t('words.arrival_date')}:</th>
                            <th>{t('words.departure_date')}:</th>
                        </tr>
                        <tr>
                            <td>{props.reservation.hotel_room_package[0].arrival}</td>
                            <td>{props.reservation.hotel_room_package[0].departure}</td>
                        </tr>
                    </table>
                    <p className="info">
                        <strong>{props.reservation.hotel_room_package[0].adults} {t('search.adult(s)')}{numChilds() > 0 ? ', ' + numChilds() + ' ' + t('search.children(s)') : null}</strong>
                    </p>
                    <br/>
                    <div>
                        <p className="info">
                            {i18n.language === "es" ?
                                <strong><a href="https://springhoteles.com/formulario-taxi-gratuito/"
                                        className="right-link">Solicitar Taxi Transfer</a></strong>
                                :
                                <strong><a href="https://springhoteles.com/en/taxi-transfer-form/" className="right-link">Taxi
                                    Transfer</a></strong>
                                
                            }
                            
                        </p>
                        <p className="icons">
                            {flights_exists &&
                                <>
                                    <a onClick={handleOpen}><IoAirplane /></a>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style} id="box">
                                            <p><a onClick={handleClose}><strong>X</strong></a></p>
                                            <BookingFlights flights={props.reservation.flight_package}/>
                                        </Box>
                                    </Modal>
                                    
                                </>
                            }
                            <FaBed/>
                        </p>
                    </div>
                </div>
                
                <div className="footer-info">
                    {(props.reservation?.client_price === props.reservation?.booking_price) || (props?.isAgency) ?
                        <p className="price"><strong>{props.reservation.total_price} €</strong></p>
                        :
                        <p className="price"><strike>{props.reservation?.booking_price}€</strike>
                            <strong> {props.reservation?.client_price}€</strong></p>
                    }
                    {(props.reservation.status.text !== "canceled") &&
                        <p className="links-wrapper">
                            <a href={url_reservation} className="right-link">{t('words.modify')}</a>
                        </p>
                    }
                </div>
            </div>
            <BookingFlights/>
        </article>
    )
}
import React from "react";
import Popup from 'reactjs-popup';
import {useTranslation} from "react-i18next";


export function PreviewRoom(params) {

    const {t, i18n} = useTranslation('common');

    const hotelUrl = (lang) => {
        if (params.isUP) {
            if (lang === 'es') {
                    return "https://springhoteles.com/hotel-bitacora-tenerife/up/"
                } else {
                    return "https://springhoteles.com/en/hotel-bitacora-tenerife/up/"
                }
        } else {
            if (params.hotel === "vulcano" || params.hotel === "bitacora") {
                if (lang === 'es') {
                    return "https://www.springhoteles.com/hotel-" + params.hotel + "-tenerife/habitaciones/"
                } else {
                    return "https://www.springhoteles.com/en/hotel-" + params.hotel + "-tenerife/rooms/"
                }
            } else if (params.hotel === "arona") {
                if (lang === 'es') {
                    return "https://www.springhoteles.com/arona-gran-hotel-tenerife/habitaciones/"
                } else {
                    return "https://www.springhoteles.com/en/arona-gran-hotel-tenerife/rooms/"
                }
            }
        }
    };

    let roomiframe = "<iframe src=" + hotelUrl(i18n.language) + " " +
        "name='roomHotel' " +
        "scrolling='Yes' " +
        "height='700px' " +
        "width='100%' " +
        "style='border: none;'" +
        "></iframe>";


    let button = <button className="motor-modal-link">{t('result.more_info')}</button>;

    if(params.isUP) {
        if(params.isMobile) {
            button = <button className="btn btn-up btn-select">
                        <span className="to-select">{t('msg.discover_up')}</span>
                    </button>
        }
        else {
            button = <button className="btn btn-up btn-select">
                <span className="to-select">&nbsp;&nbsp;&nbsp;{t('msg.discover_up')}</span>
            </button>
        }
    }

    return (
        <Popup modal position="right center"
               trigger={button}>
            <div style={{zIndex:3000}} dangerouslySetInnerHTML={{__html: roomiframe ? roomiframe : ""}}/>
        </Popup>
    )
}
import React from "react";
import {useTranslation} from "react-i18next";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;

export default function CardDetail(props) {
    const {t} = useTranslation('common');
    const room = props.room;
    const roomImage = BASEURL + props.room.roomimage;

    const people = () => {
        let people_string = room.adults + " " + t('person.adults');
        if (room.children + room.children2 > 0) {
            people_string += ", " + (room.children + room.children2) + " " + t('person.children')
        }
        if (room.babies > 0) {
            people_string += ", " + (room.babies) + " " + t('person.babies')
        }
        return people_string;
    };

    const url_reservation = "/myreservations/room/" + props.room.pms_resid_key;

    const css_reservation_status = (status = props.room.status) => {
        if (status === 'confirmed') {
            return 'label confirmed'
        } else if (status === 'canceled') {
            return 'label cancelled'
        } else if (status === 'pending') {
            return 'label'
        } else if (status === 'completed') {
            return 'label'
        } else {
            return 'label'
        }
    };

    const isCancelled = () => {
        return room?.status === 'canceled';
    };

    return (
        <article id="room-01" className="booking-room-card">
            <p className={css_reservation_status()}>
                <span>
                {t([`reservation_status.${props.room?.status}`, 'reservation_status.pending'])}
                </span>
            </p>
            <div className="booking-rom-img" style={{backgroundImage: `url("${roomImage}")`}}>
                <img src={BASEURL + props.room.roomimage}/>
            </div>
            <div className="booking-rom-info">
                <div className="principal-info">
                    <p>{t([`roomtypes.${room.translation_code}`, 'roomtypes.unspecific']) + " - Hotel " + room?.hotel}</p>
                    <a href={url_reservation} className="h2 title">{t('reservation.reservation_id_short')}: {props.room.pms_resid_key}</a>
                    <table className="date with-info">
                        <tr>
                            <th>{t('words.arrival_date')}:</th>
                            <th>{t('words.departure_date')}:</th>
                            <th className="info"/>
                        </tr>
                        <tr>
                            <td>{room.arrival}</td>
                            <td>{room.departure}</td>
                            <td className="info">{people()}</td>
                        </tr>
                    </table>
                </div>
                <div className="footer-info">
                    {room.client_price === room.booking_price ?
                        <p className="price"><strong>{room.client_price}€</strong></p>
                        :
                        <p className="price"><strike>{room.client_price}€</strike> <strong>{room.booking_price}€</strong></p>
                    }
                    {isCancelled() ? null :
                        <p className="links-wrapper">
                            <a href={url_reservation}>{t('words.modify')}</a>
                        </p>
                    }
                </div>
            </div>
        </article>
    )
}
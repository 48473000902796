import React, {useEffect} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getAgents} from "../../../actions/agency";
import AgentCard from "./AgentCard";


export function AgentsList(props) {
    const {t, i18n} = useTranslation('common');

    useEffect(() => {
        props.getAgents();
    }, []);

    return (
        <>
            {props.agents !== 'undefined' && props.agents?.length > 0 &&
            <>
                <header className="apriv-data-header">
                    <h1 className="title">{t('agencies.registered_agents')}:</h1>
                </header>
                <div className="bookings-list">
                    {props.agents.map((agent) => (
                        <AgentCard agent={agent}/>
                    ))}
                </div>
            </>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        agents: state.agency.agents,
    }
};

export default connect(mapStateToProps, {getAgents})(withTranslation('common')(AgentsList));
import React, {Component} from "react";
import {connect} from "react-redux";
import {login, registerWithEmail} from "../../../actions/auth";
import {withCookies} from "react-cookie";
import {withTranslation} from "react-i18next";
import PhoneInput from "react-phone-input-2";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";

function TranslatePoliticiesPages(language) {
    switch (language) {
        case "es":
            return process.env.REACT_APP_POLICIES_URL_ES;
        case "en":
            return process.env.REACT_APP_POLICIES_URL_EN;
        case "fr":
            return process.env.REACT_APP_POLICIES_URL_FR;
        case "nl":
            return process.env.REACT_APP_POLICIES_URL_NL;
        case "de":
            return process.env.REACT_APP_POLICIES_URL_DE;
        default:
            return process.env.REACT_APP_POLICIES_URL_ES;
    }
}

class RegisterForm extends Component {

    constructor(props) {
        super(props);
        this.passError = React.createRef();
        this.state = {
            country: '',
            region: '',
        };
    }
    selectCountry(val) {
        this.setState({country: val});
    }

    selectRegion(val) {
        this.setState({region: val});
    }

    setPhone(val) {
        this.setState({phone: val})
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    passwordValidation = (e) => {
        if(this.state.password !== e.target.value){
            e.target.classList.add("error");
            this.passError.current.style.display = "inline";
        }
        else{
            e.target.classList.remove("error");
            this.passError.current.style.display = "none";
        }
    }

    onSubmit = e => {
        e.preventDefault();
        if(this.state.password !== this.state.password_confirm){
            this.props.registerWithEmail(false);
        }
    }

    render() {

        const {registerWithEmail, i18n} = this.props;
        const {country, region} = this.state;
        const {t} = this.props;

        // const cancellation_policies = TranslatePoliticiesPages(i18n.language);

        return (
            <article className="register-form-wrapper">
                <h2 className="title">
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 8C9.07129 8 10.75 6.32129 10.75 4.25C10.75 2.17871 9.07129 0.5 7 0.5C4.92871 0.5 3.25 2.17871 3.25 4.25C3.25 6.32129 4.92871 8 7 8ZM9.625 8.9375H9.13574C8.48535 9.23633 7.76172 9.40625 7 9.40625C6.23828 9.40625 5.51758 9.23633 4.86426 8.9375H4.375C2.20117 8.9375 0.4375 10.7012 0.4375 12.875V14.0938C0.4375 14.8701 1.06738 15.5 1.84375 15.5H12.1562C12.9326 15.5 13.5625 14.8701 13.5625 14.0938V12.875C13.5625 10.7012 11.7988 8.9375 9.625 8.9375Z"
                            fill="currentColor"/>
                    </svg>
                    <span>Registro</span>
                </h2>
                <form className="register-form form-style" onSubmit={this.onSubmit}>
                    <div className="inputs-group">
                        <h4 className="sub-title">{t('words.personal_data')}</h4>
                        <div className="input-box">
                            <div className="column-inputs">
                                <div className="input-wrapper simple-input">
                                    <label htmlFor="name">{t('words.first_name')}</label>
                                    <input type="text" id="name" name="name" placeholder={t('words.placeholder_name')}
                                           /*required*/
                                           onChange={this.onChange}/>
                                </div>
                                <div className="input-wrapper simple-input">
                                    <label htmlFor="surname">{t('words.last_name')}</label>
                                    <input type="text" id="surname" name="surname"
                                           placeholder= {t('words.placeholder_first_surname')}
                                           /*required*/
                                           onChange={this.onChange}/>
                                </div>
                            </div>
                            <div className="column-inputs">
                                <div className="input-wrapper select-wrapper simple-input">
                                    <label htmlFor="country">{t('words.residence_country')}</label>
                                    <CountryDropdown
                                        /*required*/
                                        value={country}
                                        valueType="short"
                                        onChange={(val) => this.selectCountry(val)}
                                        priorityOptions={["GB", "ES", "IE", "DE", "FR", "BE", "IT"]}
                                    />
                                </div>
                                <div className="input-wrapper select-wrapper simple-input">
                                    <label htmlFor="region">{t('words.region')}</label>
                                    <RegionDropdown
                                        country={country}
                                        countryValueType="short"
                                        value={region}
                                        onChange={(val) => this.selectRegion(val)}/>
                                </div>
                            </div>

                            <div className="column-inputs">
                                <div className="input-wrapper simple-input">
                                    <label htmlFor="birthday">{t('words.born')}</label>
                                    <input type="date" id="birthday" name="birthday" placeholder="DD/MM/AAAA"
                                           /*required*/
                                           onChange={this.onChange}/>
                                </div>
                                <div className="input-wrapper simple-input">
                                    <label htmlFor="phone">{t('words.mobile_phone')}</label>
                                    <PhoneInput
                                        id="phone"
                                        name="phone"
                                        country={(country !== '' ? country.toLowerCase(): 'gb')}
                                        preferredCountries={['ie', 'es', 'gb']}
                                        onChange={(val) => {this.setPhone(val)}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inputs-group">
                        <h4 className="sub-title">{t('words.access_password')}</h4>
                        <div className="column-inputs">
                            <div className="input-wrapper simple-input">
                                <label htmlFor="password">{t('words.password')}</label>
                                <input type="password" id="password" name="password"
                                       placeholder={t('words.placeholder_password')} /*required*/
                                       onChange={this.onChange}/>
                            </div>
                            <div className="input-wrapper simple-input">
                                <label htmlFor="password_confirm">{t('bloque_azul_contrasena.contrasena_confirmar')}</label>
                                <input className={""} type="password" id="password_confirm" name="password_confirm"
                                       placeholder={t('bloque_azul_contrasena.placeholder_contrasena_confirmar')} required
                                       onChange={(e) => {
                                           this.passwordValidation(e)
                                       }}/>
                                <span ref={this.passError} className="error-msg-red">{t('msg.equal_password')}</span>
                            </div>
                        </div>

                        <div className="checkbox-wrapper">
                            <div className="checkbox-group">
                                <input id="privacy_policy" type="checkbox" name="privacy_policy" /*required*//>
                                <label htmlFor="privacy_policy">Confirmo que he leído y acepto la <a
                                    href={i18n.language === 'es' ? "https://springhoteles.com/spring-hoteles/politica-de-privacidad/" : "https://springhoteles.com/en/spring-hoteles/politica-de-privacidad/"} target={"_blank"}>Política
                                    de Privacidad</a> de Spring Hotels Group. <span
                                    className="required">(Obligatorio)</span></label>
                            </div>

                        </div>
                        <div className="spring-club-wrapper">
                            <h5 className="title">{t('msg.congratulations')}</h5>
                            <p>{t('msg.special_price')}</p>
                            <div className="checkbox-group">
                                <input id="acceptance_club" type="checkbox" name="acceptance_club" /*required*//>
                                <label htmlFor="acceptance_club">{t('msg.special_offers')}</label>
                            </div>
                        </div>
                        <div className="buttons-wrapper">
                            <a className="btn full-weidth btn-register" onClick={() => {
                                registerWithEmail(false)
                            }}>
                                <span>{t('words.return')}</span>
                            </a>
                            <input className="btn full-weidth btn-primary" type="submit" value={t('words.return')}/>
                        </div>
                    </div>
                </form>
            </article>
        )
    }
}

const mapStateToProps = state => ({
    emailFlag: state.auth.registerWithEmail
});

export default connect(
    mapStateToProps, {login, registerWithEmail}
)(withCookies(withTranslation('common')(RegisterForm)));
import React, {Component} from "react";
import {withTranslation} from "react-i18next";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Login from "./LoginCallCenter";

export class LoginPageCallCenter extends Component {

    render() {
        const {t} = this.props;
        const {history} = this.props;

        return (
            <React.Fragment>
                <Header hotel="spring"/>

                <main className="container apriv-page">
                    <section className="login-columns">
                        <Login history={history}/>

                        <article className="login-info-wrapper site-bg-light">
                            <div className="login-info">
                                <h3 className="h5 title">Desde esta web podrás:</h3>
                                <ul className="login-info-list">
                                    <li>
                                        <svg width="22px" height="22px" viewBox="0 0 22 15" version="1.1">
                                            <title>Icono Cama</title>
                                            <g id="Login" stroke="none" stroke-width="1" fill="none"
                                               fill-rule="evenodd">
                                                <g id="Home-Spring-Laptop---1440px---Login-fullpage"
                                                   transform="translate(-941.000000, -285.000000)"
                                                   fill="var(--site-color)" fill-rule="nonzero">
                                                    <g id="Group-14" transform="translate(936.000000, 232.000000)">
                                                        <g id="Group-25" transform="translate(0.000000, 44.000000)">
                                                            <g id="bed" transform="translate(5.000000, 9.000000)">
                                                                <path
                                                                    d="M19.5446029,3.34256619 L8.17270876,3.34256619 C7.61710794,3.34256619 6.68961303,3.55315682 6.68961303,4.98248473 L6.68961303,7.16904277 L21.0276986,7.16904277 L21.0276986,4.98248473 C21.0276986,3.55763747 20.1002037,3.34256619 19.5446029,3.34256619 Z"
                                                                    id="Path"></path>
                                                                <polygon id="Path"
                                                                         points="3.59796334 7.64399185 1.43380855 7.64399185 1.43380855 0 0 0 0 14.8175153 1.43380855 14.8175153 1.43380855 10.9910387 20.5527495 10.9910387 20.5527495 14.8130346 21.986558 14.8130346 21.986558 7.64399185"></polygon>
                                                                <circle id="Oval" cx="3.82199593" cy="5.25580448"
                                                                        r="2.00285132"></circle>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <p><strong>Gestionar las reservas</strong> de los clientes</p>
                                    </li>
                                    <li>
                                        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                                            <title>Icono Usuario</title>
                                            <g id="Login" stroke="none" stroke-width="1" fill="none"
                                               fill-rule="evenodd">
                                                <g id="Home-Spring-Laptop---1440px---Login-fullpage"
                                                   transform="translate(-936.000000, -428.000000)">
                                                    <g id="Group-14" transform="translate(936.000000, 232.000000)">
                                                        <g id="Group-2" transform="translate(0.000000, 196.000000)">
                                                            <rect id="Rectangle" x="0" y="0" width="32"
                                                                  height="32"></rect>
                                                            <path
                                                                d="M16.2828283,16.9090909 C20.7610714,16.9090909 24.4218381,20.7316589 24.5657103,25.1919192 C24.5691483,25.62118 24.2345592,25.9743724 23.8106387,25.9986671 L23.7641221,26 L8.80808081,26 C8.37531406,26 8.02200427,25.6598043 8.00098895,25.2322506 L8,25.1919192 C8,20.6174394 11.7083485,16.9090909 16.2828283,16.9090909 Z M16.2828283,6 C18.8489998,6 20.9292929,8.08029308 20.9292929,10.6464646 C20.9292929,13.2126362 18.8489998,15.2929293 16.2828283,15.2929293 C13.7166567,15.2929293 11.6363636,13.2126362 11.6363636,10.6464646 C11.6363636,8.08029308 13.7166567,6 16.2828283,6 Z"
                                                                id="Combined-Shape" fill="var(--site-color)"
                                                                fill-rule="nonzero"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <p><strong>Actualizar los datos personales de los clientes.</strong></p>
                                    </li>
                                    <li>
                                        <svg width="25px" height="25px" viewBox="0 0 25 10" version="1.1">
                                            <title>Icono Taxi</title>
                                            <g id="Login" stroke="none" stroke-width="1" fill="none"
                                               fill-rule="evenodd">
                                                <g id="Home-Spring-Laptop---1440px---Login-fullpage"
                                                   transform="translate(-940.000000, -487.000000)"
                                                   fill="var(--site-color)">
                                                    <g id="Group-14" transform="translate(936.000000, 232.000000)">
                                                        <g id="Group-4" transform="translate(0.000000, 244.000000)">
                                                            <path
                                                                d="M24.3824253,11.2 C24.6985763,11.2 24.9850734,11.3861868 25.1134745,11.6750892 L28.6690301,19.6750892 C28.8484733,20.0788366 28.666639,20.551606 28.2628916,20.7310492 C28.1606044,20.7765102 28.0499155,20.8 27.9379808,20.8 L4.8,20.8 C4.3581722,20.8 4,20.4418278 4,20 C4,19.8880653 4.02348978,19.7773764 4.06895076,19.6750892 L7.62450632,11.6750892 C7.75290739,11.3861868 8.03940458,11.2 8.35555556,11.2 L24.3824253,11.2 Z M12.1849904,13.36 L8.54899042,13.36 L8.54899042,14.224 L9.88099042,14.224 L9.88099042,18.4 L10.8529904,18.4 L10.8529904,14.224 L12.1849904,14.224 L12.1849904,13.36 Z M15.2089904,13.36 L14.5249904,13.36 L12.3649904,18.4 L13.3801904,18.4 L13.8553904,17.284 L15.8785904,17.284 L16.3537904,18.4 L17.3689904,18.4 L15.2089904,13.36 Z M18.8089904,13.36 L17.6929904,13.36 L19.2193904,15.7144 L17.4769904,18.4 L18.5929904,18.4 L19.8169904,16.4848 L21.0409904,18.4 L22.1569904,18.4 L20.4073904,15.7144 L21.9409904,13.36 L20.8249904,13.36 L19.8169904,14.9512 L18.8089904,13.36 Z M23.8129904,13.36 L22.8409904,13.36 L22.8409904,18.4 L23.8129904,18.4 L23.8129904,13.36 Z M14.8633904,14.9296 L15.5041904,16.42 L14.2225904,16.42 L14.8633904,14.9296 Z"
                                                                id="Combined-Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <p>Gestionar los <strong>Taxi Transfers.</strong></p>
                                    </li>
                                </ul>
                            </div>
                        </article>
                    </section>
                </main>

                <Footer hotel="spring"/>
            </React.Fragment>
        );
    }
}

export default withTranslation('common')(LoginPageCallCenter)
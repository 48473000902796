import React, { Component } from "react";
import { connect } from 'react-redux';
import { addLuggage, addTaxi, removeTaxi, removeLuggage } from "../../../actions/extras";
import { withTranslation } from "react-i18next";
import {timeDiff, getLeadTime} from "../../GTMComponent/utils/timeGTM";

class ItineraryHeader extends Component {
    render() {

        const { active, flight, cart, search, transfer } = this.props;
        const { t } = this.props;
        const left = {
            width:"50%",
            margin: "0px",
        }

        //Funcion que calcula la diferencia en noches entre dos fechas
        const nightDiff = (dateString1, dateString2) => {
            let date1 = new Date(dateString1);
            let date2 = new Date(dateString2);
            let nightDifference = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
            return nightDifference;
        }

        return (
            <dl className="checkout-box itinerary-data">
                <dt className="close">
                    <h2 className="title">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.17334 14.0332C9.55127 14.376 10.1606 15.0088 10.146 15.5185C10.146 15.542 10.1431 15.5654 10.1431 15.5918C10.0903 16.1719 9.59228 16.5029 8.99757 16.5C8.26515 16.4971 7.82863 16.0078 7.86379 15.4512C7.89309 14.9766 8.46437 14.376 8.813 14.0567C8.88038 13.9951 8.8921 13.9776 8.97706 13.9512C9.07667 13.9512 9.09131 13.96 9.17334 14.0332ZM13.102 3.47183C12.6245 2.71891 11.9712 2.2824 11.2475 1.94549C10.5005 1.63201 9.64795 1.49432 9.00342 1.50018C7.71145 1.50018 6.91751 1.88689 6.62748 2.00701C5.05719 2.82438 4.15485 4.05483 4.1988 6.1466C4.4068 8.55477 7.04056 11.3028 8.76319 12.9961C8.813 13.043 8.89503 13.128 8.94483 13.1455L9.04151 13.1485C9.10303 13.128 9.09424 13.1338 9.14405 13.0869C10.6763 11.6456 13.2661 8.81551 13.7114 6.76769C13.9164 5.61927 13.8022 4.64369 13.102 3.47183ZM8.85108 11.8096C8.03078 10.3126 6.94095 7.98056 6.82084 6.27258C6.72123 4.88099 7.15482 2.42888 8.85108 2.26775V11.8096ZM13.0083 6.77355C12.5278 8.43759 10.7436 10.5235 9.5249 11.7627C10.3393 10.2423 11.435 7.85752 11.4936 6.14074C11.5405 4.79603 11.2065 3.04117 10.0874 2.29118C12.6889 2.82731 13.4711 5.16517 13.0083 6.77355Z"
                                fill="currentColor" />
                        </svg>
                        {/* TODO Traduccion */}
                        <span>{t('itinerary.your_hotel')}</span>
                    </h2>
                </dt>
                <dd className="plegable">
                    <div className="MuiCollapse-container" styles="display:none">
                        <div className="MuiCollapse-wrapper">
                            <div className="MuiCollapse-wrapperInner">
                                <div className="itinerary-wrapper">
                                    

                                    {/*HOTEL*/}
                                    <div className="itinerary-step hotel">
                                        {/* <div className="icon-taxi">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M20.0469 11.4391L19.1875 8.125C18.8125 6.75 17.5625 5.75 16.1875 5.75H15.75V4.5C15.75 3.80977 15.1902 3.25 14.5 3.25H9.5C8.80977 3.25 8.25 3.80977 8.25 4.5V5.75H7.8125C6.4375 5.75 5.1875 6.75 4.8125 8.125L3.95312 11.4391C2.83633 11.6891 2 12.6824 2 13.875V15.75C2 16.673 2.50586 17.4703 3.25 17.9031V19.5C3.25 20.1902 3.80977 20.75 4.5 20.75H5.75C6.44023 20.75 7 20.1902 7 19.5V18.25H17V19.5C17 20.1902 17.5598 20.75 18.25 20.75H19.5C20.1902 20.75 20.75 20.1902 20.75 19.5V17.9031C21.4941 17.4699 22 16.6727 22 15.75V13.875C22 12.6824 21.1637 11.6891 20.0469 11.4391ZM5.75 15.75C5.05977 15.75 4.5 15.1902 4.5 14.5C4.5 13.8098 5.05977 13.25 5.75 13.25C6.44023 13.25 7 13.8098 7 14.5C7 15.1902 6.44023 15.75 5.75 15.75ZM6.55273 11.375L7.22461 8.78281C7.31172 8.46406 7.59922 8.25 7.81289 8.25H16.1879C16.4016 8.25 16.6891 8.46406 16.768 8.75234L17.4473 11.375H6.55273V11.375ZM18.25 15.75C17.5598 15.75 17 15.1902 17 14.5C17 13.8098 17.5598 13.25 18.25 13.25C18.9402 13.25 19.5 13.8098 19.5 14.5C19.5 15.1902 18.9402 15.75 18.25 15.75Z"
                                                    fill="currentColor" />
                                            </svg>
                                        </div> */}
                                        
                                        <div className="icon-circle">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.73722 5.5188L5.86449 6.29337C6.59761 4.59464 7.66051 3.28736 8.76029 2.67466C6.76281 2.57279 4.82742 3.44465 3.58264 5.0038C3.44327 5.1788 3.52722 5.44234 3.73722 5.5188ZM6.4924 6.52212L11.4607 8.33106C12.2057 5.80234 11.8503 3.50486 10.5726 3.03987C10.4184 2.98362 10.2574 2.95653 10.0915 2.95653C8.88279 2.95674 7.41926 4.39756 6.4924 6.52212ZM12.1975 3.92715C12.3271 4.26673 12.4232 4.64797 12.4725 5.07693C12.5921 6.11629 12.443 7.33169 12.0778 8.55564L14.2156 9.33396C14.4259 9.41042 14.6596 9.26251 14.665 9.03876C14.7134 7.04524 13.7919 5.1338 12.1975 3.92715ZM13 11.9996H8.02217L9.37508 8.28127L8.12217 7.82502L6.60344 11.9994H1.66683C1.48266 11.9994 1.3335 12.1485 1.3335 12.3329V12.9998C1.3335 13.1839 1.48266 13.3333 1.66683 13.3333H13C13.1842 13.3333 13.3334 13.1839 13.3334 12.9998V12.3329C13.3334 12.1487 13.1842 11.9996 13 11.9996Z"
                                                    fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="itinerary-step-info">
                                            <p className="title">Tenerife</p>
                                            <p className="date">
                                                <svg width="16" height="16" viewBox="0 0 16 16"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.1665 13.4167C2.1665 14.1068 2.7264 14.6667 3.4165 14.6667H12.5832C13.2733 14.6667 13.8332 14.1068 13.8332 13.4167V6.33337H2.1665V13.4167ZM10.4998 8.31254C10.4998 8.14067 10.6405 8.00004 10.8123 8.00004H11.854C12.0259 8.00004 12.1665 8.14067 12.1665 8.31254V9.35421C12.1665 9.52608 12.0259 9.66671 11.854 9.66671H10.8123C10.6405 9.66671 10.4998 9.52608 10.4998 9.35421V8.31254ZM10.4998 11.6459C10.4998 11.474 10.6405 11.3334 10.8123 11.3334H11.854C12.0259 11.3334 12.1665 11.474 12.1665 11.6459V12.6875C12.1665 12.8594 12.0259 13 11.854 13H10.8123C10.6405 13 10.4998 12.8594 10.4998 12.6875V11.6459ZM7.1665 8.31254C7.1665 8.14067 7.30713 8.00004 7.479 8.00004H8.52067C8.69255 8.00004 8.83317 8.14067 8.83317 8.31254V9.35421C8.83317 9.52608 8.69255 9.66671 8.52067 9.66671H7.479C7.30713 9.66671 7.1665 9.52608 7.1665 9.35421V8.31254ZM7.1665 11.6459C7.1665 11.474 7.30713 11.3334 7.479 11.3334H8.52067C8.69255 11.3334 8.83317 11.474 8.83317 11.6459V12.6875C8.83317 12.8594 8.69255 13 8.52067 13H7.479C7.30713 13 7.1665 12.8594 7.1665 12.6875V11.6459ZM3.83317 8.31254C3.83317 8.14067 3.9738 8.00004 4.14567 8.00004H5.18734C5.35921 8.00004 5.49984 8.14067 5.49984 8.31254V9.35421C5.49984 9.52608 5.35921 9.66671 5.18734 9.66671H4.14567C3.9738 9.66671 3.83317 9.52608 3.83317 9.35421V8.31254ZM3.83317 11.6459C3.83317 11.474 3.9738 11.3334 4.14567 11.3334H5.18734C5.35921 11.3334 5.49984 11.474 5.49984 11.6459V12.6875C5.49984 12.8594 5.35921 13 5.18734 13H4.14567C3.9738 13 3.83317 12.8594 3.83317 12.6875V11.6459ZM12.5832 3.00004H11.3332V1.75004C11.3332 1.52087 11.1457 1.33337 10.9165 1.33337H10.0832C9.854 1.33337 9.6665 1.52087 9.6665 1.75004V3.00004H6.33317V1.75004C6.33317 1.52087 6.14567 1.33337 5.9165 1.33337H5.08317C4.854 1.33337 4.6665 1.52087 4.6665 1.75004V3.00004H3.4165C2.7264 3.00004 2.1665 3.55994 2.1665 4.25004V5.50004H13.8332V4.25004C13.8332 3.55994 13.2733 3.00004 12.5832 3.00004Z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span><strong>{search.arrival}</strong> {t('words.to')} <strong>{search.departure}</strong></span>
                                            </p>
                                            <p className="night">
                                                <svg width="10" height="10" viewBox="0 0 10 10"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M5.44303 9.16671C6.72822 9.16671 7.902 8.58199 8.67964 7.62387C8.79468 7.48214 8.66924 7.27507 8.49146 7.30893C6.46992 7.69392 4.61349 6.14395 4.61349 4.1033C4.61349 2.92781 5.24276 1.84687 6.26548 1.26485C6.42313 1.17514 6.38348 0.936125 6.20435 0.903035C5.95322 0.856729 5.69839 0.833412 5.44303 0.833374C3.14308 0.833374 1.27637 2.69716 1.27637 5.00004C1.27637 7.29999 3.14015 9.16671 5.44303 9.16671Z"
                                                        fill="currentColor" />
                                                </svg>
                                                <span>{nightDiff(search.arrival, search.departure)} {t('search.nights')}</span>
                                            </p>
                                            <p className="hotel-name"><strong>{cart[0]?.hotel_name}</strong></p>
                                            <p>{cart[0]?.room_title}</p>
                                            <p className="site-color">{cart[0]?.boardtype_text}</p>
                                            <p>{t('person.Adults')}: {search.adults}</p>
                                            <p>{t('person.Children')}: {search.room_num_childs}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </dd>
            </dl>
        )
    }
}

const mapStateToProps = state => ({
    active: state.flights.isActive,
    flight: state.flights.selected_flight,
    cart: state.cart.products,
    search: state.search.payload,
    transfer: state.extras.selected_transfer,
});


export default connect(mapStateToProps)(withTranslation('common')(ItineraryHeader));
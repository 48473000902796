import React from 'react';
import TagManager from "react-gtm-module";
import store from "../../store";
import {timeDiff, getLeadTime} from "./utils/timeGTM";
import {
    convertProductHotelRoomToItems,
    getOcuppationStr,
    getAgesChilds,
    convertFlightsToItems,
    cartToItems,
    convertProductHotelToItems,
    convertProductExtrasToItems,
    convertSelectedExtrasToItems
} from "./utils/itemsGTM";

const user_agencies = process.env.REACT_APP_AGENCY_MODE_ACTIVATED;

function getUser() {
    let user = '';
    if (user_agencies === 'true') {
        const storeRedux = store.getState();
        user = storeRedux.auth.profile.user.id;
    }
    return user;
}

function setDataLayerNull() {
    /**
     * Evento para borrar el contenido del DataLayer anterior haciendo que la variable ecommerce sea null
     */
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'ecommerce': null,
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("setDataLayerNull", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        TagManager.dataLayer(tagManagerArgs);
    }

}

function getEcommerceInfo(
    items,
    transaction_id = '',
    total_price,
    payment_type = '') {
    /**
     * Bloque coon información relacionada con el aparatado ecommerce del datalayer
     */
    const storeRedux = store.getState();
    const search = storeRedux.search.payload;
    const airport_origin = search?.airport;
    //const purchase_token = storeRedux.search?.token;

    //diferencia entre el checkin y el checkout
    let arrival_date = +new Date(search.arrival);
    let departure_date = +new Date(search.departure);
    let days = (Math.abs(departure_date - arrival_date) / (1000 * 3600 * 24));

    let data_created = {
        'items': items,
        'currency': 'EUR',
        'value': total_price,
        'start_date': search.arrival,  // fecha inicio del viaje
        'end_date': search.departure,  // fecha fin del viaje
        'origin': airport_origin,                    // aeropuerto de origen
        'destination': 'TFN,TFS',
        'ocuppation': getOcuppationStr(), // Ocupación en formato (adultos-niños-bebés)
        'resident': (search.resident === 'true'),   // es residente o no
        'leadtime': getLeadTime(),     // Diferencia entre el día de búsqueda y el start_date
        'duration': days.toString() + "D",   // Diferencia entre el end_date y el start_date
        'luggage_included': false,  // Si el cliente ha seleccionado la casilla de “solo vuelos con equipaje facturado” entonces “true”
        'child_ages': getAgesChilds(search),   // edades de los niños
        'directflight': false,   // si el cliente ha seleccionado la casilla de “solo vuelos directos” entonces “true”
        'userId': getUser(), // id del usuario
        'domain': process.env.REACT_APP_DJANGO_IP
        //'purchase_token': purchase_token // variable session_token para identificar el proceso de compra
    };

    if (payment_type !== '') {
        data_created['payment_type'] = payment_type                 // forma de pago
    }

    if (transaction_id !== '') {
        data_created['transaction_id'] = transaction_id               //id de transacción
        data_created['affiliation'] = ''               //fuente que originó la compra
    }

    return data_created
}

export function impressionsGTM() {
    /**
     * Este evento se lanzará cuando un usuario realice una búsqueda de disponibilidad o se muestren artículos (habitaciones o extras) en un listado.
     */

    const storeRedux = store.getState();
    const flights = storeRedux.flights;
    const products = storeRedux.shelf.products;
    const search = storeRedux.search.payload;

    const impressionsProducts = [];

    // TODO: Elminiar el código repetido de flights.selected_flight, flights.flights.combinations, flights.flights.outbound, flights.flights.inbound

    // Se añaden los vuelos seleccionados por defecto
    impressionsProducts.push(...convertFlightsToItems(flights.selected_flight))


    // Añadimos los vuelos combinados
    flights.flights.combinations?.map(flight => {

            impressionsProducts.push(
                {
                    "item_name": flight.outbound.flight_segment[flight.outbound.flight_segment.length - 1].arrivalAirport,  // Nombre del producto (nombre del aeropuerto de destino)
                    "item_id": flight.outbound.arrivalCode,                      // Código del producto único que identifica el avión, el hotel, el extra o el aeropuerto (codigo iata del aeropuerto de destino)
                    "price": flight.totalPrice,         // precio del producto
                    "discount": "",                     // Descuento del producto
                    "coupon": search.promotion,                       // Cupón de descuento o promocode.
                    "item_brand": flight.outbound.flight_segment[0].airlineName,                   // Nombre del hotel o aerolinea o empresa que ofrece el producto
                    "item_category": "flights",         // rooms, flights o extras
                    "item_category2": "No Reembolsable",               // tipo de tarifa que se esté aplicando al artículo (“No Reembolsable”, “Cancelación Gratis”)
                    "item_category3": flight.outbound.flight_segment.length === 1,               // vuelos directos (true o false)
                    "item_category4": flight.outbound.type,               // sentido (INBOUND, OUTBOUND O TWO-WAY)
                    "item_category5": "",
                    "item_variant": flight.outbound.departureCode,                 // Régimen de la habitación o Aeropuerto de origen
                    "item_list_name": "",               // Nombre del listado: Availability Room, Availability Flight, Extras (para view_item_list), Add to Cart, Remove From Cart, Begin Checkout, Add Payment Info, Purchase
                    "item_list_id": "",                 // id del listado:
                    "index": 0,              // Posición del hotel, vuelo
                    "quantity": 1,                       // Cantidad
                    "location_id": "",                  // Id de la localización en Google Maps
                    "origin": flight.outbound.departureCode,                        // Codigo IATA del aeropuerto de salida
                    "destination": flight.outbound.arrivalCode,                   // Codigo IATA del aeropuerto de llegada
                    "start_date": flight.outbound.flight_segment[0].departureDate,                   // Fecha inicio del servicio
                    "end_date": flight.outbound.flight_segment[flight.outbound.flight_segment.length - 1].arrivalDate,                     // Fecha fin del servicio
                    "google_business_vertical": "flights",     // vertical del producto: ‘hotel_rental’,‘flights’ o ‘custom’
                    "id": "",                            // codigo id del hotel
                    "flightcode": flight.outbound.flight_segment[0].flightCode,                   // codigo del vuelo
                    "ocuppation": getOcuppationStr(),                   // adultos-niños-bebes
                    "resident": false,                     // descuento residente (true o false)
                    "leadtime": getLeadTime(),                       // diferencia entre fecha de la reserva y fecha inicio servicio
                    "duration": timeDiff(flight.outbound.flight_segment[flight.outbound.flight_segment.length - 1].arrivalTime, flight.outbound.flight_segment[0].departureTime),                       // número de noches reservadas o tiempo de vuelo
                    "child_ages": getAgesChilds(search),                    // edad1-edad2-edad3...
                    "id_chain": "",                      // nombre de la cadena hotelera
                    "start_hour": flight.outbound.flight_segment[0].departureTime,                    // hora salida del vuelo
                    "end_hour": flight.outbound.flight_segment[flight.outbound.flight_segment.length - 1].arrivalTime                       // hora llegada del vuelo
                },
                {
                    "item_name": flight.inbound.flight_segment[flight.inbound.flight_segment.length - 1].arrivalAirport, // Nombre del producto (nombre del aeropuerto de destino)
                    "item_id": flight.inbound.arrivalCode,                      // Código del producto único que identifica el avión, el hotel, el extra o el aeropuerto (codigo iata del aeropuerto de destino)
                    "price": flight.totalPrice,         // precio del producto
                    "discount": "",                     // Descuento del producto
                    "coupon": search.promotion,                       // Cupón de descuento o promocode.
                    "item_brand": flight.inbound.flight_segment[0].airlineName,                   // Nombre del hotel o aerolinea o empresa que ofrece el producto
                    "item_category": "flights",         // rooms, flights o extras
                    "item_category2": "",               // tipo de tarifa que se esté aplicando al artículo (“No Reembolsable”, “Cancelación Gratis”)
                    "item_category3": flight.inbound.flight_segment.length === 1,               // vuelos directos (true o false)
                    "item_category4": flight.inbound.type,               // sentido (INBOUND, OUTBOUND O TWO-WAY)
                    "item_category5": "",
                    "item_variant": flight.inbound.departureCode,                 // Régimen de la habitación o Aeropuerto de origen
                    "item_list_name": "",               // Nombre del listado: Availability Room, Availability Flight, Extras (para view_item_list), Add to Cart, Remove From Cart, Begin Checkout, Add Payment Info, Purchase
                    "item_list_id": "",                 // id del listado:
                    "index": 0,              // Posición del hotel, vuelo
                    "quantity": 1,                       // Cantidad
                    "location_id": "",                  // Id de la localización en Google Maps
                    "origin": flight.inbound.departureCode,                        // Codigo IATA del aeropuerto de salida
                    "destination": flight.inbound.arrivalCode,                   // Codigo IATA del aeropuerto de llegada
                    "start_date": flight.inbound.flight_segment[0].departureDate,                   // Fecha inicio del servicio
                    "end_date": flight.inbound.flight_segment[flight.inbound.flight_segment.length - 1].arrivalDate,                     // Fecha fin del servicio
                    "google_business_vertical": "flights",     // vertical del producto: ‘hotel_rental’,‘flights’ o ‘custom’
                    "id": "",                            // codigo id del hotel
                    "flightcode": flight.inbound.flight_segment[0].flightCode,                   // codigo del vuelo
                    "ocuppation": getOcuppationStr(),                   // adultos-niños-bebes
                    "resident": false,                     // descuento residente (true o false)
                    "leadtime": getLeadTime(),                       // diferencia entre fecha de la reserva y fecha inicio servicio
                    "duration": timeDiff(flight.inbound.flight_segment[flight.inbound.flight_segment.length - 1].arrivalTime, flight.inbound.flight_segment[0].departureTime),                       // número de noches reservadas o tiempo de vuelo
                    "child_ages": getAgesChilds(search),                    // edad1-edad2-edad3...
                    "id_chain": "",                      // nombre de la cadena hotelera
                    "start_hour": flight.inbound.flight_segment[0].departureTime,                    // hora salida del vuelo
                    "end_hour": flight.inbound.flight_segment[flight.inbound.flight_segment.length - 1].arrivalTime                       // hora llegada del vuelo
                },
            );
        }
    );

    // Añadimos los vuelos combinables (outbound)
    impressionsProducts.push(...convertFlightsToItems(flights.flights.outbound))

    // Añadimos los vuelos combinables (inbound)
    impressionsProducts.push(...convertFlightsToItems(flights.flights.inbound))

    // Añadimos en que posición se muestran los vuelos
    impressionsProducts.map((item, index_num) => {
        item.index = index_num
    })

    // Añadimos los hoteles con sus productos de tipo hotel-hab-pensión-tarifa
    products.map(hotel => {
            hotel.products.map((product, index_hotel) => {
                    // Añade los productos de tipo hotel a impressions
                    impressionsProducts.push(...convertProductHotelRoomToItems(product))
                    // Añade la posición en que se muestran los hoteles
                    impressionsProducts[impressionsProducts.length - 1] = index_hotel
                }
            )
        }
    );


    // TODO: añadir extras
    //const prueba = convertProductExtrasToItems()

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'view_item_list',
            'ecommerce': getEcommerceInfo(impressionsProducts),
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("impressionsGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        setDataLayerNull()
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function viewItemListExtrasGTM() {

    const storeRedux = store.getState();
    const luggages = storeRedux.extras.luggage_info.payload?.extras_luggages;
    const transfer = storeRedux.extras;
    const total_price = 0;

    let extras_items = convertProductExtrasToItems(luggages)

    
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'view_item_list',
            'ecommerce': getEcommerceInfo(extras_items, '', total_price)
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("viewItemListExtrasGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        setDataLayerNull()
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function beginCheckoutGTM() {
    /**
     * Este evento se lanza en el momento de iniciar el trámite de una reserva (checkout)
     * En este evento cargaremos tantos objetos como artículos hayan sido cargados en el carrito y por tanto se quieran ﬁnalizar su reserva.
     */
    const storeRedux = store.getState()
    const total_price = storeRedux.pkgData?.total_price

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'begin_checkout',
            'ecommerce': getEcommerceInfo(cartToItems(), '', total_price), // TODO: Añadir los extras
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("beginCheckoutGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        setDataLayerNull()
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function purchaseGTM() {
    /**
     * Se reﬂejan las compras ﬁnales de todos los usuarios.
     * Es el evento más importante
     * Es totalmente necesario incluir un 'id de transacción' y un 'value' (precio total sin descuentos y con impuestos incluidos)
     * Incluye todos los artículos que engloba la compra con la información de estos.
     */
    const storeRedux = store.getState()
    const total_price = storeRedux.pkgData?.total_price
    const pkg_id = storeRedux.pkgData.payment_response?.booking_locator

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'purchase',
            'ecommerce': getEcommerceInfo(cartToItems(), pkg_id, total_price)
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("purchaseGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        setDataLayerNull()
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function addPaymentInfoGTM(payment_type = 'Pasarela de Pago') {
    /**
     * Paso de añadidos datos de pago para el datalayer.
     * El evento puede ser ejecutado tanto al ﬁnalizar la incorporación de datos en el formulario
     * mostrado para tal efecto. Como accionando el botón que lleve al usuario al siguiente
     * paso en la tramitación de reserva.
     *
     * payment_type recibido puede ser: "Pasarela de Pago" o "Pago en el hotel"
     */
    const storeRedux = store.getState()
    const total_price = storeRedux.pkgData?.total_price

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'add_payment_info',
            'ecommerce': getEcommerceInfo(cartToItems(), '', total_price, payment_type),
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("addPaymentInfoGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        setDataLayerNull()
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function addCartGTM() {
    /**
     * Detalla la información de un artículo que se ha añadido al carrito
     * Siempre se envía to do el carrito (rooms, flights, extras)
     */
    const storeRedux = store.getState()
    const total_price = storeRedux.pkgData?.total_price

    let items = []
    // Añadir rooms
    items.push(convertProductHotelToItems(storeRedux.cart?.products[0]))
    // Añadir flights
    items.push(...convertFlightsToItems(storeRedux.flights?.selected_flight))
    // TODO: Añadir extras
    items.push(...convertSelectedExtrasToItems(storeRedux.extras?.selected_luggage))
    
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'add_to_cart',
            'ecommerce': getEcommerceInfo(items, '', total_price)
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("addCartGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        setDataLayerNull()
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function unavailabilityGTM() {
    /**
     * El evento se lanzará cuando un usuario realice una búsqueda de disponibilidad y no
     * se muestren artículos (habitaciones, vuelos o extras)
     * Se ejecuta cuando hay un evento "ROOMS_FAIL" o "FLIGHT_FAIL"
     */

    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'no_dispo',
            'ecommerce': getEcommerceInfo(),
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("unavailabilityGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        setDataLayerNull()
        TagManager.dataLayer(tagManagerArgs);
    }
}

export function userLoginGTM(user) {
    /**
     * Envía la información del usuario que se ha logueado
     */
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayer: {
            'event': 'login',
            'user_type': (user_agencies === "true" ? 'agencies' : 'user'),
            'user_id': user?.id,
            'user_name': user?.username,
            'user_email': user?.email,
            'method_login': 'Correo electrónico y contraseña',
            'timeStamp': new Date().toISOString(),
        },
    };

    if (process.env.REACT_APP_GTM_LOG === 'true') {
        console.log("userLoginGTM", tagManagerArgs);
    }
    if (process.env.REACT_APP_GTM_ACTIVE === 'true') {
        setDataLayerNull()
        TagManager.dataLayer(tagManagerArgs);
    }
}
import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation, withTranslation} from "react-i18next";
import {connect, useDispatch, useSelector} from "react-redux";

import {registerAgent, clearAgentRegister} from "../../../actions/agency";
import IcoError from "../../../static/img/icons/ico-error.svg";
import IcoSuccess from "../../../static/img/icons/ico-success.svg";
import Popup from "reactjs-popup";


export function AgentRegisteForm(props) {
    const {t} = useTranslation('common');
    const methods = useForm();
    const dispatch = useDispatch();
    const registerAgent = useSelector(state => state.agency?.register);
    const [submit, setSubmit] = useState(true);

    const onSubmit = form => {
        setSubmit(false);
        props.registerAgent(form);
    };

    return (
        <section className="apriv-data-wrapper">
            {registerAgent?.status === "KO" &&
                <Popup open={true} position="right center" closeOnDocumentClick onClose={() => dispatch(clearAgentRegister())}>
                    <div className="system-msg">
                        <div className="msg-box">
                            <div className="text-content">
                                <img className="icon" src={IcoError}/>
                                <h5 className="title">Error</h5>
                                <p>{registerAgent.info}</p>
                            </div>
                        </div>
                    </div>
                </Popup>
            }
            {registerAgent?.status === "OK" &&
                <Popup open={true} position="right center" closeOnDocumentClick onClose={() => dispatch(clearAgentRegister())}>
                    <div className="system-msg">
                        <div className="msg-box">
                            <div className="text-content">
                                <img className="icon" src={IcoSuccess}/>
                                <h5 className="title">{t('agencies.add_agent_success')}</h5>
                            </div>
                        </div>
                    </div>
                </Popup>
            }

            <header className="apriv-data-header">
                <h1 className="title">{t('agencies.add_agent')}:</h1>
            </header>

            <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)} className="apriv-data-block form-style site-bg-light">
                    <div className="inputs-group">
                        <div className="column-inputs">
                            <div className="input-wrapper">
                                <label htmlFor="name">{t('words.first_name')}
                                    <span>({t('words.required')})</span></label>
                                <input type="text"
                                       id="first_name"
                                       name="first_name"
                                       ref={methods.register({required: true})}
                                />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="surname">{t('words.last_name')}
                                    <span>({t('words.required')})</span></label>
                                <input type="text"
                                       id="last_name"
                                       name="last_name"
                                       ref={methods.register({required: true})}
                                />
                            </div>
                        </div>
                        <div className="column-inputs">
                            <div className="input-wrapper">
                                <label htmlFor="email">{t('words.email')} <span>({t('words.required')})</span></label>
                                <input type="email"
                                       id="email"
                                       name="email"
                                       ref={methods.register({
                                           required: true,
                                           pattern: {
                                               value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                                               message: "Please enter a valid email."
                                           }
                                       })}/>
                            </div>
                            <div className="input-wrapper">
                                <label
                                    htmlFor="email_confirm">{t('words.email')}<span>({t('words.required')})</span></label>
                                <input type="email"
                                       id="email_confirm"
                                       name="email_confirm"
                                       ref={methods.register({
                                           required: true,
                                           pattern: {
                                               value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                                               message: "Please enter a valid email."
                                           }
                                       })}/>
                            </div>
                        </div>
                    </div>
                    {submit ?
                        <div className="form-footer">
                            <input type="submit" value={t('agencies.add_agent')} className="btn btn-primary"/>
                        </div>
                        :
                        <div className="form-footer">
                            <input type="submit" value={t('agencies.add_agent')} className="btn btn-primary" disabled/>
                        </div>
                    }
                </form>
            </FormProvider>
        </section>
    )
}

const mapStateToProps = function (state) {
    return {
        authToken: state.auth.token
    }
};

export default connect(mapStateToProps, {registerAgent})(withTranslation('common')(AgentRegisteForm));
import {connect, useSelector} from "react-redux";
import {updateStep} from "../../../actions/stepProcess";

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import {CardInput, CardInputProps, createToken, confirmPayment} from '@monei-js/components';
import {returnCurrency} from "../../../services/util";
import {useTranslation} from "react-i18next";
import {red} from "@material-ui/core/colors";
import lock_svg from "../../../static/img/lock.png";
import LockIcon from '@material-ui/icons/Lock';
import {paymentResponse} from "../../../actions/pkgData";
import {addPaymentInfoGTM} from "../../GTMComponent/GTMComponent";

const CardInputComponent: React.FC<CardInputProps> = CardInput.driver('react', {
    React: React,
    ReactDOM: ReactDOM
});

// Custom styling can be passed to options when creating a Card Input Component.
const style = {
    base: {
        height: '44px',
        padding: '0px'
    },
    input: {
        color: '#08A7A6',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#848589'
            //color: '#CCD9D6'
        },
        '-webkit-autofill': {
            backgroundColor: '#FAFFBD'
        },
        ':focus': {
            border: '1px solid #08A7A6'
        },
        padding: '7px',
        border: '1px solid #CCD9D6',
    },
    invalid: {
        color: '#fa755a'
    }
};

const CreditCard = () => {

    const ref = React.useRef();
    const {t, i18n} = useTranslation('common');

    const [isFocused, setFocused] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const monei_id = process.env.REACT_APP_MONEI_ACOUNT_ID;

    const marginBottom = {
        marginBottom:"20px",
    }
    
    return (
        <div className="MuiBox-root jss2 checkout" style={marginBottom}>
            <div className="checkout-content">
                <div className="checkout-elements form-style max-space color-label">
                    <form id="payment-form" className="register-form form-style">
                        <dl className="checkout-box">
                            <dt>
                                <h2 className="title">
                                    <span>{t('creditcard.title')}</span>
                                    {/* <span>Pay with Credit Card</span> */}
                                </h2>
                            </dt>
                            <dd>
                                <div className="input-box">
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input">
                                            <label htmlFor="cardholder">
                                                {t('creditcard.card_holder')}
                                            </label>
                                            <input type="text" name="cardholder" className="form-control"
                                                   id="cardholder"
                                                   placeholder="John Doe" required/>
                                            <div className="invalid-feedback">
                                                {t('creditcard.warning')}
                                            </div>
                                        </div>
                                        {/* <div id="card-input" className={cn({ 'is-focused': isFocused, 'is-invalid': error !== null })}> */}
                                    </div>
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input">
                                            <label htmlFor="cardholder">
                                                {t('creditcard.card_number')}
                                            </label>
                                            <CardInputComponent
                                                ref={ref}
                                                accountId={monei_id}
                                                style={style}
                                                language={i18n.language}
                                                onFocus={() => setFocused(true)}
                                                onBlur={() => setFocused(false)}
                                                onChange={(event) => {
                                                    // Handle real-time validation errors.
                                                    if (event.isTouched && event.error) {
                                                        setError(event.error);
                                                    } else {
                                                        setError(null);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div id="card-error">{error}</div>
                                    </div>

                                </div>
                            </dd>
                        </dl>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null,
    {
        updateStep,
        paymentResponse
    }
)(CreditCard);
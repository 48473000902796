import {
    ADD_LUGGAGE,
    ADD_TRANSFER,
    REMOVE_LUGGAGE,
    REMOVE_TRANSFER,
    ADD_TRANSFER_INFO,
    ADD_LUGGAGE_INFO,
    EXTRAS_IS_DONE,
    ADD_EXTRAS_TOTAL_PRICE,
    EXTRAS_IS_FAIL,
    NO_EXTRAS
} from "../actions/types";

const initialState = {
    transfer_info: {
        state: false,
    },
    luggage_info: {
        state: false,
    },
    no_extras: false,
    extrasIsDone: false,
    extrasIsFail: {
        state: false,
        error: {}
    },
    selected_luggage: [],
    selected_transfer: {},
    total_price: 0,
    currency: "EUR",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_LUGGAGE:
            return {
                ...state,
                selected_luggage: [
                    ...state.selected_luggage,
                    action.payload
                ]
            };
        case REMOVE_LUGGAGE:
            if (action.payload === []) {

                return {
                    ...state,
                    selected_luggage: []
                };
            } else {
                return {
                    ...state,
                    selected_luggage: action.payload
                };
            }
        case ADD_TRANSFER:
            return {
                ...state,
                selected_transfer: action.payload
            };
        case REMOVE_TRANSFER:
            return {
                ...state,
                selected_transfer: []
            };
        case ADD_TRANSFER_INFO:
            return {
                ...state,
                transfer_info: {
                    state: (action.payload?.transfer !== 'false'),
                    payload: action.payload
                }
            }
        case ADD_LUGGAGE_INFO:
            return {
                ...state,
                luggage_info: action.payload
            }
        case EXTRAS_IS_DONE:
            return {
                ...state,
                extrasIsDone: action.payload
            }
        case EXTRAS_IS_FAIL:
            return {
                ...state,
                extrasIsFail: {
                    state: true,
                    error: action.payload
                }
            }
        case ADD_EXTRAS_TOTAL_PRICE:
            return {
                ...state,
                total_price: parseFloat(state.total_price) + parseFloat(action.payload.total_price),
                currency: action.payload.currency,
            }
        case NO_EXTRAS:
            return {
                ...state,
                no_extras: action.payload,
            }
        default:
            return state;
    }
}

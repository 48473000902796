import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";

export default function SelectReasons(props) {

    const {t} = useTranslation('common');
    const {register} = useFormContext();

    const [textOpen, setTextOpen] = useState(false);

    return (
        <>
            <main className="container apriv-page">
                <section className="cancel-rooms-wrapper">
                    <h2 className="h1">{t('msg.reservation_cancel')}: {props.pmskey}</h2>
                    <h3 className="title-sentence">{t('cancelation.cancel_msg')}:</h3>

                    <div className="options-wrapper">
                        <label className="btn">
                            <input name="description" type="radio" value="Cambio en las fechas de los vuelos"
                                   ref={register} onClick={() => {
                                       setTextOpen(false);
                                   }}/> {t('cancelation.msg1')}
                        </label>
                        <label className="btn">
                            <input name="description" type="radio" value="13"
                                   ref={register} onClick={() => setTextOpen(false)}/> {t('cancelation.msg2')}
                        </label>
                        <label className="btn">
                            <input name="description" type="radio" value="14"
                                   ref={register} onClick={() => setTextOpen(false)}/> {t('cancelation.msg3')}
                        </label>
                        <label className="btn">
                            <input name="description" type="radio" value="16"
                                   ref={register} onClick={() => setTextOpen(false)}/> {t('cancelation.msg4')}
                        </label>
                        <label className="btn">
                            <input name="description" type="radio" value="18"
                                   ref={register} onClick={() => setTextOpen(false)}/> {t('cancelation.msg5')}
                        </label>
                        <label className="btn">
                            <input name="description" type="radio" value="15"
                                   ref={register} onClick={() => setTextOpen(false)}/> {t('cancelation.msg6')}
                        </label>
                        <label className="btn">
                            <input name="description" type="radio" value="Reserva Duplicada"
                                   ref={register} onClick={() => setTextOpen(false)}/> {t('cancelation.msg7')}
                        </label>
                        <label className="btn">
                            <input name="description" type="radio" value="19"
                                   ref={register} onClick={() => setTextOpen(false)}/> {t('cancelation.msg9')}
                        </label>

                        <label className="btn">
                            <input type="radio" name="description" value="Otros motivos" ref={register}
                                   onClick={() => setTextOpen(true)}/> {t('cancelation.msg8')}
                        </label>
                        {textOpen ?
                            <div id="cancel_info" className="MuiCollapse-container" styles="display:none">
                                <div className="MuiCollapse-wrapper">
                                    <div className="MuiCollapse-wrapperInner">
                                        <div className="cancel-info">
                                            <p className="title-sentence">{t('cancelation.msg8_1')}</p>
                                            <div className="input-wrapper">
                                                <textarea id="extra-info" name="description" ref={register}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>

                    <button type="submit" className="btn">
                        <span>{t('msg.reservation_cancel')}</span>
                    </button>
                </section>
            </main>

        </>
    )

}
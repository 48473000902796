import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import {removeCart} from "../../services/cart/actions";
import {cartTotalDelete} from "../../services/total/actions";
import {WarningMessage} from "../Dashboard/CallCenterUtils";
import {Redirect} from "react-router-dom";

export class Success extends Component {
    constructor(props) {
        super(props);

        if (typeof props.match.params.hotelname !== 'undefined') {
            this.state = ({
                hotel: props.match.params.hotelname,
                search_query: props.location.search,
            });
            this.props.handleSelectOnThemesInApp(props.match.params.hotelname);
        } else {
            this.state = ({
                hotel: 'spring',
                search_query: props.location.search,
            });
            this.props.handleSelectOnThemesInApp(this.state.hotel);
        }

        this.props.removeCart();
        this.props.cartTotalDelete();
    }


    render() {
        const {t, i18n} = this.props;
        const AGENCY_MODE_ACTIVATED = (process.env.REACT_APP_AGENCY_MODE_ACTIVATED === 'true')

        if(AGENCY_MODE_ACTIVATED && !this.props.isAuthenticated)
            return <Redirect to="/login"/>

        return (
            <React.Fragment>
                <Header hotel={this.state.hotel}/>

                {this.props.isCallCenterActive && <WarningMessage/>}

                <section className="success container">
                    <article className="success-header">

                        <h1>{t('reservation.success')}</h1>
                        <p>¡{t('msg.looking_you')}! <strong>
                            {t('reservation.email_data')}.
                        </strong></p>
                        <p>{t('reservation.reservation_id')}:</p>
                        <p className="h1">{this.props.location.state?.pms_reservations_str}</p>
                        <br/>
                        <p>{t('reservation.status')}:</p>
                        <p className="h1">{this.props.location.state?.status.toUpperCase()}</p>
                        <br/><br/>
                        <a href="/myreservations"
                           className="btn primary">
                            {t('msg.reservation_manage').toUpperCase()}</a>
                    </article>
                    <article className="success-banner" styles="background-image:url(assets/img/banner-vuelos.jpg);">
                        {/*<article className="success-banner" styles={{backgroundImage: `url(${bannerVuelos})`}}>*/}
                        <h2 className="h3">{t('flights.save_money')}</h2>
                        <p className="primary-title-font">{t('flights.msg_save_money')}</p>
                        <br/>
                        <a href="https://springhotels.oceanbooking.com/" target="_blank"
                           className="btn ico-arrow">{t('flights.search')}</a>
                        <br/><br/><br/>
                        <a href="https://springhoteles.com/" target="_blank">
                            {t('msg.see_conditions')}</a>
                    </article>
                </section>

                <Footer hotel={this.state.hotel}/>
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => ({
    isCallCenterActive: state.callcenter.callCenterActive,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(
    mapStateToProps,
    {removeCart, cartTotalDelete}
)(withTranslation('common')(Success));
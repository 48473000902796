import React from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {CustomView, isMobile} from "react-device-detect";
import Popup from 'reactjs-popup';
import CheckBoxSharpIcon from '@material-ui/icons/CheckBoxSharp';

import {addProduct} from '../../../../services/cart/actions';
import {Carousel} from '../../../Carousel';
import {PreviewRoom} from "../../../PreviewRoom/PreviewRoom";
import IcoSuccess from "../../../../static/img/icons/ico-success.svg";

// TODO: Eliminar cantidad de productos

const blockMobile = {
    display: 'block',
};

function addStandarProduct(product, addProduct) {
    product.springclub = false;
    addProduct(product);
}

const Product = ({product, addProduct}) => {
    const isCommissionView = useSelector(state => state.agency.isCommissionView);
    // const commisionvalue = parseFloat(useSelector(state => state.auth.agency.field_descuento_neto_tarifa));
    const commisionvalue = Math.round(useSelector(state => state.auth.agency.descuento));

    // if (closedMarket(
    //     storeRedux.geoip.country_code_iso3,
    //     storeRedux.search.payload.arrival,
    //     storeRedux.search.payload.departure))
    // {
    //     product.availability = 0;
    // }

    const {t} = useTranslation('common');

    return (
        <>
            <CustomView condition={isMobile === true}>
                <button>
                    <dt className="opened show_alltime">
                        <h3 className="title"> {
                            t('boardtypes.' + product.boardtype + '.title').endsWith('title') ?
                                product.boardtype
                                :
                                t('boardtypes.' + product.boardtype + '.title')
                        } </h3>
                        <p className="price primary-title-font">
                            <strong>{product.pricewithoffer.toFixed(2)}{product.currencyFormat}</strong></p>
                        <p className="info">
                        <span className="grey-color">{
                            t('boardtypes.' + product.boardtype + '.description').endsWith('description') ?
                                null
                                :
                                t('boardtypes.' + product.boardtype + '.description')
                        }</span>
                        </p>
                    </dt>
                </button>
                <section>

                    <dd className="show_alltime" style={blockMobile}>
                        <div className="mobile-info">
                            <p className="pension-price-info">
                                <span
                                    className="original-price">{product.pricewithoutoffer.toFixed(2)}{product.currencyFormat}</span>

                                <strong
                                    className="pension-price"> {product.pricewithoffer.toFixed(2)}{product.currencyFormat} </strong>
                            </p>
                            {product.availability <= 0 ?
                                <button className="btn btn-green btn-selected" disabled>
                                    <span className="to-select">{t('result.no_availability')}</span>
                                </button>
                                :
                                <button className="btn btn-green btn-select"
                                        onClick={() => addStandarProduct(product, addProduct)}>
                                    <span className="to-select">{t('result.select')}</span>
                                </button>
                            }
                        </div>

                        <div className="pension-standar">
                            <div className="pension-info">
                                <p><strong className="primary-title-font">{t('msg.discount_applied')}:</strong></p>
                            </div>

                            <p className="pension-price-info">
                                {isCommissionView ?
                                    <>
                                        <strong
                                            className="pension-price"> {((product.pricewithoffer * commisionvalue) / 100).toFixed(2)}{product.currencyFormat} </strong>
                                        <Popup modal trigger={
                                            <a className="ico-info primary-title-font motor-modal-link">{t('msg.discount_applied')}</a>
                                        }>
                                            <div className="motor-modal-box">
                                                <div className="discount-info-wrapper">
                                                    <h3 className="title">{t('msg.discount_applied').toUpperCase()}</h3>
                                                    <p className="discount-info">
                                                        <img className="icon icon-notice" src={IcoSuccess}/>
                                                        <strong>{product.offertext}</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </Popup>
                                    </>
                                    :
                                    <>
                                        {product.offertext && <><strong
                                            className="primary-title-font">{product.offertext} <CheckBoxSharpIcon
                                            style={{color: '#84B440'}}/></strong>
                                            <br/>
                                        </>}
                                    </>
                                }
                            </p>
                        </div>
                    </dd>

                    {product.boardtype === "TIP+UP" &&
                    <>
                        <PreviewRoom product={[product]} isUP={true} isMobile={true}/>
                        <br/><br/>
                        <div className="pension-up-wrapper">
                            <strong className="title">{t('msg.access_boardtype_up')}:</strong>
                            <Carousel product={product} isMobile={true}/>
                        </div>
                    </>
                    }

                </section>

            </CustomView>
            <CustomView condition={isMobile === false}>

                <dt className="opened show_alltime">
                    <h3 className="title"> {
                        t('boardtypes.' + product.boardtype + '.title').endsWith('title') ?
                            product.boardtype
                            :
                            t('boardtypes.' + product.boardtype + '.title')
                    } </h3>
                    <p className="info">
                        <span className="grey-color">{
                            t('boardtypes.' + product.boardtype + '.description').endsWith('description') ?
                                null
                                :
                                t('boardtypes.' + product.boardtype + '.description')
                        }</span>
                    </p>
                </dt>
                <dd className="show_alltime">
                    <div className="pension-club">
                        <p className="pension-price-info">
                            <strong
                                className="pension-price"> {product.pricewithoffer.toFixed(2)}{product.currencyFormat} </strong>
                        </p>
                        {product.availability <= 0 ?
                            <button className="btn btn-green btn-selected" disabled>
                                <span className="to-select">{t('result.no_availability')}</span>
                            </button>
                            :
                            <button className="btn btn-green btn-select"
                                    onClick={() => addStandarProduct(product, addProduct)}>
                                <span className="to-select">{t('result.select')}</span>
                            </button>
                        }
                    </div>

                    <div className="pension-standar">
                        <p className="pension-price-info">
                            {isCommissionView ?
                                <>
                                    <span
                                        className="original-price">{product.pricewithoutoffer.toFixed(2)}{product.currencyFormat}</span>
                                    <strong
                                        className="pension-price"> {((product.pricewithoffer * commisionvalue) / 100).toFixed(2)}{product.currencyFormat} </strong>
                                    <Popup modal trigger={
                                        <a className="ico-info primary-title-font motor-modal-link">{t('msg.discount_applied')}</a>
                                    }>
                                        <div className="motor-modal-box">
                                            <div className="discount-info-wrapper">
                                                <h3 className="title">{t('msg.discount_applied').toUpperCase()}</h3>
                                                <p className="discount-info">
                                                    <img className="icon icon-notice" src={IcoSuccess}/>
                                                    <strong>{product.offertext}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </Popup>
                                </>
                                :
                                <>
                                    {product.offertext && <><strong
                                        className="primary-title-font">{product.offertext} <CheckBoxSharpIcon
                                        style={{color: '#84B440'}}/></strong>
                                        <br/>
                                    </>}
                                </>
                            }
                        </p>

                    </div>
                </dd>

                {product.boardtype === "TIP+UP" &&
                <>
                    <PreviewRoom product={[product]} isUP={true}/>
                    <div className="pension-up-wrapper">
                        <strong className="title">{t('msg.access_boardtype_up')}:</strong>
                        <Carousel product={product}/>
                    </div>
                </>
                }

            </CustomView>
        </>
    );
};

Product.propTypes = {
    product: PropTypes.object.isRequired,
    addProduct: PropTypes.func.isRequired
};

export default connect(
    null,
    {addProduct}
)(Product);

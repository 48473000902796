import {connect, useSelector} from "react-redux";
import {updateStep} from "../../../actions/stepProcess";

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import {CardInput, CardInputProps, createToken, confirmPayment} from '@monei-js/components';
import {returnCurrency} from "../../../services/util";
import {useTranslation} from "react-i18next";
import {red} from "@material-ui/core/colors";
import lock_svg from "../../../static/img/lock.png";
import LockIcon from '@material-ui/icons/Lock';
import {paymentResponse} from "../../../actions/pkgData";
import {addPaymentInfoGTM} from "../../GTMComponent/GTMComponent";
import Resume from "./Resume";

const CardInputComponent: React.FC<CardInputProps> = CardInput.driver('react', {
    React: React,
    ReactDOM: ReactDOM
});

// Custom styling can be passed to options when creating a Card Input Component.
const style = {
    base: {
        height: '44px',
        padding: '0px'
    },
    input: {
        color: '#08A7A6',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#848589'
            //color: '#CCD9D6'
        },
        '-webkit-autofill': {
            backgroundColor: '#FAFFBD'
        },
        ':focus': {
            border: '1px solid #08A7A6'
        },
        padding: '7px',
        border: '1px solid #CCD9D6',
    },
    invalid: {
        color: '#fa755a'
    }
};

const Payment = ({updateStep, paymentResponse}) => {

    const ref = React.useRef();
    const {t, i18n} = useTranslation('common');

    const [isFocused, setFocused] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [click, setClick] = React.useState(false);
    const [error, setError] = React.useState(null);
    const payment_id = useSelector(state => state.payment.payment_id);
    const payment = useSelector(state => state.payment);
    const session_token = useSelector(state => state.search.token);
    const monei_id = process.env.REACT_APP_MONEI_ACOUNT_ID;
    const monei_test = process.env.REACT_APP_PASS_CHECKOUT;

    function moneiTokenHandler(token, card_name) {
        new addPaymentInfoGTM();
        return confirmPayment({
            paymentId: payment_id,
            paymentToken: token,
            paymentMethod: {card: {cardholderName: card_name}}
        })
            .then(function (result) {
                // At this moment you can show a customer the payment result
                // But you should always rely on the result passed to the callback endpoint on your server
                // to update the order status
                if (result.status === "SUCCEEDED") {
                    paymentResponse(session_token);
                }
                console.log(result);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setClick(true);
        setLoading(true);
        let card_name = event.target.cardholder.value;
        event.preventDefault();
        // Pass current CardInputComponent ref to create token
        const result = await createToken(ref.current);
        setLoading(false);
        if (result.error) {
            // Inform the user if there was an error.
            setError(result.error);
        } else {
            setError(null);
            // Send the token to your server.
            return moneiTokenHandler(result.token, card_name);
        }

    };

    return (
        <div className="MuiBox-root jss2 checkout">
            <Resume/>
            {monei_test === "true" &&
                <div className="checkout-content">
                    <div className="checkout-elements form-style max-space color-label">
                        <dl className="checkout-box">
                            <dt>
                                <h2 className="title">
                                    <span>Modo test</span>
                                </h2>
                            </dt>
                            <dd>
                                <p>En modo test poner una de las siguientes tarjetas:</p>

                                {/* <table className="table-test">
                                <tr>
                                    <td><strong>Numero de tarjeta:</strong> XXXXXX</td>
                                    <td><strong>MM/AA:</strong> 11/22</td>
                                    <td><strong>CVC:</strong> 777</td>
                                </tr>
                            </table> */}
                                <p><strong>Número de tarjeta:</strong> 4444444444444406 (Visa)</p>
                                <p><strong>Número de tarjeta:</strong> 5555555555555508 (Mastercard)</p>
                                <p>Para todos los números de tarjeta de prueba, use <strong>12/34</strong> como fecha de
                                    vencimiento y <strong>123</strong> como código CVC</p>
                            </dd>
                        </dl>
                    </div>
                </div>
            }

            <div className="checkout-content">
                <div className="checkout-elements form-style max-space color-label">
                    <form id="payment-form" onSubmit={handleSubmit} className="register-form form-style">
                        <dl className="checkout-box">
                            <dt>
                                <h2 className="title">
                                    <span>{t('creditcard.title')}</span>
                                    {/* <span>Pay with Credit Card</span> */}
                                </h2>
                            </dt>
                            <dd>
                                <div className="input-box">
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input">
                                            <label htmlFor="cardholder">
                                                {t('creditcard.card_holder')}
                                            </label>
                                            <input type="text" name="cardholder" className="form-control"
                                                   id="cardholder"
                                                   placeholder="John Doe" required/>
                                            <div className="invalid-feedback">
                                                {t('creditcard.warning')}
                                            </div>
                                        </div>
                                        {/* <div id="card-input" className={cn({ 'is-focused': isFocused, 'is-invalid': error !== null })}> */}
                                    </div>
                                    <div className="column-inputs">
                                        <div className="input-wrapper simple-input">
                                            <label htmlFor="cardholder">
                                                {t('creditcard.card_number')}
                                            </label>
                                            <CardInputComponent
                                                ref={ref}
                                                accountId={monei_id}
                                                style={style}
                                                language={i18n.language}
                                                onFocus={() => setFocused(true)}
                                                onBlur={() => setFocused(false)}
                                                onChange={(event) => {
                                                    // Handle real-time validation errors.
                                                    if (event.isTouched && event.error) {
                                                        setError(event.error);
                                                    } else {
                                                        setError(null);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div id="card-error">{error}</div>
                                    </div>

                                    <div className="add-flights-select-wrapper">
                                        <span className="add-flights-text">
                                            <strong>{t('agencies.payment_gateway_msg_title')}
                                             </strong>
                                            <br/>
                                            {t('agencies.payment_gateway_msg_text')}
                                        </span>
                                    </div>

                                </div>
                            </dd>
                        </dl>

                        <button type="submit" disabled={click} className="final-btn" id="pay">
                            <LockIcon/>&nbsp;
                            <span>{t('words.paid') + " " + payment.amount / 100 + " " + returnCurrency(payment.currency)}</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default connect(null,
    {
        updateStep,
        paymentResponse
    }
)(Payment);
import React, { Component, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { addLuggage, addTaxi, removeTaxi, removeLuggage } from "../../../actions/extras";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import AirlineLogo from "../Flights/AirlineLogo";
import { format } from 'date-fns';
import Collapse from "@material-ui/core/Collapse/Collapse";
import { returnCurrency } from "../../../services/util";
import Timer from "../../EngineResult/Timer/Timer";
import { isMobile } from "react-device-detect";

export default function CartMobile() {

    const { t, i18n } = useTranslation('common');
    const flight = useSelector(state => state.flights.selected_flight);
    const flights = useSelector(state => state.flights);
    const search = useSelector(state => state.search.payload);
    const outbound_last_stop = flight[1].stops;
    const inbound_last_stop = flight[0].stops;
    const flightsActive = useSelector(state => state.flights.isActive);
    const products = useSelector(state => state.shelf.products);
    const cart = useSelector(state => state.cart.products);
    const product = useSelector(state => state);
    const cartTotal = useSelector(state => state.total.data);
    const pkgData = useSelector(state => state.pkgData);
    const extras = useSelector(state => state.extras);
    const [isCartOpen, setIsCartOpen] = useState(true);
    const [isOpenFlights, setIsOpenFlights] = useState(true);
    const [isOpenRoom, setIsOpenRoom] = useState(true);
    const [isOpenExtras, setIsOpenExtras] = useState(true);


    const display = {
        display: "flex"
    }

    const adults = parseInt(search.adults);
    const childs = parseInt(search.room_num_childs);

    //Funcion que calcula la diferencia en noches entre dos fechas
    const nightDiff = (dateString1, dateString2) => {
        let date1 = new Date(dateString1);
        let date2 = new Date(dateString2);
        let nightDifference = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
        return nightDifference;
    }

    const childs_ages = (t, reservation) => {
        let children_text = "(";

        if (parseInt(reservation?.room_num_childs) === 1) {
            // Controlar el singular y el plural
            if (parseInt(reservation.children_ages) !== 1) {
                return "(" + reservation.children_ages + " " + t('search.years') + ")";
            } else if (parseInt(reservation.children_ages) === 1) {
                return "(" + reservation.children_ages + " " + t('search.year') + ")";
            }
        } else if (parseInt(reservation?.room_num_childs) > 1) {

            reservation?.children_ages?.map((children_age, index) => {
                if (index === reservation.children_ages.length - 1) {
                    // Controlar el singular y el plural
                    if (parseInt(children_age) !== 1) {
                        children_text += children_age + " " + t('search.years') + "), ";
                    } else if (parseInt(children_age) === 1) {
                        children_text += children_age + " " + t('search.year') + "), ";
                    }
                } else {
                    children_text += children_age + ", ";
                }
            });

            return children_text;
        }
    };

    return <dl className="checkout-box itinerary-data">

        <dt className="close" onClick={() => {
            setIsCartOpen(!isCartOpen)
        }}>
            <h2 className="title">

                {/* TODO Traduccion */}
                <span>{t('cart.your_reservation')}</span>
            </h2>
        </dt>
        <Collapse in={isCartOpen} timeout="auto" unmountOnExit>
            <div className="cart-content">
                <dd className="plegable">
                    <div className="MuiCollapse-container" styles="display:none">
                        <div className="MuiCollapse-wrapper">
                            <div className="MuiCollapse-wrapperInner">
                                <div className="itinerary-step place" onClick={() => { setIsOpenFlights(!isOpenFlights) }}>
                                    <table>
                                        <tr>
                                            <td className="title"><strong>Vuelos</strong> (2 pasajeros)</td>
                                            <td><strong>{isOpenFlights ? t('cart.see_less') : t('cart.see_more')}</strong></td>
                                        </tr>
                                    </table>
                                </div>
                                <Collapse in={isOpenFlights} timeout="auto" unmountOnExit>
                                    <div style={display}>
                                        {/*Vuelo de ida*/}
                                        <table className="cart-flight">
                                            <tbody>
                                                <tr>
                                                    <th className="type" colSpan="2">
                                                        <span>{t('cart.outbound')}</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td className="airline">
                                                        <AirlineLogo airline_code={flight[1].flight_segment[0].airlineCode}
                                                            airline_name={flight[1].flight_segment[0].airlineName} />
                                                    </td>
                                                </tr>
                                                <tr className="flight-outbound">
                                                    {/* TODO Traduccion*/}
                                                    <td className="date">
                                                        <td className="date">{format(new Date(flight[1].flight_segment[0].departureDate), "dd/MM/yyyy")}</td>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="airports">
                                                        <p>
                                                            <strong>{flight[1].flight_segment[0].departureTime}</strong>&nbsp;
                                                            {flight[1].flight_segment[0].departureCityName} - {flight[1].flight_segment[0].departureAirport} ({flight[1].flight_segment[0].departureCode})
                                                        </p>
                                                        <p>
                                                            <strong>{flight[1].flight_segment[outbound_last_stop].arrivalTime}</strong>&nbsp;
                                                            {flight[1].flight_segment[outbound_last_stop]?.arrivalCityName} - {flight[1].flight_segment[outbound_last_stop]?.arrivalAirport} ({flight[1].flight_segment[outbound_last_stop]?.arrivalCode})
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {flight[1].flight_segment.length === 1 ?
                                                        <td className="stops"><strong>{t("itinerary.direct")}</strong></td>
                                                        :
                                                        <td className="stops">
                                                            {flight[1].flight_segment.length - 1 === 1 ?
                                                                <strong>{flight[1].flight_segment.length - 1} {t("itinerary.stop")}</strong>
                                                                :
                                                                <strong>{flight[1].flight_segment.length - 1} {t("itinerary.stops")}</strong>
                                                            }
                                                        </td>
                                                    }
                                                </tr>
                                                <tr>
                                                    {!flight[1].luggages_included ?
                                                        <td className="luggage"><strong>{t('flights.luggage_no_included')}</strong></td>
                                                        :
                                                        <td className="luggage"><strong>{t('flights.luggage_included')}</strong></td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="see-more">

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/*Vuelo de vuelta*/}
                                        <table className="cart-flight">
                                            <tbody>
                                                <tr>
                                                    <th className="type" colSpan="2">
                                                        {t('cart.inbound')}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td className="airline">
                                                        <AirlineLogo airline_code={flight[1].flight_segment[0].airlineCode}
                                                            airline_name={flight[1].flight_segment[0].airlineName} />
                                                    </td>
                                                </tr>
                                                <tr className="flight-inbound">
                                                    {/* TODO Traduccion*/}
                                                    <td className="date">{format(new Date(flight[0].flight_segment[0].departureDate), "dd/MM/yyyy")}</td>
                                                </tr>
                                                <tr>
                                                    <td className="airports">
                                                        <p>
                                                            <strong>{flight[0].flight_segment[0].departureTime}</strong>&nbsp;
                                                            {flight[0].flight_segment[0].departureCityName} - {flight[0].flight_segment[0].departureAirport} ({flight[0].flight_segment[0].departureCode})
                                                        </p>
                                                        <p>
                                                            <strong>{flight[0].flight_segment[inbound_last_stop].arrivalTime}</strong>&nbsp;
                                                            {flight[0].flight_segment[inbound_last_stop]?.arrivalCityName} - {flight[0].flight_segment[inbound_last_stop]?.arrivalAirport} ({flight[0].flight_segment[inbound_last_stop]?.arrivalCode})
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    {flight[0].flight_segment.length === 1 ?
                                                        <td className="stops"><strong>{t("itinerary.direct")}</strong></td>
                                                        :
                                                        <td className="stops">
                                                            {flight[0].flight_segment.length - 1 === 1 ?
                                                                <strong>{flight[0].flight_segment.length - 1} {t("itinerary.stop")}</strong>
                                                                :
                                                                <strong>{flight[0].flight_segment.length - 1} {t("itinerary.stops")}</strong>
                                                            }
                                                        </td>
                                                    }
                                                </tr>
                                                <tr>
                                                    {!flight[0].luggages_included ?
                                                        <td className="luggage"><strong>{t('flights.luggage_no_included')}</strong></td>
                                                        :
                                                        <td className="luggage"><strong>{t('flights.luggage_included')}</strong></td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="see-more">

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </dd>
                <dd className="plegable">
                    <div className="dd room-list">
                        <div className="cart-room-header" onClick={() => { setIsOpenRoom(!isOpenRoom) }}>
                            <table>
                                <tr>
                                    <td><h5>{t('spring.room')} { }</h5></td>
                                    <td><strong>{isOpenRoom ? t('cart.see_less') : t('cart.see_more')}</strong></td>
                                </tr>
                            </table>
                            {!flightsActive &&
                                <p className="price">

                                    <strong className="final" title="final">
                                        {product.pricewithoffer.toFixed(2)}{product.currencyFormat}
                                    </strong>
                                </p>
                            }
                        </div>
                        <Collapse in={isOpenRoom} timeout="auto" unmountOnExit>
                            <div className="cart-room-info">
                                <p>
                                    <strong>{cart[0].room_title}</strong> - {cart[0].hotel_name}
                                </p>
                                <p>
                                    {cart[0].boardtype_text}
                                </p>
                                <p>
                                    <strong>{format(new Date(search.arrival), "dd/MM/yyyy")}</strong> {t('words.to')}
                                    <strong> {format(new Date(search.departure), "dd/MM/yyyy")}</strong>
                                </p>
                                <p>{nightDiff(search.arrival, search.departure)} {t('search.nights')}</p>
                                <p>{t('person.adults').charAt(0).toUpperCase() + t('person.adults').slice(1)}: {search.adults}</p>
                                {search.room_num_childs !== "0" ?
                                    <p>{t('person.children').charAt(0).toUpperCase() + t('person.children').slice(1)}: {search.room_num_childs}</p>
                                    // <p>{t('person.children').charAt(0).toUpperCase() + t('person.children').slice(1)}: {product.reservation?.room_num_childs}</p>
                                    : null
                                }
                            </div>
                        </Collapse>
                        <div className="promo-banner">
                            <p>
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.33301 8.39889V3.08331C1.33301 2.39295 1.89264 1.83331 2.58301 1.83331H7.89858C8.2301 1.83331 8.54805 1.96501 8.78246 2.19943L14.3002 7.7172C14.7884 8.20535 14.7884 8.99681 14.3002 9.48496L8.98465 14.8005C8.4965 15.2887 7.70504 15.2887 7.21689 14.8005L1.69913 9.28277C1.46471 9.04835 1.33301 8.73041 1.33301 8.39889H1.33301ZM4.24968 3.49998C3.55931 3.49998 2.99968 4.05962 2.99968 4.74998C2.99968 5.44035 3.55931 5.99998 4.24968 5.99998C4.94004 5.99998 5.49968 5.44035 5.49968 4.74998C5.49968 4.05962 4.94004 3.49998 4.24968 3.49998Z"
                                        fill="currentColor"></path>
                                </svg>
                                <span>{t('cart.discount')}</span>
                            </p>
                            {/* El descuento esta comentado porque en paquetes no se muestran los precios por separado */}
                            {/*<p className="discount-price">{flight_discount}</p>*/}
                        </div>

                    </div>
                </dd>
                <dd className="plegable">
                    <div className="dd extras-list">
                        <table className="extras-list-header" onClick={() => { setIsOpenExtras(!isOpenExtras) }}>
                            <tr className="header-info">
                                <td className="title"><strong>{t('result.extras')} </strong></td>
                                <td className="price"><strong>{parseFloat(extras.total_price)} {returnCurrency(extras.currency)}</strong></td>
                            </tr>
                            <tr>
                                <td colspan="2"><strong>{isOpenExtras ? t('cart.see_less') : t('cart.see_more') }</strong></td>
                            </tr>
                        </table>

                        <Collapse in={isOpenExtras} timeout="auto" unmountOnExit>
                            <table>
                            {extras.selected_luggage.map((luggage) => {
                                return (
                                    <tr className="cart-extra">
                                        <td>{luggage.flight_type === "OUTBOUND" ? t('cart.outbound_luggage') : t('cart.inbound_luggage')} {parseInt(luggage.passengerId) + 1}</td>
                                        {luggage.pricingDetail === 0 ?
                                            <td className="price">{t('words.included')}</td>
                                            :
                                            <td className="price"><strong>{parseFloat(luggage.pricingDetail).toFixed(2)} {returnCurrency("EUR")}</strong></td>
                                        }
                                    </tr>
                                )
                            })}
                            </table>

                            {extras.selected_transfer.state &&
                                <div className="cart-extra">
                                    <p className="title">Taxi transfer Tenerife Sur</p>
                                    {extras.transfer_info.price == 0 ?
                                        <p className="price">{t('words.included')}</p>
                                        :
                                        <p className="price"><strong>{extras.selected_transfer.price} {returnCurrency(extras.selected_transfer.currency)}</strong></p>
                                    }
                                </div>
                            }
                        </Collapse>
                    </div>
                </dd>

                <dd className="plegable">
                    {(flightsActive && flight != null) ?
                        <table className="process-cart-content">
                            <tbody>
                                <tr className="cart-price">
                                    <th><h5>{t('cart.final_price')}</h5></th>
                                    <td>
                                        <p className="price">
                                            <span className="old" title="sin descuento">
                                                {parseFloat(parseFloat(cartTotal.totalPriceWithoutOffer) + flights.totalFlightsPrice).toFixed(2)} {returnCurrency(product[0]?.currencyid)}
                                            </span>
                                            <strong className="final" title="final">
                                                {pkgData.total_price} {returnCurrency(product[0]?.currencyid)}
                                            </strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr className="cart-price">
                                    <th><h5>{t('cart.pay_hotel')}</h5></th>
                                    <td>
                                        <p className="price"><strong
                                            className="pay-hotel">
                                            {pkgData.pay_in_hotel} {returnCurrency(product[0]?.currencyid)}
                                        </strong></p>
                                    </td>
                                </tr>
                                <tr className="cart-price">
                                    <th><h5>{t('cart.pay_now')} <span>({t('cart.no_reembolsable')})</span></h5></th>
                                    <td>
                                        <p className="price"><strong
                                            className="pay-hotel">
                                            {pkgData.pay_now} {returnCurrency(product[0]?.currencyid)}
                                        </strong></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        :
                        <div className="cart-price">
                            <h5>{t('cart.final_price')}</h5>
                            <p className="price">
                                <strong className="final" title="final">
                                </strong>
                            </p>
                        </div>
                    }
                    <Timer />
                </dd>
            </div>
        </Collapse>
    </dl>
}
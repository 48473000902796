
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { format } from 'date-fns';
import AirlineLogo from '../../EngineResult/Flights/AirlineLogo';
import PopUpInfo from "../../EngineResult/Flights/PoUpInfo";
import Popup from "reactjs-popup";
import { flexbox } from '@chakra-ui/react';
import { isMobile } from "react-device-detect";
import Collapse from '@material-ui/core/Collapse';
import {toggleFlightCart} from "../../../services/cart/actions";

class CartFlights extends Component {

    openCart = () => {
        this.props.toggleFlightCart(!this.props.cart_flights);
    };

    render() {

        const { flight, t, cart_flights, toggleFlightCart } = this.props;
        const adults = parseInt(this.props.search.adults);
        const children = (this.props.search.room_num_childs != undefined ? parseInt(this.props.search.room_num_childs) : 0);
        const outbound_last_stop = flight[1].stops;
        const inbound_last_stop = flight[0].stops;


        const display = {
            display: "flex"
        }
        
        return (
            <>
                <div className="flights-list-header" onClick={this.openCart}>
                    <div className="header-info">
                        <p className="title">
                            <strong>{t('cart.flights')}</strong> ({adults + children} {t('cart.passengers')})
                        </p>
                        <p><strong>{cart_flights ? t('cart.see_less') : t('cart.see_more')}</strong></p>
                    </div>
                </div>


                    <Collapse in={cart_flights}
                    timeout="auto" unmountOnExit>
                        <div style={display}>
                            {/*Vuelo de ida*/}
                            <table className="cart-flight">
                                <tbody>
                                    <tr>
                                        <th className="type" colSpan="2">
                                            <span>{t('cart.outbound')}</span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="airline">
                                            <AirlineLogo airline_code={flight[1].flight_segment[0].airlineCode} airline_name={flight[1].flight_segment[0].airlineName} />
                                        </td>
                                    </tr>
                                    <tr className="flight-outbound">
                                        {/* TODO Traduccion*/}
                                        <td className="date">{format(new Date(flight[1].flight_segment[0].departureDate), "dd/MM/yyyy")}</td>

                                    </tr>
                                    <tr>
                                        <td className="airports">
                                            <p>
                                                <strong>{flight[1].flight_segment[0].departureTime}</strong>&nbsp;
                                                {flight[1].flight_segment[0].departureCityName} - {flight[1].flight_segment[0].departureAirport} ({flight[1].flight_segment[0].departureCode})
                                            </p>
                                            <p>
                                                <strong>{flight[1].flight_segment[outbound_last_stop].arrivalTime}</strong>&nbsp;
                                                {flight[1].flight_segment[outbound_last_stop]?.arrivalCityName} - {flight[1].flight_segment[outbound_last_stop]?.arrivalAirport} ({flight[1].flight_segment[outbound_last_stop]?.arrivalCode})
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        {flight[1].flight_segment.length === 1 ?
                                            <td className="stops"><strong>{t("itinerary.direct")}</strong></td>
                                            :
                                            <td className="stops">
                                                {flight[1].flight_segment.length - 1 === 1 ?
                                                    <strong>{flight[1].flight_segment.length - 1} {t("itinerary.stop")}</strong>
                                                    :
                                                    <strong>{flight[1].flight_segment.length - 1} {t("itinerary.stops")}</strong>
                                                }
                                            </td>
                                        }
                                    </tr>
                                    <tr>
                                        {!flight[1].luggages_included ?
                                            <td className="luggage"><strong>{t('flights.luggage_no_included')}</strong></td>
                                            :
                                            <td className="luggage"><strong>{t('flights.luggage_included')}</strong></td>
                                        }
                                    </tr>
                                    <tr>
                                        <td className="see-more">
                                            <Popup
                                                trigger={
                                                    <a href="#" className="info-link">
                                                        {t("details.see_details_and_rate")}
                                                    </a>
                                                }
                                                modal
                                                nested
                                            >{close => (
                                                <PopUpInfo
                                                    close={close}
                                                    stops={flight.stops}
                                                    flight_segment={flight[1].flight_segment}
                                                    outbound={false}
                                                    luggages_included={flight[1].luggages_included} />
                                            )}
                                            </Popup>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            {/*Vuelo de vuelta*/}
                            <table className="cart-flight">
                                <tbody>
                                    <tr>
                                        <th className="type" colSpan="2">
                                            {t('cart.inbound')}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="airline">
                                            <AirlineLogo airline_code={flight[0].flight_segment[0].airlineCode} airline_name={flight[0].flight_segment[0].airlineName} />
                                        </td>
                                    </tr>
                                    <tr className="flight-inbound">
                                        {/* TODO Traduccion*/}
                                        <td className="date">{format(new Date(flight[0].flight_segment[0].departureDate), "dd/MM/yyyy")}</td>
                                    </tr>
                                    <tr>
                                        <td className="airports">
                                            <p>
                                                <strong>{flight[0].flight_segment[0].departureTime}</strong>&nbsp;
                                                {flight[0].flight_segment[0].departureCityName} - {flight[0].flight_segment[0].departureAirport} ({flight[0].flight_segment[0].departureCode})
                                            </p>
                                            <p>
                                                <strong>{flight[0].flight_segment[inbound_last_stop].arrivalTime}</strong>&nbsp;
                                                {flight[0].flight_segment[inbound_last_stop]?.arrivalCityName} - {flight[0].flight_segment[inbound_last_stop]?.arrivalAirport} ({flight[0].flight_segment[inbound_last_stop]?.arrivalCode})
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        {flight[0].flight_segment.length === 1 ?
                                            <td className="stops"><strong>{t("itinerary.direct")}</strong></td>
                                            :
                                            <td className="stops">
                                                {flight[0].flight_segment.length - 1 === 1 ?
                                                    <strong>{flight[0].flight_segment.length - 1} {t("itinerary.stop")}</strong>
                                                    :
                                                    <strong>{flight[0].flight_segment.length - 1} {t("itinerary.stops")}</strong>
                                                }
                                            </td>
                                        }
                                    </tr>
                                    <tr>
                                        {!flight[0].luggages_included ?
                                            <td className="luggage"><strong>{t('flights.luggage_no_included')}</strong></td>
                                            :
                                            <td className="luggage"><strong>{t('flights.luggage_included')}</strong></td>
                                        }
                                    </tr>
                                    <tr>
                                        <td className="see-more">
                                            <Popup
                                                trigger={
                                                    <a href="#">{t("details.see_details_and_rate")}</a>
                                                }
                                                modal
                                                nested
                                            >{close => (
                                                <PopUpInfo
                                                    close={close}
                                                    stops={flight.stops}
                                                    flight_segment={flight[0].flight_segment}
                                                    outbound={false}
                                                    luggages_included={flight[0].luggages_included} />
                                            )}
                                            </Popup>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Collapse>
                
            </>
        );
    }
}

const mapStateToProps = state => ({
    flight: state.flights.selected_flight,
    search: state.search.payload,
    cartProducts: state.cart.products,
    cart_flights: state.cart.cart_flights,
});

export default connect(mapStateToProps, {toggleFlightCart})(withTranslation('common')(CartFlights));
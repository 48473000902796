import React, {Component} from "react";
import PropTypes, {instanceOf} from "prop-types";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {loginCallCenter} from "../../actions/auth";
import Spinner from "../Spinner";
import {Cookies, withCookies} from "react-cookie";


export class LoginCallCenter extends Component {
    state = {
        username: "",
        password: "",
        customer_email: "",
    };

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
        cookies: instanceOf(Cookies).isRequired
    };

    onSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        });
        this.props.loginCallCenter(this.state.username, this.state.password, this.state.customer_email, this.props.cookies);
    };

    onChange = e => this.setState({[e.target.name]: e.target.value});

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isLoginRedux !== this.props.isLoginRedux) {
            if (!this.props.isLoginRedux) {
                this.setState({
                    isLoading: false
                });
            }
        }
    }

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/myreservations"/>
        }
        const {username, password, customer_email} = this.state;

        const {t} = this.props;

        return (
            <React.Fragment>
                <article className="login-form-wrapper">
                    <h2 className="h1 title">CONTACT CENTRE</h2>
                    <form className="login-form form-style" onSubmit={this.onSubmit}>
                        <div className="input-wrapper">
                            <label htmlFor="user">E-mail | Spring Club</label>
                            <input type="text" id="username" name="username"
                                   placeholder={t('msg.input_email_spring')}
                                   value={username}
                                   onChange={this.onChange}
                                   required
                            />
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="password">{t('bloque_azul_contrasena.contrasena')}</label>
                            <input type="password" id="password" name="password"
                                   placeholder={t('msg.input_password')}
                                   value={password}
                                   onChange={this.onChange}
                                   required
                            />
                        </div>
                        <br/><br/><br/>
                        <div className="input-wrapper">
                            <label htmlFor="user">E-mail del Cliente</label>
                            <input type="text" id="customer_email" name="customer_email"
                                   placeholder="customer@customer.com"
                                   value={customer_email}
                                   onChange={this.onChange}
                                   required
                            />
                        </div>

                        {this.state.isLoading ?
                            <>
                                <Spinner step={"search"}/>
                                <input className="btn btn-primary full-weidth" type="submit" value={t('words.login')}
                                       disabled/>
                            </>
                            :
                            <input className="btn btn-primary full-weidth" type="submit" value={t('words.login')}/>
                        }
                    </form>
                </article>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoginRedux: state.auth.isLoading
});

export default connect(
    mapStateToProps, {loginCallCenter}
)(withCookies(withTranslation('common')(LoginCallCenter)));
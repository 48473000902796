import {
    SEARCH,
    GET_TOKEN,
    SET_TOKEN_EXPIRATION_TIME,
    SET_MULTIHOTEL,
    SET_EXPIRATION_TIME,
    SET_COUNTRY_DATA,
    SET_IPSTACK_DATA,
} from './types';
import axios from "axios";
import {dingusRooms} from "../services/shelf/actions";
import {GetFlightsAPI} from "../services/flights/actions";
import {removeCart, toggleFlightCart, toggleHotelCart} from "../services/cart/actions";
import {setTemporalPkgId} from "./pkgData";
import {invalidToken, tokenConfig} from "./auth";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;
const API_URL = process.env.REACT_APP_PACKAGE_API_URL;

export const createSearch = searchdata => {
    return {
        type: SEARCH,
        payload: searchdata,
    };
};

export const setToken = (token) => ({type: GET_TOKEN, payload: token})

export const getToken = (search, active_flights, isAuthenticated, lang = "en") => async (dispatch, getState) => {
    axios.post(BASEURL + API_URL, {}, tokenConfig(getState))
        .then(function (response) {
            dispatch(invalidToken(false));
            dispatch({
                type: GET_TOKEN,
                payload: response.data.token,
            })
            if(active_flights){
                dispatch(removeCart())
                dispatch(GetFlightsAPI(search, response.data.token))
            }
            dispatch(dingusRooms(search, response.data.token, isAuthenticated, active_flights, lang))
            dispatch(setTemporalPkgId("TMP-" + response.data.package))
            window.localStorage.setItem('session_token', response.data.token);
            window.localStorage.setItem('temporal_pkg_id', "TMP-" + response.data.package);
        })
        .catch(function (error) {
            if(error.response.status === 401){
                if(error.response.data.detail === 'Invalid token.'){
                    dispatch(invalidToken(true));
                }
            }
            console.log(error.response.data);
            return error;
        });
};

export const setTokenExpirationTime = (time) => {
    return {type: SET_TOKEN_EXPIRATION_TIME, payload: time}
};

export const setMultiHotel = (payload) => {
    return {type: SET_MULTIHOTEL, payload: payload}
};

export const setExpirationTime = (expirated) => {
    return {type: SET_EXPIRATION_TIME, payload: expirated}
};

export const setIpStackData = (payload) => {
    return {type: "SET_IPSTACK_DATA", payload: payload}
};
import {ADD_TRAVELERS} from "../actions/types";

export default function (state = {}, action) {
    switch (action.type) {
        case ADD_TRAVELERS:
            return {
                payload: action.payload
            };
        default:
            return state;
    }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import queryString from "query-string";

import Box from '@material-ui/core/Box';

import {
    fetchExtraProducts,
    dingusRooms
} from '../../services/shelf/actions';
import {getFlights, GetFlightsAPI, setActivateFlights} from '../../services/flights/actions';
import {createSearch, getToken, setIpStackData, setMultiHotel, setToken} from "../../actions/search";
import {updateStep} from "../../actions/stepProcess";

import Spinner from '../Spinner';
import RoomList from "./RoomList";
import RoomListAgency from "./RoomListAgency";
import Cart from "../Cart";
import i18next from "i18next";
import {impressionsGTM} from "../GTMComponent/GTMComponent";
import BookingProcess from "./BookingProcess/BookingProcess";
import Flights from "./Flights/Flights";
import Extras from "./Extras/Extras";
import Login from "./Login/Login";
import ReserveForm from "./ReserveForm/ReserveForm";
import Payment from "./Payment/Payment";
import {withTranslation} from "react-i18next";
import {calculateTotalPrice, setTemporalPkgId} from "../../actions/pkgData";
import PopUpError from "./PopUpError/PopUpError";
import CardComp from "./MultiHotel/CardComp";
import {InfoBanner} from "./InfoBanner";
import Success from "./Success/Success";
import Failure from "./Success/Failure";
import axios from "axios";
import Filters from "./Filters/Filters";
import {FaFilter} from 'react-icons/fa';
import {removeCart, toggleFlightCart, toggleHotelCart} from "../../services/cart/actions";
import pkgData from "../../reducers/pkgData";
import { isMobile, isMobileOnly, isTablet, isDesktop } from "react-device-detect";
import {invalidToken} from "../../actions/auth";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;
const INTERNAL = process.env.REACT_APP_INTERNAL;
const AGENCIES = process.env.REACT_APP_AGENCY_MODE_ACTIVATED;

class EngineResult extends Component {

    static propTypes = {
        fetchProducts: PropTypes.func,
        fetchExtraProducts: PropTypes.func,
        filters: PropTypes.array,
        sort: PropTypes.string
    };

    state = {
        productsReceived: 0,
        countryReceived: undefined,
        count: 0,
        active_flights: false,
        multihotel: false,
    };

    getCountry = async () => {
        const api_ipstack = process.env.REACT_APP_IPSTACK_API;
        axios.get("https://api.ipstack.com/check?access_key=" + api_ipstack, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(res => {
            this.props.setIpStackData(res.data);
        }).catch(err => {
            console.log(err);
        });
    };

    checkToken(session_token, query_parsed, state = this.state, props = this.props) {
        axios.post(BASEURL + "/packages-api/packages/get_package_data/", {token: session_token})
            .then(function (response) {
                props.invalidToken(false);
                if (response.data.error !== undefined) {
                    props.getToken(query_parsed, state.active_flights, props.isAuthenticated, props.i18n.language);
                } else if (response.data.status.text !== "completed" && response.data.status.text !== "pending paymenty") {
                    let session_token_expiration_time = window.localStorage.getItem("session_token_expiration_time");
                    if (Date.now() < Date.parse(session_token_expiration_time) || session_token_expiration_time === 'undefined') {
                        let temporal_pkg_id = window.localStorage.getItem("temporal_pkg_id");
                        props.setToken(session_token);
                        props.setTemporalPkgId(temporal_pkg_id);
                        props.dingusRooms(query_parsed, session_token, props.isAuthenticated, state.active_flights, props.i18n.language)
                        if (state.active_flights) {
                            props.removeCart()
                            props.GetFlightsAPI(query_parsed, session_token)
                        }
                    } else {
                        props.getToken(query_parsed, state.active_flights, props.isAuthenticated, props.i18n.language);
                    }
                } else {
                    props.getToken(query_parsed, state.active_flights, props.isAuthenticated, props.i18n.language);
                }
            })
            .catch(function (error) {
                console.log(error.response.data);
                return false;
            });
    }

    sortChildrenAges(childrenAges) {
        let sort_array = [];
        if (childrenAges.length === 1 || !Array.isArray(childrenAges)) {
            sort_array.push(parseInt(childrenAges));
        } else {
            childrenAges.map((age) => {
                sort_array.push(parseInt(age));
            });
            sort_array.sort((a, b) => {
                if (a > b) return 1;
                if (a < b) return -1;
                return 0;
            });
        }
        return sort_array;
    }

    async componentDidMount() {
        await this.getCountry();
        const query_parsed = queryString.parse(this.props.search_query);
        query_parsed.lang = i18next.language;
        this.state.multihotel = (query_parsed.hotel === undefined);
        query_parsed.multihotel = this.state.multihotel;
        this.state.multihotel ? document.title = "OceanBooking" : document.title = "Spring Hoteles";
        this.state.active_flights = (query_parsed.airport !== "null");
        let session_token = window.localStorage.getItem("session_token");
        if (query_parsed.children_ages?.length > 0) {
            query_parsed.children_ages = this.sortChildrenAges(query_parsed.children_ages);
        }
        this.props.createSearch(query_parsed);
        if (session_token !== null && session_token !== undefined) {
            this.checkToken(session_token, query_parsed)
        } else {
            this.props.getToken(query_parsed, this.state.active_flights, this.props.isAuthenticated, this.props.i18n.language);
        }
        this.props.updateStep({id: 1, name: "result", description: "titles.your_travel"});
    }

    handleFetchExtraProducts = (
        search = this.props.search
    ) => {
        this.props.fetchExtraProducts(search);
    };

    render() {
        const {
            products,
            productsIsDone,
            flightsIsDone,
            flightsIsFail,
            productsIsFail,
            t,
            extras,
            payment,
            invalid_token,
        } = this.props;
        const {productsclub} = this.props;
        const {active_flights, multihotel} = this.state;
        const {stepProcess} = this.props;

        return (
            <React.Fragment>
                {((!(productsIsDone || productsIsFail.state) || !(flightsIsDone || flightsIsFail.state || !active_flights)) && !invalid_token) &&
                    <Spinner step={"search"}/>}
                {invalid_token && <PopUpError/>}
                {stepProcess.id < 6  && 
                    <BookingProcess process_step={"results"}/>
               
                }

                {(flightsIsFail.state || productsIsFail.state) && <PopUpError/>}
                <div className="search-result container">
                    <div className="search-result-content">
                        <p className="search-result-title">{t(stepProcess.description)}</p>
                    </div>
                </div>
                <div className="search-result container">
                    <div className="search-result-content">
                        {(stepProcess.id === 1) &&
                            <Flights/>
                        }
                        {/*Mover este componente a la linea 157 si procede*/}
                        {(stepProcess.id < 6 && isDesktop )  && (!active_flights || flightsIsDone && productsIsDone) &&
                            <Box component="div" display="inline">
                                <Cart cartEdit={stepProcess.id >= 2 && 'false'} hotel={this.props.hotel}
                                      isCheckoutView={this.props.isCommissionView}/>
                            </Box>
                        }

                        {(stepProcess.id < 3 && !isDesktop) && (!active_flights || flightsIsDone && productsIsDone) ?
                            <Box component="div" display="inline">
                                <Cart cartEdit={stepProcess.id >= 2 && 'false'} hotel={this.props.hotel}
                                      isCheckoutView={this.props.isCommissionView}/>
                            </Box>
                        :
                        ''
                        }
                        <Box component="div" className="search-result-list" display="inline">

                            <div className="list-header">
                                {(stepProcess.id < 5) &&
                                    <h2 className="list-title">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.625 9C6.65883 9 7.5 8.15883 7.5 7.125C7.5 6.09117 6.65883 5.25 5.625 5.25C4.59117 5.25 3.75 6.09117 3.75 7.125C3.75 8.15883 4.59117 9 5.625 9ZM13.875 6H8.625C8.41781 6 8.25 6.16781 8.25 6.375V9.75H3V4.875C3 4.66781 2.83219 4.5 2.625 4.5H1.875C1.66781 4.5 1.5 4.66781 1.5 4.875V13.125C1.5 13.3322 1.66781 13.5 1.875 13.5H2.625C2.83219 13.5 3 13.3322 3 13.125V12H15V13.125C15 13.3322 15.1678 13.5 15.375 13.5H16.125C16.3322 13.5 16.5 13.3322 16.5 13.125V8.625C16.5 7.17516 15.3248 6 13.875 6Z"
                                                fill="currentColor"/>
                                        </svg>
                                        <span>{t('room.your')}</span>
                                    </h2>
                                }
                                {!multihotel &&
                                    < p className="flight-offer">
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.33301 8.39889V3.08331C1.33301 2.39295 1.89264 1.83331 2.58301 1.83331H7.89858C8.2301 1.83331 8.54805 1.96501 8.78246 2.19943L14.3002 7.7172C14.7884 8.20535 14.7884 8.99681 14.3002 9.48496L8.98465 14.8005C8.4965 15.2887 7.70504 15.2887 7.21689 14.8005L1.69913 9.28277C1.46471 9.04835 1.33301 8.73041 1.33301 8.39889H1.33301ZM4.24968 3.49998C3.55931 3.49998 2.99968 4.05962 2.99968 4.74998C2.99968 5.44035 3.55931 5.99998 4.24968 5.99998C4.94004 5.99998 5.49968 5.44035 5.49968 4.74998C5.49968 4.05962 4.94004 3.49998 4.24968 3.49998Z"
                                                fill="currentColor"/>
                                        </svg>
                                        <span>{t('flights.discount_with_hotel')}</span>
                                    </p>
                                }
                                {/*<div className="input-wrapper simple-input select-wrapper orderby">*/}
                                {/*    <select required="" name="rcrs-country">*/}
                                {/*        <option value="">Ordenar por:</option>*/}
                                {/*        <option value="stars">Estrellas</option>*/}
                                {/*        <option value="prices">Precios</option>*/}
                                {/*    </select>*/}
                                {/*</div>*/}
                            </div>

                            {stepProcess.id === 1 && productsIsDone &&
                                <>
                                    {multihotel !== 'false' ?
                                        <div>
                                            <CardComp products={products} productsclub={productsclub}
                                                      hotel_name={this.props.hotel}/>
                                        </div>
                                        :
                                        <RoomList products={products[0].products}
                                                  productsclub={productsclub[0].products}
                                                  hotel={this.props.hotel} />
                                    }
                                </>
                            }
                            {stepProcess.id === 2 && !extras.extrasIsDone && !extras.no_extras &&
                                <Spinner step={"extras"}/>}
                            {stepProcess.id === 2 && (extras.extrasIsDone || extras.no_extras) && !extras.extrasIsFail.state &&
                                <Extras/>
                            }
                            {extras.extrasIsFail.state && <PopUpError/>}
                            {stepProcess.id === 3 &&
                                <Login/>
                            }
                            {stepProcess.id === 4 &&
                                <ReserveForm/>
                            }
                            {stepProcess.id === 5 &&
                                <>{payment.status === "PENDING_API_RESPONSE" ?
                                    <Spinner step={"payment"}/>
                                    :
                                    <Payment/>
                                }
                                </>
                            }
                            {stepProcess.id === 6 &&
                                <>{(payment.status === "PENDING" || payment.status === "PENDING_API_RESPONSE") && (!active_flights || INTERNAL === "true" || AGENCIES === "true") ?
                                    <Spinner step={"success"}/>
                                    :
                                    <Success/>
                                }
                                </>
                            }
                            {stepProcess.id === 7 &&
                                <Failure/>
                            }
                        </Box>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    products: state.shelf.products,
    productsclub: state.shelf.productsClub,
    extraproducts: state.shelf.extraproducts,
    productsIsDone: state.shelf.isDone,
    filters: state.filters.items,
    sort: state.sort.type,
    search: state.search.payload,
    uid: state.auth.user?.id,
    isAuthenticated: state.auth.isAuthenticated,
    isAgency: state.auth.isAgency,
    agencyID: state.auth.agency?.pms_number,
    isCommissionView: state.agency?.isCommissionView,
    stepProcess: state.stepProcess,
    flights: state.flights,
    token: state.search.token,
    flightsIsDone: state.flights.isDone,
    flightsIsFail: state.flights.isFail,
    productsIsFail: state.shelf.isFail,
    extras: state.extras,
    productsCart: state.cart.products,
    payment: state.payment,
    pkgData: state.pkgData,
    invalid_token: state.auth.invalid_token,
});

const mapDispatchToProps = {
    fetchExtraProducts,
    createSearch,
    updateStep,
    getFlights,
    setActivateFlights,
    getToken,
    calculateTotalPrice,
    setToken,
    dingusRooms,
    GetFlightsAPI,
    setTemporalPkgId,
    setMultiHotel,
    removeCart,
    toggleFlightCart,
    toggleHotelCart,
    setIpStackData,
    invalidToken,
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('common')(EngineResult));
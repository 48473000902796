import {
    AGENCY_COMMISSIONVIEW_UPDATE, GET_AGENTS, AGENT_REGISTER,
    AGENT_REGISTER_ERROR, AGENT_REGISTER_CLEAR, AGENT_SPRING40
} from "../actions/types";

const initialState = {
    isCommissionView: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AGENCY_COMMISSIONVIEW_UPDATE:
            return {
                ...state,
                isCommissionView: action.payload
            };
        case AGENT_SPRING40:
            return {
                ...state,
                spring40active: action.payload
            };
        case GET_AGENTS:
            return {
                ...state,
                agents: action.payload
            };
        case AGENT_REGISTER_CLEAR:
            return {
                ...state,
                register: undefined
            };
        case AGENT_REGISTER:
        case AGENT_REGISTER_ERROR:
            return {
                ...state,
                register: action.payload
            };
        default:
            return state;
    }
}
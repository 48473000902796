import React, {Component} from "react";
import PropTypes, {instanceOf} from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {login} from "../../actions/auth";
import {Cookies, withCookies} from 'react-cookie';
import Spinner from "../Spinner";
import {Redirect} from "react-router-dom";


export class Login extends Component {
    state = {
        username: "",
        password: "",
        agency: (process.env.REACT_APP_AGENCY_MODE_ACTIVATED === "true"),
    };

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
        cookies: instanceOf(Cookies).isRequired
    };

    onSubmit = e => {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password, this.state.agency, this.props.cookies);
    };

    onChange = e => this.setState({[e.target.name]: e.target.value});
    onChangeAgency = e => this.setState({[e.target.name]: e.target.checked});

    render() {
        if (this.props.isAuthenticated && this.props.isAgency) {
            window.location.replace("https://oceanbooking.com/agencies");
        }
        else if(this.props.isAuthenticated){
            return <Redirect to="/"/>
            // Vuelvo atrás y refresco la pag de búsqueda
            //this.props.history.goBack();
            //window.location.reload(false);
        }
        const {username, password, agency} = this.state;

        const {t, i18n} = this.props;
        let language = i18n.language;
        if(i18n.language.split('-')) {
            language = i18n.language.split('-')[0].toLowerCase();
        }

        const REGISTER_URL = process.env.REACT_APP_BASE_URL_BACKEND + "/agencies-api/register/" + language + "/";

        return (
            <React.Fragment>
                <article className="login-form-wrapper">
                    <h2 className="h1 title">{t('loginpage.access')}</h2>
                    <form className="login-form form-style" onSubmit={this.onSubmit}>
                        <div className="input-wrapper">
                            <label htmlFor="user">E-mail</label>
                            <input type="text" id="username" name="username"
                                   placeholder={t('msg.input_email_spring')}
                                   value={username}
                                   onChange={this.onChange}
                                   required
                            />
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="password">{t('bloque_azul_contrasena.contrasena')}</label>
                            <input type="password" id="password" name="password"
                                   placeholder={t('msg.input_password')}
                                   value={password}
                                   onChange={this.onChange}
                                   required
                            />
                        </div>
                        {/* <div className="input-wrapper">
                            <input type="checkbox" value="true" id="agency" name="agency"
                                   onChange={this.onChangeAgency}/>
                            <label htmlFor="canary_resident">{t('msg.im_agency')}</label>
                        </div> */}
                        <div className="input-wrapper">
                            <a href="/password-reset">{t('msg.lost_password')}</a>
                        </div>
                        {this.props.isLoading ?
                            <>
                                <Spinner step={"search"}/>
                                <input className="btn btn-primary full-weidth" type="submit" disabled
                                       value={t('loginpage.login')}/>
                            </>
                            :
                            <>
                                <input className="btn btn-primary full-weidth" type="submit"
                                       value={t('loginpage.login')}/>
                                <br/><br/>
                                <a href={REGISTER_URL}>
                                    <input className="btn full-weidth" value={t('msg.new')}/>
                                </a>
                            </>
                        }
                    </form>
                    {/*<p className="or-label">*/}
                    {/*    <span>o</span>*/}
                    {/*</p>*/}
                    {/*<a className="btn full-weidth btn-google">Continúa con Google</a>*/}
                    {/*<a className="btn full-weidth btn-facebook">Continúa con Facebook</a>*/}
                </article>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    isAgency: state.auth.isAgency,
});

export default connect(
    mapStateToProps, {login}
)(withCookies(withTranslation('common')(Login)));
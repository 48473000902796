import React from 'react';

import Product from '../Product';
import {useSelector} from "react-redux";

const ProductList = ({products, productsclub, room_title, hotel_chains, provider}) => {

    let arrival = new Date(useSelector(state => state.search.payload.arrival));
    let departure = new Date(useSelector(state => state.search.payload.departure));
    let christmas_offer_date_1 = new Date(arrival.getFullYear(), process.env.REACT_APP_CHRISTMAS_MONTH-1, process.env.REACT_APP_CHRISTMAS_DAY_1);
    let christmas_offer_date_2 = new Date(arrival.getFullYear(), process.env.REACT_APP_CHRISTMAS_MONTH-1, process.env.REACT_APP_CHRISTMAS_DAY_2);

    return productsclub.map((p, indexproduct) => {
        p.christmas_offer = false;
        if(products.length > 0)
            products[indexproduct].christmas_offer = false;
        if((arrival <= christmas_offer_date_1 && departure >= christmas_offer_date_1) ||
        (arrival <= christmas_offer_date_2 && departure >= christmas_offer_date_2) ||
            (arrival <= christmas_offer_date_1 && departure >= christmas_offer_date_2)){
            p.christmas_offer = true;
            if(products.length > 0)
                products[indexproduct].christmas_offer = true;
        }
        return <Product product={products[indexproduct]} translate_room={p.translation_code} productclub={p} key={p.id} room_title={room_title} hotel_chains={hotel_chains} provider={provider} />;
    });
};

export default ProductList;
import {UPDATE_CART, CART_TOTAL_DELETE} from './actionTypes';

const initialState = {
    data: {
        productQuantity: 0,
        installments: 0,
        totalPrice: 0,
        currencyId: 'EUR',
        currencyFormat: '€'
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_CART:
            return {
                ...state,
                data: action.payload
            };
        case CART_TOTAL_DELETE:
            return {
                ...state,
                data: initialState.data
            };
        default:
            return state;
    }
}

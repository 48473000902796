import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadCart, removeProduct, changeProductQuantity, removeCart } from '../../services/cart/actions';
import { cartTotalDelete, updateCart } from '../../services/total/actions';
import { formatPrice, returnCurrency } from '../../services/util';
import { updateStep } from "../../actions/stepProcess";
import { timeDiff, getLeadTime } from "../GTMComponent/utils/timeGTM";

import CartProduct from './CartProduct/CartProduct';
import CartFlights from "./CartFlights/CartFlights";
import Timer from '../EngineResult/Timer/Timer';

import Collapse from '@material-ui/core/Collapse';
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import { addCartGTM } from '../GTMComponent/GTMComponent';
import { modifyReservation } from '../../actions/reservations';
import { createDashboardAlert } from '../../actions/alerts';
import { Link } from "react-router-dom";
import CartExtras from "./CartExtras/CartExtras";
import {addTotalPrice, extrasIsDone, getLuggage, getTransfer, noExtras} from "../../actions/extras";
import { calculateTotalPrice, extrasResponse, searchResponse } from "../../actions/pkgData";

import { GrClose } from "react-icons/gr";
import stepProcess from '../../reducers/stepProcess';

const AGENCIES = process.env.REACT_APP_AGENCY_MODE_ACTIVATED;


class Cart extends Component {
    static propTypes = {
        loadCart: PropTypes.func.isRequired,
        updateCart: PropTypes.func.isRequired,
        cartProducts: PropTypes.array.isRequired,
        newProduct: PropTypes.object,
        removeProduct: PropTypes.func,
        productToRemove: PropTypes.object,
        changeProductQuantity: PropTypes.func,
        productToChange: PropTypes.object
    };

    state = {
        isOpen: true
    };

    componentDidMount() {
        if (this.props.isModifying && this.props.cartProducts.length > 0) {
            // Borramos el carrito actual cuando se modifica una habitación
            const { removeCart, cartTotalDelete } = this.props;
            removeCart();
            cartTotalDelete();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.newProduct !== this.props.newProduct) if (this.props.isModifying && this.props.cartProducts.length > 0) {
        } else {
            this.addProduct(nextProps.newProduct);
        }

        if (nextProps.productToRemove !== this.props.productToRemove) {
            this.removeProduct(nextProps.productToRemove);
        }

        if (nextProps.productToChange !== this.props.productToChange) {
            this.changeProductQuantity(nextProps.productToChange);
        }
    }

    openFloatCart = () => {
        this.setState({ isOpen: true });
    };

    closeFloatCart = () => {
        this.setState({ isOpen: false });
    };

    openCart = () => {
        this.setState({ isOpen: !(this.state.isOpen) });
    };

    addProduct = (product) => {
        if (!this.props.flights.isActive) {
            const { cartProducts, updateCart } = this.props;

            product.reservation = this.props.search;
            product.quantity = 1;
            product.id = product.id + "-" + Date.now();

            product.pricewithoffer = Math.round((product.pricewithoffer) * 100) / 100;

            new addCartGTM();
            cartProducts.push(product);
            updateCart(cartProducts);
            this.openFloatCart();
        }
    };

    checkAgencyDates = (arrival) => {
        let arrival_date = new Date(arrival);
        let max_date = new Date();
        max_date.setDate(max_date.getDate() + 15);
        return (arrival_date < max_date);
    };

    removeProduct = product => {
        if (!this.props.flights.isActive) {
            const { cartProducts, updateCart } = this.props;

            const index = cartProducts.findIndex(p => p.id === product.id);
            if (index >= 0) {
                cartProducts.splice(index, 1);
                updateCart(cartProducts);
            }

            //new removeCartGTM(product);
        }
    };

    cartButton = (t) => {
        if (this.props.stepProcess.id < 3 && this.props.cartProducts.length > 0) {
            if (this.props.isModifying) {
                const { idRoomToChange } = this.props;
                return <Link to={'/myreservations/room/' + idRoomToChange}>
                    <button onClick={() => this.proceedToModify(t)} className="final-btn">
                        {t('modification.to_modify')}
                    </button>
                </Link>
            } else {
                return <button onClick={() => this.proceedToCheckout(t)} className="final-btn">
                    {t('cart.reservation')}
                </button>
            }
        }
    };

    proceedToModify = (t) => {
        const { idRoomToChange, createDashboardAlert, cartProducts, modifyReservation } = this.props;
        const { productQuantity } = this.props.cartTotal;

        if (!productQuantity) {
            alert(t('cart.add_product'));
        } else {
            modifyReservation(idRoomToChange, cartProducts[0]).then(data => {
                if (data?.status === "OK") {
                    createDashboardAlert(t('modification.successfully_modified'), "success-msg");
                } else {
                    createDashboardAlert(t('ERROR') + ": " + data?.info, "error-msg");
                }
            }
            );
        }
    };
    proceedToCheckout = async (t) => {
        const {
            productQuantity,
        } = this.props.cartTotal;

        let data = {}
        if (this.props.cartProducts.length < 1) {
            alert(t('cart.add_product'));
        } else {
            if (this.props.stepProcess.id <= 1) {
                await this.props.searchResponse(this.props.cartProducts, this.props.flights, this.props.token);
                if (this.props.pkgData.search_response.status === "OK") {
                    this.props.updateStep({
                        id: 2,
                        name: "EXTRAS",
                        description: "titles.your_extras"
                    });
                } else {
                    alert("Error en la asignación de vuelos o habitaciones");
                }
                new addCartGTM();
                this.props.calculateTotalPrice()
                // Cerramos el carrito solo en mobile
                if (isMobile) {
                    this.closeFloatCart()
                }

                // Petición de extras
                try {
                    if (this.props.flights.isActive) {
                        // Si no hay extras pedidos mo se cambia el vuelo se piden, si no continuamos con los que estaban por sio solo se quería cambiar el hotel
                        if (!this.props.extras.luggage_info.state || this.props.extras.luggage_info.inbound_token !== this.props.flights.selected_flight[1].token || this.props.extras.luggage_info.outbound_token !== this.props.flights.selected_flight[0].token) {
                            // Se piden maletas
                            this.props.getLuggage(this.props.selected_flight, this.props.token)
                            this.props.addTotalPrice(this.props.extras.total_price, "€", true)
                        }
                    }
                    // Si no hay transfer pedido se pide
                    if (!this.props.extras.transfer_info.state){
                        // Se pide el transfer
                        this.props.getTransfer(this.props.selected_flight, this.props.search, this.props.token)
                    }
                    // Se marca como completado
                    this.props.extrasIsDone(true)
                } catch (e) {
                    this.props.noExtras(true);
                }
            } else if (this.props.stepProcess.id <= 2) {
                await this.props.extrasResponse(this.props.token, this.props.selected_luggage);
                if (this.props.pkgData.extras_response.status === "OK") {
                    this.props.calculateTotalPrice()
                    this.props.updateStep({
                        id: 4,
                        name: "FORM",
                        description: "titles.confirm"
                    })
                } else {
                    alert("Error en la asignación de extras");
                }
            }
        }
    };

    changeProductQuantity = changedProduct => {
        const { cartProducts, updateCart } = this.props;

        const product = cartProducts.find(p => p.id === changedProduct.id);
        product.quantity = changedProduct.quantity;
        if (product.quantity <= 0) {
            this.removeProduct(product);
        }
        updateCart(cartProducts);
    };

    render() {
        const { t } = this.props;
        const {
            cartTotal,
            cartProducts,
            removeProduct,
            changeProductQuantity,
            flights,
            stepProcess,
            extras,
            pay_now,
            pay_in_hotel,
            totalPrice,
            timeToken,
            sessionExpired,
        } = this.props;
        const { auth } = this.props;


        const relative = {
            position: "relative"
        }

        const sticky = {
            position: "sticky"
        }

        const firstPadding = { padding: "17px 20px 10px" }

        const secondPadding = { padding: "10px 20px 20px" }

        let commisionView;
        let commission;
        let total_price = 0;
        if (cartTotal.totalPrice !== null) {
            total_price = parseFloat(cartTotal.totalPrice);
        }

        if (this.props.isCheckoutView && auth.isAgency) {
            commisionView = true;
            commission = auth.agency.descuento;
        } else if (this.props.isCheckoutView && (auth.user?.rol === 'agent')) {
            commisionView = true;
            commission = auth.user.agency_commission;
        } else {
            commisionView = false
        }

        const products = cartProducts.map((p, index) => {
            return (
                <CartProduct product={p} removeProduct={removeProduct} changeProductQuantity={changeProductQuantity}
                    key={p.id} cartEdit={this.props.cartEdit} indexHab={index + 1} />
            );
        });

        let classes = ['float-cart'];

        if (!!this.state.isOpen) {
            classes.push('float-cart--open');
        }

        let cartClasses = ['process-cart in-top opened'];

        if (!((isMobile || window.innerWidth < 1100) && cartProducts.length > 0)) {
            cartClasses = 'process-cart in-top opened empty-price';
        }

        return (
            <aside className="search-result-aside">
                <article className={cartClasses}>

                    <header className="process-cart-header" onClick={this.openCart}>
                        <div className="mobile-price-info">
                            {/* TODO Traduccion*/}
                            <p className="site-color">Precio final</p>
                            <p className="price">
                                <span
                                    className="old">{(Math.round((cartTotal.totalPriceWithoutOffer) * 100) / 100) + cartTotal.currencyFormat}</span>
                                <strong
                                    className="final">{(Math.round((total_price) * 100) / 100) + cartTotal.currencyFormat}</strong>
                            </p>
                        </div>
                        <h3 className="title primary-title-font">{t('cart.your_reservation')}</h3>
                    </header>


                    <Collapse in={isMobile ? (cartProducts.length < 1 ? false : this.state.isOpen) : this.state.isOpen}
                        timeout="auto" unmountOnExit>
                        <div className="cart-content">
                            <a onClick={this.openCart}>
                                <div className="only-mobile dropdown-cart-header site-bg-light">
                                    <h5 className="title">{t('cart.your_reservation')} <GrClose /></h5>

                                </div>
                            </a>

                            <div className="dd flights-list">
                                {(flights.isActive && flights.selected_flight != null) ?
                                    <CartFlights />
                                    :
                                    <div className="add-flights-select-wrapper">
                                        <span
                                            className="add-flights-text">{t('flights.add_flight')}
                                            <strong> {t("flights.discount_flight")}</strong></span>
                                    </div>
                                }
                            </div>

                            <div className="dd rooms-list">
                                {products}
                                {!products.length && (
                                    <p>
                                        {t('cart.add_empty')} <br />
                                    </p>
                                )}
                                {(flights.isActive && flights.selected_flight != null) &&
                                    <div className="promo-banner">
                                        <p>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1.33301 8.39889V3.08331C1.33301 2.39295 1.89264 1.83331 2.58301 1.83331H7.89858C8.2301 1.83331 8.54805 1.96501 8.78246 2.19943L14.3002 7.7172C14.7884 8.20535 14.7884 8.99681 14.3002 9.48496L8.98465 14.8005C8.4965 15.2887 7.70504 15.2887 7.21689 14.8005L1.69913 9.28277C1.46471 9.04835 1.33301 8.73041 1.33301 8.39889H1.33301ZM4.24968 3.49998C3.55931 3.49998 2.99968 4.05962 2.99968 4.74998C2.99968 5.44035 3.55931 5.99998 4.24968 5.99998C4.94004 5.99998 5.49968 5.44035 5.49968 4.74998C5.49968 4.05962 4.94004 3.49998 4.24968 3.49998Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                            <span>{t('cart.discount')}</span>
                                        </p>
                                        {/* El descuento esta comentado porque en paquetes no se muestran los precios por separado */}
                                        {/*<p className="discount-price">{flight_discount}</p>*/}
                                    </div>
                                }
                            </div>

                            {stepProcess.id >= 2 && (extras.selected_transfer?.state || extras.selected_luggage?.length > 0) &&
                                <div className="dd extras-list">
                                    <CartExtras />
                                </div>
                            }
                        </div>
                    </Collapse>


                    <div className="process-cart-content">
                        {(commisionView && !this.props.search.spring40) ?
                            <>
                                <div className="cart-price">
                                    <h5>{t('msg.pvp')}</h5>
                                    <p className="price">
                                        <strong className="final" title="final">
                                            {`${formatPrice(
                                                total_price,
                                                cartTotal.currencyId
                                            )}${cartTotal.currencyFormat}`}
                                        </strong>
                                    </p>
                                </div>

                                <div className="cart-price">
                                    <h5>{t('words.commission')} ({Math.round(commission)}%)</h5>
                                    <p className="price">
                                        <strong className="final" title="final">
                                            {((total_price * commission) / 100).toFixed(2)}{cartTotal.currencyFormat}
                                        </strong>
                                    </p>
                                </div>

                                <div className="cart-price">
                                    <h5>{t('cart.final_price')}</h5>
                                    <p className="price">
                                        <span className="old" title="sin descuento">
                                            {`${formatPrice(
                                                total_price,
                                                cartTotal.currencyId
                                            )}${cartTotal.currencyFormat}`}
                                        </span>
                                        <strong className="final" title="final">asd
                                            {(total_price - ((total_price * commission) / 100)).toFixed(2)}{cartTotal.currencyFormat}
                                        </strong>
                                    </p>
                                </div>
                            </>
                            :
                            <>
                                {/* TODO Traduccion*/}
                                {(flights.isActive && flights.selected_flight != null) ?
                                    <>
                                        {cartProducts.length > 0 &&
                                            <>
                                                <div className="cart-price">
                                                    <h5>{t('cart.final_price')} {(AGENCIES === "true" && t('cart.net_price'))}</h5>
                                                    <p className="price">
                                                        <span className="old" title="sin descuento">
                                                            {parseFloat(parseFloat(cartTotal.totalPriceWithoutOffer) + flights.totalFlightsPrice).toFixed(2)} {returnCurrency(cartProducts[0]?.currencyid)}
                                                        </span>
                                                        <strong className="final" title="final">
                                                            {totalPrice} {returnCurrency(cartProducts[0]?.currencyid)}
                                                        </strong>
                                                    </p>
                                                </div>
                                                <div className="cart-price">
                                                    <h5>{AGENCIES === "true" ? t('cart.hotel_paid_agencies') : t('cart.pay_hotel')}</h5>
                                                    <p className="price"><strong
                                                        className="pay-hotel">
                                                        {pay_in_hotel} {returnCurrency(cartProducts[0]?.currencyid)}
                                                    </strong></p>
                                                </div>
                                                <div className="cart-price">
                                                    <h5>{t('cart.pay_now')} <span>({t('cart.no_reembolsable')})</span>
                                                    </h5>
                                                    <p className="price"><strong
                                                        className="pay-hotel">
                                                        {pay_now} {returnCurrency(cartProducts[0]?.currencyid)}
                                                    </strong></p>
                                                </div>
                                            </>
                                        }
                                    </>
                                    :
                                    <div className="cart-price">
                                        <h5>{(AGENCIES === "true" ? t('cart.netPrice') : t('cart.final_price'))}</h5>
                                        <p className="price">
                                            <strong className="final" title="final">
                                                {(`${cartTotal.currencyFormat} ${formatPrice(
                                                    (cartProducts.length > 0 ? cartTotal.totalPrice : 0),
                                                    cartTotal.currencyId
                                                )}`)}
                                            </strong>
                                        </p>
                                    </div>
                                }
                            </>
                        }
                        {AGENCIES === "true" &&
                            <>

                                {!this.checkAgencyDates(this.props.search.arrival) &&

                                    <div className="dd flights-list" style={firstPadding}>
                                        <div className="add-flights-select-wrapper">
                                            <span className="add-flights-text">
                                                <strong>{t('agencies.payment_msg_title')}
                                                </strong>
                                                <br /><br />
                                                {t('agencies.payment_msg_text')}
                                            </span>
                                        </div>
                                    </div>
                                }
                                <div className="dd flights-list" style={secondPadding}>
                                    <div className="add-flights-select-wrapper">
                                        <span className="add-flights-text">
                                            <strong>{t('agencies.cancel_conditions_msg_title')}
                                            </strong>
                                            <br /><br />
                                            {t('agencies.cancel_conditions_msg_text')}
                                        </span>
                                    </div>
                                </div>

                            </>
                        }
                    </div>

                    {this.props.buttonDisabled ? null :
                        <footer className="process-cart-footer">
                            {this.cartButton(t)}
                        </footer>
                    }
                </article>
            </aside>
        );
    }
}

const mapStateToProps = state => ({
    cartProducts: state.cart.products,
    newProduct: state.cart.productToAdd,
    productToRemove: state.cart.productToRemove,
    productToChange: state.cart.productToChange,
    cartTotal: state.total.data,
    search: state.search.payload,
    auth: state.auth,
    isModifying: state.reservations.modification?.isModifying,
    idRoomToChange: state.reservations.modification?.idRoomToChange,
    stepProcess: state.stepProcess,
    flights: state.flights,
    extras: state.extras,
    provider: state.shelf.provider,
    token: state.search.token,
    selected_flight: state.flights.selected_flight,
    pay_now: state.pkgData.pay_now,
    pay_in_hotel: state.pkgData.pay_in_hotel,
    totalPrice: state.pkgData.total_price,
    selected_luggage: state.extras.selected_luggage,
    pkgData: state.pkgData,
    timeToken: state.search.token_expiration_time
});

export default connect(
    mapStateToProps,
    {
        loadCart,
        updateCart,
        removeProduct,
        changeProductQuantity,
        removeCart,
        cartTotalDelete,
        modifyReservation,
        createDashboardAlert,
        updateStep,
        getTransfer,
        getLuggage,
        searchResponse,
        extrasResponse,
        calculateTotalPrice,
        noExtras,
        extrasIsDone,
        addTotalPrice
    }
)(withTranslation('common')(Cart));

import React, {useState} from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {useTranslation} from "react-i18next";
import {passwordRecovery} from "../../actions/auth";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";


function PasswordRecovery(props) {

    const {t} = useTranslation('common');
    const methods = useForm();

    const [datasent, setDatasent] = useState(false);

    const onSubmit = form => {
        setDatasent(true);
        props.passwordRecovery(form);
    };

    return (
        <React.Fragment>
            <Header hotel="spring"/>

            <main className="container apriv-page">
                <section className="recover-password-wrapper">
                    <header className="recover-password-header">
                        <h1 className="title">{t('pswd.recovery_pswd')}</h1>
                        <p>{t('pswd.email_msg')}</p>
                    </header>

                    <form onSubmit={methods.handleSubmit(onSubmit)}
                          className="recover-password-block form-style site-bg-light">
                        <div className="inputs-group">
                            <div className="input-wrapper">
                                <label htmlFor="email">{t('words.email')} <span>({t('words.required')})</span></label>
                                <input type="email" id="email" name="email"
                                       placeholder="name@email.com" required
                                       ref={methods.register({required: true})}
                                />
                            </div>
                        </div>
                        <div className="form-footer">
                            {datasent ?
                                <input type="submit" value={t('pswd.recovery_pswd')} className="btn btn-primary" disabled/>
                                :
                                <input type="submit" value={t('pswd.recovery_pswd')} className="btn btn-primary"/>
                            }
                        </div>
                    </form>

                    <footer className="recover-password-footer">
                        <p><a href="/myreservations">{t('words.login').toUpperCase()}</a></p>
                    </footer>
                </section>
            </main>

            <Footer hotel="spring"/>
        </React.Fragment>
    );
}

export default connect(null, {passwordRecovery})(PasswordRecovery);
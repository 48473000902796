import {
    SET_AMOUNT,
    SET_CURRENCY,
    SET_PAYMENT_ID,
    SET_PAYMENT_TOKEN,
    SET_STATUS
} from "../actions/types";

const initialState = {
    amount: 0,
    currency: "",
    payment_id: "",
    payment_token: "",
    status: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_AMOUNT:
            return {
                ...state,
                amount: action.payload
            };
        case SET_CURRENCY:
            return {
                ...state,
                currency: action.payload
            };
        case SET_PAYMENT_ID:
            return {
                ...state,
                payment_id: action.payload
            };
        case SET_PAYMENT_TOKEN:
            return {
                ...state,
                payment_token: action.payload
            };
        case SET_STATUS:
            return {
                ...state,
                status: action.payload
            };
        default:
            return state;
    }
}

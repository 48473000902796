import React, { Component, useEffect } from "react";
import { connect } from 'react-redux';
import { addLuggage, addTransfer, removeTransfer, removeLuggage } from "../../../actions/extras";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withTranslation } from "react-i18next";
import { returnCurrency } from "../../../services/util";
import { viewItemListExtrasGTM } from "../../GTMComponent/GTMComponent";
import { isMobile } from "react-device-detect";

class Extras extends Component {

    constructor(props) {
        super(props);
        this.state = {
            outbound_luggage: "",
            inbound_luggage: "",
            luggage_list: {},
            transfer: false,
            reload: false,
        };
    }

    Transfer() {
        if (this.props.hotel_chains === "SPRING HOTELES") {
            let arrival_date = +new Date(this.props.search.arrival);
            let departure_date = +new Date(this.props.search.departure);
            let days = (Math.abs(departure_date - arrival_date) / (1000 * 3600 * 24));
            return days >= 6;
        } else {
            return false;
        }
    }

    includedLuggages() {
        if (this.props.extras.luggage_info.payload?.included_luggages?.length > 0) {
            this.props.extras.luggage_info.payload?.included_luggages.map((luggage) => {
                if (this.state.luggage_list[luggage.token] != true) {
                    this.addLuggageCart(luggage)
                }
            })
        }
    }

    addLuggageCart(luggage) {
        if(luggage.flight_type === "GROUPED"){
            luggage.baggageList.map((baggage) => {
                this.props.addLuggage(baggage)
            })
        }
        else{
            this.props.addLuggage(luggage)
        }
    }

    removeLuggageCart(luggage, selected_luggage) {
        if(luggage.flight_type === "GROUPED"){
            luggage.baggageList.map((baggage) => {
                console.log(baggage)
                this.props.removeLuggage(baggage)
            })
        }
        else{
            this.props.removeLuggage(luggage)
        }
    }

    componentDidMount() {
        this.props.extras.selected_luggage.map((luggage) => {
            this.state.luggage_list[luggage.token] = true;
        })
        if (this.Transfer()) {
            this.props.addTransfer({
                state: true,
                price: 0,
                currency: "€"
            });
        }
        this.includedLuggages();
        
        setTimeout(() => {
            this.setState({
                reload: true
            })
        }, 1000);
    }

    

    render() {

        const {
            removeLuggage,
            extras,
            extrasFinalPrice,
            extrasCurrency,
            selectedLuggage,
            addTransfer,
            removeTransfer,
            hotel_chains
        } = this.props;
        const {luggage_list, transfer} = this.state;
        const free_transfer = this.Transfer();
        const transfer_info = extras.transfer_info;
        const extra_outbound_luggage = extras.luggage_info.payload?.extras_luggages[0].filter(luggage => luggage.flight_type === "OUTBOUND");
        const extra_inbound_luggage = extras.luggage_info.payload?.extras_luggages[0].filter(luggage => luggage.flight_type === "INBOUND");
        const two_way_inbound_luggage = extras.luggage_info.payload?.extras_luggages[0].filter(luggage => luggage.flight_type === "TWO-WAY");
        const group_luggage = extras.luggage_info.payload?.extras_luggages[0].filter(luggage => luggage.flight_type === "GROUPED");

        const included_outbound_luggage = extras.luggage_info.payload?.included_luggages?.filter(luggage => luggage.flight_type === "OUTBOUND");
        const included_inbound_luggage = extras.luggage_info.payload?.included_luggages?.filter(luggage => luggage.flight_type === "INBOUND");

        const { t } = this.props;


        //comprueba si el token asociado a la maleta ya esta añadido 
        const checkTokenLuggageInUse = (token, index) => {

            if (extras.selected_luggage.length === 0){
                return false;
            }
            else{
                extras.selected_luggage.map((baggage) => {
                    if (baggage.token === token){
                        return true;
                    }
                    else {
                        return false;
                    }
                });
            }
        }


        return (
            <React.Fragment>
                <div className="search-result container">
                    <div className="search-result-content">
                        <div className="MuiBox-root jss2 search-result-list">
                            {/* Si NO hay extras mostramos un mensaje */}
                            {extras.no_extras && hotel_chains !== "SPRING HOTELES" &&
                                <div className="no-extras">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21.7266 19.2913L17.8324 15.3971C17.6567 15.2214 17.4184 15.1237 17.1684 15.1237H16.5318C17.6098 13.7449 18.2504 12.0107 18.2504 10.1242C18.2504 5.63636 14.614 2 10.1262 2C5.63832 2 2.00195 5.63636 2.00195 10.1242C2.00195 14.612 5.63832 18.2484 10.1262 18.2484C12.0127 18.2484 13.7469 17.6079 15.1257 16.5298V17.1665C15.1257 17.4165 15.2233 17.6547 15.3991 17.8305L19.2932 21.7246C19.6604 22.0918 20.2541 22.0918 20.6173 21.7246L21.7227 20.6193C22.0898 20.2521 22.0898 19.6584 21.7266 19.2913ZM10.1262 15.1237C7.36471 15.1237 5.12665 12.8896 5.12665 10.1242C5.12665 7.36276 7.36081 5.12469 10.1262 5.12469C12.8876 5.12469 15.1257 7.35885 15.1257 10.1242C15.1257 12.8857 12.8915 15.1237 10.1262 15.1237Z"
                                            fill="currentColor"></path>
                                    </svg>
                                    <div className="info-wrapper">
                                        <p>{t('extras.no_extras')}.</p>
                                    </div>
                                </div>
                            }

                            {/* Si hay transfer disponible mostramos el componente */}
                            {transfer_info?.state && this.props.hotel_chains === "SPRING HOTELES" &&
                                <div className={"extra-card" + (transfer_info.state ? " selected" : "")}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20.0469 11.4391L19.1875 8.125C18.8125 6.75 17.5625 5.75 16.1875 5.75H15.75V4.5C15.75 3.80977 15.1902 3.25 14.5 3.25H9.5C8.80977 3.25 8.25 3.80977 8.25 4.5V5.75H7.8125C6.4375 5.75 5.1875 6.75 4.8125 8.125L3.95312 11.4391C2.83633 11.6891 2 12.6824 2 13.875V15.75C2 16.673 2.50586 17.4703 3.25 17.9031V19.5C3.25 20.1902 3.80977 20.75 4.5 20.75H5.75C6.44023 20.75 7 20.1902 7 19.5V18.25H17V19.5C17 20.1902 17.5598 20.75 18.25 20.75H19.5C20.1902 20.75 20.75 20.1902 20.75 19.5V17.9031C21.4941 17.4699 22 16.6727 22 15.75V13.875C22 12.6824 21.1637 11.6891 20.0469 11.4391ZM5.75 15.75C5.05977 15.75 4.5 15.1902 4.5 14.5C4.5 13.8098 5.05977 13.25 5.75 13.25C6.44023 13.25 7 13.8098 7 14.5C7 15.1902 6.44023 15.75 5.75 15.75ZM6.55273 11.375L7.22461 8.78281C7.31172 8.46406 7.59922 8.25 7.81289 8.25H16.1879C16.4016 8.25 16.6891 8.46406 16.768 8.75234L17.4473 11.375H6.55273V11.375ZM18.25 15.75C17.5598 15.75 17 15.1902 17 14.5C17 13.8098 17.5598 13.25 18.25 13.25C18.9402 13.25 19.5 13.8098 19.5 14.5C19.5 15.1902 18.9402 15.75 18.25 15.75Z"
                                            fill="currentColor"/>
                                    </svg>
                                    <div className="extra-info">
                                        <p className="title"><strong>{t('transfer.taxi_transfer')}</strong></p>
                                        <p>{t('transfer.to_transfer')}</p>
                                    </div>
                                    {transfer_info.payload.is_free ?
                                        <div className="bottom-info">
                                            <div className="extra-price">
                                                <p className="final">{transfer_info.payload?.price_total} {returnCurrency(transfer_info.payload?.price_total_currency)}</p>
                                            </div>
                                            <div className="btn-wrapper">
                                                <p className="btn btn-included">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M6.59467 14.3731L1.71966 9.4981C1.42678 9.20522 1.42678 8.73034 1.71966 8.43743L2.7803 7.37677C3.07318 7.08386 3.54808 7.08386 3.84096 7.37677L7.125 10.6608L14.159 3.62677C14.4519 3.33388 14.9268 3.33388 15.2197 3.62677L16.2803 4.68743C16.5732 4.98031 16.5732 5.45518 16.2803 5.7481L7.65533 14.3731C7.36242 14.666 6.88755 14.666 6.59467 14.3731V14.3731Z"
                                                            fill="currentColor"/>
                                                    </svg>
                                                    <span>{t('words.included')}</span>
                                                </p>
                                            </div>
                                        </div>
                                        :
                                        <div className="bottom-info">
                                            <div className="extra-price">
                                                <p className="final">{transfer_info.payload?.price_total}{returnCurrency(transfer_info.payload?.price_total_currency)}</p>
                                            </div>
                                            <div className="btn-extras">
                                                {!transfer ?
                                                    <>
                                                        <button className="btn btn-select" onClick={() => {
                                                            this.setState({transfer: true});
                                                            addTransfer({
                                                                state: true,
                                                                price: transfer_info.payload.price_total,
                                                                currency: transfer_info.payload.price_total_currency
                                                            })
                                                        }}>
                                                            <span>{t('result.select')}</span>
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button className="btn btn-remove" onClick={() => {
                                                            this.setState({transfer: false});
                                                            removeTransfer(transfer_info.payload)
                                                        }}>
                                                            <span>{t('result.remove')}</span>
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            
                            {/* Si hay maletas extras para el vuelo mostramos el componente */}
                            {(extras.luggage_info.payload?.extras_luggages?.length > 0 || extras.luggage_info.payload?.included_luggages?.length > 0) && extras.luggage_info.state &&
                                <div className={"extra-card"}>
                                    
                                    {extras.luggage_info.payload?.extras_luggages?.length > 0 &&
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7 20.75H17V5.125C17 4.08984 16.1602 3.25 15.125 3.25H8.875C7.83984 3.25 7 4.08984 7 5.125V20.75ZM9.5 5.75H14.5V7H9.5V5.75ZM22 8.875V18.875C22 19.9102 21.1602 20.75 20.125 20.75H18.25V7H20.125C21.1602 7 22 7.83984 22 8.875ZM5.75 20.75H3.875C2.83984 20.75 2 19.9102 2 18.875V8.875C2 7.83984 2.83984 7 3.875 7H5.75V20.75Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                                {/* TODO esta etiqueta da un warning */}
                                                <Typography>
                                                    <div className="extra-info">
                                                        <p className="title">
                                                            <strong>{t('extras.add_facturation')}</strong>
                                                        </p>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                {extras.luggage_info.payload?.extras_luggages?.length > 0 &&
                                                    <>
                                                        {extra_outbound_luggage.length > 0 &&
                                                            <Typography>
                                                                <div className="extras-ida animate__zoomOutDown">
                                                                    <span className="title">{t('cart.outbound')}</span>
                                                                    {extra_outbound_luggage?.map((luggage, index) => {
                                                                        return (
                                                                            <div className="bottom-info" key={index}>
                                                                                <div className="extra-text">
                                                                                    <p>
                                                                                        <span>{luggage.baggageName} - {t('extras.traveler')} {luggage.passengerId + 1} ({luggage.type === "A" ? t('extras.adult') : t('extras.children')})</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="extra-price">
                                                                                    <p className="final">{luggage.pricingDetail} {returnCurrency(luggage.pricingDetail.currency)}</p>
                                                                                </div>
                                                                                <div className="btn-wrapper">
                                                                                    {!luggage_list[luggage.token] ?
                                                                                        <>
                                                                                            <button
                                                                                                className={luggage_list[luggage.token] || checkTokenLuggageInUse(luggage.token, index) ? "btn btn-selected animate-button" : "btn btn-select"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        luggage_list: {
                                                                                                            ...luggage_list,
                                                                                                            [luggage.token]: true
                                                                                                        }
                                                                                                    });
                                                                                                    this.addLuggageCart(luggage)
                                                                                                }}>
                                                                                                <span>{t('result.select')}</span>
                                                                                            </button>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <button
                                                                                                className={luggage_list[luggage.token] || checkTokenLuggageInUse(luggage.token, index) ? "btn btn-selected animate-button" : "btn btn-select"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        luggage_list: {
                                                                                                            ...luggage_list,
                                                                                                            [luggage.token]: false
                                                                                                        }
                                                                                                    });
                                                                                                    this.removeLuggageCart(luggage, selectedLuggage)
                                                                                                }}>
                                                                                                <span>{luggage_list[luggage.token] ? t('result.added') : t('result.select')}</span>
                                                                                            </button>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Typography>
                                                        }
                                                        {extra_inbound_luggage.length > 0 &&
                                                            <Typography>
                                                                <div className="extras-vuelta">
                                                                    <span className="title">{t('cart.inbound')}</span>
                                                                    {extra_inbound_luggage?.map((luggage, index) => {
                                                                        return (
                                                                            <div className="bottom-info" key={index}>
                                                                                <div className="extra-text">
                                                                                    <p>
                                                                                        <span>{luggage.baggageName} - {t('extras.traveler')} {luggage.passengerId + 1} ({luggage.type === "A" ? t('extras.adult') : t('extras.children')})</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="extra-price">
                                                                                    <p className="final">{luggage.pricingDetail} {returnCurrency(luggage.pricingDetail.currency)}</p>
                                                                                </div>
                                                                                <div className="btn-wrapper">
                                                                                    {!luggage_list[luggage.token] ?
                                                                                        <>
                                                                                            <button
                                                                                                className={luggage_list[luggage.token] || checkTokenLuggageInUse(luggage.token, index) ? "btn btn-selected animate-button" : "btn btn-select"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        luggage_list: {
                                                                                                            ...luggage_list,
                                                                                                            [luggage.token]: true
                                                                                                        }
                                                                                                    });
                                                                                                    this.addLuggageCart(luggage)
                                                                                                }}>
                                                                                                <span>{t('result.select')}</span>
                                                                                            </button>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <button
                                                                                                className={luggage_list[luggage.token] || checkTokenLuggageInUse(luggage.token, index) ? "btn btn-selected animate-button" : "btn btn-select"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        luggage_list: {
                                                                                                            ...luggage_list,
                                                                                                            [luggage.token]: false
                                                                                                        }
                                                                                                    });
                                                                                                    this.removeLuggageCart(luggage, selectedLuggage)
                                                                                                }}>
                                                                                                <span>{luggage_list[luggage.token] ? t('result.added') : t('result.select')}</span>
                                                                                            </button>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Typography>
                                                        }
                                                        {two_way_inbound_luggage.length > 0 &&
                                                            <Typography>
                                                                <div className="extras-vuelta">
                                                                    <span className="title">{t('flights.two-way')}</span>
                                                                    {two_way_inbound_luggage?.map((luggage, index) => {
                                                                        return (
                                                                            <div className="bottom-info" key={index}>
                                                                                <div className="extra-text">
                                                                                    <p>
                                                                                        <span>{luggage.baggageName} - {t('extras.traveler')} {luggage.passengerId + 1} ({luggage.type === "A" ? t('extras.adult') : t('extras.children')})</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="extra-price">
                                                                                    <p className="final">{luggage.pricingDetail} {returnCurrency(luggage.pricingDetail.currency)}</p>
                                                                                </div>
                                                                                <div className="btn-wrapper">
                                                                                    {!luggage_list[luggage.token] ?
                                                                                        <>
                                                                                            <button
                                                                                                className={luggage_list[luggage.token] ? "btn btn-selected animate-button" : "btn btn-select"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        luggage_list: {
                                                                                                            ...luggage_list,
                                                                                                            [luggage.token]: true
                                                                                                        }
                                                                                                    });
                                                                                                    this.addLuggageCart(luggage)
                                                                                                }}>
                                                                                                <span>{t('result.select')}</span>
                                                                                            </button>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <button
                                                                                                className={luggage_list[luggage.token] ? "btn btn-selected animate-button" : "btn btn-select"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        luggage_list: {
                                                                                                            ...luggage_list,
                                                                                                            [luggage.token]: false
                                                                                                        }
                                                                                                    });
                                                                                                    this.removeLuggageCart(luggage, selectedLuggage)
                                                                                                }}>
                                                                                                <span>{luggage_list[luggage.token] ? t('result.added') : t('result.select')}</span>
                                                                                            </button>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Typography>
                                                        }
                                                        {group_luggage.length > 0 &&
                                                            <Typography>
                                                                <div className="extras-vuelta">
                                                                    <span className="title">{t('flights.two-way')}</span>
                                                                    {group_luggage?.map((luggage, index) => {
                                                                        return (
                                                                            <div className="bottom-info" key={index}>
                                                                                <div className="extra-text">
                                                                                    <p>
                                                                                        <span>{luggage.bagsAllowed} {t('extras.extra_luggage_group')} {luggage.passengerId+1}</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="extra-price">
                                                                                    <p className="final">{luggage.pricingDetail} {returnCurrency("EUR")}</p>
                                                                                </div>
                                                                                <div className="btn-wrapper">
                                                                                    {!luggage_list[luggage.baggageList[0].token] ?
                                                                                        <>
                                                                                            <button
                                                                                                className={luggage_list[luggage.baggageList[0].token] ? "btn btn-selected animate-button" : "btn btn-select"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        luggage_list: {
                                                                                                            ...luggage_list,
                                                                                                            [luggage.baggageList[0].token]: true
                                                                                                        }
                                                                                                    });
                                                                                                    this.addLuggageCart(luggage)
                                                                                                }}>
                                                                                                <span>{t('result.select')}</span>
                                                                                            </button>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <button
                                                                                                className={luggage_list[luggage.baggageList[0].token] ? "btn btn-selected animate-button" : "btn btn-select"}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        luggage_list: {
                                                                                                            ...luggage_list,
                                                                                                            [luggage.baggageList[0].token]: false
                                                                                                        }
                                                                                                    });
                                                                                                    this.removeLuggageCart(luggage, selectedLuggage)
                                                                                                }}>
                                                                                                <span>{luggage_list[luggage.baggageList[0].token] ? t('result.added') : t('result.select')}</span>
                                                                                            </button>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Typography>
                                                        }
                                                    </>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    }

                                    {extras.luggage_info.payload?.included_luggages?.length > 0 &&
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7 20.75H17V5.125C17 4.08984 16.1602 3.25 15.125 3.25H8.875C7.83984 3.25 7 4.08984 7 5.125V20.75ZM9.5 5.75H14.5V7H9.5V5.75ZM22 8.875V18.875C22 19.9102 21.1602 20.75 20.125 20.75H18.25V7H20.125C21.1602 7 22 7.83984 22 8.875ZM5.75 20.75H3.875C2.83984 20.75 2 19.9102 2 18.875V8.875C2 7.83984 2.83984 7 3.875 7H5.75V20.75Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                                {/* TODO esta etiqueta da un warning */}
                                                <Typography>
                                                    <div className="extra-info">
                                                        <p className="title">
                                                            <strong>Equipaje incluido</strong>
                                                        </p>
                                                        <p>1 pieza (56x40x25 cm)</p>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                {extras.luggage_info.payload?.included_luggages?.length > 0 &&
                                                    <>
                                                        <Typography>
                                                            <div className="extras-ida">
                                                                <span className="title">{t('cart.outbound')}</span>
                                                                {included_outbound_luggage?.map((luggage, index) => {
                                                                    return (
                                                                        <div className="bottom-info" key={index}>
                                                                            <div className="extra-text">
                                                                                <p>
                                                                                    <span>{luggage.baggageName} - {t('extras.traveler')} {luggage.passengerId + 1} ({luggage.type === "A" ? t('extras.adult') : t('extras.children')})</span>
                                                                                </p>
                                                                            </div>
                                                                            <div className="btn-wrapper">
                                                                                <p className="btn btn-included">
                                                                                    <svg width="18" height="18"
                                                                                         viewBox="0 0 18 18" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M6.59467 14.3731L1.71966 9.4981C1.42678 9.20522 1.42678 8.73034 1.71966 8.43743L2.7803 7.37677C3.07318 7.08386 3.54808 7.08386 3.84096 7.37677L7.125 10.6608L14.159 3.62677C14.4519 3.33388 14.9268 3.33388 15.2197 3.62677L16.2803 4.68743C16.5732 4.98031 16.5732 5.45518 16.2803 5.7481L7.65533 14.3731C7.36242 14.666 6.88755 14.666 6.59467 14.3731V14.3731Z"
                                                                                            fill="currentColor"/>
                                                                                    </svg>
                                                                                    <span>{t('words.included')}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </Typography>

                                                        <Typography>
                                                            <div className="extras-vuelta">
                                                                <span className="title">{t('cart.inbound')}</span>
                                                                {included_inbound_luggage?.map((luggage, index) => {
                                                                    return (
                                                                        <div className="bottom-info" key={index}>
                                                                            <div className="extra-text">
                                                                                <p>
                                                                                    <span>{luggage.baggageName} - {t('extras.traveler')} {luggage.passengerId + 1} ({luggage.type === "A" ? t('extras.adult') : t('extras.children')})</span>
                                                                                </p>
                                                                            </div>
                                                                            <div className="btn-wrapper">
                                                                                <p className="btn btn-included">
                                                                                    <svg width="18" height="18"
                                                                                         viewBox="0 0 18 18" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M6.59467 14.3731L1.71966 9.4981C1.42678 9.20522 1.42678 8.73034 1.71966 8.43743L2.7803 7.37677C3.07318 7.08386 3.54808 7.08386 3.84096 7.37677L7.125 10.6608L14.159 3.62677C14.4519 3.33388 14.9268 3.33388 15.2197 3.62677L16.2803 4.68743C16.5732 4.98031 16.5732 5.45518 16.2803 5.7481L7.65533 14.3731C7.36242 14.666 6.88755 14.666 6.59467 14.3731V14.3731Z"
                                                                                            fill="currentColor"/>
                                                                                    </svg>
                                                                                    <span>{t('words.included')}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </Typography>
                                                    </>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                </div>
                            }

                            {/* Total de los extras */}
                            {!extras.no_extras &&
                                <div className="extra-footer">
                                    <p className="title">{t('extras.price_extras')}</p>
                                    <p className="price">{extrasFinalPrice.toFixed(2)} {returnCurrency(extrasCurrency)}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    search: state.search.payload,
    selected_flight: state.flights.selected_flight,
    extras: state.extras,
    extrasIsDone: state.extras.extrasIsDone,
    extrasFinalPrice: state.extras.total_price,
    extrasCurrency: state.extras.currency,
    selectedLuggage: state.extras.selected_luggage,
    selected_room: state.cart.products,
    hotel_chains: state.cart.products[0].hotel_chains,
});

export default connect(mapStateToProps, {
    addTransfer,
    addLuggage,
    removeTransfer,
    removeLuggage,
})(withTranslation('common')(Extras));
import React from 'react';
import { useState, useEffect, useRef} from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { isDesktop } from "react-device-detect";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function PopUpExpire() {
    const [open, setOpen] = useState(true);
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }} id="modal-expire">
                    
                    <a onClick={handleClose}><strong>X</strong></a>
                    
                    <div className="cuerpo">
                        <h2 id="parent-modal-title">Ups!! La sesión ha expirado.</h2>
                        <p id="parent-modal-description">
                            Reintente el proceso de compra.
                        </p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
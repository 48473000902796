export const LOAD_CART = 'LOAD_CART';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY';
export const UPDATE_CART = 'UPDATE_CART';
export const REMOVE_CART = 'REMOVE_CART';
export const ADD_PRODUCT_DINGUS = "ADD_PRODUCT_DINGUS";

export const TOGGLE_FLIGHT_CART = 'TOGGLE_FLIGHT_CART';

export const TOGGLE_HOTEL_CART = 'TOGGLE_HOTEL_CART';

export const TOGGLE_EXTRAS_CART = 'TOGGLE_EXTRAS_CART';
import {FETCH_PRODUCTS, FETCH_PRODUCTS_CLUB, UPDATE_PRODUCTS, SET_PROVIDER, ROOMS_FAIL} from './actionTypes';

const initialState = {
    products: [],
    productsClub: [],
    isDone: false,
    provider: "",
    isFail: {
        state: false,
        error: ""
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_PRODUCTS:
            return {
                ...state,
                products: action.payload,
            };
        case FETCH_PRODUCTS_CLUB:
            return {
                ...state,
                productsClub: action.payload,
            };
        case UPDATE_PRODUCTS:
            return {
                ...state,
                popupActive:true,
                isDone: true
            };
        case SET_PROVIDER:
            return{
                ...state,
                provider: action.payload
            }
        case ROOMS_FAIL:
            return {
                ...state,
                isFail: {
                    state: true,
                    error: action.payload
                }
            }
        default:
            return state;
    }
}

import {
    ALERTS_CREATE_DASHBOARD, ALERTS_DELETE_DASHBOARD
} from './types';


export const createDashboardAlert = (msg, msgType) => {
  return {
    type: ALERTS_CREATE_DASHBOARD,
    payload: {
        msg: msg,
        msgType: msgType
    },
  };
};

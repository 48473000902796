import React, {Component} from "react";
import {useSelector} from "react-redux";
import {PdfResponse} from "../PDF/PdfResponse";
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {useTranslation} from "react-i18next";
import {format} from 'date-fns';
import {returnCurrency} from "../../../services/util";
import { GoAlert } from "react-icons/go";

function Success() {

    const pkg = useSelector(state => state.pkgData.temporal_pkg_id);
    const total_price = useSelector(state => state.total.data.totalPrice);
    const old_total_price = useSelector(state => state.total.data.totalPriceWithoutOffer);
    const travelers = useSelector(state => state.travelers.payload);
    const flights = useSelector(state => state.flights.selected_flight);
    const active_flights = useSelector(state => state.flights.isActive);
    const search = useSelector(state => state.search.payload);
    const adults = useSelector(state => state.search.payload.adults);
    const children = useSelector(state => state.search.payload.room_num_childs);
    const room = useSelector(state => state.cart.products[0]);
    const extras = useSelector(state => state.extras);
    const {t} = useTranslation('common');

    //Calculamos la diferencia entre las fechas
    const arrival_date = new Date(search.departure);
    const departure_date = new Date(search.arrival);
    const nights = ((arrival_date - departure_date) / (1000 * 3600 * 24));

    return <div class="search-result confirm-booking-wrapper container">
        <div class="search-result-content confirm-booking-content">
            <p class="search-result-title confirm-booking-header">
                <span className="text-failure"><GoAlert/> Ha ocurrido un error al reservar los vuelos. Por favor ponte en contacto con OceanBooking en info@oceanbooking.com o en el +34 822 13 00 84 (24/h)</span>
                <br/>
                <span className="text-failure-2">Por favor, no intentes repetir la reserva antes de contactar con OceanBooking.</span>
                <span class="booking-id">
                    {t('result.reservation')} nº <strong>{pkg}</strong>
                </span>
            </p>
        </div>
    </div>;
}

export default connect(null, null)((Success));
import {
    ADD_LUGGAGE,
    ADD_TRANSFER,
    REMOVE_LUGGAGE,
    REMOVE_TRANSFER,
    ADD_TRANSFER_INFO,
    ADD_LUGGAGE_INFO,
    EXTRAS_IS_DONE,
    ADD_EXTRAS_TOTAL_PRICE,
    EXTRAS_IS_FAIL,
    NO_EXTRAS
} from './types';
import axios from "axios";
import {calculateTotalPrice} from "./pkgData";
import store from "../store";
import { viewItemListExtrasGTM, addCartGTM } from "../components/GTMComponent/GTMComponent";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;
const API_URL_POST = process.env.REACT_APP_PACKAGE_API_URL_POST;

const timeout = process.env.REACT_APP_TIMEOUT;

export const addLuggage = (luggage) => (dispatch) => {

    const selected_flight = store.getState().flights.selected_flight;
    if (luggage.flight_type === "OUTBOUND") {
        luggage.token_flight = selected_flight[1].token
    } else {
        luggage.token_flight = selected_flight[0].token
    }
    dispatch(addTotalPrice((luggage.pricingDetail !== undefined ? luggage.pricingDetail : 0), "EUR"));
    dispatch({
        type: ADD_LUGGAGE,
        payload: luggage
    })

    addCartGTM();
    dispatch(calculateTotalPrice())
}

export const addTransfer = (transfer) => (dispatch) => {
    dispatch(addTotalPrice(transfer.price, transfer.currency));
    dispatch({
        type: ADD_TRANSFER,
        payload: transfer
    })
    dispatch(calculateTotalPrice())
}

export const removeLuggage = (deleted_luggage) => (dispatch) => {
    const selected_luggage = store.getState().extras.selected_luggage;
    dispatch(addTotalPrice(deleted_luggage.pricingDetail, "€", true));
    dispatch({
        type: REMOVE_LUGGAGE,
        payload: (selected_luggage.filter(luggage => luggage.token !== deleted_luggage.token)[0] === undefined ? [] : selected_luggage.filter(luggage => luggage.token !== deleted_luggage.token))
    })
    dispatch(calculateTotalPrice())
}

export const removeTransfer = (transfer) => (dispatch) => {
    dispatch(addTotalPrice(transfer.price_total, transfer.price_total_currency, true));
    dispatch({
        type: REMOVE_TRANSFER
    })
    dispatch(calculateTotalPrice())
}

const addTaxiInfo = (payload) => {
    return {
        type: ADD_TRANSFER_INFO,
        payload: payload
    }
}

const Transfer = (search) => {
    let arrival_date = +new Date(search.arrival);
    let departure_date = +new Date(search.departure);
    return (Math.abs(departure_date - arrival_date) / (1000 * 3600 * 24));
}

export const getTransfer = (flight, search, token, lang = "en") => (dispatch) => {

    let request = {
        type: "EXTRAS",
        action: "GET_TRANSFER_AVAILABILITY",
        token: token,
        payload: {
            hotel: search.hc,
            airport: "TFS",
            nights: Transfer(search),
            lang: lang
        }
    }
    axios.post(BASEURL + API_URL_POST, request, {timeout: timeout})
        .then(function (response) {
            return dispatch(addTaxiInfo(response.data));
        })
        .catch(function (error) {
            console.log("error: " + error);
            if (error.response.status === 400)
                return dispatch(extrasIsFail(error.response));
            else
                return dispatch(extrasIsFail(error))
        });
}

const addLuggageInfo = (payload, state, inbound_token = "", outbound_token = "") => {
    return {
        type: ADD_LUGGAGE_INFO,
        payload: {
            state: state,
            inbound_token: inbound_token, // Se usan los tokens de los vuelos para comprobar
            outbound_token: outbound_token, // si han cambiado los vuelos y hay que resetear los extras
            payload: payload
        }
    }
}

export const getLuggage = (flight, token) => (dispatch) => {
    let request = {
        type: "EXTRAS",
        action: "GET_LUGGAGES_AVAILABLE",
        token: token,
        payload: {
            tokenOutbound: flight[1].token,
            tokenInbound: flight[0].token,
            sessionToken: flight[1].session_token
        }
    }
    axios.post(BASEURL + API_URL_POST, request, {timeout: 20000})
        .then(function (response) {
            if(response.data.error !== undefined && response.data.error === "No luggages"){
                dispatch(addLuggageInfo(response.data, false));
            }
            else{
                dispatch(addLuggageInfo(response.data, true, flight[0].token, flight[1].token));
            }
            response.data.included_luggages?.map(luggage => {
                dispatch(addLuggage(luggage));
            })
            viewItemListExtrasGTM();
            return dispatch(extrasIsDone(true));
            
        })
        .catch(function (error) {
            console.log("error: " + error);
            if(error.message.includes('timeout')){
                dispatch(extrasIsDone(true))
            }
            return error
        });
}

export const addTotalPrice = (price, currency, subtract = false) => {
    return {type: ADD_EXTRAS_TOTAL_PRICE, payload: {total_price: (subtract ? (price * -1) : price), currency: currency}}
}

export const extrasIsDone = (flag) => {
    return {
        type: EXTRAS_IS_DONE,
        payload: flag
    }
}

export const extrasIsFail = (error) => {
    return {
        type: EXTRAS_IS_FAIL,
        payload: error
    }
}

export const noExtras = (flag) => {
    return{
        type: NO_EXTRAS,
        payload: flag
    }
}
import axios from 'axios';
import {tokenConfig} from './auth';

import {
    GET_RESERVATIONS,
    GET_RESERVATION_DETAIL,
    GET_RESERVATION_ROOM,
    CANCEL_ROOM,
    GET_AVAILABILITY,
    NO_AVAILABILITY,
    SET_ROOM_MODIFICATION,
    TRANSFER_REQUEST
} from './types';
import {removeCart} from "../services/cart/actions";
import {cartTotalDelete} from "../services/total/actions";

const BASEURL = process.env.REACT_APP_BASE_URL_BACKEND;
const PACKAGES_URL = process.env.REACT_APP_PACKAGES_URL;

export const getReservations = () => (dispatch, getState) => {
    let url = '/packages-api/packages/get_packages_user/';

    if (getState().callcenter.callCenterActive) {
        url += '?email=' + getState().callcenter.emailCustomer
    }

    if (getState().auth.user?.rol === "agent") {
        url += '?email=' + getState().auth.user.agency_email
    }

    axios
        .post(BASEURL + url, "", tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: GET_RESERVATIONS,
                payload: res.data,
            });
        })
    //.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getReservationDetail = (idReservation) => (dispatch, getState) => {
    let url = '/api/reservation/' + idReservation + "/";

    if (getState().callcenter.callCenterActive) {
        url += '?email=' + getState().callcenter.emailCustomer
    }

    if (getState().auth.user?.rol === "agent") {
        url += '?email=' + getState().auth.user.agency_email
    }

    axios
        .get(BASEURL + url, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: GET_RESERVATION_DETAIL,
                payload: res.data,
            });
        })
};


export const getRoomReservation = (token) => (dispatch, getState) => {
    let url = BASEURL + PACKAGES_URL;

    axios
        .post(url + 'get_package_data/', {token: token},tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: GET_RESERVATION_ROOM,
                payload: res.data,
            });
        })
};


export const getPDFReservation = (token, reservationID) => {

    const config = {
        responseType: 'blob',
        headers: {
            "Content-Type": "application/json"
        }
    };

    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }

    axios
        .get(BASEURL + '/api/reservation/pdf/' + reservationID + '/', config)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'spring_' + reservationID + '_booking_confirmation.pdf');
            document.body.appendChild(link);
            link.click();
        });
};


export const cancelRoom = (form) => (dispatch, getState) => {
    const body = JSON.stringify({form});

    let url = '/api/reservation/cancelation/';

    if (getState().callcenter.callCenterActive) {
        url += '?email=' + getState().callcenter.emailCustomer
    }

    if (getState().auth.user?.rol === "agent") {
        url += '?email=' + getState().auth.user.agency_email
    }

    axios
        .post(BASEURL + url, body, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: CANCEL_ROOM,
                payload: res.data,
            });
        })
};


export const transferRequest = (form) => (dispatch, getState) => {

    const body = JSON.stringify({
        "transfer":
            {
                "arrival": {
                    "flight": form['inbound_locator'],
                    "airline": form['inbound_company'],
                    "airport_origin": form['inbound_airport'],
                    "airport_destination": "TFS",
                    "date": form['arrival_date'],
                    "time": form['inbound_time']
                },
                "departure": {
                    "flight": form['outbound_locator'],
                    "airline": form['outbound_company'],
                    "airport_origin": "TFS",
                    "airport_destination": form['outbound_airport'],
                    "date": form['departure_date'],
                    "time": form['outbound_time']
                },
                "data": {
                    "booking_num": form['booking_num'],
                    "lang": "en",
                    "extra_info": form['extra_info']
                }
            }
    });

    let url = '/api/transfer/request/';

    if (getState().callcenter.callCenterActive) {
        url += '?email=' + getState().callcenter.emailCustomer
    }

    axios
        .post(BASEURL + url, body, tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: TRANSFER_REQUEST,
                payload: res.data,
            });
        })
};

export const checkAvailability = (products) => (dispatch, getState) => {
    const body = JSON.stringify({products});
    const provider = products[0]?.provider;
    let url = null;

    // TODO: Minimizar los datos del product a enviar

    // Cambiar la URL según el provider
    if (provider === "DINGUS") {
        url = '/dingus-api/availability/check/';
    } else if (provider === "NAV") {
        url = '/api/reservation/check/';
    } else {
        // Borramos el carrito al ser un proveedor desconocido
        dispatch(removeCart());
        dispatch(cartTotalDelete());
        dispatch({
            type: NO_AVAILABILITY,
        });
    }

    if (url !== null) {
        axios
            .post(BASEURL + url, body, tokenConfig(getState))
            .then((res) => {
                dispatch({
                    type: GET_AVAILABILITY,
                    payload: res.data,
                });

                if (res.data?.checkAvailability === false) {
                    // Borramos el carrito por tener productos sin disponibilidad
                    dispatch(removeCart());
                    dispatch(cartTotalDelete());

                    dispatch({
                        type: NO_AVAILABILITY,
                    });
                }
            })
    }
};

/**
 * Activar el modo de modificación de una reserva en redux
 * @param pmskey ID de la reserva
 */
export const setModifyRoom = (pmskey) => ({
    type: SET_ROOM_MODIFICATION,
    payload: parseInt(pmskey)
});


/**
 * Modificar una reserva
 * @param pmskey ID de la reserva a modificar
 * @param newroom Datos de la nueva reserva
 * @returns {Function}
 */
export const modifyReservation = (pmskey, newroom) => (dispatch, getState) => {
    let url = '/api/reservation/room/change/' + pmskey + "/";

    const body = JSON.stringify({pmskey, newroom});

    try {
        return axios.post(BASEURL + url, body, tokenConfig(getState)).then(res => res.data);
    } catch (error) {
        return "KO"
    }
};
import { UPDATE_STEP_PROCESS } from '../actions/types.js'

export default function stepProcess(state = {}, action = {}) {
    switch (action.type) {
        case UPDATE_STEP_PROCESS:
            return {
                ...state,
                id: action.payload.id,
                name: action.payload.name,
                description: action.payload.description
            };
        default:
            return state
    }
}
import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from "react-redux";
import { noInsurance, setInsurance } from "../../../actions/insurances";
import {withTranslation} from "react-i18next";

class Insurances extends Component {

    constructor() {
        super();

        this.clicked = this.clicked.bind(this);
        this.changed = this.changed.bind(this);
    }

    clicked(e) {
        console.log("clicked");
        //this.props.onPassFail();
    }

    changed() {
        console.log("changed");
        //this.props.onPassFail();
    }

    useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }));
    render() {
        const { insurances, setInsourance } = this.props;
        const {t} = this.props;

        return (
            <div className={this.useStyles.root}>
                <dl className="checkout-box insurances">
                    <dt>
                        <h2 className="title">
                            <span>{t('words.dont_worry')}</span>
                        </h2>
                    </dt>

                    {Object.keys(insurances.insurances.insurances).map((insurance_code, index) => {

                        return <>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={"insurance-accordion-" + insurance_code}
                                    onClick={(e) => this.clicked(e)}
                                >
                                    <input type="radio" name={"insurance"}
                                        id={"insurance-input-" + insurance_code} onClick={(e) => this.clicked(e)} />
                                    <Typography className={this.useStyles.heading}>

                                        <p className="inputs-box">
                                            <span
                                                className="insurance-name">{insurances.insurances.insurances[insurance_code].policyName}</span>
                                        </p>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Typography>
                                        <div className="insurance-description">
                                            <div className="description">
                                                <p>
                                                    <span>
                                                        {insurances.insurances.insurances[insurance_code].description}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="price">
                                                <p>{insurances.insurances.insurances[insurance_code].finalPrice}</p>
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    })}
                    <Accordion>
                        <AccordionSummary
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <input type="radio" name="insurance"></input>
                            <Typography className={this.useStyles.heading}>
                                <p className="inputs-box">
                                    <span className="insurance-name">No quiero seguro</span>
                                </p>
                            </Typography>
                        </AccordionSummary>
                    </Accordion>
                </dl>
            </div>


        );
    }
}

const mapStateToProps = state => ({
    insurances: state.insurances
});

export default connect(mapStateToProps, { setInsurance, noInsurance }) (withTranslation('common')(Insurances));